import React, { useEffect } from 'react';
import classNames from 'classnames';
import type { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';

import styles from './Item.module.css';
import { Remove } from './Remove';
import { Handle } from './Handle';
import { Shrink } from './Shrink';
import { Enlarge } from './Enlarge';
import { Move } from './Move';

export interface Props {
  childen: React.ReactNode;
  color?: string;
  disabled?: boolean;
  dragOverlay?: boolean;
  dragging?: boolean;
  fadeIn?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  listeners?: DraggableSyntheticListeners;
  resizeProps?: any;
  showHandle?: boolean;
  sorting?: boolean;
  style?: React.CSSProperties;
  transform?: Transform | null;
  transition?: string | null;
  width?: number;
  wrapperStyle?: React.CSSProperties;

  onRemove?(): void;

  onResize?: ((id: UniqueIdentifier, width?: number) => void);

  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props['transform'];
    transition: Props['transition'];
    childen: Props['childen'];
  }): React.ReactElement;
}

export const Item = React.memo(React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      color,
      dragOverlay,
      dragging,
      disabled,
      fadeIn,
      handle,
      handleProps,
      height,
      index,
      listeners,
      onRemove,
      onResize,
      renderItem,
      resizeProps,
      showHandle,
      sorting,
      style,
      transition,
      transform,
      wrapperStyle,
      width,
      ...props
    },
    ref,
  ) => {
    useEffect(() => {
      if (!dragOverlay) {
        return;
      }

      document.body.style.cursor = 'grabbing';

      return () => {
        document.body.style.cursor = '';
      };
    }, [dragOverlay]);

    return renderItem ? (
      renderItem({
        dragOverlay: Boolean(dragOverlay),
        dragging: Boolean(dragging),
        sorting: Boolean(sorting),
        index,
        fadeIn: Boolean(fadeIn),
        listeners,
        ref,
        style,
        transform,
        transition,
        children,
        width,
      })
    ) : (
      <div
        className={classNames(
          styles.Wrapper,
          fadeIn && styles.fadeIn,
          sorting && styles.sorting,
          dragOverlay && styles.dragOverlay,
        )}
        style={
            {
              ...wrapperStyle,
              gridColumn: width && width === 2 ? 'span 2' : 'span 1',
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(', '),
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              '--color': color,
            } as React.CSSProperties
          }
        ref={ref}
      >
        <div
          className={classNames(
            styles.Item,
            dragging && styles.dragging,
            handle && styles.withHandle,
            dragOverlay && styles.dragOverlay,
            disabled && styles.disabled,
            color && styles.color,
          )}
          style={style}
          data-cypress="draggable-item"
          {...(!handle ? listeners : undefined)}
          {...props}
          tabIndex={!handle ? 0 : undefined}
        >
          {children}
          <span className={styles.Actions}>
            {onRemove ? (
              <Remove className={styles.Remove} onClick={onRemove} />
            ) : null}
            {onResize
              ? width === 2
                ? <Shrink className={styles.Resize} onClick={onResize} />
                : <Enlarge className={styles.Resize} onClick={onResize} />
              : null}
            {handle ? <Move className={styles.Move} {...handleProps} {...listeners} /> : null}
            {showHandle ? <Handle className={styles.Handle} /> : null}
          </span>
        </div>
      </div>
    );
  },
));
