import * as React from 'react';
import { useContext } from 'react';
import { RolesTypes } from '../types/acl';

export interface UserCanProps {
  section: keyof RolesTypes
  type: 'view' | 'actions' | 'exports'
  permission: string | string[]
}

export const isSupportStaff = (email:string) => email.startsWith('amansutti') || email.startsWith('lliusso');

export const UserContext = React.createContext<{
  user: {isStaff: boolean, isSupportStaff: boolean} & RolesTypes | null; // @todo wip, tbd
  setUser:(value: {isStaff: boolean} & RolesTypes | null) => void;
  userCan: (props: UserCanProps) => boolean;
    }>({
      user: null,
      setUser: () => {},
      userCan: () => false,
    });

const ACLWrapper: React.FC<UserCanProps & {children: JSX.Element}> = (props) => {
  const { userCan } = useContext(UserContext);
  const {
    section, permission, type, children,
  } = props;

  const localConfig = localStorage.getItem('test-components-config');
  const debugConfig = localConfig && JSON.parse(localConfig);
  const { debugAcl } = debugConfig || {};

  return userCan({ section, type, permission })
    ? debugAcl // @todo add && user?.isStaff
      ? (
        <section
          className="debug-acl debug-acl-allowed"
          data-title={`${section} - ${type} - ${permission}`}
        >
          {children}
        </section>
      )
      : children
    : debugAcl // @todo add && user?.isStaff
      ? (
        <div
          className="debug-acl debug-acl-not-allowed"
          data-title={`${section} - ${type} - ${permission}`}
        >
          {`No permissions for: ${section} ${type} ${permission}`}
        </div>
      )
      : null;
};

export default ACLWrapper;
