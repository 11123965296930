import { dateFromMilliseconds } from '../stats/utils';
import {
  CustomFormattersType, formatterChoice, UnitsType, UomType,
} from '../utils/unitsFormatter';
import { useCallback } from 'react';

export default function getAxisTitleFormat(measure: string, unit?: string) {
  return `<span style="font-family: var(--font-family-alt); font-weight: bold;">${measure}</span>&nbsp;${unit || '(-)'}`;
}

export const tooltipStyle = {
  borderRadius: 9,
  backgroundColor: 'rgb(255, 255, 255)',
  borderColor: 'rgba(0, 0, 0, 0.6)',
  borderWidth: 1,
  style: {
    fontFamily: 'var(--font-family-alt)',
    color: 'var(--text-secondary)',
  },
};

export const labelsStyle = {
  fontFamily: 'var(--font-family-alt)',
  fill: 'var(--secondary-color)',
  color: 'var(--secondary-color)',
  fontSize: '14px',
  fontWeight: 'bold',
};

export const axisLabelsStyle = {
  fontFamily: 'var(--font-family-main)',
  fill: 'var(--secondary-color)',
  color: 'var(--secondary-color)',
  fontSize: '14px',
};

export type TooltipKeys = {
  key: string;
  value: any;
  uom?: UomType;
  unit?: UnitsType;
  valueType?: 'multi-value' | 'GenericValueType' | 'date' | 'CustomValueType' | 'no-key' | 'only-key' | 'CustomRow';
  customFormatter?: CustomFormattersType;
  customTranslation?: string;
};

export function tooltipFormatter(tooltipInfo: TooltipKeys[]) {
  return tooltipInfo.map((row) => {
    if (row.value !== undefined || row.value !== null) {
      switch (row.valueType) {
        case 'multi-value':
          return `${row.key}: <span style="font-family: var(--font-family-alt); font-weight: 600;">${row.value.join(', ')}</span>`;

        case 'date':
          return `${row.key}: <span style="font-family: var(--font-family-alt); font-weight: 600;">${dateFromMilliseconds(row.value)}</span>`;

        case 'GenericValueType':
          return `${row.key}: <span style="font-family: var(--font-family-alt); font-weight: 600;">${formatterChoice(row.unit)(row.uom, row.value) || '-'}</span> ${row.uom || ''}`;

        case 'CustomValueType':
          if (row.key === '') {
            return '<span></span>';
          }
          return `${row.key}: <span style="font-family: var(--font-family-alt); font-weight: 600;">${formatterChoice(row.unit, row.customFormatter)(row.uom, row.value) || '-'}</span> ${row.uom || ''}`;

        case 'no-key':
          return `<span style="font-family: var(--font-family-alt); font-weight: 600;">${row.value}</span> ${row.uom}`;

        case 'only-key':
          return `${row.key}`;

        case 'CustomRow':
          return `${row.customTranslation || ''}`;

        default:
          return `${row.key}:<span style="font-family: var(--font-family-alt); font-weight: 600;"> ${row.value}</span>`;
      }
    }
  }).join('</br>');
}

export const approximatedHighSeries = [
  'cardio',
  'hdop',
  'satprsum',
  'nsat',
  'avg_anchors',
  'avg_residue',
  'bad_fixes_perchdop',
  'battery',
  'Vbatt_mV',
];

export const serieApproximation = (serie) => {
  if (approximatedHighSeries.includes(serie)) {
    return 'high';
  }

  return function (data: Array<number | null>) {
    if (!data) {
      return null;
    }

    if (data.find((d) => d === null)) {
      // eslint-disable-next-line no-param-reassign
      data = data.filter(Number);
    }
    if (!data.length) {
      return null;
    }

    // eslint-disable-next-line prefer-spread
    const max = Math.max.apply(Math, data);
    // eslint-disable-next-line prefer-spread
    const min = Math.min.apply(Math, data);
    return Math.abs(max) >= Math.abs(min)
      ? max
      : min;
  };
}
