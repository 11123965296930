import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { UserContext } from '../ACLWrapper';

function TestUserContext() {
  const userContext = React.useContext(UserContext);

  return (
    <>
      <Typography variant="h5" gutterBottom>UserContext</Typography>
      <Paper id="test-user-context">
        <Box p={2}>
          <h4>User permissions</h4>
          {JSON.stringify(userContext.user)}

          <h4 style={{ marginTop: '2rem' }}>
            <code>userContext.userCan</code>
            {' '}
            example usage
          </h4>
          <table style={{ maxWidth: '100%', width: '600px' }}>
            <thead>
              <tr style={{ textAlign: 'left' }}>
                <th>Session</th>
                <th>Type</th>
                <th>Permission</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>templates</td>
                <td>actions</td>
                <td>create_template</td>
                <td>
                  {JSON.stringify(userContext.userCan({
                    section: 'templates',
                    type: 'actions',
                    permission: 'create_template',
                  }))}
                </td>
              </tr>
              <tr>
                <td>sessions</td>
                <td>actions</td>
                <td>create_session</td>
                <td>
                  {JSON.stringify(userContext.userCan({
                    section: 'sessions',
                    type: 'actions',
                    permission: 'create_session',
                  }))}
                </td>
              </tr>
              <tr>
                <td>radar</td>
                <td>exports</td>
                <td>pdf</td>
                <td>
                  {JSON.stringify(userContext.userCan({
                    section: 'radar',
                    type: 'exports',
                    permission: 'pdf',
                  }))}
                </td>
              </tr>
              <tr>
                <td>radar</td>
                <td>exports</td>
                <td>invalidKey</td>
                <td>
                  {JSON.stringify(userContext.userCan({
                    section: 'radar',
                    type: 'exports',
                    permission: 'invalidKey',
                  }))}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestUserContext;
