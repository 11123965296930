import React from 'react';

/**
 * Componente per effettuare test in fase di sviluppo su una rotta `/test-components/current`
 *
 * @description NON COMMITTARE le modifiche. Usarlo solo per testare componenti singolarmente.
 *
 * @constructor
 */
const TestComponentsCurrent = () => <div>Dummy component for atomic tests</div>;

export default TestComponentsCurrent;
