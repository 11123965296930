import React, { useState, useEffect, useCallback } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import {
  Box, Paper, Tab, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQueryParams } from '../utils/hooks';
import { getQueryParams } from '../Table';

type PropsIn = {
  id?: string,
  tabs: {
    key: string,
    label: string,
    icon?: React.ReactElement,
    component: React.ReactElement
  }[]
  onTabChange?: (t: string) => void
  layout?: {
    hideMarginBottom?: boolean
  }
  firstTabSelected?: number;
}

const useStyles = makeStyles({
  root: {
    '& .MuiTab-textColorPrimary.Mui-selected': {
      pointerEvents: 'none',
    },
  },
});

function TabsComponent(props: PropsIn) {
  const {
    id, onTabChange, tabs, layout, firstTabSelected,
  } = props;
  const [queryParams, setQueryParams] = useQueryParams();
  const tabsKey = `tab${id ? `-${id}` : ''}`;

  const [selectedTab, setSelectedTab] = useState<string | null>(tabs[0]?.key || null);

  useEffect(() => {
    const initialQueryParams = getQueryParams() || { };
    if (!initialQueryParams[tabsKey]) {
      if (!firstTabSelected) {
        initialQueryParams[tabsKey] = tabs[0]?.key;
      } else {
        initialQueryParams[tabsKey] = tabs[firstTabSelected]?.key;
      }
    }
    setQueryParams(initialQueryParams, { replace: true });
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<{}>, newTab: string) => {
    setSelectedTab(newTab);

    const newQueryParams = { }; // reset other filters on tab change => different content
    newQueryParams[tabsKey] = newTab;
    setQueryParams({ ...queryParams, ...newQueryParams });

    if (onTabChange) {
      onTabChange(newTab);
    }
  }, [queryParams]);

  useEffect(() => {
    if (queryParams[tabsKey]) {
      setSelectedTab(queryParams[tabsKey] || null);
    }
  }, [queryParams[tabsKey]]);

  const tabComponents = tabs.map((tab, index) => (
    <Tab
      key={tab.key}
      style={{
        borderTopLeftRadius: index === 0 ? '12px' : 0,
        borderTopRightRadius: index === tabs.length - 1 ? '12px' : 0,
      }}
      label={(
        <Typography
          variant="button"
        >
          {tab.label}
        </Typography>
      )}
      value={tab.key}
      icon={tab.icon}
    />
  ));

  const classes = useStyles();

  return (
    <Paper className="tab-container tab-container__generic">
      <AppBar
        style={{
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',

          zIndex: '0',
        }}
        elevation={0}
        position="relative"
      >
        <Tabs
          className={classes.root}
          value={selectedTab}
          indicatorColor="secondary"
          textColor="primary"
          onChange={handleChange}
          style={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}
        >
          {tabComponents}
        </Tabs>
      </AppBar>

      <Box
        p={2}
        key={selectedTab}
        style={{
          paddingTop: layout?.hideMarginBottom ? '' : '2.5rem',
        }}
      >
        {selectedTab && tabs.find((t) => t.key === selectedTab)?.component}
      </Box>
    </Paper>
  );
}

export const TabsWidget = (props: PropsIn) => (
  <Paper className="tab-container tab-container__no-bg" elevation={0}>
    <TabsComponent
      {...props}
    />
  </Paper>
);

export default TabsComponent;
