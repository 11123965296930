import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryCached } from 'components/utils/graphql';
import EditableIcon from '@images/icons/svg/editable.svg';
import { PropsInAutocomplete, PropsInCommon, SelectField } from './SelectField';
import { TeamSessionTagsTypeRes, TeamSessionTagsTypeVars, TSTAG } from '../../query/tags';
import { CACHE_AND_NETWORK } from '../../lib/cache';

interface ITagsField {
  filters: {
    objIds?: number[]
    hasTeamsession?: boolean
    teamId: string
    first?: number
    skip?: number
    sort?: string
  }
  value: {
    id: string
    name: string
  }[]
}

type PropsIn = ITagsField &
  Pick<PropsInCommon, 'disabled' | 'required'> &
  Pick<PropsInAutocomplete, 'onChange'> &
  {
    loading?: boolean,
    naked?: boolean,
    readonly?: boolean,
    isEditable?: boolean,
  }

const TagsField = (props: PropsIn) => {
  const { t } = useTranslation();
  const {
    disabled, filters, loading: isLoading, naked, required, value, readonly, isEditable,
    onChange,
  } = props;

  const { loading, data } = useQueryCached<{ res: TeamSessionTagsTypeRes }, TeamSessionTagsTypeVars>(TSTAG, {
    variables: filters,
    ...CACHE_AND_NETWORK,
  });

  const [showEditInfo, setShowEditInfo] = useState(true);

  return (
    <div style={{ position: 'relative' }} onFocus={() => setShowEditInfo(false)} onBlur={() => setShowEditInfo(true)}>
      <SelectField
        id="labels-tags"
        label={t('table.tags', 'tags')}
        onChange={(v) => onChange(v)}
        canAddNewItems
        multiple
        fullWidth
        autocomplete
        naked={naked}
        required={required}
        readonly={readonly}
        disabled={disabled}
        options={data?.res?.content?.map((tag) => ({
          id: tag.id,
          value: tag.name || '',
        })) || []}
        value={value.map((tag) => ({ id: tag.id, value: tag.name }))}
      />
      { showEditInfo && isEditable && !readonly && !disabled
        ? (
          <div style={{ position: 'absolute', top: '13px', right: '35px' }}>
            <EditableIcon />
          </div>
        )
        : null}
    </div>
  );
};

export default TagsField;
