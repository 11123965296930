import React, { useEffect, useLayoutEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import { Options } from 'highcharts';
import ReactDOMServer from 'react-dom/server';
import { highcharts } from '../../App';
import { getPrimaryColorGradient } from '../utils/colors';
import getAxisTitleFormat, { tooltipStyle, TooltipKeys, tooltipFormatter } from './utils';

export type Axis = {
  name: string,
  uom: string,
}

type PropsIn = {
  xLabel?: Axis,
  xIcon?: React.ReactElement,
  yLabel?: Axis,
  yIcon?: React.ReactElement,
  name?: string,
  data: any,
}

const VariWideChart = (props: PropsIn) => {
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Options>({});
  const {
    xLabel, xIcon, yLabel, yIcon, data,
  } = props;

  useEffect(() => {
    setChartOptions({
      credits: {
        enabled: false,
      },
      chart: {
        type: 'variwide',
        spacingTop: 25,
        height: 580,
      },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      tooltip: {
        ...tooltipStyle,
        shared: false,
        split: false,
        useHTML: true,
        headerFormat: '',
        formatter() {
          const { point } = this;
          let tooltipObject : TooltipKeys[];
          if (point?.options?.nameY && point?.options?.nameZ) {
            tooltipObject = [
              {
                key: point?.options?.nameY,
                value: point.y,
                uom: yLabel?.uom,
                valueType: 'CustomValueType',
              },
              {
                key: point?.options?.nameZ,
                value: point.z,
                uom: xLabel?.uom,
                valueType: 'CustomValueType',
              },
            ];
          } else {
            tooltipObject = [
              {
                key: point?.options?.nameY,
                value: point.y,
                uom: yLabel?.uom,
                valueType: 'CustomValueType',
              },
            ];
          }
          return tooltipFormatter(tooltipObject);
        },
      },
      xAxis: {
        type: 'category',
        labels: {
          enabled: true,
          formatter() {
            if (this.value === 0) {
              return Math.round(data[0].z);
            }
            if (this.value === 1) {
              return Math.round(data[1].z);
            }
            return `${this.value}`;
          },
          align: 'center',
          style: {
            fontWeight: '600',
          },
        },
        title: xLabel && {
          text: `${getAxisTitleFormat(xLabel.name, xLabel.uom)}${
            xIcon ? ReactDOMServer.renderToString(xIcon) : ''}`,
          useHTML: true,
          style: {
            fontSize: 'var(--labels-font-size)',
            fontWeight: '700',
            fontFamily: 'var(--font-family-alt)',
            justifyContent: 'center',
          },
        },
        scrollbar: {
          enabled: false,
          showFull: false,
        },
      },
      yAxis: {
        title: yLabel && {
          text: `${getAxisTitleFormat(yLabel.name, yLabel.uom)}${
            yIcon ? ReactDOMServer.renderToString(yIcon) : ''}`,
          useHTML: true,
          style: {
            fontSize: 'var(--labels-font-size)',
            fontWeight: '700',
            fontFamily: 'var(--font-family-alt)',
          },
        },
      },
      series: [{
        name: '',
        data,
        type: 'variwide',
        colorByPoint: true,
        colors: getPrimaryColorGradient(2).reverse(),
        pointRange: 0,
        /* dataLabels: {
          enabled: true,
          crop: false,
          overflow: 'none',
          format: `<span><span>{point.y}</span> ${yLabel?.units}<br>`
                            + `<span>{point.z}</span> ${xLabel?.units}</span>`,
          style: {
            fontSize: '18px',
            lineHeight: '20px',
            textAlign: 'center',
            color: 'var(--secondary-color)',
          },
        }, */
      }],
      legend: {
        enabled: false,
      },
      title: {
        text: '',
      },
    });
  }, []);

  useLayoutEffect(() => {
    setChartOptions({
      ...chartOptions,
      ...{
        series: [{
          name: '',
          data,
          type: 'variwide',
          colorByPoint: true,
          colors: getPrimaryColorGradient(2).reverse(),
          pointRange: 0,
          /* dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            format: `<span><span>{point.y}</span> ${yLabel?.units}<br>`
                              + `<span>{point.z}</span> ${xLabel?.units}</span>`,
            style: {
              fontSize: '18px',
              lineHeight: '20px',
              textAlign: 'center',
              color: 'var(--secondary-color)',
            },
          }, */
        }],
      },
    });
  }, [data]);

  return (
    <HighchartsReact
      highcharts={highcharts}
      options={chartOptions}
      ref={chartComponentRef}
    />
  );
};

export { VariWideChart };
