import { AthleteType } from './athlete'
import { ClubType } from './club'
import { TrackType } from './track'

export type DeviceType = {
  id: string,
  createdOn?: Date,
  updatedOn?: Date,
  deviceId: string,
  uniqueId: string,
  serialId: string,
  club: ClubType,
  athlete?: AthleteType,
  hasAccelerometer: boolean,
  hasMagnetometer: boolean,
  hasGyroscope: boolean,
  memorySize?: number,
  firmwareVersion: string,
  goalkeeper: boolean,
  notes: string,
  trackSet: TrackType[],
  active?: boolean,
}
export const getDeviceFragmentName = () => {
  return 'DeviceTypeFields'
}
export const getDeviceFragment = () => {
  return `
    fragment ${getDeviceFragmentName()} on DeviceType {
      id
      createdOn
      updatedOn
      deviceId
      serialId
    }
  `
}

