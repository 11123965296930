import { gql } from '@apollo/client';
import { getLimitedTeamFragment, getLimitedTeamFragmentName, getTeamFragment, getTeamFragmentName } from 'models/team';
import { ExportTemplateOrderingDirectionChoices, ExportTemplateSportChoices, TemplateType } from 'models/template';

export type CreateTemplateVars = {
  name: string;
  sport?: string;
  teamId?: string;
  visibility: string;
  pdfOrientation: string;
  highlights: boolean;
  roleGrouping: boolean;
  kpi: any[];
  ordering: string;
  orderingDirection: string;
};
export type CreateTemplateRes = {
  res: TemplateType;
};

export const SET_USER_TEAM_TEMPLATE = gql`
  ${getLimitedTeamFragment(['with_club'])}
  mutation SetUserTemplate($templateId: ID!) {
    res: setUserTemplate(templateId: $templateId) {
      id
      isSuperuser
      firstName
      lastName
      email
      isStaff
      isActive
      lastTeam {
        ...${getLimitedTeamFragmentName(['with_club'])}
      }
      preferredLanguage
      userunits {
        speed
        power
        energy
        percentage
        temperature
      }
      userroleSet{
        id
        athlete {
          id
          name
        }
        team {
          id
          name
        }
        roleType
      }
      template {
        id
        kpi{
          name, unit, uom, value
        }
      }
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation createTemplate(
    $name: String!
    $sport: ExportTemplateSportChoices
    $teamId: ID!
    $visibility: ExportTemplateVisibilityChoices
    $pdfOrientation: ExportTemplatePdfOrientationChoices
    $highlights: Boolean
    $roleGrouping: Boolean
    $kpi: [String]
    $ordering: String
    $orderingDirection: String
  ) {
    res: createTemplate(
      name: $name
      sport: $sport
      teamId: $teamId
      visibility: $visibility
      pdfOrientation: $pdfOrientation
      highlights: $highlights
      roleGrouping: $roleGrouping
      kpi: $kpi
      ordering: $ordering
      orderingDirection: $orderingDirection
    ) {
      name
      sport
      visibility
      pdfOrientation
      highlights
      roleGrouping
      kpi {
        name
      }
      ordering
      orderingDirection
    }
  }
`;

export type DeleteTemplateVars = {
  templateId?: string;
};
export type DeleteTemplateRes = {
  data: {
    deleteTemplate: boolean;
  };
};
export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($templateId: ID!) {
    deleteTemplate(templateId: $templateId)
  }
`;

export type UpdateTemplateVars = {
  name: string;
  templateId: number;
  visibility: string;
  pdfOrientation: string;
  highlights: boolean;
  roleGrouping: boolean;
  kpi: any[];
  ordering: string;
  orderingDirection: ExportTemplateOrderingDirectionChoices;
};
export type UpdateTemplateRes = {
  res: TemplateType;
};

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate(
    $teamId: ID!
    $templateId: ID!
    $name: String!
    $visibility: ExportTemplateVisibilityChoices
    $pdfOrientation: ExportTemplatePdfOrientationChoices
    $highlights: Boolean
    $roleGrouping: Boolean
    $kpi: [String]
    $ordering: String
    $orderingDirection: String
  ) {
    res: updateTemplate(
      teamId: $teamId
      templateId: $templateId
      name: $name
      visibility: $visibility
      pdfOrientation: $pdfOrientation
      highlights: $highlights
      roleGrouping: $roleGrouping
      kpi: $kpi
      ordering: $ordering
      orderingDirection: $orderingDirection
    ) {
      name
      visibility
      pdfOrientation
      highlights
      roleGrouping
      kpi {
        name
      }
      ordering
      orderingDirection
    }
  }
`;
