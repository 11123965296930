import {
  ReportSeriesVars,
  ReportSeriesRes,
  REPORT_SERIES,
} from 'query/track';
import { useTranslation } from 'react-i18next';
import { useQueryCached } from './graphql';
import { messageToConsole } from './debug';

export function kpiLabelExtractor(teamId: string, key: string, which?: 'shorLabel'|'label') {
  const kpi = getKPIListByTeam(teamId)?.filter((k: object) => {
    if (k.key === key) {
      return true;
    }
    return false;
  });
  return !kpi || kpi?.length === 0 ? null : kpi[0][which || 'shortLabel'];
}

export const camelCaser = (group?: string, key?: string) => {
  if (!group && key && process.env.NODE_ENV !== 'production') {
    messageToConsole(`[i18n] check - group: ${group} - key: ${key}`, 'info');
  }
  const groupArray = group?.split(' ') || [];
  const newGroup = groupArray.length > 1
    ? groupArray.map((el, i) => {
      if (i !== 0) {
        return el.charAt(0).toUpperCase() + el.substring(1).toLowerCase();
      }
      return el;
    }).join('') : group;

  if (key) {
    const keyArray = key.split('_');
    const newKey = keyArray.length > 1
      ? keyArray.map((el, i) => {
        if (i !== 0) {
          return el.charAt(0)
            .toUpperCase() + el.substring(1)
            .toLowerCase();
        }
        return el;
      }).join('') : key;

    return `${newGroup}.${newKey}`;
  }
  return `${newGroup}`;
};

export function getKPIListByTeam(team: string) {
  const { loading, data } = useQueryCached<ReportSeriesRes, ReportSeriesVars>(
    REPORT_SERIES,
    {
      variables: {
        teamId: team,
        excludeFields: ['teamsessions_count', 'tot_mechanical_events'],
      },
    },
  );
  const { t } = useTranslation();

  return loading ? [] : data?.reportSeries?.map((el) => (
    {
      ...el,
      key: el.key,
      longLabel: t(`kpi2.${camelCaser(el.group, el.key)}.longLabel`, el.key.split('_').join(' ')),
      label: t(`kpi2.${camelCaser(el.group, el.key)}.label`, el.key.split('_').join(' ')),
      shortLabel: t(`kpi2.${camelCaser(el.group, el.key)}.shortLabel`, el.key.split('_').join(' ')),
      group: t(`kpi2.${camelCaser(el.group)}.title`, el.group),
    }
  ));
}
