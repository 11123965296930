import React from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { IconWrapper } from 'lib/icons';
import TitleView from 'components/layout/TitleView';
import WindRoseChart from '../charts/WindRoseChart';

interface PropsIn {
  data: any // @todo fix
}

export default function DistanceByBodyOrientation(props: PropsIn) {
  const { data } = props;
  const { t } = useTranslation();

  const chartData = data?.res?.imu && {
    front: data?.res?.imu?.distanceFront,
    right: data?.res?.imu?.distanceRight,
    back: data?.res?.imu?.distanceBack,
    left: data?.res?.imu?.distanceLeft,
  };

  return (
    (chartData && (
    <Box className="chart-widget">
      <Box className="title">
        <TitleView
          title={t('track.widgets.mecKPI.labels.distanceByBodyOrientation', 'distance by body orientation')}
          prefixIcon={<IconWrapper name="distByBodyOrientation" size="medium" color="primary" />}
          align="left"
        />
      </Box>
      <WindRoseChart
        data={chartData}
      />
    </Box>
    ))
    || null
  );
}
