import React, { useContext, useMemo, useRef } from 'react';
import { CursorContext } from '../track/TrackChartContainer';
import { LatLngExpression, Marker as LeafletMarker } from 'leaflet';
import { Marker } from 'react-leaflet';


const svgIconMarkerNoHeading = L.divIcon({
  html: `
  <svg height='34' width='34'>
  <circle cx='17' cy='17' r='5' stroke='white' strokeWidth='2' fill='#ffd600'/>
</svg> 
  `,
  className: '',
  iconSize: [34, 34],
  iconAnchor: [17, 17],
});

const svgIconMarker = L.divIcon({
  html: `<svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.7715 22.0564C16.5641 23.8395 20.2734 23.0211 22.0564 20.2285C23.8395 17.4359 23.0211 13.7266 20.2285 11.9436C17.436 10.1605 13.7267 10.9789 11.9436 13.7715C10.1605 16.5641 10.9789 20.2734 13.7715 22.0564Z" fill="#FFD600"/>
  <path d="M34 8.33333C34 7.57893 32.9474 7.5023 32.6467 8.19419C30.0563 14.1548 24.0259 18.3333 17 18.3333C9.97412 18.3333 3.94371 14.1548 1.35331 8.19419C1.05262 7.5023 0 7.57893 0 8.33333C0 17.5381 7.61116 25 17 25C26.3888 25 34 17.5381 34 8.33333Z" fill="#FFD600"/>
  </svg>  
  `,
  className: '',
  iconSize: [34, 30],
  iconAnchor: [17, 21],
});

interface Props {
  geo: number,
  timestamps: number[];
  path: {[k: string]: [number, number]};
  heading: {[k: string]: number};
}

const MapChartCursor = (props: Props) => {
  const {
    timestamps,
    path,
    geo,
    heading,
  } = props;
  const { cursor } = useContext(CursorContext);
  const markerRef = useRef<LeafletMarker<any>>();

  const markerPosition: LatLngExpression | undefined = useMemo(() => {
    if (!cursor) {
      return;
    }

    const cursorPoint = timestamps.includes(Math.abs(cursor))
       ? cursor
       : timestamps.find((t) => t >= cursor && Math.abs(t - cursor) < 1000);

     if (!cursorPoint) {
       return;
     }

    if (markerRef.current && heading[cursorPoint]) {
      markerRef.current.options.rotationAngle = heading[cursorPoint] + geo;
    }

    return path[cursorPoint] || undefined;
  }, [cursor, timestamps]);

  return markerPosition && (
      <Marker
        position={markerPosition}
        ref={markerRef}
        icon={markerRef.current?.options?.rotationAngle
          ? svgIconMarker
          : svgIconMarkerNoHeading}
      />
  ) || null;
}

export default MapChartCursor;
