import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import TitleView from 'components/layout/TitleView';
import { StackedColumnChart } from '../charts/StackedColumnChart';
import { IconWrapper } from '../../lib/icons';
import { eventsColorByName } from '../charts/colors';

/*
@TODO
- DRY: create a common chart component
 */

interface PropsIn {
  data: any // @todo fix
}

export default function ImpactsByIntensity(props: PropsIn) {
  const { data } = props;
  const { t } = useTranslation();

  return (
    (data?.res?.imu?.eventsImpacts && (
      <Box className="chart-widget">
        <TitleView
          title={t('track.widgets.mecKPI.labels.impactsByIntensity', 'impacts by intensity')}
          prefixIcon={<IconWrapper name="impacts" size="medium" color="primary" />}
          align="left"
        />
        <StackedColumnChart
          data={data?.res?.imu}
          colors={eventsColorByName}
          categories={['impacts']}
          validKeys={['eventsImpacts']}
        />
      </Box>
    ))
    || null
  );
}
