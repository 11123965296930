import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Badge, IconButton, IconButtonProps } from '@material-ui/core';
import React, { useState, useEffect, useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const buttonBgColors = {
  default: '#FFFFFF',
  inherit: 'inherit',
  primary: 'var(--primary-color)',
  secondary: 'var(--secondary-color)',
  danger: '#d50032',
} as const;

const buttonFgColors = {
  default: 'var(--secondary-color)',
  inherit: 'inherit',
  primary: '#ffffff',
  secondary: '#ffffff',
  danger: '#ffffff',
} as const;

const buttonFgHoverColors = {
  default: 'var(--secondary-color)',
  inherit: 'inherit',
  primary: 'var(--secondary-color)',
  secondary: 'var(--secondary-color)',
  danger: 'var(--secondary-color)',
} as const;

const buttonBgHoverColors = {
  default: '#BFFFF3',
  inherit: '#BFFFF3',
  primary: '#BFFFF3',
  secondary: '#BFFFF3',
  danger: '#FFBFC6',
} as const;

type ColorKeys = keyof typeof buttonFgHoverColors;

const StyledButton = withStyles(() => ({
  root: {
    border: '1px solid transparent',
    borderRadius: '18px',
    boxSizing: 'border-box',
    padding: '0.5rem 1rem',
    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    lineHeight: '1.333',
    letterSpacing: '0.4px',
    color: (props: {colorkey?: ColorKeys}) => buttonFgColors[props.colorkey || 'default'],
    backgroundColor: (props: {colorkey?: ColorKeys}) => buttonBgColors[props.colorkey || 'default'],
    '&&:hover': {
      backgroundColor: (props: {colorkey?: ColorKeys}) => buttonBgHoverColors[props.colorkey || 'default'],
      color: (props: {colorkey?: ColorKeys, hoverAll:boolean}) => (props.hoverAll ? 'var(--primary-gradient-2)' : buttonFgHoverColors[props.colorkey || '#00DDB5']),
    },
  },
  disabled: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}))(Button);

const StyledIconButton = withStyles(() => ({
  root: {
    background: '#FFFFFF',
    border: '1px solid transparent',
    boxSizing: 'border-box',
    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    width: 36,
    height: 36,
    fontSize: '1.125rem',
    '&&:hover': {
      backgroundColor: (props: {customHoverBgColor}) => props.customHoverBgColor ? props.customHoverBgColor : '#BFFFF3',
    },
    '& img': {
      width: 20,
      height: 20,
      maxWidth: 20,
    },
  },
  disabled: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}))(IconButton);

const useStyles = makeStyles((theme) => createStyles({
  flat: {
    boxShadow: 'none',
    marginLeft: 0,
    '&&:hover': {
      background: 'transparent',
    },
  },
  active: {
    color: theme.palette.primary.dark,
  },
  small: {
    height: 30,
    fontSize: '0.875rem',
    '& svg': {
      transform: 'scale(0.8)',
    },
  },
  medium: {
    height: 36,
    fontSize: '1.125rem',
  },
  large: {
    height: 42,
    fontSize: '1.375rem',
  },

  rotateIcon: {
    '& svg': {
      animation: 'icon-spin 4s linear infinite',
    },
  },
  validation: {
    // @ts-ignore
    color: theme.palette.primary.contrast,
  },
  collapseOnMobile: {
    [theme.breakpoints.down('md')]: {
      minWidth: '36px',
      width: '36px',
      '& .MuiButton-label': {
        textIndent: '-99rem',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
      '& .MuiButton-endIcon': {
        margin: 0,
      },
    },
  },
  collapseOnSmallDesktop: {
    [theme.breakpoints.down('lg')]: {
      '&:not(.force-large)': {
        minWidth: '21px',
        width: '21px',
        '& .MuiButton-label': {
          textIndent: '-99rem',
        },
        '& .MuiButton-startIcon': {
          margin: 0,
        },
        '& .MuiButton-endIcon': {
          margin: 0,
        },
      },
    },
  },
}));

interface BaseButtonProps {
  badge?: number | string,
  badgeColor?: 'error' | 'primary' | 'secondary' | 'default',
  children?: React.ReactNode,
  collapseOnMobile?: boolean,
  collapseOnSmallDesktop?: boolean,
  color?: ColorKeys | 'danger',
  flat?: boolean,
  isIconButton?: boolean,
  active?: boolean,
  marginRight?: boolean,
  noMargins?: boolean,
  rotate?: boolean,
  isValidation?: boolean,
  buttonSize?: 'sm' | 'md' | 'lg',
  tooltipText?: string,
  expiredWarning?: boolean;
  hoverAll?:boolean;
}

type PropsIn = Omit<ButtonProps, keyof BaseButtonProps> & Omit<IconButtonProps, keyof BaseButtonProps> & BaseButtonProps

const TooltipWrapperChild = React.forwardRef<HTMLDivElement, {children: JSX.Element, marginRight?: boolean, noMargins?: boolean, center?: boolean}>((props, ref) => {
  const {
    noMargins, marginRight, center, ...elementProps
  } = props;

  return (
    <div
      {...elementProps}
      style={{
        marginLeft: !noMargins ? '1rem' : '0',
        marginRight: marginRight && !noMargins ? '1rem' : '0',
        textAlign: center ? 'center' : undefined,
      }}
      ref={ref}
    >
      {props.children}
    </div>
  );
});
export const WithTooltipWrapper = ({
  text, children, marginRight, noMargins, center,
}: {
  text: string | undefined,
  children: JSX.Element,
  marginRight?: boolean,
  noMargins?: boolean
  center?: boolean
}) => (text
  ? <Tooltip arrow title={text}><TooltipWrapperChild marginRight={marginRight} noMargins={noMargins} center={center}>{children}</TooltipWrapperChild></Tooltip>
  : <TooltipWrapperChild marginRight={marginRight} noMargins={noMargins} center={center}>{children}</TooltipWrapperChild>);

/**
 * Base Button
 *
 * @description This is a wrapper to the default UI kit (MUI 4)
 * Params are from ButtonProps & IconButtonProps.
 * These additional params are available
 *
 * @param {number} [badge] - a number to show in the corner badge
 * @param {('error'|'primary'|'secondary'|'default')} [badgeColor] - badge color
 * @param {React.ReactNode} [children] - children component
 * @param {boolean} [flat] - if true the button is rendered flat, without shadows
 * @param {boolean} [collapseOnMobile] - if true the button becames an icon button on mobile
 * @param {boolean} [collapseOnSmallDesktop] - if true the button becames an icon button on small desktop
 * @param {boolean} [isIconButton] - if true is an icon button
 * @param {boolean} [active] - if true it uses primary color
 * @param {boolean} [marginRight] - if true there is a right margin
 * @param {boolean} [rotate] - if true it rotates (loop)
 *
 * @example
 *       <BaseButton
 *         onClick={handleClick}
 *         startIcon={<DeleteIcon/>}
 *         disabled={false}
 *         isIconButton={true}
 *         active={true}
 *         badge={3}
 *       >Trash bin</BaseButton>
 */

const BaseButton = React.forwardRef((props: PropsIn, ref) => {
  const {
    badge,
    badgeColor,
    children,
    className,
    collapseOnMobile,
    collapseOnSmallDesktop,
    color,
    flat,
    isIconButton,
    active,
    rotate,
    startIcon,
    marginRight,
    noMargins,
    isValidation,
    buttonSize,
    tooltipText,
    expiredWarning,
    hoverAll,
    ...rest
  } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  const tooltip = useMemo(() => {
    const shouldDisplayTooltip = (
      isIconButton || (collapseOnMobile && windowWidth < 900) || (collapseOnSmallDesktop && windowWidth < 1600))
      || (tooltipText && !collapseOnSmallDesktop && !collapseOnMobile);

    return shouldDisplayTooltip ? tooltipText : undefined;
  }, [windowWidth, tooltipText, isIconButton, collapseOnMobile, collapseOnSmallDesktop]);

  const classes = useStyles({
    color,
  });

  const buttonClasses = `${className || ''} ${active ? classes.active : ''} ${flat ? classes.flat : ''}`
    + `${isValidation ? classes.validation : ''}`
    + ` ${
      buttonSize === 'sm'
        ? classes.small
        : buttonSize === 'lg'
          ? classes.large
          : classes.medium || ''
    }`;
  const mobileClasses = `${collapseOnMobile ? classes.collapseOnMobile : ''} `;
  const smallDesktopClasses = `${collapseOnSmallDesktop ? classes.collapseOnSmallDesktop : ''} `;

  const badgeClasses = `${expiredWarning ? 'badge-expired-teams' : ''}`;

  return (
    <Badge
      invisible={!badge}
      badgeContent={badge}
      color={badgeColor || 'secondary'}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      overlap="rectangular"
      className={badgeClasses}
    >
      <WithTooltipWrapper
        text={tooltip}
        noMargins={noMargins}
        marginRight={marginRight}
      >
        {
          isIconButton
            ? (
              <StyledIconButton
                innerRef={ref}
                // @ts-ignore
                color={color}
                {...rest}
                className={`${rotate ? classes.rotateIcon : ''} ${buttonClasses}`}
              >
                {children}
              </StyledIconButton>
            ) : (
              <StyledButton
                innerRef={ref}
                startIcon={startIcon}
                colorkey={color}
                hoverAll={hoverAll}
                className={
                  `${buttonClasses} ${mobileClasses} ${smallDesktopClasses}`
                }
                {...rest}
              >
                {children}
              </StyledButton>
            )
        }
      </WithTooltipWrapper>
    </Badge>
  );
});

export default BaseButton;
