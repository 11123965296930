import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import BaseButton from '../form/BaseButton';

export const getDebugToolsConfig = (key?: string) => {
  const localConfig = localStorage.getItem('test-components-config');
  const debugConfig = localConfig && JSON.parse(localConfig);

  return key && debugConfig
    ? debugConfig[key]
    : debugConfig;
};

function ToolsSetup() {
  const localConfig = localStorage.getItem('test-components-config');
  const [config, setConfig] = useState(localConfig && JSON.parse(localConfig) || {});

  const changeConfig = (key, value) => {
    setConfig((prev) => ({
      ...prev,
      ...{
        [key]: value,
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem('test-components-config', JSON.stringify(config));
  }, [config]);

  const buttonStatus = (key: string) => config[key];
  const setupKeys = {
    debugAcl: {
      label: 'Debug ACL',
      description: <Box>
        When enabled you will see a colored border around ACLWrapper components (see
        <a href="#acl-wrapper-test">this test</a>
        )
      </Box>,
    },
    debugSessions: {
      label: 'Debug SESSIONS',
      description: <Box>
        When enabled you will see a debug window in Sessions creation/editing sections
      </Box>,
    },
    debugPlayer: {
      label: 'Debug PLAYER',
      description: <Box>
        When enabled you will see a web player tab in session details
      </Box>,
    },
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>Debug Tools</Typography>
      <Row>
        {
          Object.keys(setupKeys)
            .map((sk) => (
              <Column md={3} key={sk}>
                <Paper>
                  <Box p={2}>
                    <Box mb={2}>{setupKeys[sk].description}</Box>
                    <BaseButton
                      active={buttonStatus(sk)}
                      onClick={() => changeConfig(sk, !config[sk])}
                    >
                      {setupKeys[sk].label}
                    </BaseButton>
                  </Box>
                </Paper>
              </Column>
            ))
        }
      </Row>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default ToolsSetup;
