import { DEFAULT_DECIMALS_NUM } from '../constants';

/**
 * Genera numeri interi random entro un range di interi (compresi)
 *
 * @param min
 * @param max
 */
export const randomIntFromInterval = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

export const toDefaultFixedDecimals = (value: number) => (value === undefined
  ? 0
  : parseFloat(
    (
      typeof value === 'number'
        ? value
        : parseInt(value, 10)
    ).toFixed(DEFAULT_DECIMALS_NUM),
  ));
