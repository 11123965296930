import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import ReactDOMServer from 'react-dom/server';
import { filterGraphQlData } from '../utils/graphql';
import { KPIType } from '../../query/track';
import { HIGHCHART_OPTIONS } from '../constants';
import getAxisTitleFormat, { tooltipStyle } from './utils';
import { getUomTranslation } from '../utils/utils';
import { formatterChoice, UnitsType, UomType } from '../utils/unitsFormatter';

export interface SingleStackedColumnData{
  id: string,
  props: {
    key: string,
    value: string,
  },
  data: [KPIType],
}

interface PropsIn {
  data: {[k:string]: SingleStackedColumnData[]} // SingleStackedColumnData | SingleStackedColumnData[]
  categories: string[]
  colors?: {[k:string]: string}
  hideLabel?: boolean
  hideXLabel?: boolean
  singleColumn?: boolean
  yMax?: number
  yLabel?: string
  skipKey?: boolean
  validKeys?: string[],
  uom: string | UomType;
  unit: string | UnitsType;
}

const StackedColumnChart = React.memo((props: PropsIn) => {
  const {
    skipKey, data, colors, yMax,
    yLabel, categories, hideLabel, hideXLabel, validKeys,
    singleColumn, uom, unit,
  } = props;
  const { t } = useTranslation();
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const srcData = filterGraphQlData(data);
  const chartData: Highcharts.SeriesOptionsType[] = [];
  const xLabels = hideXLabel ? { enabled: false }
    : {
      step: 0,
      style: {
        fontSize: 'var(--labels-font-size)',
        color: 'var(--secondary-color)',
      },
    };

  Object.keys(srcData)
    .filter((k) => !validKeys || validKeys.includes(k))
    .forEach((k) => {
      srcData[k].forEach((d, l) => {
        if (!chartData[l]) {
          chartData[l] = {
            name: skipKey ? d.props.value : d.props.key,
            data: [],
            color: colors ? colors[d.props.key] : undefined,
            id: d.id,
            type: 'column',
            tooltip: {
              headerFormat: '',
              pointFormatter() {
                return ReactDOMServer.renderToString(
                  <div className="stacked-column-chart-tooltip">
                    <div className="stacked-column-chart-cover-top" />
                    <span>{skipKey ? `${d.props.value}: ` : `${d.props.key} ${d.props.value}: `}</span>
                    <span style={{ fontFamily: 'var(--font-family-alt', fontWeight: 600 }}>{`${formatterChoice(unit)(uom, this.y)} `}</span>
                    <span>{getUomTranslation(t, d?.data[0]?.uom)}</span>
                    <div className="stacked-column-chart-cover-bottom" />
                  </div>,
                );
              },
            },
          };
        }

        if (singleColumn && chartData[l].data.length > 0) {
          chartData[l].data[0] += d?.data[0]?.value;
        } else {
          chartData[l].data.push(d?.data[0]?.value);
        }
      });
    });

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    chart: {
      type: 'column',
    },
    tooltip: {
      ...tooltipStyle,
      useHTML: true, // Enable HTML content in the tooltip
      shadow: false,
      shared: false,
      split: false,
      headerFormat: '',
    },
    xAxis: {
      categories,
      labels: xLabels,
    },
    yAxis: {
      title: {
        text: yLabel && uom ? getAxisTitleFormat(yLabel, uom) : '',
      },
      labels: {
        step: 1,
      },
      max: yMax,
      stackLabels: {
        useHTML: true,
        enabled: true,
        formatter: function () {
          if (yMax) {
            return formatterChoice(unit)(uom, this.total);
          }
          return null;
        },
        style: {
          fontSize: 'var(--labels-font-size)',
          fontFamily: 'var(--font-family-main)',
          color: 'var(--secondary-color)',
        },
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
      column: {
        maxPointWidth: HIGHCHART_OPTIONS.MaxColumnWidth,
        stacking: 'normal',
        dataLabels: {
          enabled: !hideLabel,
          style: {
            color: 'contrast',
            fontSize: 'var(--labels-font-size)',
            fontWeight: 'bold',
            textOutline: '0',
          },
          color: 'white',
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            if (this.y && this.y > 0) {
              // eslint-disable-next-line react/no-this-in-sfc
              return formatterChoice(unit)(uom, this.y);
            }
            return '';
          },
        },
      },
    },
    series: chartData,
    legend: {
      align: 'center',
      x: 0,
      verticalAlign: 'bottom',
      layout: 'horizontal',
      y: 20,
      reversed: true,
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
      borderColor: '#CCC',
      borderWidth: 0,
      shadow: false,
      itemStyle: {
        fontWeight: '300',
      },
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
});

export { StackedColumnChart };
