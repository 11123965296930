import {
  Box, Mark, Slider, Tooltip, Typography, withStyles,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import StopIcon from '@images/icons/svg/pause.svg';
import SkipNextIcon from '@images/icons/svg/skipNext.svg';
import X05 from '@images/icons/svg/X0.5.svg';
import X1 from '@images/icons/svg/X1.svg';
import X25 from '@images/icons/svg/X2.5.svg';
import X5 from '@images/icons/svg/X5.svg';
import format from 'date-fns/format';
import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import HeaderPopover from 'components/layout/HeaderPopover';
import BaseButton from '../form/BaseButton';
import { Maybe } from '../../__generated__/graphql';
import { DrillTimesType } from './WebPlayer';

export interface MarkDrill extends Mark {
  index?: Maybe<number>,
  id?: Maybe<number>,
  tags?: Maybe<string>[]
}

interface PlayerControlsProps {
  handlePlayClick: () => void;
  handleFullscreenClick: () => void;
  playerStatus: 0 | 1;
  currentTime: number;
  // setCurrentTime: (n:number) => void;
  valueLabelFormat: React.ComponentProps<typeof Slider>['valueLabelFormat'];
  timeFormat: string;
  startTimestamp?: number;
  endTimestamp?: number;
  marks: MarkDrill[];
  handleChange: (event: ChangeEvent<{}>, time: number | number[]) => void;
  currentDrill: number | null;
  playerSpeed: number;
  setPlayerSpeed: (n) => void;
  drillTimes: DrillTimesType;
  currDrillRef: React.MutableRefObject<number>;
  goToNextDrill: () => void;
}

interface SliderDrillsProps {
  marks: MarkDrill[];
  min?: number;
  max?: number;
  currentTime: number;
}

const CustomTooltip = withStyles(() => ({
  tooltip: {
    top: '10px',
  },
}))(Tooltip);

const SliderDrills = (props: SliderDrillsProps) => {
  const {
    min, max, marks, currentTime,
  } = props;

  const isDrillSelected = (startTime, endTime) => currentTime >= startTime && currentTime <= endTime;

  const marksBars = useMemo(() => {
    const out: [MarkDrill, MarkDrill][] = [];
    for (let i = 0; i < marks.length; i += 2) {
      out.push([marks[i], marks[i + 1]]);
    }

    return out;
  }, [min, max, marks]);

  if (min && max && marks && marks.length) {
    const range = max - min;

    return (
      <Box className="player-marks-overlay">
        {
          marksBars.map((bar) => (
            <Box
              key={bar[0].index}
              position="absolute"
              left={`${(bar[0].value - min) / range * 100}%`}
              width={`${(bar[1].value - bar[0].value) / range * 100}%`}
            >
              <CustomTooltip
                title={`${bar[0].label} - ${bar[1].label}`}
                arrow
                placement="top"
              >
                <span style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontWeight: isDrillSelected(bar[0].value, bar[1].value) ? 800 : 400,
                }}
                >
                  {`D${bar[0].index}`}
                </span>
              </CustomTooltip>
            </Box>
          ))
        }
      </Box>
    );
  }

  return null;
};

const PlayerControls = (props: PlayerControlsProps) => {
  const {
    handlePlayClick,
    handleFullscreenClick,
    playerStatus,
    currentTime,
    // setCurrentTime,
    valueLabelFormat,
    timeFormat,
    startTimestamp,
    endTimestamp,
    marks,
    currentDrill,
    currDrillRef,
    handleChange,
    playerSpeed,
    setPlayerSpeed,
    goToNextDrill,
    drillTimes,
  } = props;

  const timezoneOffset = startTimestamp
    ? -new Date(startTimestamp).getTimezoneOffset() * 60000
    : 0;

  const startDrill = drillTimes && drillTimes.length > 0 && currentDrill !== null && drillTimes[currentDrill].start;
  const endDrill = drillTimes && drillTimes.length > 0 && currentDrill !== null && drillTimes[currentDrill].end;

  const startTime = startDrill
    ? startDrill
    : startTimestamp
      ? new Date(startTimestamp).getTime() + timezoneOffset
      : 0;
  const endTime = endDrill
    ? endDrill
    : endTimestamp
      ? new Date(endTimestamp).getTime() + timezoneOffset
      : 0;

  const [hoverTime, setHoverTime] = useState<number | undefined>(currentTime);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });

  const getRealTime = (time : number) => format(time - timezoneOffset, timeFormat);

  // get currentTime based on mouse position on slider:
  const getCurrentTimeValue = (event) => {
    const slider = event.currentTarget;
    const rect = slider.getBoundingClientRect();
    const xPos = event.clientX - rect.left;
    return Math.round((xPos / rect.width) * (endTime - startTime) + startTime);
  };

  const updateCurrentDrill = (currTime) => {
    if (currTime === 0) return;

    drillTimes.forEach(({ start }, index) => {
      if (currTime >= start && currTime <= drillTimes[index + 1].end) {
        currDrillRef.current = index + 1;
      }
    });
  };

  const handleSliderChange = useCallback((event, newValue) => {
    setHoverTime(undefined);
    handleChange(event, newValue);
    if (Object.hasOwn(event, 'getBoundingClientRect')) {
      const currTime = getCurrentTimeValue(event);
      updateCurrentDrill(currTime);
    }
  }, []);

  const handleMouseMove = useCallback((event) => {
    const slider = event.currentTarget;
    const rect = slider.getBoundingClientRect();
    const xPos = event.clientX - rect.left;
    const currTime = Math.round((xPos / rect.width) * (endTime - startTime) + startTime);
    setHoverTime(currTime);
    setTooltipPosition({
      left: xPos,
      top: 18,
    });
  }, [endTime, startTime]);

  const handleMouseLeave = useCallback(() => setHoverTime(undefined),  [])

  return (
    <Box style={{ position: 'relative' }}>
      <Box mt={2} display="flex" alignItems="center" style={{ padding: '16px 0px' }}>
        <Box width="calc(100% - 6px)" position="relative">
          {hoverTime !== undefined && (
            <Tooltip
              title={getRealTime(hoverTime)}
              placement="top"
              open={!!hoverTime}
              arrow
              style={{
                position: 'absolute',
                left: tooltipPosition.left,
                top: tooltipPosition.top,
              }}
            >
              <span />
            </Tooltip>
          )}
          <Slider
            value={currentTime}
            valueLabelFormat={valueLabelFormat}
            aria-labelledby="continuous-slider"
            marks={currentDrill === null && marks}
            min={startTime}
            max={endTime}
            onChange={handleSliderChange}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
          {currentDrill === null && (
            <SliderDrills
              marks={marks}
              min={startTime}
              max={endTime}
              currentTime={currentTime}
            />
          )}
        </Box>
      </Box>
      <Box>
        <Typography
          gutterBottom
          style={{
            padding: '0.5px 5px',
            border: '1px solid #fff',
            position: 'absolute',
            color: '#fff',
            left: '46.25%',
            top: '-60px',
          }}
        >
          {currentTime ? getRealTime(currentTime) : '--:--:--'}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width="41.3%">
          <Typography gutterBottom>
            {startTimestamp ? getRealTime(startTime) : '--:--:--'}
          </Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '10px', marginTop: '-10px' }}>
          <BaseButton isIconButton noMargins onClick={handlePlayClick}>
            {playerStatus === 0 && <PlayArrowIcon />}
            {playerStatus === 1 && <StopIcon />}
          </BaseButton>
          { marks.length > 0
          && (
          <BaseButton
            isIconButton
            noMargins
            onClick={() => {
              goToNextDrill();
            }}
          >
            <SkipNextIcon />
          </BaseButton>
          )}
          <HeaderPopover
            buttonId="player-speed"
            className="player-speed-popover"
            icon={playerSpeed === 20 ? <X05 />
              : playerSpeed === 40 ? <X1 />
                : playerSpeed === 60 ? <X25 />
                  : <X5 />}
            popoverContent={[
              {
                value: '0.5x',
                label: '0.5x',
                onClick: () => setPlayerSpeed(20),
              },
              {
                value: '1x',
                label: '1x',
                onClick: () => setPlayerSpeed(40),
              },
              {
                value: '2.5x',
                label: '2.5x',
                onClick: () => setPlayerSpeed(60),
              },
              {
                value: '5x',
                label: '5x',
                onClick: () => setPlayerSpeed(80),
              },
            ]}

          />
          <BaseButton isIconButton noMargins>
            <FullscreenIcon onClick={handleFullscreenClick} />
          </BaseButton>
        </Box>
        <Box style={{ marginLeft: 'auto' }}>
          <Typography gutterBottom>
            {endTimestamp ? getRealTime(endTime) : '--:--:--'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PlayerControls);
