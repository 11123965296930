import { gql } from '@apollo/client'
import { TeamSessionTagType } from 'models/team_session'

export type TeamSessionTagsTypeRes = {
  content?: TeamSessionTagType[],
  count?: number,
  offset?: number,
  pageSize?: number,
}
export type TeamSessionTagsTypeVars = {
  teamId?: string,
  first?: number,
  skip?: number,
  sort?: string,
  bypassPermission?: boolean,
}
export const TSTAG = gql`
  query TeamsessionsTag(
    $teamId: ID!,
    $first: Int,
    $skip: Int,
    $sort: String
    $bypassPermission: Boolean,
  ) {
    res: teamSessionsTags(
      teamId: $teamId,
      first: $first,
      skip: $skip,
      sort: $sort,
      bypassPermission: $bypassPermission,
    ) {
      content {
        id
        name
      },
      count
      offset
      pageSize
    }
  }
`
