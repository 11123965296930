import React from 'react';
import ColorPicker from './form/ColorPicker';
import { OutlinedWrapper } from './form/FieldsGroup';
import { WEB_SAFE_COLORS } from './constants';

type PropsIn= {
className?: string,
defaultColor?: string;
disabled?: boolean;
disableGradientPicker?: boolean;
label?: string;
onChange?: (color: string) => void;
onClose?: (color: string) => void;
readonly?: boolean;
required?: boolean;
showDefaultColors?: boolean;
groundColors?: boolean;
}

export default function ColorPickerWrapper(props: PropsIn) {
  const {
    className, defaultColor, disabled, disableGradientPicker, label, onChange, onClose, readonly, required,
    showDefaultColors, groundColors,
  } = props;

  return (
    <OutlinedWrapper className={className} label={required ? `${label} *` : label}>
      <ColorPicker
        disabled={disabled}
        defaultColor={defaultColor || WEB_SAFE_COLORS[5]}
        disableGradientPicker={disableGradientPicker}
        onChange={onChange}
        onClose={onClose}
        readonly={readonly}
        showDefaultColors={showDefaultColors}
        groundColors={groundColors}
      />
    </OutlinedWrapper>
  );
}
