import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import BaseButton from '../form/BaseButton';
import { LoadingContext } from '../../Authenticator';

function TestGlobalLoading() {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  return (
    <>
      <Typography variant="h5" gutterBottom>Global Loading</Typography>
      <Paper>
        <Box p={2}>
          <BaseButton onClick={() => setIsLoading(!isLoading)}>
            TOGGLE LOADING STATUS:
            {' '}
            {isLoading ? 'LOADING' : 'NOT LOADING'}
          </BaseButton>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestGlobalLoading;
