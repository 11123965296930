import { GroundType } from 'models/ground';
import React from 'react';
import {
  Circle, Group, Line, Path, Text,
} from 'react-konva';
import {
  DEFAULT_PITCH_LINE_COLOR,
  DEFAULT_PITCH_STROKEWIDTH,
  ICE_HOCKEY_CENTER_RADIUS,
  ICE_HOCKEY_CORNER_RADIUS,
  ICE_HOCKEY_DISK_RADIUS,
  ICE_HOCKEY_FIRST_DISK_X_OFFSET,
  ICE_HOCKEY_LINE_OFFSET,
  ICE_HOCKEY_SCOREKEEPER_AREA_RADIUS,
} from '../constants';
import {
  rotate, flipPoint, computeAngle, flipX, flipY, describeArc, convertRadians,
} from '../utils';

function Area(props: {
  vertexA: { x: number; y: number };
  vertexC: { x: number; y: number };
  height: number;
  center: { x: number; y: number };
  scale: number;
  flipped?: boolean;
  showInDrawer?: boolean;
}) {
  const {
    vertexA, vertexC, height, scale, flipped, center, showInDrawer,
  } = props;

  const scaledCornerRadius = ICE_HOCKEY_CORNER_RADIUS * scale;
  const scaledFirstDiskXOffset = ICE_HOCKEY_FIRST_DISK_X_OFFSET * scale;
  const scaledLineOffset = ICE_HOCKEY_LINE_OFFSET * scale;

  const sevenPointsOriginX = flipped
    ? flipX({ x: (scaledFirstDiskXOffset + center.x) / 2.50, y: vertexA.y - height }, center)

    : (scaledFirstDiskXOffset + center.x) / 2.50;

  const sevenPointsOriginY = flipped
    ? flipY({ x: center.x - scaledLineOffset, y: vertexA.y - height / 2 }, center)
    : vertexA.y - height / 2;

  const sevenPointsCoordinates = [90, 60, 30, 0, 330, 300, 270];
  const sevenPointsRadius = 8 * scale;

  return (
    <Group>
      <Path
        data={describeArc(
          flipped ? vertexC.x - scaledCornerRadius : vertexA.x + scaledCornerRadius,
          flipped ? vertexC.y + scaledCornerRadius : vertexA.y - scaledCornerRadius,
          scaledCornerRadius < 0 ? scaledCornerRadius * -1 : scaledCornerRadius,
          flipped ? 0 : 180,
          flipped ? 90 : 270,
        )}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* seven points */}
      { sevenPointsCoordinates.map((point) => (
        <Circle
          x={
            flipped ? sevenPointsOriginX + sevenPointsRadius * Math.cos(convertRadians(point))
              : sevenPointsOriginX + sevenPointsRadius * Math.cos(convertRadians(point - 180)) // 180 GRADI = PIGRECO
        }
          y={
              sevenPointsOriginY + sevenPointsRadius * Math.sin(convertRadians(point))
        }
          radius={6}
          stroke="black"
          fill="black"
          strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
        />
      ))}
      {/* vertical lines */}
      <Line
        points={
          flipped
            ? [
              flipX({ x: center.x - scaledLineOffset, y: vertexA.y - height }, center),
              flipY({ x: center.x - scaledLineOffset, y: vertexA.y - height }, center),
              flipX({ x: center.x - scaledLineOffset, y: vertexA.y }, center),
              flipY({ x: center.x - scaledLineOffset, y: vertexA.y }, center),
            ]
            : [center.x - scaledLineOffset, vertexA.y - height, center.x - scaledLineOffset, vertexA.y]
        }
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Path
        data={describeArc(
          flipped ? vertexC.x - scaledCornerRadius : vertexA.x + scaledCornerRadius,
          flipped ? vertexA.y - scaledCornerRadius : vertexC.y + scaledCornerRadius,
          scaledCornerRadius < 0 ? scaledCornerRadius * -1 : scaledCornerRadius,
          flipped ? 90 : 270,
          flipped ? 180 : 0,
        )}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      { !flipped && showInDrawer
      && (
      <Text
        x={sevenPointsOriginX + sevenPointsRadius * Math.cos(convertRadians(90))}
        y={sevenPointsOriginY + sevenPointsRadius * Math.sin(convertRadians(90))}
        fontSize={25}
        text="A"
        fill={DEFAULT_PITCH_LINE_COLOR}
      />
      )}
      { !flipped && showInDrawer
    && (
    <Text
      x={sevenPointsOriginX + sevenPointsRadius * Math.cos(convertRadians(270))}
      y={sevenPointsOriginY + sevenPointsRadius * Math.sin(convertRadians(270))}
      fontSize={25}
      text="B"
      fill={DEFAULT_PITCH_LINE_COLOR}
    />
    )}
      {flipped && showInDrawer
      && (
      <Text
        x={sevenPointsOriginX + sevenPointsRadius * Math.cos(convertRadians(270))}
        y={sevenPointsOriginY + sevenPointsRadius * Math.sin(convertRadians(270))}
        fontSize={25}
        text="C"
        fill={DEFAULT_PITCH_LINE_COLOR}
      />
      )}
      {flipped && showInDrawer && (
      <Text
        x={sevenPointsOriginX + sevenPointsRadius * Math.cos(convertRadians(90))}
        y={sevenPointsOriginY + sevenPointsRadius * Math.sin(convertRadians(90))}
        fontSize={25}
        text="D"
        fill={DEFAULT_PITCH_LINE_COLOR}
      />
      )}
    </Group>
  );
}

export default function ShortTrack(props: { groundData: GroundType; scale: number; vertices: boolean, showInDrawer: boolean; }) {
  const {
    groundData, scale, vertices, showInDrawer,
  } = props;

  const offsetXForVertices = 31.925 / 2;
  const offsetYForVertices = 13.91 / 2;

  let vertexA = {
    x:
      rotate(flipPoint({ x: groundData.vertexAX?.value - offsetXForVertices, y: groundData.vertexAY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexAX?.value, y: groundData.vertexAY?.value - offsetYForVertices }, groundData), computeAngle(groundData)).y
      * scale,
  };
  let vertexC = {
    x:
      rotate(flipPoint({ x: groundData.vertexCX?.value + offsetXForVertices, y: groundData.vertexCY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexCX?.value, y: groundData.vertexCY?.value + offsetYForVertices }, groundData), computeAngle(groundData)).y
      * scale,
  };

  if (vertexA.x > vertexC.x) {
    const swap = vertexC;
    vertexC = vertexA;
    vertexA = swap;
  }

  const width = vertexC.x - vertexA.x;
  const height = vertexA.y - vertexC.y;

  const center = { x: vertexA.x + width / 2, y: vertexA.y - height / 2 };

  const scaledCornerRadius = ICE_HOCKEY_CORNER_RADIUS * scale;
  const scaledCenterRadius = ICE_HOCKEY_CENTER_RADIUS * scale;
  const scaledDiskRadius = ICE_HOCKEY_DISK_RADIUS * scale;

  return (
    <Group>
      {/* border */}
      <Line
        points={[vertexA.x + scaledCornerRadius, vertexA.y, vertexC.x - scaledCornerRadius, vertexA.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={[vertexC.x, vertexC.y + scaledCornerRadius, vertexC.x, vertexA.y - scaledCornerRadius]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={[
          vertexA.x + scaledCornerRadius,
          vertexA.y - height,
          vertexC.x - scaledCornerRadius,
          vertexA.y - height,
        ]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={[vertexA.x, vertexC.y + scaledCornerRadius, vertexA.x, vertexA.y - scaledCornerRadius]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* left area */}
      <Area vertexA={vertexA} vertexC={vertexC} height={height} center={center} scale={scale} vertices={vertices} showInDrawer={showInDrawer} />
      {/* halfcourt */}
      <Line
        points={[vertexA.x + width / 2, vertexA.y, vertexC.x - width / 2, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={vertexA.x + width / 2}
        y={vertexA.y - height / 2}
        radius={scaledCenterRadius < 0 ? scaledCenterRadius * -1 : scaledCenterRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={vertexA.x + width / 2}
        y={vertexA.y - height / 2}
        radius={scaledDiskRadius < 0 ? scaledDiskRadius * -1 : scaledDiskRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* rightarea */}
      <Area vertexA={vertexA} vertexC={vertexC} height={height} center={center} scale={scale} flipped showInDrawer={showInDrawer} />
    </Group>
  );
}
