import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  MapContainer, Polyline, TileLayer, useMap,
} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import { PolylineProps } from 'react-leaflet/types/Polyline';

import 'leaflet/dist/leaflet.css';

// stupid hack so that leaflet's images work after going through webpack
import marker from 'leaflet/dist/images/marker-icon.png?file';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png?file';
import markerShadow from 'leaflet/dist/images/marker-shadow.png?file';

import CLIENT_SETTINGS from 'lib/client_settings';
import { MapLayerType, MAP_LAYER_TYPE_DEFAULT } from '../../../lib/maps';
import { TeamContext } from '../../../Authenticator';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
});

const ResizeMap = () => {
  const map = useMap();
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  map._onResize();
  return null;
};

const offsetChina = { x: 40, y: -210 };

// @ts-ignore
const baiduCRS = new window.L.Proj.CRS(
  'EPSG:3395',
  `+proj=merc +lon_0=0 +k=1 +x_0=${offsetChina.x} +y_0=${offsetChina.y} +datum=WGS84 +units=m +no_defs`,
  {
    resolutions: [
      262144, 131072, 65536, 32768, 16384, 8192, 4096, 2048, 1024, 512, 256,
      128, 64, 32, 16, 8, 4, 2, 1,
    ],
    origin: [0, 0],
    bounds: L.bounds([20037508.342789244, 0], [0, 20037508.342789244]),
  },
);

export const getMapBoundsFromPoints = (positions: [number, number][]) => {
  const latLngs = positions.map((position) => L.latLng(position[0], position[1]));
  const bounds = L.latLngBounds(latLngs);
  return bounds;
};

const arrow = (props) => [
  {
    offset: '100%',
    repeat: 0,
    symbol: L.Symbol.arrowHead({
      pixelSize: 5,
      polygon: false,
      pathOptions: {
        stroke: true,
        color: props.color,
      },
    }),
  },
];

export const PolylineArrow = (props: PolylineProps) => {
  const map = useMap();
  const polyRef = React.createRef();

  useEffect(() => {
    if (!map) return;
    const polyline = polyRef.current;
    const arrowPoly = arrow(props.pathOptions);

    const arrowDecorator = L.polylineDecorator(polyline, {
      patterns: arrowPoly,
    });

    arrowDecorator.addTo(map);

    return () => {
      arrowDecorator.remove();
    };
  }, [map]);

  return <Polyline ref={polyRef} {...props} />;
};

type MapType = 'BAIDU' | 'GOOGLE' | 'OSM' | undefined

const TileLayerComponent = (props: {type: MapType, layer?: MapLayerType}) => {
  const { layer, type } = props;

  // @todo va sistemato il copyright per i vari provider
  // @todo esportare la GOOGLE_API_KEY

  const baiduLayer = {
    satellite: 'http://shangetu{s}.map.bdimg.com/it/u=x={x};y={y};z={z};v=009;type=sate&fm=46',
    roadmap: 'https://maponline{s}.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl',
    terrain: 'https://maponline{s}.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl',
  };

  return (type?.toUpperCase() === 'GOOGLE'
    ? (
      <ReactLeafletGoogleLayer
        apiKey={CLIENT_SETTINGS.public.googleApiKey}
        type={layer || MAP_LAYER_TYPE_DEFAULT}
      />
    )
    : type?.toUpperCase() === 'BAIDU'
      ? (
        <TileLayer
          url={baiduLayer[layer || MAP_LAYER_TYPE_DEFAULT]}
          // url="http://online{s}.map.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl&scaler=1&p=1"
          subdomains={['0', '1', '2', '3']}
          maxNativeZoom={18}
          minNativeZoom={3}
          tms
        />
      )
      : (
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      )
  );
};

interface ChangeMapCenterProps {
  coords: L.LatLngExpression;
  zoom?: number;
}

const ChangeMapCenter = (props: ChangeMapCenterProps) => {
  const {coords, zoom} = props;
  const coordsRef = useRef<ChangeMapCenterProps['coords'] | null>(null);
  const zoomRef = useRef<ChangeMapCenterProps['zoom'] | null>(null);
  const map = useMap();

  if (!coordsRef.current
    || !zoomRef.current
    || coordsRef.current.lat !== coords.lat
    || coordsRef.current.lng !== coords.lng
    || zoomRef.current !== zoom
  ) {
    coordsRef.current = coords;
    zoomRef.current = zoom || map.getZoom();
    map.setView(coords, zoom || map.getZoom());
  }

  return null;
}

interface Props {
  bounds?: L.LatLngBoundsExpression
  center?: L.LatLngTuple
  height?: number
  layer?: MapLayerType
  type?: MapType
  updateOnZoomMove?: boolean
  zoom?: number
}

const MapComponent: React.FC<Props> = (props) => {
  const {
    bounds, center, children, height, type, updateOnZoomMove, zoom, layer,
  } = props;

  const { globalTeam } = useContext(TeamContext);

  const [map, setMap] = useState<any>(null);
  const mapService = type || globalTeam?.club.mapService;
  const mapType = mapService ? mapService.toUpperCase() as MapType : undefined;

  useEffect(() => {
    if (bounds && map) {
      map.fitBounds(bounds);
    }
  }, [bounds]);

  const minZoom = 3;
  const maxZoom = 21;
  const zoomLevel = bounds ? undefined : zoom && zoom <= maxZoom ? zoom : 17;
  // @todo valutare se possibile prendere i livelli massimi di zoom satellitare in base al provider

  return (
    <MapContainer
      bounds={bounds}
      center={center}
      minZoom={minZoom}
      maxZoom={maxZoom}
      zoom={zoomLevel}
      crs={mapType === 'BAIDU' ? baiduCRS : L.CRS.EPSG3857}
      style={{ width: '100%', height: `${height || 550}px` }}
      scrollWheelZoom={false}
      whenCreated={setMap}
    >
      <ResizeMap />
      {updateOnZoomMove && center && <ChangeMapCenter
        coords={{lat: center[0], lng: center[1]}}
        zoom={zoom}
      />}
      <TileLayerComponent type={mapType} layer={layer} key={layer} />
      {children}
    </MapContainer>
  );
};

export default MapComponent;
