import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CACHE_AND_NETWORK } from 'lib/cache';
import { useQueryCached } from 'components/utils/graphql';
import DropdownButton from '../DropdownButton';
import { AVAILABLE_ZONES } from '../../query/track';

interface PropsIn {
  className?: string;
  onChange: (zones: string[]) => void;
  preset?: string;
  athletesessionId?: string;
}

const getAvailableZones = (athletesessionId) => data?.res;

export const defaultZones = [
  {
    id: 'noZone',
    i18n: 'track.widgets.basicKPI.labels.noZone',
    onSeries: '',
    value: 'noZone',
  },
  {
    id: 'speed',
    i18n: 'kpi2.speedZones.title',
    onSeries: 'speed',
    value: 'speed',
  },
  {
    id: 'relative_speed',
    i18n: 'kpi2.%SpeedZones.title',
    onSeries: 'speed',
    value: 'relativeSpeed',
  },
  {
    id: 'acc',
    i18n: 'kpi2.accZones.title',
    onSeries: 'acc',
    value: 'acc',
  },
  {
    id: 'dec',
    i18n: 'kpi2.decZones.title',
    onSeries: 'acc',
    value: 'dec',
  },
  {
    id: 'power',
    i18n: 'kpi2.powerZones.title',
    onSeries: 'power',
    value: 'power',
  },
  {
    id: 'hr_zones',
    i18n: 'track.widgets.hrKPI.labels.HRZones',
    onSeries: 'cardio',
    value: 'cardio',
  },
];

const mapZoneLabel = (zoneId) => `${defaultZones.filter((s) => zoneId === s.value).map((s) => s.i18n)[0]}`;

export function SelectZones(props: PropsIn) {
  const { t } = useTranslation();
  const { className, onChange, preset } = props;

  const { data } = useQueryCached(AVAILABLE_ZONES, {
    variables: {
      athletesessionId: props.athletesessionId,
    },
    ...CACHE_AND_NETWORK,
  });

  const [zones, setZones] = useState(defaultZones.filter((s) => preset === s.id).map((s) => s.id));
  const [zoneLabel, setZoneLabel] = useState(`${t(mapZoneLabel('noZone'))}`);
  const handleClick = (e) => {
    if (zones.length < 2) {
      setZoneLabel(`${t(mapZoneLabel(e))}`);
      if (e === 'noZone') {
        setZones([]);
      } else if (zones.includes(e)) {
        setZones(zones.filter((s) => s !== e) || []);
      } else {
        setZones([e]);
      }
    }
  };

  useEffect(() => {
    onChange(zones);
  }, [zones]);

  return (
    <div className={className}>
      <DropdownButton
        label={zoneLabel}
        popoverContent={
          defaultZones.filter((zone) => data?.res?.includes(zone.id)).map((zone) => ({
            value: zone.value,
            label: t(zone.i18n, { defaultValue: zone.value }),
            onClick: () => handleClick(zone.value),
          })) || []
        }
        small
        multiple={false}
        values={zones}
        anchor="right"
      />
    </div>
  );
}
