import { gql } from '@apollo/client';
import { TeamType } from 'models/team';
import {
  KPIType, LayoutType, TrackAthleteSessionKpiStateChoices, TrackAthleteSessionStateChoices,
} from './track';
import { AthleteType } from '../models/athlete';
import { ASProfileType, MPProfileType } from './performance';
import { AthleteSessionDrillType, AthleteSessionType, MechType } from '../models/athlete_session';
import { TeamSessionCategoryType, getWeatherFragmentName, getWeatherFragment } from '../models/team_session';
import { TrackType } from '../models/track';
import { RpeRowType } from '../components/calendar/RPEForm';

export type TeamSessionsVars = {
  id: number;
  templateId: number;
  drill: number | null;
  withAthleteSession?: boolean;
  withGround?: boolean;
  fieldsLimit?: number;
};

export const SESSION_HEADER = gql`
    ${getWeatherFragment('with_weather')}
    query SessionHeader(
        $id: ID!,
        $templateId: ID = null,
        $drill: ID = null,
    ) {
        res: teamSession(
            id: $id,
            drill: $drill,
            templateId: $templateId # nullable
        ) {
            id
            drill
            athleteCount
            needAthletesessionReprocess
            startTimestamp
            state
            originalTeamsession {
                id
                drill
                startTimestamp
            }
            category {
                id
                name
                color
            }
            totalTime {
                unit
                uom
                value
            }
            weather {
                ...${getWeatherFragmentName('with_weather')}
            }
            tags {
                id
                name
            }
            notes
            matchCycle
            matchDistance {
                date
                nextDays
                nextOpponent
                nextHome
                prevDays
                prevOpponent
                prevHome
            }
            drills {
                wholeSession {
                    id
                    index
                    totalTime {
                        value
                        unit
                        uom
                    }
                    start
                    end
                    tags
                    notes
                    intervals {
                        start
                        end
                    }
                }
                relatedDrills {
                    id
                    index
                    start
                    end
                    totalTime {
                        value
                        unit
                        uom
                    }
                    tags
                    notes
                    intervals {
                        start
                        end
                    }
                }
            }            
        }
    }
`;

export const SESSION = gql`
    query Session(
        $id: ID!,
        $templateId: ID = null,
        $drill: ID = null,
        $withAthleteSession: Boolean = true,
        $withGround: Boolean = false,
    ) {
        res: teamSession(
            id: $id,
            drill: $drill,
            templateId: $templateId # nullable
        ) {
            id
            drill
            drillEnabled
            isStatsValid
            getCardioFirstbeat
            originalTeamsession {
                id
                drill
                startTimestamp
            }
            endTimestamp
            team {
                id
                rpeFormat
            }
            startTimestamp
            endTimestamp
            tags {
                id
                name
            }
            drills {
                wholeSession {
                    id
                    index
                    totalTime {
                        value
                        unit
                        uom
                    }
                    start
                    end
                    tags
                    notes
                    intervals {
                        start
                        end
                    }
                }
                relatedDrills {
                    id
                    index
                    start
                    end
                    totalTime {
                        value
                        unit
                        uom
                    }
                    tags
                    notes
                    intervals {
                        start
                        end
                    }
                }
            }
            notes
            nature
            hasRpe
            athleteSessions @include(if: $withAthleteSession) {
                id
                startDate
                startTime
                endDate
                drills {
                    wholeSession {
                        id
                        index
                        start
                        end
                        totalTime {
                            unit
                            uom
                            value
                        }
                        notes
                        intervals {
                            start
                            end
                        }
                        tags
                    }
                    relatedDrills {
                        id
                        index
                        start
                        end
                        totalTime {
                            unit
                            uom
                            value
                        }
                        notes
                        intervals {
                            start
                            end
                        }
                        tags 
                    }
                }
                state
                isStatsValid
                track {
                    id
                    hasCardio
                    trackSession
                    incomingFileFormat
                    ground @include(if: $withGround){
                        id
                        name
                        groundType
                        groundCoordsType
                        groundSurfaceColor
                        groundSurface
                        offset
                        vertexALatitude
                        vertexALongitude
                        vertexBLatitude
                        vertexBLongitude
                        vertexCLatitude
                        vertexCLongitude
                        vertexDLatitude
                        vertexDLongitude
                        vertexAX{ unit, value, uom}
                        vertexAY { unit, value, uom}
                        vertexBX { unit, value, uom}
                        vertexBY { unit, value, uom}
                        vertexCX { unit, value, uom}
                        vertexCY { unit, value, uom}
                        vertexDX { unit, value, uom}
                        vertexDY { unit, value, uom}
                    }
                }
                athlete {
                    id
                    name
                }
                duration {
                    unit
                    uom
                    value
                }
                totalTime {
                    unit
                    uom
                    value
                }
                distance {
                    unit
                    uom
                    value
                }
                equivalentDistanceIndex {
                    element {
                        unit
                        uom
                        value
                    }
                }
                averageP {
                    element {
                        unit
                        uom
                        value
                    }
                }
                mpe {
                    unit
                    uom
                    value
                }
                recoveryAverageTime {
                    unit
                    uom
                    value
                }
                accelerationEvents {
                    eventCount {
                        unit
                        uom
                        value
                    }
                }
            }
            rpeSet {
                id
                athlete{
                    name
                    id
                }
                duration
                value
            }
            category {
                id
                name
                color
            }
            complementaryData {
                roles {
                    kpi {
                        value
                        uom
                        unit
                        name
                        ready
                    }
                    nAthletes
                    playingRole
                }
                team {
                    kpi {
                        name
                        unit
                        uom
                        value
                        ready
                    }
                }
                averages
                relevantValues {
                    athletesessionId
                    kpi {
                      name
                      top
                      bottom
                      first
                    }
                }
                roles {
                    playingRole
                    nAthletes
                    kpi {
                        name
                        value
                        unit
                        uom
                    }
                }
            }
        }
    }
`;

export type GenericValueType = {
  unit: string;
  uom: string;
  value: number;
};

export type GenericZoneType = {
  absLowerBound: GenericValueType;
  absUpperBound: GenericValueType;
  distance: GenericValueType;
  extremes: number[];
  lowerBound: GenericValueType;
  thresholdIndex: number;
  time: GenericValueType;
  upperBound: GenericValueType;
};

export type GenericZoneEvent = {
  eventCount: GenericValueType;
  eventDuration: GenericValueType;
  thresholdIndex: number;
  thresholdValue: GenericValueType;
};

export type GenericBoundsType = {
  element: GenericValueType;
  lowerBound: GenericValueType;
  upperBound: GenericValueType;
};

export type GenericDict = {
  key: string;
  value: string | null;
};

export type GenericImuEventType = {
  data: GenericValueType[];
  id: string;
  props: GenericDict;
};

export type ImuType = {
  distance: GenericValueType;
  distanceBack: GenericValueType;
  distanceFront: GenericValueType;
  distanceLeft: GenericValueType;
  distanceRight: GenericValueType;
  eventsDivesByReturnTime: GenericImuEventType[];
  eventsDivesLeft: GenericImuEventType[];
  eventsDivesRight: GenericImuEventType[];
  eventsImpacts: GenericImuEventType[];
  eventsJumps: GenericImuEventType[];
};

export type BoundingBoxType = {
  area: number;
  coordinates: number[][];
};

export type PathType = {
  boundingBox: BoundingBoxType;
  data: number[][];
};

export type PowerTableType = {
  measure: String;
  nEvents: GenericValueType;
  thresholds: GenericValueType[];
};

export type PowerEventsType = {
  eventsAvDuration: GenericValueType;
  eventsAvPower: GenericValueType;
  eventsCount: GenericValueType;
  powertableDistance: PowerTableType[];
  powertableSpeed: PowerTableType[];
  powertableTime: PowerTableType[];
  recoveryAvPower: GenericValueType;
  recoveryAvTime: GenericValueType;
};

export type PowerEventPathType = {
  boundingBox: BoundingBoxType;
  colorData: number[];
  data: number[][];
};

export type GenericValueDict = {
  key: string;
  value: GenericValueType;
};

export type GenericScatterType = {
  group: number;
  id: string;
  props: GenericValueDict[];
  x: number;
  y: number;
};

export type SessionType = 'DRILL' | 'RPE' | 'RPE_ONLY' | 'STD';
export type TrackTeamSessionStateChoices = 'L' | 'P' | 'R' | 'LO';
export type WeatherType = {
  temperature: GenericValueType;
  visibility: GenericValueType;
  latitude: GenericValueType;
  longitude: GenericValueType;
  summary: string;
  icon: string;
};

export type IntervalType = {
  end: string
  start: string
}

export interface DrillType {
  end: string;
  id: number;
  index: number;
  intervals: IntervalType[]
  notes: string
  start: string
  tags: string[]
  totalTime: GenericValueType
}

export interface DrillsType {
  relatedDrills: DrillType[];
  wholeSession: DrillType;
}

export type TeamSessionTagsType = {
  createdOn: string;
  forcevelocityprofileSet: ASProfileType[];
  id: number;
  metabolicpowerprofileSet: MPProfileType[];
  name: string;
  team: TeamType;
  teamsessionSet: TeamSessionType[];
  updatedOn: string;
};

export interface TeamSessionTagsTypes {
  id: number;
  name: string;
  occurrences: number;
}

export type TeamSessionType = {
  athleteSessions: AthleteSessionType[];
  athleteSessionsCount: number;
  category: TeamSessionCategoryType;
  createdOn: string;
  drill: number;
  drills: DrillsType;
  drillCount: number;
  drillEnabled: boolean;
  endTimestamp: string;
  hasRpe: boolean;
  id: number;
  isStatsValid: boolean;
  nature: SessionType;
  notes: string;
  originalTeamsession: TeamSessionType;
  'hh:mm:ss format';
  startTimestamp: string;
  state: TrackTeamSessionStateChoices;
  submittedBy: string;
  tags: TeamSessionTagsTypes[];
  team: TeamType;
  totalTime: GenericValueType;
  updatedOn: string;
  weather: WeatherType;
  rpeSet: RpeRowType[];
  duration: number;
};

export interface AthleteSessionsType {
  KPIState: TrackAthleteSessionKpiStateChoices;
  accZones: GenericZoneType[];
  accelerationEvents: GenericZoneEvent;
  anaerobicEnergy: GenericValueType;
  athlete: AthleteType;
  averageHdop: GenericValueType;
  averageHr: GenericValueType;
  averageHrPercentual: GenericValueType;
  averageP: GenericBoundsType;
  averagePowerAer: GenericValueType;
  averageSatprsum: GenericValueType;
  averageV: GenericValueType;
  cardioZones: GenericZoneType[];
  createdOn: string;
  datetimeIntervals: string;
  decZones: GenericZoneType[];
  decelerationEvents: GenericZoneEvent;
  distRunning: GenericValueType;
  distWalking: GenericValueType;
  distance: GenericValueType;
  drill: number;
  duration: GenericValueType;
  eccentricIndexV1: GenericValueType;
  edwardsTl: GenericValueType;
  endDt: String;
  energyRunning: GenericValueType;
  energyWalking: GenericValueType;
  equivalentDistance: GenericValueType;
  equivalentDistanceIndex: GenericBoundsType;
  eventsActiveMuscleLoad: GenericValueType;
  eventsAverageActiveMusclePower: GenericValueType;
  eventsList: string[];
  id: number;
  imu: ImuType;
  isConsolidate: boolean;
  isStatsValid: boolean;
  kpi: KPIType[];
  layout: LayoutType[];
  locomotionActiveMuscleLoad: GenericValueType;
  locomotionAverageActiveMusclePower: GenericValueType;
  maxValuesAcc: GenericValueType;
  maxValuesCardio: GenericValueType;
  maxValuesDec: GenericValueType;
  maxValuesPower: GenericValueType;
  maxValuesSpeed: GenericBoundsType;
  mech: MechType;
  metabolicpowerprofileSet: MPProfileType[];
  mpe: GenericValueType;
  path: PathType;
  powerEvents: PowerEventsType;
  powerEventsAvgPower: GenericValueType;
  powerEventsAvgTime: GenericValueType;
  powerEventsCount: number;
  powerEventsPath: PowerEventPathType;
  powerEventsTotalTime: GenericValueType;
  powerZones: GenericZoneType[];
  powerseries: GenericScatterType[];
  recoveryAveragePower: GenericValueType;
  recoveryAverageTime: GenericValueType;
  relatedDrills: AthleteSessionDrillType[];
  rpe: GenericBoundsType;
  sampleIntervals: string;
  selfSampleIntervals: string;
  seriesList: string[];
  speedEvents: GenericZoneEvent;
  speedZones: GenericZoneType[];
  startDate: String;
  startTime: string;
  state: TrackAthleteSessionStateChoices;
  teamsession: TeamSessionType;
  timeRunning: GenericValueType;
  timeWalking: GenericValueType;
  tl: GenericValueType;
  totalDistance: GenericValueType;
  totalEnergy: GenericValueType;
  totalTime: GenericValueType;
  track: TrackType;
  trackEventSlope: number;
  trackSampleCount: number;
  updatedOn: string;
  wholeSessionDrill: AthleteSessionDrillType;
  nature: string;
}

export const TEAMSESSION_ATHLETES_SESSIONS = gql`
    query TeamSessionAthletesession(
        $id: ID!,
        $templateId: ID = null,
        $drill: ID = null,
        $fieldsLimit: Int,
    ) {
        res: teamSession(
            id: $id,
            drill: $drill,
            templateId: $templateId,
            fieldsLimit: $fieldsLimit
        ) {
            id
            drill
            athleteSessions {
                id
                masterAthleteSession
                drill
                state
                isStatsValid
                starter
                track {
                    id
                    hasCardio
                    athlete {
                        id
                        name
                        lastName
                        firstName
                    }
                }
                totalTime {
                    value
                    uom
                    unit
                }
                athlete {
                    id
                    lastName
                    firstName
                    name
                    shortName
                    playerSet {
                        number
                        team {
                            id
                        }
                    }
                }
                
                identifierKpi {
                    name
                    value
                    group
                    uom
                    unit
                }
                kpi {
                    name
                    value
                    group
                    uom
                    unit
                }
            }
        }
    }
`;

export const EXPORT_POSSIBLE = gql`
  query ExportPossible($teamId: ID!, $target: [String]) {
    res: exportPossible(teamId: $teamId, target: $target)
    {
      splitExport
      rwpExport
    }
    }
`;