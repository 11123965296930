import React, { useState, useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';
import LinkIcon from '@material-ui/icons/Link';
import { IconButton } from '@material-ui/core';
import { WebsocketRoomsContext } from 'Authenticator';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import BaseButton from 'components/form/BaseButton';
import SendIcon from '@material-ui/icons/Send';
import AnyIssuesIcon from '@images/icons/svg/anyIssues.svg';
import { useQueryCached } from 'components/utils/graphql';
import CancelIcon from '../../img/IconHeader/cancel.svg';
import UserReportedDrawer from '../UserIssues';
import { SideDrawer } from './SideDrawer';
import BridgeWidget from './BridgeWidget';
import NetworkMonitor from '../widgets/NetworkMonitor';
import { USER_ACADEMY } from '../../query/user';
import ACLWrapper from '../ACLWrapper';
import { CACHE_AND_NETWORK } from '../../lib/cache';
import WarningWidget from './WarningWidget';

const StyledIconButton = withStyles(() => ({
  root: {
    background: '#FFFFFF',
    border: '1px solid transparent',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 3px 14px rgba(0, 0, 0, 0.12)',
    width: 36,
    height: 36,
    '&&:hover': {
      background: 'rgba(0, 255, 210, 0.08)',
      border: '1px solid #00DDB5',
    },
    marginLeft: '1rem',
  },
}))(IconButton);

function AcademyAuthForm() {
  const [academyUser, setAcademyUser] = useState('');
  const [academyPwd, setAcademyPwd] = useState('');
  const [academyHost, setAcademyHost] = useState('');
  useQueryCached(USER_ACADEMY, {
    onCompleted: (data) => {
      setAcademyUser(data?.userAcademy?.login);
      setAcademyPwd(data?.userAcademy?.passwd);
      setAcademyHost(data?.userAcademy?.host);
    },
    ...CACHE_AND_NETWORK,
  });
  return (
    <form id="academy-login-form" action={`${academyHost}/wp-login.php`} method="post" hidden target="_blank">
      <input type="hidden" name="log" id="user_login" value={academyUser} />
      <input type="hidden" name="pwd" id="user_pass" value={academyPwd} />
    </form>
  );
}

function Footer(props: { menuIsOpen: boolean }) {
  const { t } = useTranslation();

  const [contentOpen, setContentOpen] = useState(false);
  const [sendIssue, setSendIssue] = useState(false);
  const [blockSend, setBlockSend] = useState(true);
  const { messages } = useContext(WebsocketRoomsContext);
  const installationAlert = messages['ws.installationAlert']?.value as string;
  const warningMessage = t('issues.content.placeholder.fieldsRequiredWarning', 'category, title and description are required.');

  return (
    <Box
      className="footer"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      boxShadow={3}
      position="fixed"
      bottom={0}
      left={props.menuIsOpen ? 235 : 77}
      right={0}
      bgcolor="background.paper"
      color="text.primary"
      zIndex="99"
    >
      <Box className="academy-button">
        <ACLWrapper section="academy" type="view" permission="link_view">
          <BaseButton
            flat
            startIcon={<LinkIcon />}
            hoverAll
            onClick={() => document.getElementById('academy-login-form').submit()}
          >
            {t('user.drawer.footer.academy')}
          </BaseButton>
        </ACLWrapper>
      </Box>
      <Box>
        <NetworkMonitor />
      </Box>
      <Box id="bridge-chat-box">
        <WarningWidget
          installationAlert={installationAlert}
        />
        <BridgeWidget />
        <BaseButton
          startIcon
          isIconButton
          marginRight
          onClick={() => {
            setContentOpen(true);
          }}
          tooltipText={t('issues.content.placeholder.anyIssues', 'any issues')}
        >
          <AnyIssuesIcon />
        </BaseButton>
      </Box>

      <SideDrawer
        open={contentOpen}
        setOpen={setContentOpen}
        title={t('issues.title', 'any issues? report here')}
        isLoading={sendIssue}
        customClasses="hide-on-screenshot"
        actionsLeft={[
          <BaseButton
            key="cancel"
            startIcon={<CancelIcon />}
            onClick={() => {
              setContentOpen(false);
            }}
            noMargins
          >
            {t('stats.drawer.button.cancel', 'cancel')}
          </BaseButton>,
        ]}
        actionsRight={[
          <BaseButton
            key="send"
            color="primary"
            startIcon={<SendIcon />}
            onClick={() => {
              setSendIssue(true);
            }}
            disabled={blockSend}
            isValidation={!blockSend}
            tooltipText={blockSend ? warningMessage : undefined}
            noMargins
          >
            {t('header.action.submit', 'submit')}
          </BaseButton>,
        ]}
      >
        <UserReportedDrawer
          sendIssue={sendIssue}
          setSendIssue={setSendIssue}
          setContentOpen={setContentOpen}
          setBlockSend={setBlockSend}
        />
      </SideDrawer>
      <AcademyAuthForm />
    </Box>
  );
}

export default Footer;
