import React from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { CodeRounded } from '@material-ui/icons'

function DevBar() {
  return process.env.NODE_ENV !== 'production' && (
  <Box className="dev-bar" component="div" padding="8px 15px" margin="8px 12px">
    <Link to="/test-components"><Tooltip arrow title="Test Components"><CodeRounded /></Tooltip></Link>
  </Box>
  )
  || null
}

export default DevBar
