import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownButton from 'components/DropdownButton';
import Box from '@material-ui/core/Box';
import { useQueryCached } from 'components/utils/graphql';
import { SERIES_LIST, SeriesListRes, SeriesListVars } from '../../query/track';

interface PropsCommon {
  className?: string;
  athleteSessionId?: string;
  trackId?: string;
  values: string[];
  disabled?: boolean;
  label?: string;
  disableMultipleSelection?: boolean;
}

interface PropsSingle {
  onChange: (series: null | string) => void;
  singleValue: true;
  disableMultipleSelection: true;
}

interface PropsMultiple {
  onChange: (series: null | string[]) => void;
  singleValue?: false;
}

type PropsIn = PropsCommon & PropsSingle | PropsCommon & PropsMultiple;

export default function SelectSeries(props: PropsIn) {
  const { t } = useTranslation();
  const {
    athleteSessionId, className, onChange, singleValue, trackId, values, disabled, label, disableMultipleSelection,
  } = props;

  const valuesList = values.map((value) => value.replace('hr', 'cardio'));

  const { data } = useQueryCached<SeriesListRes, SeriesListVars>(SERIES_LIST(trackId ? 'track' : 'session'), {
    variables: trackId
      ? {
        trackId,
      }
      : {
        id: athleteSessionId,
      },
    skip: !trackId && !athleteSessionId,
    /*
    * Apollo Client bug, using useEffect hook to update data

    onCompleted: () => (data) => {
      if (data.res?.seriesList?.length > 0) {
        setSeries(data.res.seriesList.filter((s) => (props.preset.split(',') || []).includes(s)))
      }
    },
    */
  });

  const handleClick = (e) => {
    if (e === 'cardio' || e === 'cardio_percentual') {
      const event = new CustomEvent('cardioClicked');
      window.dispatchEvent(event);
    }
    if (singleValue) {
      if (values === e) {
        onChange(null);
      } else {
        onChange(e);
      }
    } else if (values.includes(e)) {
      onChange(values.filter((s) => s !== e));
    } else {
      onChange([...values, e]);
    }
  };

  return (
    <Box className={className}>
      <DropdownButton
        label={label ? (label === 'speed' ? 'speed' : t(`series.${label}.label`)) : t('stats.series', 'series')}
        multiple={!singleValue}
        multipleSelection={!disableMultipleSelection}
        disabled={disabled}
        popoverContent={
          data?.res?.seriesList
            ?.filter((s) => s.indexOf('_events') === -1 && s.indexOf('event_') === -1 && s !== 'mark')
            .map((name) => ({
              value: name,
              label: t(`series.${name}.label`, { defaultValue: name }),
              onClick: () => handleClick(name),
            })) || []
        }
        small
        values={valuesList}
      />
    </Box>
  );
}
