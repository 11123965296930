import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import TitleView from '../layout/TitleView';

function TestTitleView() {
  return (
    <>
      <Typography variant="h5" gutterBottom>Title View</Typography>
      <Paper>

        <Box pt={2} display="flex" alignItems="center" justifyContent="center">
          <Row>

            <Column xs={2}>
              <Box p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <TitleView title="Small Title" size="small" smallMargin />
                <Typography variant="subtitle2" gutterBottom>18px</Typography>
              </Box>
            </Column>

            <Column xs={2}>
              <Box p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <TitleView title="Medium Title" size="medium" smallMargin />
                <Typography variant="subtitle2" gutterBottom>20px</Typography>
              </Box>
            </Column>

            <Column xs={2}>
              <Box p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <TitleView title="Large Title" size="large" smallMargin />
                <Typography variant="subtitle2" gutterBottom>30px</Typography>
              </Box>
            </Column>

          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestTitleView;
