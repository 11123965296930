import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import EventIcon from '@material-ui/icons/Event';
import { isValid, parse } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { enGB } from 'date-fns/locale';
import { dateTimeToUtc } from 'components/utils/utils';
import { DATE_FORMAT } from '../constants';

type PropsIn = {
  id?:string;
  label: string;
  date: Date | null;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  error?: boolean;
  helperText?: string;
  onBlur?: (date: Date | null) => void;
  onChange?: (date: Date | null) => void;
  placeholder?: string;
  keyboardOff?: boolean;
};

export default function DatePicker(props: PropsIn) {
  const {
    id, label, date, disabled, minDate, maxDate, readonly, required,
    onBlur, onChange, error, helperText, placeholder, keyboardOff,
  } = props;

  const isDisabled: boolean = disabled !== undefined ? disabled : false;
  const isReadonly: boolean = readonly !== undefined ? readonly : false;
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider locale={enGB} utils={DateFnsUtils}>
      <KeyboardDatePicker
        minDate={minDate || undefined}
        maxDate={maxDate || undefined}
        className={`datepicker-class${isDisabled && ' disabled' || ''}${isReadonly && ' readonly' || ''}`}
        disabled={isDisabled}
        readOnly={isReadonly}
        autoOk
        fullWidth
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        label={required ? `${label} *` : label}
        format={DATE_FORMAT}
        value={date ? dateTimeToUtc(new Date(date)) : null}
        error={error || false}
        helperText={helperText || ''}
        placeholder={placeholder || t('form.datePicker.datePlaceholder', { defaultValue: 'YYYY-MM-DD' })}
        keyboardIcon={!readonly ? <EventIcon /> : <svg width="5px" />}
        inputProps={{
          id,
          readOnly: isReadonly,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputAdornmentProps={{ position: 'end' }}
        onChange={(dt) => {
          if (onChange) {
            onChange(dt);
          }
        }}
        onBlur={(event) => {
          if (onBlur) {
            if (
              event.target.value === ''
            || !isValid(event.target.value)
            ) {
              onBlur(null);
              return;
            }

            onBlur(parse(event.target.value, DATE_FORMAT, new Date()));
          }
        }}
        onKeyDown={(e) => {
          if (keyboardOff) { // disable manual entry from keyboard
            e.preventDefault();
          }
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
