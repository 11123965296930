import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import BaseButton from '../form/BaseButton';
import { Loader } from '../../lib/icons';

function TestLocalLoading() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <>
      <Typography variant="h5" gutterBottom>Local Loading</Typography>
      <Paper>
        <Box p={2}>
          <BaseButton onClick={() => setIsLoading(!isLoading)}>
            TOGGLE LOADING STATUS:
            {' '}
            {isLoading ? 'LOADING' : 'NOT LOADING'}
          </BaseButton>
          {isLoading && (
            <Loader
              noPadding
              style={{
                width: 'auto',
              }}
            />
          )}
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestLocalLoading;
