import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TemplateSideDrawer from './_templates/TemplateSideDrawer';
import { AppBarContext } from './AppBarContext';
import Header from './layout/Header';
import TestACLWrapper from './_test_components/TestACLWrapper';
import TestBaseButton from './_test_components/TestBaseButton';
import TestBaseChip from './_test_components/TestBaseChip';
import TestBaseEditableTable from './_test_components/TestBaseEditableTable';
import TestCheckbox from './_test_components/TestCheckbox';
import TestColorPicker from './_test_components/TestColorPicker';
import TestColorPickerWrapper from './_test_components/TestColorPickerWrapper';
import TestDatepickerField from './_test_components/TestDatepickerField';
import TestDialogBox from './_test_components/TestDialogBox';
import TestDialogButton from './_test_components/TestDialogButton';
import TestDropzone from './_test_components/TestDropzone';
import TestEditableChip from './_test_components/TestEditableChip';
import TestEditableSelectField from './_test_components/TestEditableSelectField';
import TestSelectField from './_test_components/TestSelectField';
import TestEditableTable from './_test_components/TestEditableTable';
import TestEditableTableClientSide from './_test_components/TestEditableTableClientSide';
import TestEditableTextField from './_test_components/TestEditableTextField';
import TestErrorBoundary from './_test_components/TestErrorBoundary';
import TestGetGradientFn from './_test_components/TestGetGradientFn';
import TestGlobalLoading from './_test_components/TestGlobalLoading';
import TestHeaderPopover from './_test_components/TestHeaderPopover';
import TestIconWrapper from './_test_components/TestIconWrapper';
import TestImageTools from './_test_components/TestImageTools';
import TestListField from './_test_components/TestListField';
import TestLocalLoading from './_test_components/TestLocalLoading';
import TestModal from './_test_components/TestModal';
import TestMultiEditSideDrawer from './_test_components/TestMultiEditSideDrawer';
import TestTagsField from './_test_components/TestTagsField';
import TestTextField from './_test_components/TestTextField';
import TestTitleView from './_test_components/TestTitleView';
import TestUrlHook from './_test_components/TestUrlHook';
import TestUserContext from './_test_components/TestUserContext';
import TestLeaflet from './_test_components/TestLeaflet';
import ToolsSetup from './_test_components/ToolsSetup';
import TestGridSortable from './_test_components/TestGridSortable';
import TestMediaRecorder from './_test_components/TestMediaRecorder';

function TestComponents() {
  const headerContext = React.useContext(AppBarContext);

  React.useEffect(
    () => {
      headerContext.setContent(
        <Header />,
      );
    },
    [headerContext.setContent],
  );

  return (
    <>
      <ToolsSetup />
      <TestMediaRecorder />
      <TestGridSortable />
      <TestLeaflet />
      <Typography variant="h4" gutterBottom>Components</Typography>
      <TestBaseChip />
      <TestBaseButton />
      <TestCheckbox />
      <TestTextField />
      <TestEditableSelectField />
      <TestSelectField />
      <TestDatepickerField />
      <TestEditableTextField />
      <TestEditableTable />
      <TestBaseEditableTable />
      <TestEditableTableClientSide />
      <TestTagsField />
      <TestHeaderPopover />
      <TestGlobalLoading />
      <TestLocalLoading />
      <TestListField />
      <TestMultiEditSideDrawer />
      <TestEditableChip />
      <TestColorPicker />
      <TestColorPickerWrapper />
      <TestDialogButton />
      <TestDialogBox />
      <TestModal />
      <TestImageTools />
      <TestACLWrapper />
      <TestDropzone />
      <TestErrorBoundary />

      <Typography variant="h4" gutterBottom>Contexts</Typography>
      <TestUserContext />

      <Typography variant="h4" gutterBottom>Custom Hooks</Typography>
      <TestUrlHook />

      <Typography variant="h4" gutterBottom>Templates</Typography>
      <Typography variant="h5" gutterBottom>SideDrawer con logiche</Typography>
      <TemplateSideDrawer />

      <Typography variant="h4" gutterBottom>Functions</Typography>
      <Typography variant="h5" gutterBottom>getGradient(num)</Typography>

      <Divider style={{ margin: '1rem' }} />

      <TestGetGradientFn />

      <Divider style={{ margin: '2rem' }} />

      <TestIconWrapper />

      <Divider style={{ margin: '2rem' }} />

      <TestTitleView />
    </>
  );
}

export default TestComponents;
