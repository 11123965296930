import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import WarningButton from './WarningButton';

type propsIn = {
  installationAlert: string;
};

const WarningWidget = (props: propsIn) => {
  const { installationAlert } = props;
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const { t } = useTranslation();
  const [status, setStatus] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  let start = '';
  let end = '';

  if (installationAlert) {
    [start, end] = installationAlert.split('-');
  }

  useEffect(() => {
    const shouldShowSnackbar = localStorage.getItem('showSnackbarAfterReload');

    if (shouldShowSnackbar) {
      enqueueSnackbar(t('global.installation.pageUpdated', 'version successfully updated'), { variant: 'success' });
      localStorage.removeItem('showSnackbarAfterReload');
    }
  }, [enqueueSnackbar, t]);

  const handleReload = () => {
    localStorage.setItem('showSnackbarAfterReload', 'true');
    window.location.reload(true);
  };

  return (
    <div className="warning-section">
      {(installationAlert || status === 1) && (
        <div
          onMouseEnter={() => setIsButtonHovered(true)}
          onMouseLeave={() => setIsButtonHovered(false)}
        >
          {isButtonHovered && status === 1 ? (
            <Tooltip arrow placement="top" title={t('global.version.oldStatus', 'new version available, click here')}>
              <span>
                <WarningButton onClick={handleReload} />
              </span>
            </Tooltip>
          ) : isButtonHovered && installationAlert ? (
            <Tooltip
              arrow
              placement="top"
              title={(
                <Trans
                  i18nKey="global.installation.title"
                  defaults="a new version will be installed from {start} {end}"
                  values={{
                    start,
                    end,
                  }}
                />
              )}
            >
              <span>
                <WarningButton />
              </span>
            </Tooltip>
          ) : (
            <WarningButton />
          )}
        </div>
      )}
    </div>
  );
};

export default WarningWidget;
