import { useState, useEffect } from 'react';

export default function useLocalStorage(key, initialValue, dependency) {
  // Initialize state with value from localStorage or the initial value
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    // Update localStorage whenever the value changes
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  useEffect(() => {
    // re-fetch value from localStorage when the dependency changes
    const storedValue = localStorage.getItem(key);
    setValue(storedValue ? JSON.parse(storedValue) : initialValue);
  }, [key, dependency]);

  return [value, setValue];
}

