import { getQueryParams } from '../components/Table';

// extend btoa function to also encode text containing non-ASCII characters
export const encodeTextToBase64 = (text : string) : string => {
  const utf8Encoded = unescape(encodeURIComponent(text));
  return window.btoa(utf8Encoded);
};
// inverse of encodeTextToBase64
export const decodeBase64ToText = (base64String : string) : string => {
  const binaryData = window.atob(base64String);
  return decodeURIComponent(escape(binaryData));
};

const dataHydration = (d: any) => ({
  d,
  t: (new Date()).getTime(),
});

export const isQuotaExceededError = (err: unknown): boolean => (
  err instanceof DOMException
    // tutti eccetto Firefox
    && (err.code === 22
      // Firefox
      || err.code === 1014
      // testa anche il nome del campo
      // eccetto Firefox
      || err.name === 'QuotaExceededError'
      // Firefox
      || err.name === 'NS_ERROR_DOM_QUOTA_REACHED')
);

export const getLocalStorageSize = () => {
  let lsTotal = 0;
  let xLen;

  Object.keys(localStorage).forEach((x) => {
    xLen = ((localStorage[x].length + x.length) * 2);
    lsTotal += xLen;
  });

  return lsTotal;
};

export const tableSettingsGC = () => {
  console.info('[TGC] running...');
  const timeLimit = (new Date()).getTime() - 15552000000; // 6 mesi
  const keysRegex = /t:(\d+):(o|f|p):(.*)/;
  const localStorageKeys = Object.keys(localStorage).filter((k) => k.match(keysRegex));
  const storageTimeStamps: number[] = [];

  // prima ripulisco le chiavi vecchie (> 6 mesi)
  localStorageKeys.forEach((k) => {
    const value = localStorage.getItem(k);
    try {
      const item = value && JSON.parse(value);

      if (item) {
        storageTimeStamps.push(item.t); // salvo il time stamp

        if (item.t < timeLimit) {
          localStorage.removeItem(k);
        }
      }
    } catch (e) {
      console.log(e);
    }
  });

  // ipotizziamo limite a 5 mega, trigger a 4.5MB
  const nearLocalStorageLimit = getLocalStorageSize() >= 4500000;
  let shouldRunNextTime = false;

  if (nearLocalStorageLimit) {
    // cerchiamo le chiavi più vecchie ed elimino tutte quelle di quella data o nei 15 gg precedenti
    // eslint-disable-next-line prefer-spread
    const oldest = Math.max.apply(Math, storageTimeStamps);
    const oldestTimeLimit = oldest - 1296000000; // 15 gg

    // elimino tutte le entry che sono nei 15gg precedenti quella più vecchia
    localStorageKeys.forEach((k) => {
      const value = localStorage.getItem(k);
      try {
        const item = value && JSON.parse(value);

        if (item) {
          if (item.t < oldestTimeLimit) {
            localStorage.removeItem(k);
            shouldRunNextTime = true;
          }
        }
      } catch (e) {
        console.log(e);
      }
    });

    if (shouldRunNextTime) {
      // in questo caso ci sono state operazioni di pulizia, se serve potrò rilanciare il GC
      tableSettingsGC();
      console.info('[TGC] continue');
      return false;
    }

    // non ho potuto cancellare nulla, mi fermo, non dipende da questo store
    console.info('[TGC] Ko!');
    return true;
  }

  // true, c'è spazio
  console.info('[TGC] Ok!');
  return true;
};

export const saveTableFilters = (teamId: string, key: string, filters: { [k: string]: any }) => {
  // console.log('saveTableFilters', teamId, key, filters);
  if (!key) {
    return;
  }

  try {
    localStorage.setItem(`t:${teamId}:f:${key}`, JSON.stringify(dataHydration(filters)));
  } catch (e) {
    if (isQuotaExceededError(e)) {
      console.warn('localStorage limit hit!');
      tableSettingsGC();
    } else {
      console.error(e);
    }
  }
};

/**
 *
 * @description Funzione per recuperare i filtri dal `localStorage`.
 * I dati recuperati dal `localStorage` sono salvati come **stringhe**.
 * Da qui il parametro `processinFn` che permette ad esempio il ripristino di stringhe data in oggetti `Date`
 *
 * @param teamId
 * @param key chiave per salvare il filtro
 * @param processingFn opzionale, funzione che processa i dati al restore
 *
 * @example Recupero i filtri e processo eventuali valori data
 *
 *
 * // funzione per processare i dati caricati dal `localStorage`
 *   const processFiltersValuesFromDB = (fs) => ({
 *     ...fs,
 *     ...{
 *       startUploadedDate: fs.startUploadedDate && new Date(fs.startUploadedDate),
 *     },
 *   });
 *
 *   // passo anche la funzione oltre la chiave
 *   loadTableFilters(teamId, tableKey, processFiltersValuesFromDB);
 *
 *
 */
export const loadTableFilters = (teamId:string, key: string, processingFn?: (value: any) => any) => {
  // console.log('loadTableFilters', teamId, key, processingFn);
  if (!key) {
    return undefined;
  }

  try {
    const data = localStorage.getItem(`t:${teamId}:f:${key}`);
    let obj;

    obj = data && JSON.parse(data);
    obj = obj && obj.d;

    return obj
      ? processingFn
        ? processingFn(obj)
        : obj
      : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const saveTableOptions = (teamId: string, key: string, options: { [k: string]: any }) => {
  // console.log('saveTableOptions', teamId, key, options);
  try {
    localStorage.setItem(`t:${teamId}:o:${key}`, JSON.stringify(dataHydration(options)));
  } catch (e) {
    if (isQuotaExceededError(e)) {
      console.warn('localStorage limit hit!');
      tableSettingsGC();
    } else {
      console.error(e);
    }
  }
};

export const loadTableOptions = (teamId: string, key: string) => {
  // console.log('loadTableOptions', teamId, key, queryParams);
  if (!key) {
    return undefined;
  }
  const queryParams = getQueryParams();
  const optionsQueryKey = `td${teamId}_options-${key}`;

  if (queryParams && queryParams[optionsQueryKey]) {
    try {
      return JSON.parse(decodeBase64ToText(queryParams[optionsQueryKey]));
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  try {
    const data = localStorage.getItem(`t:${teamId}:o:${key}`);
    const obj = data && JSON.parse(data);

    return obj && obj.d;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export interface QueryParamsI {
  [p: string]: any
}

export const saveQueryParams = (teamId: string, key: string | undefined, params: QueryParamsI) => {
  // console.log('saveQueryParams', teamId, key, params);
  if (!key) {
    return;
  }

  try {
    localStorage.setItem(`t:${teamId}:p:${key}`, JSON.stringify(
      dataHydration(
        encodeTextToBase64(
          JSON.stringify(params),
        ),
      ),
    ));
  } catch (e) {
    if (isQuotaExceededError(e)) {
      console.warn('localStorage limit hit!');
      tableSettingsGC();
    } else {
      console.error(e);
    }
  }
};

export const loadQueryParams = (teamId: string, key: string | undefined) => {
  // console.log('loadQueryParams', teamId, key);
  if (!key) {
    return undefined;
  }

  try {
    const data = localStorage.getItem(`t:${teamId}:p:${key}`);

    if (data) {
      const obj = JSON.parse(data);
      return obj?.d && JSON.parse(decodeBase64ToText(obj.d));
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }

  return undefined;
};
