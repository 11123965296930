import React, { useState, useEffect, useContext } from 'react';
import Table, { TableOptions } from 'components/Table';
import {
  getUomTranslation,
  MIN_ROWS_PER_PAGE, RangeLabel, secondsToHHMMSS, secondsToMmSs,
} from 'components/utils/utils';
import { Box, Radio } from '@material-ui/core';
import { greenColors, blueColors, redColors } from 'components/constants';
import { useTranslation } from 'react-i18next';
import { CACHE_AND_NETWORK } from 'lib/cache';
import { useQueryCached, useLazyQueryCached } from 'components/utils/graphql';
import HorizontalPercentageBarChart from '../charts/HorizontalPercentageBarChart';
import Error from '../layout/Error';
import { Loader } from '../../lib/icons';
import { GlobalFiltersContext } from './View';
import { getPrimaryColorGradient } from '../utils/colors';

type ZonesTableProps = {
  templateId: string;
  trackId?: string;
  athleteSessionId?: string;
  timeEnabled?: boolean
  distanceEnabled?: boolean
  query: any;
  currentDrill?: number,
};

function GenericZoneChart(props: ZonesTableProps & {
  zoneName: string;
  measurePrefix: string;
  // colorMap: string[]
}) {
  const {
    templateId, trackId, athleteSessionId, query, zoneName, measurePrefix,
    // colorMap,
    timeEnabled, distanceEnabled, currentDrill,
  } = props;

  const { t } = useTranslation();

  const { filters } = useContext(GlobalFiltersContext);

  const [options, setOptions] = useState<{ type: string }>({
    type: timeEnabled ? 'time' : 'dist',
  });
  const [loadData, { error, loading, data }] = useLazyQueryCached(query, {
    variables: trackId
      ? {
        templateId,
        trackId,
        start: filters.min,
        end: filters.max,
      }
      : {
        templateId,
        id: athleteSessionId,
        drill: currentDrill,
        start: filters.min,
        end: filters.max,
      },
  });

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [filters.min, filters.max]);

  let total = 0;

  const changeType = (event) => setOptions({ type: event.currentTarget.value });

  if (loading) return <Loader />;
  if (error) return <Error />;

  return (
    <Box>
      {(
        (data?.res
          && data.res[zoneName]?.map((value, i) => {
            const isDistance = options.type === 'dist';
            total += (isDistance ? value?.distance?.value : value?.time?.value) || 0;

            return {
              id: `${measurePrefix}-${i + 1}`,
              key: `${measurePrefix}-${i + 1}`,
              values: {
                zone: `Z${i + 1}`,
                lowerBound: {
                  ...value.lowerBound,
                  ...{ value: value.lowerBound?.value && Number(value.lowerBound?.value).toFixed(2) },
                },
                upperBound: {
                  ...value.upperBound,
                  ...{ value: value.upperBound?.value && Number(value.upperBound?.value).toFixed(2) },
                },
              },
              time: value.time.value,
              distance: value?.distance?.value || 0,
              uom: isDistance ? getUomTranslation(t, value?.distance?.uom) : 'mm:ss',
            };
          }))
        || []
      )
        // .filter((zone) => typeof zone.distance === 'number')
        .reverse()
        .map((zone, id, arr) => (
          <HorizontalPercentageBarChart
            title={zone.values.zone}
            subtitle={RangeLabel({ lowerBound: zone.values.lowerBound, upperBound: zone.values.upperBound })}
            percentage={((options.type === 'dist' ? zone.distance : zone.time) / total) * 100}
            value={options.type === 'dist' ? zone.distance : zone.time ? secondsToMmSs(zone.time) : '-'}
            unit={options.type === 'dist' && 'distance'}
            colorStart={getPrimaryColorGradient(arr.length).reverse()[id]}
            uom={zone.uom}
            key={id}
          />
        ))}

      {timeEnabled && distanceEnabled && (
      <Box className="zone-radio-box">
        <label className="radiobutton-label">
          <Radio
            checked={options.type === 'dist'}
            onChange={changeType}
            value="dist"
            inputProps={{ 'aria-label': 'dist' }}
          />
          {t('track.widgets.labels.distPerZone', 'dist per zone')}
        </label>

        <label className="radiobutton-label">
          <Radio
            checked={options.type === 'time'}
            onChange={changeType}
            value="time"
            inputProps={{ 'aria-label': 'time' }}
          />
          {t('track.widgets.labels.timePerZone', 'time per zone')}
        </label>
      </Box>
      )}
    </Box>
  );
}

function SpeedZoneChart(props: ZonesTableProps) {
  const { templateId, trackId, query } = props;
  const { t } = useTranslation();
  const [options, setOptions] = useState<any>({
    type: 'time',
  });
  const { error, data } = useQueryCached(query, {
    variables: {
      templateId,
      trackId,
    },
    ...CACHE_AND_NETWORK,
  });

  let total = 0;

  const changeType = (event) => setOptions({ type: event.currentTarget.value });

  if (error) return <Error />;

  return (
    <Box>
      {(
        data?.res?.speedZones.map((value, i) => {
          const isDistance = options.type === 'dist';
          total += (isDistance ? value?.distance?.value : value?.time?.value) || 0;

          return {
            id: `speedzone-${i + 1}`,
            key: `speedzone-${i + 1}`,
            speedBlank: {
              zone: `Z${i + 1}`,
              lowerBound: {
                ...value.lowerBound,
                ...{ value: Number(value.lowerBound?.value || 0).toFixed(2) },
              },
              upperBound: {
                ...value.upperBound,
                ...{ value: Number(value.upperBound?.value || 0).toFixed(2) },
              },
            },
            speedTime: value.time.value,
            speedDistance: value?.distance?.value || '-',
            uom: isDistance ? 'm' : 'mm:ss',
          };
        }) || []
      )
        .filter((zone) => typeof zone.speedDistance === 'number')
        .map((zone, id) => (
          <HorizontalPercentageBarChart
            title={zone.speedBlank.zone}
            subtitle={RangeLabel({ lowerBound: zone.speedBlank.lowerBound, upperBound: zone.speedBlank.upperBound })}
            percentage={((options.type === 'dist' ? zone.speedDistance : zone.speedTime) / total) * 100}
            value={options.type === 'dist' ? zone.speedDistance : zone.speedTime ? secondsToMmSs(zone.speedTime) : '-'}
            colorStart={greenColors[id]}
            uom={zone.uom}
            key={id}
          />
        ))}

      <Box className="zone-radio-box">
        <label className="radiobutton-label">
          <input
            onChange={changeType}
            type="radio"
            name="track-zone-type"
            value="dist"
            defaultChecked={options.type === 'dist'}
          />
          {t('track.widgets.labels.distPerZone', 'dist per zone')}
        </label>
        <label className="radiobutton-label">
          <input
            onChange={changeType}
            type="radio"
            name="track-zone-type"
            value="time"
            defaultChecked={options.type === 'time'}
          />
          {t('track.widgets.labels.timePerZone', 'time per zone')}
        </label>
      </Box>
    </Box>
  );
}

function AccZoneChart(props: ZonesTableProps) {
  const { templateId, trackId, query } = props;

  const { error, data } = useQueryCached(query, {
    variables: {
      templateId,
      trackId,
    },
    ...CACHE_AND_NETWORK,
  });
  if (error) return <Error />;

  let total = 0;

  return (
    <Box>
      {(
        data?.res?.accZones.map((value, i) => {
          const currentTime = value.time.value ? secondsToHHMMSS(value.time.value) : '-';
          total += value?.distance?.value || 0;
          return {
            key: `acczone-${i + 1}`,
            values: {
              name: `Z${i + 1}`,
              subtitle: RangeLabel({ lowerBound: value.lowerBound, upperBound: value.upperBound }),
              value: currentTime,
              percentage: value.distance.value,
            },
          };
        }) || []
      )
        .filter((zone) => typeof zone.values.percentage === 'number')
        .map((zone, id) => (
          <HorizontalPercentageBarChart
            title={zone.values.name}
            subtitle={zone.values.subtitle}
            percentage={(zone.values.percentage / total) * 100}
            value={zone.values.value}
            colorStart={blueColors[id]}
            key={id}
          />
        ))}
    </Box>
  );
}

function DecZoneChart(props: ZonesTableProps) {
  const { templateId, trackId, query } = props;

  const { error, data } = useQueryCached(query, {
    variables: {
      templateId,
      trackId,
    },
    ...CACHE_AND_NETWORK,
  });

  let total = 0;

  if (error) return <Error />;

  return (
    <Box>
      {(
        data?.res?.decZones.map((value, i) => {
          const currentTime = value.time.value ? secondsToHHMMSS(value.time.value) : '-';
          total += value?.distance?.value;

          return {
            key: `acczone-${i + 1}`,
            values: {
              name: `Z${i + 1}`,
              subtitle: RangeLabel({ lowerBound: value.lowerBound, upperBound: value.upperBound }),
              value: currentTime,
              percentage: value.distance.value,
            },
          };
        }) || []
      )
        .filter((zone) => typeof zone.values.percentage === 'number')
        .map((zone, id) => (
          <HorizontalPercentageBarChart
            title={zone.values.name}
            subtitle={zone.values.subtitle}
            percentage={(zone.values.percentage / total) * 100}
            value={zone.values.value}
            colorStart={blueColors[id]}
            key={id}
          />
        ))}
    </Box>
  );
}

function PowerZoneChart(props: ZonesTableProps) {
  const { templateId, trackId, query } = props;

  const { error, data } = useQueryCached(query, {
    variables: {
      templateId,
      trackId,
    },
    ...CACHE_AND_NETWORK,
  });

  let total = 0;

  if (error) return <Error />;

  return (
    data?.res?.powerZones.map((value, i) => {
      const currentTime = value.time.value ? secondsToHHMMSS(value.time.value) : '-';
      total += value?.distance?.value;
      return {
        key: `powerzone-${i + 1}`,
        values: {
          name: `Z${i + 1}`,
          subtitle: RangeLabel({ lowerBound: value.lowerBound, upperBound: value.upperBound }),
          value: currentTime,
          percentage: value.distance.value,
        },
      };
    }) || []
  )
    .filter((zone) => typeof zone.values.percentage === 'number')
    .map((zone, id) => (
      <HorizontalPercentageBarChart
        title={zone.values.name}
        subtitle={zone.values.subtitle}
        percentage={(zone.values.percentage / total) * 100}
        value={zone.values.value}
        colorStart={redColors[id]}
        key={id}
      />
    ));
}

function CardioZoneChart(props: ZonesTableProps) {
  const { templateId, trackId, query } = props;
  const [options, setOptions] = useState<TableOptions>({
    rowsPerPage: MIN_ROWS_PER_PAGE,
    page: 0,
  });
  const { loading, error, data } = useQueryCached(query, {
    variables: {
      templateId,
      trackId,
    },
    ...CACHE_AND_NETWORK,
  });
  if (error) return <Error />;

  const headers = [
    { key: 'cardio-blank', value: 'zones', align: 'sx' },
    { key: 'cardio-time', value: 'time', align: 'dx' },
    { key: 'cardio-distance', value: 'distance', align: 'dx' },
  ];
  return (
    <Table
      loading={loading}
      // count={data?.res?.count || 0}
      options={options}
      onOptionsChange={(o) => setOptions(o)}
      headers={headers}
      rows={
        data?.res?.cardioZones.map((value, i) => {
          const currentTime = value.time.value ? secondsToHHMMSS(value.time.value) : '-';
          return {
            key: `cardio-${i + 1}`,
            values: [
              <span>
                Z
                {i + 1}
                {' '}
                (
                {value.lowerBound?.value || 0}
                {' '}
                -
                {value.upperBound?.value}
                )
              </span>,
              currentTime,
              <span>{value.distance.value || '-'}</span>,
            ],
          };
        }) || []
      }
    />
  );
}

export {
  AccZoneChart,
  DecZoneChart,
  SpeedZoneChart,
  PowerZoneChart,
  CardioZoneChart,
  GenericZoneChart,
  redColors,
  greenColors,
  blueColors,
};
