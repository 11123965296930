import { createSearchParams, useSearchParams } from 'react-router-dom';
import {
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';

/**
 * Hook per utilizzare i query params
 *
 * Funzionamento simile a use state, con la differenza che aggiorna i parametri del URL. *
 *
 * @return {[{}, ((values, options?: {replace?: boolean | undefined, state?: any}) => void), {[p: string]: any}]}
 * @example Utilizzo base, aggiunge (push) un punto nella storia di navigazione
 * const [queryParams, setQueryParams] = useQueryParams();
 *
 * setQueryParams({
 *   ...queryParams,
 *   ...{
 *     page: 3,
 *   },
 * });
 *
 * @example Replace del punto corrente nella storia di navigazione
 * <!-- consigliabile nel caso di "redirect" dopo aggiornamento dei parametri. Questo evita di dover fare doppio click
 * per tornare indietro.
 * Caso d'uso: accedo ad un indirizzo e devo aggiornare i parametri del url per indicare che è attiva la prima tab.
 * Senza "replace" `/urlpagina` => `/urlpagina?tab=1`,  se clicco indietro prima torno a `/urlpagina` e poi alla precendete
 *
 * Con "replace" `/urlpagina` viene rimpiazzato da `/urlpagina?tab=1`, se clicco indietro non troverò più `/urlpagina`
 * nella storia di navigazione. -->
 *
 * const [queryParams, setQueryParams] = useQueryParams();
 *
 * setQueryParams({
 *   ...queryParams,
 *   ...{
 *     page: 3,
 *   },
 * }, {replace: true}); <-- va aggiunto questo
 */
export const useQueryParams = (): [
  { [k: string]: any },
  (values: { [k: string]: any }, options?: {replace?: boolean | undefined, state?: any}) => void,
  { [k: string]: any }
] => {
  const [queryParams, setQueryParams] = useSearchParams();
  const prevQueryParamsRef = useRef<{ [k: string]: any }>({});

  const filterValues = (values, options?: {replace?: boolean | undefined, state?: any}) => {
    const newQueryParams = createSearchParams(Object.keys(values).reduce((acc, curr) => {
      if (
        !Array.isArray(values[curr]) && values[curr]
        || Array.isArray(values[curr]) && values[curr].length > 0
      ) {
        acc[curr] = values[curr];
      }
      return acc;
    }, {}));

    setQueryParams(newQueryParams, {
      ...options,
      ...{
        state: options?.state || window.history.state,
      },
    });
  };

  const out = {};

  queryParams.forEach((v, k) => {
    out[k] = v;
  });

  useEffect(() => {
    prevQueryParamsRef.current = out;
  }, [out]);

  return [out, filterValues, prevQueryParamsRef.current];
};

export const useStateWithCallback = <T>(initialState: T, callback: Function | undefined = undefined) => {
  const [state, setState] = useState<T>(initialState);

  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current && callback) {
      callback(state);
    } else {
      didMount.current = true;
    }
  }, [state, callback]);

  return [state, setState];
};

export const useStateWithCallbackInstant = (initialState, callback) => {
  const [state, setState] = useState(initialState);

  const didMount = useRef(false);

  useLayoutEffect(() => {
    if (didMount.current) {
      callback(state);
    } else {
      didMount.current = true;
    }
  }, [state, callback]);

  return [state, setState];
};

export const useStateWithCallbackLazy = (initialValue) => {
  const callbackRef = useRef(null);

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (callbackRef.current) {
      // @ts-ignore
      callbackRef.current(value);

      callbackRef.current = null;
    }
  }, [value]);

  const setValueWithCallback = useCallback(
    (newValue, callback) => {
      callbackRef.current = callback;

      return setValue(newValue);
    },
    [],
  );

  return [value, setValueWithCallback];
};
