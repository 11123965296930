import React from 'react';
import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import StyledHeaderIconButton from './HeaderIconButton';
import BaseButton from './form/BaseButton';

type PropsIn = {
  icon: React.ReactElement;
  label: string;
  disabled?: boolean;
  hasShadow?: boolean;
  isDropdown?: boolean;
  tooltip?: string;
  action?: (_e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: 'medium' | 'large' | 'small';
};

export default function CustomButton(props: PropsIn) {
  const {
    icon, label, disabled, isDropdown, tooltip, action, size,
  } = props;
  return (
    <Tooltip
      arrow
      title={tooltip || ''}
      aria-label={tooltip || ''}
      disableFocusListener={!tooltip}
      disableHoverListener={!tooltip}
      disableTouchListener={!tooltip}
    >
      {label ? (
        <BaseButton
          style={{ margin: 0 }}
          className="filter-button"
          onClick={(e) => {
            action(e);
          }}
          disabled={disabled}
          size={size || 'large'}
          disableTouchRipple
          disableRipple
          startIcon={icon}
          endIcon={isDropdown ? <ArrowDropDownIcon /> : null}
          noMargins
        >
          {label}
        </BaseButton>
      ) : (
        <StyledHeaderIconButton
          onClick={(e) => {
            action(e);
          }}
        >
          {icon}
        </StyledHeaderIconButton>
      )}
    </Tooltip>
  );
}
