import { colorsPalette, getPrimaryColorGradient } from '../utils/colors';

export const eventsColors = colorsPalette === 'green' ? getPrimaryColorGradient(4).slice(1) : getPrimaryColorGradient(3);

export const eventsColorByName = {
  low: eventsColors[0],
  medium: eventsColors[1],
  high: eventsColors[2],
};

export const eventsColorByNameFMS = {
  slow: eventsColors[0],
  medium: eventsColors[1],
  fast: eventsColors[2],
};
