import { blue } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'

const StyledHeaderIconButton = withStyles(() => ({
  root: {
    background: '#FFFFFF',
    border: '1px solid #828282',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 3px 14px rgba(0, 0, 0, 0.12)',
    width: 48,
    height: 48,
    '&&:hover': {
      background: 'rgba(0, 255, 210, 0.08)',
      border: '1px solid #00DDB5',
    },
  },
}))(IconButton)

export default StyledHeaderIconButton
