import React, { useState } from 'react';
import BaseChip, { PropsIn as BaseChipProps } from './BaseChip';
import EditableTextField from './EditableTextField';

interface PropsIn {
  onSave: (value: string) => void
}

export default function EditableChip(props: BaseChipProps & PropsIn) {
  const { label, onSave } = props;
  const [isEditing, setIsEditing] = useState(false);

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const handleClear = () => {
    setTimeout(() => {
      setIsEditing(false);
    }, 0);
  };

  const handleSave = (e) => {
    onSave(e);
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setIsEditing(false);
    }
  }

  return (
    <>
      {
        !isEditing
        && (
          <BaseChip
            onClick={handleClick}
            {...props}
          />
        )
      }
      {
        isEditing
        && (
          <EditableTextField
            onSave={handleSave}
            defaultValue={label as string || ''}
            onClear={handleClear}
            forceFocus
            className="editable-chip"
            onKeyDown={handleKeyDown}
          />
        )
      }
    </>
  );
}
