import React, {
  useState, useEffect, createContext, useContext, useMemo,
} from 'react';
import { useMutation } from '@apollo/client';
import Breadcrumbs from 'components/Breadcrumbs';
import Header from 'components/layout/Header';
import { DATE_TIME_FORMAT, SNACKBAR_TIMEOUT, SNACKBAR_TIMEOUT_LONGER } from 'components/constants';
import { ATHLETE_SESSION_BY_TRACK } from 'query/athletesessions';
import Paper from '@material-ui/core/Paper';
import { AppBarContext } from 'components/AppBarContext';
import format from 'date-fns/format';
import { Box, Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { format as dateFormat } from 'date-fns';
import { DeleteButton } from 'components/form/DeleteButton';
import DeleteDialogBox from 'components/form/DeleteDialogBox';
import { CACHE_AND_NETWORK } from 'lib/cache';
import { OutlinedWrapper } from 'components/form/FieldsGroup';
import { IconWrapper, Loader } from 'lib/icons';
import { useQueryCached, useLazyQueryCached, get404Responses } from 'components/utils/graphql';
import { withMe } from 'components/MeUserWrapper';
import DialogBox from 'components/DialogBox';
import ConfirmIcon from '@images/icons/svg/confirm.svg';
import TrackTabContainer from './TrackTabContainer';
import ChartContainer from './TrackChartContainer';
import { GROUNDS_BY_CLUB } from '../../query/grounds';
import EditableTextField from '../form/EditableTextField';
import {
  DELETE_TRACK,
  DeleteTrackRes,
  DeleteTrackVars, INTERPOLATE_SERIES, InterpolateSeriesVars,
  MORE_INFO,
  MoreInfoRes,
  MoreInfoVars, REMOVE_CARDIO,
  UPDATE_TRACK_FULL,
} from '../../query/track';
import EditableSelectField from '../form/EditableSelectField';
import { AthleteOptionsRes, AthleteOptionsVars, ATHLETES_OPTIONS } from '../../query/athlete';
import InfoIcon from '../../img/IconHeader/info.svg';
import CancelIcon from '../../img/IconHeader/cancel.svg';
import { SideDrawer } from '../layout/SideDrawer';
import { TextField } from '../form/TextField';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import BaseButton from '../form/BaseButton';
import Error from '../layout/Error';
import { ExportButton } from '../form/ExportButton';
import { EXPORT_DATETIME_FORMAT } from '../../mutation/export';
import { getAthleteName, getLocalPicturePath } from '../utils/athlete';
import AthleteCardBasic from '../AthleteCardBasic';
import { LoadingContext, FilesTrackFilterContext } from '../../Authenticator';
import ACLWrapper, { UserContext } from '../ACLWrapper';
import PageMessage from '../PageMessage';
import { globalEventsChannel } from '../../lib/events';
import { useQueryParams } from '../utils/hooks';
import HeaderPopover from '../layout/HeaderPopover';
import Page404 from '../Page404';
import {
  AthleteSessionType,
  QueryTrackAthletesessionArgs,
  GroundTypeRes,
  QueryGroundsArgs, MutationUpdateTrackArgs, TrackType,
} from '../../__generated__/graphql';

export type TrackExportDataType = {
    id: string,
    user: string,
    active_series: string,
    start_ts: string | null,
    end_ts: string | null,
    new_web_app: true,
}

type PropsIn = {
  teamId: string;
  templateId: string;
  trackId: string;
  userId: string;
  isTeamLocked: string;
};

export const GlobalFiltersContext = createContext<{
  filters: {
    min?: number;
    max?: number;
  };
  setFilters:(range: any) => void;
    }>({
      filters: {},
      setFilters: () => {},
    });

const TrackView = (props: PropsIn) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const headerContext = useContext(AppBarContext);
  const [queryParams] = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isTeamLocked, templateId, trackId, teamId, userId,
  } = props;

  const [changeDevice] = useState(false);
  const [showCardioButton, setShowCardioButton] = useState(false);
  const [moreInfoDrawerOpen, setMoreInfoDrawerOpen] = useState(false);
  const [deleteTrackConfirmationOpen, setDeleteTrackConfirmationOpen] = useState(false);
  const [hrDataOpenModal, setHrDataOpenModal] = useState({ remove: false, replace: false });
  const [loadingHrData, setLoadingHrData] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const userContext = useContext(UserContext);
  const { setIncomingFileId } = useContext(FilesTrackFilterContext);
  const isStaff = userContext.user?.isStaff;

  const [filters, setFilters] = useState({
    min: queryParams.start ? parseInt(queryParams.start, 10) : undefined,
    max: queryParams.end ? parseInt(queryParams.end, 10) : undefined,
  });

  useEffect(() => {
    const handleCardioClicked = () => {
      setShowCardioButton((prev) => !prev);
    };
    window.addEventListener('cardioClicked', handleCardioClicked);
    return () => {
      window.removeEventListener('cardioClicked', handleCardioClicked);
    };
  }, []);

  useEffect(() => () => {
    setIsLoading(false);
  }, []);

  const [updateTrack, updateTrackData] = useMutation<TrackType, MutationUpdateTrackArgs>(UPDATE_TRACK_FULL, {
    onCompleted: () => {
      navigate('/tracks');
    },
  });

  const [deleteTrack] = useMutation<DeleteTrackRes, DeleteTrackVars>(DELETE_TRACK, {});
  const [removeCardio] = useMutation<DeleteTrackRes, DeleteTrackVars>(REMOVE_CARDIO, {});
  const [interpolateSeries] = useMutation<DeleteTrackRes, InterpolateSeriesVars>(INTERPOLATE_SERIES, {});

  const [loadGrounds, grounds] = useLazyQueryCached<{res: GroundTypeRes}, QueryGroundsArgs>(GROUNDS_BY_CLUB);

  const details = useQueryCached<{res: AthleteSessionType}, QueryTrackAthletesessionArgs>(ATHLETE_SESSION_BY_TRACK, {
    variables: {
      templateId,
      trackId,
      start: null,
      end: null,
    },
    errorPolicy: 'all',
    ...CACHE_AND_NETWORK,
    onCompleted: (data) => {
      if (data?.res?.track?.team?.club?.id) {
        loadGrounds({
          variables: {
            clubId: data.res.track.team.club.id,
          },
        });
      }
      setIsLoading(false);
    },
  });
  const invalidState = details?.data?.res?.state === 'INVALID';

  const athletes = useQueryCached<AthleteOptionsRes, AthleteOptionsVars>(ATHLETES_OPTIONS, {
    variables: {
      teamId,
      sort: 'last_name,first_name',
      onDate: null,
    },
    ...CACHE_AND_NETWORK,
  });

  const athleteOptions = useMemo(
    () => {
      const options = athletes?.data?.res?.content?.map((a) => ({
        id: a.id,
        name: a.name.toLowerCase(),
      }));
      return options;
    },
    [athletes?.data?.res?.content],
  );

  const [loadMoreInfo, { data: moreInfoQueryMeta, loading: moreInfoLoading }] = useLazyQueryCached<MoreInfoRes, MoreInfoVars>(MORE_INFO, {
    variables: {
      trackId,
    },
    ...CACHE_AND_NETWORK,
  });

  const moreInfoData = moreInfoQueryMeta?.moreInfo || [];
  const moreInfo = isStaff ? moreInfoData?.slice(1) : moreInfoData?.slice(1, 2);
  const incomingFileId = moreInfoQueryMeta?.moreInfo[0].value;

  const [exportData, setExportData] = useState<TrackExportDataType>({
    id: trackId,
    user: userId,
    active_series: '',
    start_ts: null,
    end_ts: null,
    new_web_app: true,
  });

  const handleDeleteTrack = () => {
    deleteTrack({
      variables: {
        trackId,
      },
    }).then(() => {
      enqueueSnackbar(t('track.dialog.deleteTrack.success', 'track successfully deleted'), {
        variant: 'success',
        autoHideDuration: SNACKBAR_TIMEOUT,
      });
      navigate('/tracks');
    });
  };

  const handleRemoveCardio = () => {
    removeCardio({
      variables: {
        trackId,
      },
    })
      .then(() => {
        enqueueSnackbar(
          t('track.dialog.hrData.remove.snackbar', 'cardio successfully removed'),
          {
            variant: 'success',
            autoHideDuration: SNACKBAR_TIMEOUT,
          },
        );
        setTimeout(() => window.location.reload(), 1500);
      });
  };

  const handleInterpolateSeries = () => {
    interpolateSeries({
      variables: {
        trackId,
        sampleStart: filters?.min,
        sampleEnd: filters?.max,
        field: 'cardio',
      },
    }).then(() => {
      enqueueSnackbar(t('track.dialog.hrData.replace.snackbar.end', 'series successfully interpolated'), {
        variant: 'success',
        autoHideDuration: SNACKBAR_TIMEOUT,
      });
      const event = new CustomEvent('hrDataChanged');
      window.dispatchEvent(event);
      setLoadingHrData(false);
    });
  };

  const filtersValue = useMemo(() => ({ filters, setFilters }), [filters]);

  const firstLetterFirstName = details.data?.res?.athlete?.firstName ? `_${details.data?.res?.athlete?.firstName[0]}` : '';

  useEffect(() => {
    if (!isLoading && updateTrackData.called) {
      setIsLoading(true);
      details.refetch();
    }
  }, [updateTrackData.called]);

  useEffect(() => {
    headerContext.setContent(
      <Header
        leftButtons={[
          <ACLWrapper key="export" section="tracks" type="exports" permission={['pdf', 'xlsx', 'csv', 'raw_csv']}>
            <ExportButton
              teamId={teamId}
              templateId={templateId}
              filename={`gpexe_track_${dateFormat(
                details?.data?.res?.track?.timestamp
                  ? new Date(details.data.res.track.timestamp) : new Date(),
                EXPORT_DATETIME_FORMAT,
              )}_${details.data?.res?.track?.athlete?.lastName || 'unassigned'}${firstLetterFirstName}`}
              disable={!exportData.active_series || exportData.active_series === ''}
              exportData={[
                { format: 'pdf', label: t('exports.type.standardPdf', 'standard PDF'), data: exportData },
                { format: 'xlsx', label: t('exports.type.excel', 'time course XLSX'), data: exportData },
                { format: 'csv', label: t('exports.type.timeCourseCsv', 'time course CSV'), data: exportData },
                { format: 'csv', label: t('exports.type.rawCsv', 'raw CSV'), data: exportData },
              ]}
              target="Track"
              collapseOnMobile
            />
          </ACLWrapper>,
          <ACLWrapper key="delete" section="tracks" type="actions" permission="delete_track">
            <DeleteButton
              collapseOnMobile
              onClick={() => setDeleteTrackConfirmationOpen(true)}
              disabled={!!(isTeamLocked || details.loading)}
            />
          </ACLWrapper>,
          showCardioButton && (
            <ACLWrapper key="HR" section="tracks" type="actions" permission="delete_track">
              <HeaderPopover
                tooltip={loadingHrData ? t('global.processing.dataInProcess', 'data being processed') : undefined}
                disabled={loadingHrData}
                className="more-button"
                buttonId="more-button"
                icon={<IconWrapper name="HR" size="small" color="primary" />}
                label={t('track.header.cardioButton', 'HR')}
                collapseOnSmallDesktop
                popoverContent={[
                  ...[{
                    value: 'removeCardio',
                    label: t('track.widgets.labels.cardioButtonRemove', 'remove HR data'),
                    onClick: () => setHrDataOpenModal((prev) => ({ ...prev, remove: true })),
                  }],
                  ...(filters?.min || filters?.max) ? [{
                    value: 'replaceCardio',
                    label: t('track.widgets.labels.cardioButtonReplace', 'replace HR data'),
                    onClick: () => setHrDataOpenModal((prev) => ({ ...prev, replace: true })),
                  }] : [],
                ].filter((i) => i)}
              />
            </ACLWrapper>
          ),
          ...[
            <BaseButton
              key="more-info-action"
              startIcon={<InfoIcon />}
              collapseOnMobile
              tooltipText={t('tooltip.moreInfo', 'info')}
              disabled={details.loading}
              onClick={() => {
                setMoreInfoDrawerOpen(true);
                loadMoreInfo();
              }}
            >
              {t('track.header.moreInfo', 'more info')}
            </BaseButton>,
          ],
        ]}
      />,
    );

    return () => {
      headerContext.setContent(undefined);
    };
  }, [headerContext.setContent, exportData.active_series, exportData, showCardioButton, loadingHrData]);

  useEffect(() => {
    const loading = details.loading || athletes.loading;
    setIsLoading(loading);
  }, [details.loading, athletes.loading]);

  useEffect(() => {
    if (details.data?.res?.track?.team?.id && details.data.res.track.team.id !== teamId) {
      globalEventsChannel.emit('onTeamChange', details.data.res.track.team.id);
    }
  }, [details.data]);

  const errorDifferentTeam = (error) => error.message && error.message === 'Template matching query does not exist.';

  if (details.error) {
    if (get404Responses(details.error)) {
      return (<Page404 />);
    } if (errorDifferentTeam(details.error)) {
      return null;
    }

    return <Error />;
  }

  if (!details.data?.res || details.loading) {
    return null;
  }

  const story = [
    { path: '/tracks', label: t('track.tracks', 'tracks') },
    {
      path: '',
      label: details.data?.res.track?.timestamp
        ? `${getAthleteName(details?.data?.res?.track?.athlete)} 
    ${format(new Date(details.data?.res.track.timestamp), DATE_TIME_FORMAT)}`
        : '',
    },
  ];

  const trackIsDisabled = !details.data?.res?.isConsolidate;
  const storedOnGlacier = details.data?.res.track?.storedOnGlacier;
  const athlete = details.data?.res?.track?.athlete;
  const athleteNumber = details.data?.res?.track?.athlete?.playerSet?.find((set) => set?.team?.id === teamId)?.number;

  const handleUpdateAthlete = (value) => {
    setIsLoading(true);
    return updateTrack({
      variables: {
        trackId,
        groundId: parseInt(details.data?.res?.track?.ground?.id, 10),
        athleteId: value, // details.data?.res?.athlete.id,
        notes: details.data?.res?.track?.notes || '',
      },
    });
  };
  const handleUpdateGround = (value) => {
    setIsLoading(true);

    return updateTrack({
      variables: {
        trackId,
        groundId: parseInt(value, 10), // details.data?.res?.track?.ground?.id,
        athleteId: details?.data?.res?.athlete?.id || '',
        notes: details.data?.res?.track?.notes || '',
      },
    });
  };

  const handleUpdateNotes = (value) => {
    setIsLoading(true);

    return updateTrack({
      variables: {
        trackId,
        groundId: parseInt(details.data?.res?.track?.ground?.id, 10),
        athleteId: details?.data?.res?.athlete?.id || '',
        notes: value, // details.data?.res?.track.notes,
      },
    });
  };

  if (isLoading) return '';

  return (
    <Box>
      <Breadcrumbs story={story} />

      <Row>
        {trackIsDisabled
          && (
            <Column sm={12}>
              <PageMessage
                iconName="info"
                title={t('track.tabs.noData.title', 'data not ready')}
                text={t('track.tabs.noData.content', 'Data processing is currently in progress.')}
                noMargins
              />
            </Column>
          )}
        <Column sm={9} md={6}>
          <Paper className="track--form paper-margin-bottom">
            <Box p={2}>
              <Row>
                <Column xs={12} md={6}>
                  <TextField
                    readonly
                    label={t('table.startDateTime', 'start date/time')}
                    value={details.data?.res.track?.timestamp
                      ? format(new Date(details.data?.res.track.timestamp), DATE_TIME_FORMAT)
                      : ''}
                  />
                </Column>
                <Column xs={12} md={6}>
                  <TextField
                    readonly
                    label={t('track.widgets.labels.device', 'device')}
                    value={details.data?.res.track?.device?.serialId || '-'}
                  />
                </Column>
              </Row>
              <Row>
                <Column xs={6} lg={5}>
                  <EditableSelectField
                    isEditable
                    label={t('track.widgets.labels.athlete', 'athlete')}
                    name="athlete"
                    disabled={!userContext.userCan({ section: 'tracks', type: 'actions', permission: 'update_track' })}
                    defaultValue={details.data?.res?.track?.athlete?.id
                      || t('track.widgets.labels.athleteNull', 'unassigned')}
                    noneLabel={details.data?.res?.track?.athlete?.id
                      || t('track.widgets.labels.athleteNull', 'unassigned')}
                    hideNone={!!details.data?.res?.track?.athlete?.id}
                    // link={`/team/${props.teamId}/athlete/${athletesessions.data?.res?.athlete?.id}`}
                    loading={updateTrackData.loading}
                    onSave={(value) => handleUpdateAthlete(value)}
                    onBlur={() => null}
                    options={athleteOptions || []}
                  />
                </Column>
                <Column xs={6} lg={5}>
                  <EditableSelectField
                    isEditable
                    label={t('track.widgets.labels.ground', 'ground')}
                    name="ground-select"
                    defaultValue={details.data?.res?.track?.ground?.id || '-'}
                    disabled={!userContext.userCan({ section: 'tracks', type: 'actions', permission: 'update_track' })}
                    loading={grounds.loading || updateTrackData.loading}
                    onSave={(value) => handleUpdateGround(value)}
                    options={grounds?.data?.res?.content?.map((ground) => ({ id: ground.id, name: ground.name })) || []}
                  />
                </Column>
                <Column xs={6} lg={2}>
                  <TextField
                    readonly
                    label={t('track.widgets.labels.sessions', 'sessions')}
                    value={details.data?.res?.track?.nSessions ? details.data?.res?.track?.nSessions : '-'}
                    onClick={() => {
                      if (details.data?.res?.track?.nSessions) {
                        navigate(`/athlete_sessions/?track=${details.data?.res?.track.id}`);
                      }
                    }}
                    endAdornment={details.data?.res?.track?.nSessions ? <LinkIcon /> : null}
                    className={details.data?.res?.track?.nSessions ? 'track-date-field' : 'cursor-default-textinput'}
                  />
                </Column>
              </Row>
            </Box>
          </Paper>

          <Row>
            <Column xs={12}>
              <Paper className="track--form">
                <Box p={2}>
                  <Row>
                    <Column xs={12}>
                      <EditableTextField
                        id="outlined-multiline-static"
                        label={t('track.widgets.labels.notes', 'notes')}
                        multiline
                        placeholder={t('track.widgets.labels.addNotes', 'add notes')}
                        disabled={!userContext.userCan({ section: 'tracks', type: 'actions', permission: 'update_track' })}
                        rows={3}
                        defaultValue={details?.data?.res?.track?.notes || ''}
                        loading={updateTrackData.loading}
                        onSave={(value) => handleUpdateNotes(value)}
                      />
                    </Column>
                  </Row>
                </Box>
              </Paper>
            </Column>
          </Row>
        </Column>
        <Column sm={3} md={6}>
          <Box style={{ marginLeft: '-4px' }} mr="12px" alignSelf="flex-start">
            <Paper className="athlete__profile__wrapper">
              <AthleteCardBasic
                id={athlete?.id}
                shortName={athlete?.shortName || t('track.widgets.labels.athleteNull', 'unassigned')}
                thumbnail={athlete?.thumbnail && getLocalPicturePath(athlete?.thumbnail)}
                number={athlete ? athleteNumber : ''}
              />
            </Paper>
          </Box>
        </Column>
      </Row>

      <Box className="time-chart-container" />
      {!trackIsDisabled && (
        <GlobalFiltersContext.Provider value={filtersValue}>
          <ChartContainer
            data={details.data}
            trackId={trackId}
            athleteSessionId={details.data?.res?.id}
            templateId={templateId}
            teamId={teamId}
            layout={details.data?.res?.layout}
            setActiveSeries={setExportData}
            setTrackExportData={setExportData}
            disabled={storedOnGlacier}
          />
          <Box className="track-tab-container" />
          <TrackTabContainer
            changeDevice={changeDevice}
            trackId={trackId}
            templateId={templateId}
            layout={details.data?.res?.layout}
            modelVersion={details.data?.res?.analysisModelVersion}
            isGk={details.data?.res?.isGk}
            athleteSessionId={details.data?.res?.id}
          />
        </GlobalFiltersContext.Provider>
      )}
      <Box className="track-bottom-spacer" />

      <SideDrawer
        title="info"
        open={moreInfoDrawerOpen}
        setOpen={setMoreInfoDrawerOpen}
        onClose={() => setMoreInfoDrawerOpen(false)}
        onOpen={() => setMoreInfoDrawerOpen(true)}
        actionsLeft={[
          <BaseButton
            key="cancel"
            startIcon={<CancelIcon />}
            onClick={() => setMoreInfoDrawerOpen(false)}
            noMargins
          >
            cancel
          </BaseButton>,
        ]}
      >
        {moreInfoLoading ? (
          <Loader />
        ) : (
          <Box display="flex" flexDirection="column" height="100%" border="0">
            <Column xs={12}>
              <Box p="12px">
                {moreInfo?.map((info) => {
                  if (info.key === 'filename') {
                    return info.value ? (
                      <Row key={info.key}>
                        <Column xs={12}>
                          <OutlinedWrapper label={t('files.table.file', 'file')}>
                            <Box display="flex" alignItems="center">
                              <Box
                                onClick={() => {
                                  setIncomingFileId(incomingFileId);
                                  navigate('/files');
                                }}
                                display="flex"
                                flexDirection="row"
                                style={{ cursor: 'pointer' }}
                                alignContent="start"
                                marginLeft={1}
                                className="link-style files-tracks-drawer-field"
                              >
                                <Typography
                                  style={{ marginRight: '8px' }}
                                  key={`track-${trackId}-button`}
                                >
                                  {info.value}
                                </Typography>
                                <LinkIcon fontSize="medium" htmlColor="var(--secondary-color)" />
                              </Box>
                            </Box>
                          </OutlinedWrapper>
                        </Column>
                      </Row>
                    ) : null;
                  }
                  return (
                    <Row key={info.key}>
                      <Column xs={12}>
                        <TextField label={info.key.replaceAll('_', ' ')} readonly value={info.value} multiline />
                      </Column>
                    </Row>
                  );
                })}
              </Box>
            </Column>
          </Box>
        )}
      </SideDrawer>
      <DeleteDialogBox
        title={t('dialog.title.warning', 'warning')}
        content={(
          <Trans
            i18nKey="track.dialog.deleteTrack.text"
            defaults="The current track will be deleted <br /> Are you sure you want to proceed?"
          />
)}
        onClose={() => setDeleteTrackConfirmationOpen(false)}
        visible={deleteTrackConfirmationOpen}
        onClick={handleDeleteTrack}
      />
      <DeleteDialogBox
        title={t('dialog.title.warning', 'warning')}
        content={(
          <Trans
            i18nKey="track.dialog.hrData.remove.modal"
            defaults="Every HR Data will be removed from the track: are you sure?"
          />
)}
        onClose={() => setHrDataOpenModal((prev) => ({ ...prev, remove: false }))}
        onClick={() => {
          setHrDataOpenModal((prev) => ({ ...prev, remove: false }));
          setLoadingHrData(true);
          handleRemoveCardio();
        }}
        visible={hrDataOpenModal.remove}
      />
      <DialogBox
        title={`${t('dialog.title.warning', 'warning')}`}
        buttonAction={t('dialog.action.confirm', 'confirm')}
        buttonColor="primary"
        icon={<ConfirmIcon />}
        content={(
          t('track.dialog.hrData.replace.modal', 'The selected part of the HR data will be replaced interpolating values between first and last point of the selection: are you sure?')
      )}
        visible={hrDataOpenModal.replace}
        onClose={() => setHrDataOpenModal((prev) => ({ ...prev, replace: false }))}
        onClick={() => {
          setHrDataOpenModal((prev) => ({ ...prev, replace: false }));
          enqueueSnackbar(t('track.dialog.hrData.replace.snackbar.init', 'replacing HR data in progress, please wait a few minutes'), {
            variant: 'success',
            autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
          });
          setLoadingHrData(true);
          handleInterpolateSeries();
        }}
      />

    </Box>
  );
};

export default withMe(TrackView);
