import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogButton from 'components/layout/DialogButton';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { SNACKBAR_TIMEOUT_LONGER } from '../constants';
import {
  FORCE_RESET_USER_PASSWORD,
  ForceResetUserPasswordRes, ForceResetUserPasswordVars,
  RESET_USER_PASSWORD,
  ResetUserPasswordRes,
  ResetUserPasswordVars,
} from '../../mutation/user';
import { IconWrapper } from '../../lib/icons';

type PropsIn = {
  email: string;
  buttonSize?: 'sm' | 'md' | 'lg';
  forcedReset?: boolean;
  disabled?: boolean;
};

export default function ResetPasswordDialogButton(props: PropsIn) {
  const { email, buttonSize, forcedReset, disabled } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [requestNewPassword] = useMutation<ResetUserPasswordRes, ResetUserPasswordVars>(RESET_USER_PASSWORD, {
    onCompleted: (status) => {
      if (!status.requestResetUserPassword.success) {
        const messages = status.requestResetUserPassword.messageKeys
          .map((m) => t(`user.resetPassword.errorMessage.${m}`)) // @todo manca il value per eventuali messaggi
          .join('<br />');

        enqueueSnackbar(messages, {
          variant: 'error',
          autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
        });
      } else {
        enqueueSnackbar(t('user.resetPassword.success', 'Request sent! Check your inbox.'), {
          variant: 'success',
          autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
        });
      }
    },
    onError: () => {
      enqueueSnackbar(t('user.dialog.somethingWrong', 'something went wrong'), {
        variant: 'error',
        autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
      });
    },
  });

  const [forceNewPassword] = useMutation<ForceResetUserPasswordRes, ForceResetUserPasswordVars>(FORCE_RESET_USER_PASSWORD, {
    onCompleted: (status) => {
      if (!status.forceResetUserPassword.success) {
        const messages = status.forceResetUserPassword.messageKeys
          .map((m) => t(`user.resetPassword.errorMessage.${m}`)) // @todo manca il value per eventuali messaggi
          .join('<br />');

        enqueueSnackbar(messages, {
          variant: 'error',
          autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
        });
      } else {
        enqueueSnackbar(t('user.resetPassword.adminSuccess', 'request sent successfully'), {
          variant: 'success',
          autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
        });
      }
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(t('user.dialog.somethingWrong', 'something went wrong'), {
        variant: 'error',
        autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
      });
    },
  });

  return (
    <DialogButton
      collapseOnMobile
      disabled={disabled}
      buttonLabel={t('user.resetPassword.button', 'reset password')}
      buttonIcon={<IconWrapper name="PROCESSING" size="medium" />}
      dialogTitle={t('dialog.action.confirm', 'confirm')}
      dialogContent={t('user.resetPassword.dialogContent', {
        defaultValue: `You are trying to reset the password for user ${email}. Are you sure you want to proceed?`,
        email,
      })}
      onClick={() => (forcedReset
        ? forceNewPassword({
          variables: {
            email,
          },
        })
        : requestNewPassword({
          variables: {
            email,
          },
        }))}
      buttonSize={buttonSize}
    />
  );
}
