type GroundTypeChoices = 'FOOTBALL'|'RUGBY'|'RUGBY_HALF'|'BADMINTON'|'HANDBALL'|'BASKET'|'EMPTY'
type GroundSurfaceChoices = 'GRASS'|'SYNTHETIC'|'COMPACT'
type groundCoordsTypeChoices = 'LOCAL'|'GLOBAL'
export type GroundSetType = {
  id: string,
  createdOn: Date,
  updatedOn: Date,
  name: string,
  // club: ClubType,
  groundType: GroundTypeChoices,
  groundSurface: GroundSurfaceChoices,
  groundSurfaceColor: string,
  groundCoordsType: groundCoordsTypeChoices,
  vertexALatitude: string,
  vertexALongitude: boolean,
  vertexBLatitude: boolean,
  vertexBLongitude: boolean,
  vertexCLatitude: boolean,
  vertexCLongitude: boolean,
  vertexDLatitude: boolean,
  vertexDLongitude: boolean,
  vertexAX: boolean,
  vertexAY: boolean,
  vertexBX: boolean,
  vertexBY: boolean,
  vertexCX: boolean,
  vertexCY: boolean,
  vertexDX: boolean,
  vertexDY: boolean,
  offset: boolean,
  // teamSet: TeamType[],
  // incomingfileSet: IncomingFileType[],
  // trackSet: TrackType[],
}

export const getGroundSetFragmentName = () => {
  return 'GroundSetTypeFields'
}
export const getGroundSetFragment = () => {
  return `
    fragment ${getGroundSetFragmentName()} on GroundType {
      id
      name
      groundType
      groundCoordsType
      groundSurface
      groundSurfaceColor
      vertexALatitude
      vertexALongitude
      vertexBLatitude
      vertexBLongitude
      vertexCLatitude
      vertexCLongitude
      vertexDLatitude
      vertexDLongitude
      vertexAX {unit, value, uom}
      vertexAY {unit, value, uom}
      vertexBX {unit, value, uom}
      vertexBY {unit, value, uom}
      vertexCX {unit, value, uom}
      vertexCY {unit, value, uom}
      vertexDX {unit, value, uom}
      vertexDY {unit, value, uom}
      offset
    }
  `
}
