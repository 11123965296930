import React, { useState, useEffect } from 'react';
import {
  Grid, GridSpacing, Divider, Box, Chip,
} from '@material-ui/core';
import BaseChip from 'components/form/BaseChip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Column } from 'components/layout/Column';
import { Row } from 'components/layout/Row';
import {
  ReportSeriesRes, ReportSeriesVars, REPORT_SERIES, Kpi,
} from 'query/track';
import Arrows from '@images/ChipIcon/fast-rewind3.svg';
import ArrowDown from '@images/ChipIcon/fast-rewind.svg';
import ArrowUp from '@images/ChipIcon/fast-rewind2.svg';
import { ExportTemplateOrderingDirectionChoices } from 'models/template';
import { useTranslation, Trans } from 'react-i18next';
import { IconWrapper, Loader } from 'lib/icons';
import Error from 'components/layout/Error';
import { useQueryCached } from 'components/utils/graphql';
import { Alert } from '@material-ui/lab';
import { camelCaser } from '../utils/kpiLabelExtractor';
import { CACHE_AND_NETWORK } from '../../lib/cache';
import GridSortable from '../form/GridSortable';

type PropsIn = {
  teamId: string;
  kpi2: string[];
  kpiCounter: number;
  setKpiCounter: (n:number) => void;
  setKpiList: (kpis) => void;
  ordering: string;
  orderingDirection: ExportTemplateOrderingDirectionChoices;
  setOrdering: (key: string) => void;
  setOrderingDirection: (dir: ExportTemplateOrderingDirectionChoices) => void;
  filterObsolete?: boolean;
};

export function KpiChip(props: {
  label: string;
  isSelected: boolean;
  action: () => void;
  selectable: boolean;
  hoverDisabled?: boolean;
}) {
  const {
    label, isSelected, action, selectable, hoverDisabled,
  } = props;

  const [selected, setSelected] = useState(isSelected);
  const [hover, setHover] = useState(false);

  const classname = hoverDisabled
    ? `${selected ? 'selected-no-hover' : 'unselected-no-hover'}`
    : `${selected ? 'selected' : 'unselected'}`;
  const icon = selectable ? selected ? (
    <IconWrapper name="remove" size="smaller" color="primary" />
  ) : (
    <IconWrapper name="add" size="smaller" color="primary" />
  ) : null;

  return (
    <span onClick={action}>
      <BaseChip
        clickable={selectable}
        size="small"
        label={label}
        onClick={
          selectable
            ? () => {
              selected ? setSelected(false) : setSelected(true);
            }
            : () => {}
        }
        onMouseOver={
          selectable
            ? () => {
              hover ? setHover(false) : setHover(true);
            }
            : () => {}
        }
        className={classname}
        icon={icon}
      />
    </span>
  );
}

export type DividerWithTextProps = {
  text: string;
  spacing: GridSpacing;
};

export function DividerWithText(props: DividerWithTextProps) {
  const { text, spacing } = props;

  return (
    <Grid container alignItems="center" spacing={spacing}>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item>{text}</Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );
}

function OrdererChip(props: {
  id: string;
  kpi: any;
  orderingKey: string;
  orderingDirection: ExportTemplateOrderingDirectionChoices;
  handleSelection: (key) => void;
}) {
  const {
    id, kpi, orderingKey, orderingDirection, handleSelection,
  } = props;
  const { t } = useTranslation();

  // HR translations group
  const kpiGroup = kpi.group === 'HR zones' && ['hr', 'percentage'].includes(kpi.uom)
    ? 'main' : kpi.group;

  return (
    <span
      id={id}
      key={`id${id}-${kpi.key}`}
    >
      <BaseChip
        id={id}
        key={`${id}-${kpi.key}`}
        clickable
        size="small"
        label={t(`kpi2.${camelCaser(kpi.group === 'fixed' ? 'main' : kpiGroup, kpi.key)}.label`, kpi.key.split('_').join(' '))}
        onClick={() => {
          handleSelection(kpi.key);
        }}
        icon={(
          <Box pl={1} pt={1.2} pb={2}>
            <Column>
              <Row>
                {orderingKey !== kpi.key ? <Arrows /> : orderingDirection === '+' ? <ArrowUp /> : <ArrowDown />}
              </Row>
            </Column>
          </Box>
        )}
      />
    </span>
  );
}

function OrdererKpi(props: {
  kpi: any[];
  fixedKpis: any[];
  otherKpis: any[];
  setKpiSelected: (e: any) => void;
  ordering: string;
  orderingDirection: ExportTemplateOrderingDirectionChoices;
  setOrdering: (key: string) => void;
  setOrderingDirection: (dir: ExportTemplateOrderingDirectionChoices) => void;
}) {
  const {
    kpi, fixedKpis, otherKpis, setKpiSelected, ordering, orderingDirection, setOrdering, setOrderingDirection,
  } = props;
  const { t } = useTranslation();

  const setCurrentlySelectedWrap = (key: string) => {
    setOrdering(key);
    setOrderingDirection(orderingDirection === '+' ? '-' : '+');
  };

  const handleOrderChange = (arr: { key: number }[]) => {
    const newOrder = arr.reduce((acc: Kpi[], curr, index) => {
      const k = kpi.find((ki) => ki.id === curr.key);
      if (k) {
        acc.push({
          ...k,
          order: index + 4,
        });
      }
      return acc;
    }, []);

    setKpiSelected([...fixedKpis, ...newOrder]);
  };

  return (
    <Box>
      <Box pt={3} pb={2} padding={0}>
        <DividerWithText text={t('templates.labels.columnOrdering', 'COLUMN ORDERING AND DATA SORTING')} spacing={3} />
      </Box>
      <Column xs={12} style={{ marginBottom: '0.5rem' }}>
        {fixedKpis.map((curr) => (
          <OrdererChip
            id={curr.id}
            key={curr.id}
            kpi={curr.kpi}
            handleSelection={setCurrentlySelectedWrap}
            orderingKey={ordering}
            orderingDirection={orderingDirection}
          />
        ))}
      </Column>
      <Column xs={12} className="sortable-kpis-box">
        <GridSortable
          items={otherKpis
            .map((curr) => (
              <OrdererChip
                id={curr.id}
                key={curr.id}
                kpi={curr.kpi}
                handleSelection={setCurrentlySelectedWrap}
                orderingKey={ordering}
                orderingDirection={orderingDirection}
              />
            ))}
          onItemsMove={handleOrderChange}
        />
      </Column>
    </Box>
  );
}
const fixedOrderByKey = {
  athlete: 0,
  short_name: 1,
  jersey: 2,
};

export default function SelectableKpi(props: PropsIn) {
  const {
    teamId, kpiCounter, setKpiCounter, ordering, orderingDirection,
    setOrdering, setOrderingDirection, setKpiList, kpi2, filterObsolete,
  } = props;
  const { t } = useTranslation();
  const [fixedKpis, setFixedKpis] = useState([]);
  const [otherKpis, setOtherKpis] = useState([]);
  const [counters, setCounters] = useState({
    identifier: 0,
    main: 1,
    met: 0,
    metabolic_power_events: 0,
    mech: 0,
    locomotion: 0,
    speed_zones: 0,
    '%_speed_zones': 0,
    power_zones: 0,
    hr_zones: 0,
    acc_zones: 0,
    dec_zones: 0,
    imu_events: 0,
  });
  const [kpiSelected, setKpiSelected] = useState([]);

  const formatGroupStr = (group) => group.replaceAll(' ', '_').toLowerCase();

  const {
    loading,
    error,
    data: kpis,
  } = useQueryCached<ReportSeriesRes, ReportSeriesVars>(REPORT_SERIES, {
    variables: {
      teamId,
      filterObsolete,
    },
    ...CACHE_AND_NETWORK,
    onCompleted: (data) => {
      const groups = {
        identifier: 0,
        main: 1,
        met: 0,
        metabolic_power_events: 0,
        mech: 0,
        locomotion: 0,
        speed_zones: 0,
        '%_speed_zones': 0,
        power_zones: 0,
        hr_zones: 0,
        acc_zones: 0,
        dec_zones: 0,
        imu_events: 0,
      };

      const kpiData = data.reportSeries.map((obj) => {
        // moved hr kpis from main group to HR zones group
        if (obj.group === 'main' && (obj.unit === 'hr' || obj.unit === 'percentage')) {
          return { ...obj, group: 'HR zones' };
        }
        return obj;
      });

      kpi2?.forEach((k) => {
        const kpi = kpiData.filter((d) => d.key === k)[0];
        if (kpi?.group && kpi.key !== 'average_time') {
          if (kpi.isIdentifier) {
            groups.identifier += 1;
          } else {
            const targetGroup = formatGroupStr(kpi.group);
            groups[targetGroup] = groups[targetGroup] + 1 || 1;
          }
        }
      });
      setCounters(groups);
    },
  });

  const identifKpis = (kpis?.reportSeries || []).filter((k) => k.isIdentifier).map((idKpi) => idKpi.key);
  const identifKpisSelected = () => kpiSelected.some((sk) => identifKpis.includes(sk.kpi.key));

  const filteredKpis = kpis?.reportSeries?.reduce((acc, curr) => {
    // skip iteration (to remove ext work -)
    if (curr.key === 'external_work_negative') return acc;
    // move HR kpis from main/basic to HR zones/heart rate
    if (curr.group === 'main' && (curr.unit === 'hr' || curr.unit === 'percentage')) {
      if (!acc['HR zones']) {
        acc['HR zones'] = [];
      }
      acc['HR zones'].push({ ...curr, group: 'HR zones' });
    } else if (curr.isIdentifier) {
      if (!acc.identifier) {
        acc.identifier = [];
      }
      acc.identifier.push(curr);
    } else {
      if (!acc[curr.group]) {
        acc[curr.group] = [];
      }
      acc[curr.group].push(curr);
    }
    return acc;
  }, {}) || [];

  const handleOrderKpiRemoved = (removedKpiKey: string) => {
    // moves ordering to the first identification KPI available if any
    const selectedIdentifKpis = kpiSelected.slice().sort((k1, k2) => k1.order - k2.order)
      .filter((sk) => identifKpis.includes(sk.kpi.key) && sk.kpi.key !== removedKpiKey);

    if (selectedIdentifKpis.length) {
      setOrdering(selectedIdentifKpis[0].kpi.key);
      setOrderingDirection('+');
    }
  };

  const selectKpi = (kpiTarget) => {
    const currentMaxOrder = kpiSelected
      .reduce((maxObj, currObj) => (currObj.order > maxObj.order ? currObj : maxObj), kpiSelected[0])?.order;
    const idx = currentMaxOrder + 1;
    const sort = true;
    const currOrder = fixedOrderByKey[kpiTarget.key];
    const kpi = {
      kpi: kpiTarget,
      order: currOrder ?? idx,
      sort,
      id: `id${idx}`,
    };

    let filter;
    const exist = kpiSelected.some((e) => e.kpi.key === kpiTarget.key);
    const groupTarget = kpiTarget.isIdentifier ? 'identifier' : formatGroupStr(kpiTarget.group);

    if (!exist) {
      const validOrdering = kpiSelected.map((ks) => ks.kpi.key).includes(ordering);
      if (kpiTarget.isIdentifier && !identifKpisSelected() && !validOrdering) {
        setOrdering(kpiTarget.key);
        setOrderingDirection('+');
      }
      setKpiSelected((prev) => [...prev, kpi]);
      setCounters({
        ...counters,
        [groupTarget]: counters[groupTarget] + 1,
      });
    } else {
      filter = kpiSelected.filter((x) => x.kpi.key !== kpiTarget.key);
      setKpiSelected(filter);
      if (kpiTarget.key === ordering) {
        handleOrderKpiRemoved(kpiTarget.key);
      }
      setCounters({
        ...counters,
        [groupTarget]: counters[groupTarget] - 1,
      });
    }
  };

  useEffect(() => {
    const kpiarr = [];
    const allKpis = kpis?.reportSeries.concat({ key: 'average_time', unit: 's', group: 'main' });

    kpi2.forEach((e, idx) => {
      allKpis?.forEach((curr) => {
        if (e === curr.key) {
          const sort = true; // da modificare quando verranno implementate icon per il sort
          // 'duration' should always be positioned after all identif kpis.
          const durOrd = curr.key === 'average_time' ? 3 : undefined;
          const kpi = {
            kpi: curr,
            order: durOrd ?? fixedOrderByKey[e] ?? idx,
            sort,
            id: `id${idx}`,
          };
          kpiarr.push(kpi);
        }
      });
    });
    setKpiSelected(kpiarr);
  }, [kpis]);

  useEffect(() => {
    if (kpiSelected.length) {
      const sortedKpis = kpiSelected.slice().sort((k1, k2) => k1.order - k2.order);
      const fixedK = [...sortedKpis.filter((obj) => [...identifKpis, 'average_time'].includes(obj.kpi.key))];
      const fixedKpiKeys = fixedK.map((fk) => fk.kpi.key);
      const otherK = sortedKpis.filter((obj) => !fixedKpiKeys.includes(obj.kpi.key));

      setFixedKpis(fixedK);
      setOtherKpis(otherK);
      setKpiList([...fixedK.map((k) => k.kpi.key), ...otherK.map((k) => k.kpi.key)]);
      setKpiCounter(kpiSelected.length);
    }
  }, [kpiSelected]);

  const createKpiChip = (group, kpi) => {
    if (kpi.group === group || kpi.isIdentifier) {
      // just for translations:
      let kpiGroup = group;
      if ((kpi.unit === 'percentage' || kpi.unit === 'hr') && kpi.group === 'HR zones') {
        kpiGroup = 'main';
      }
      if (group === 'identifier') {
        kpiGroup = 'main';
      }
      return (
        <KpiChip
          hoverDisabled={kpi.key === 'athlete'}
          selectable={kpi.key !== 'athlete'}
          key={`${kpi.key}`}
          label={t(`kpi2.${camelCaser(kpiGroup, kpi.key)}.label`, kpi.key.split('_').join(' '))}
          isSelected={kpi2.includes(kpi.key)}
          action={() => {
            if (kpi.key !== 'athlete') {
              selectKpi(kpi);
            }
          }}
        />
      );
    }
    return null;
  };

  const createKpiAccordionSummary = (title, group) => (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Typography>{title}</Typography>
      {counters[formatGroupStr(group)] > 0 ? (
        <Chip
          label={counters[formatGroupStr(group)]}
          size="small"
          className="counter-accordion"
        />
      ) : null}
    </Box>
  );

  const createKpiAccordion = (title: string, group: string, accordionId: string) => {
    if (filteredKpis[group] == null) {
      return '';
    }
    const accordionKpis = filteredKpis[group]?.map((curr) => (
      curr.key === 'average_time' ? null : createKpiChip(group, curr)));

    if (group === 'main') {
      accordionKpis.unshift(
        <KpiChip
          hoverDisabled
          selectable={false}
          key="average_time"
          label={t('table.duration', 'duration')}
          isSelected
          action={() => {}}
        />,
      );
    }

    return (
      <Accordion className="accordion">
        <AccordionSummary
          style={{ maxHeight: '40px', minHeight: '40px' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${accordionId}-content`}
          id={`${accordionId}-header`}
        >
          {createKpiAccordionSummary(title, group)}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {accordionKpis}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  if (error) return <Error />;
  if (loading) return <Loader />;

  return (
    <Box>
      <Box sx={{ marginY: '10px' }}>
        <Box pt={3} pb={2}>
          <DividerWithText text={t('templates.labels.selectKpi', 'SELECT KPI')} spacing={3} />
        </Box>
        {createKpiAccordion(
          `${t('templates.labels.athIdentification', 'athlete identification')}*`,
          'identifier',
          'panel0a',
        )}
        {createKpiAccordion(t('kpi2.main.title', 'basic'), 'main', 'panel1a')}
        {createKpiAccordion(t('kpi2.speedZones.title', 'speed zones'), 'speed zones', 'panel6a')}
        {createKpiAccordion(t('kpi2.%SpeedZones.title', '% speed zones'), '% speed zones', 'panel11a')}
        {createKpiAccordion(t('kpi2.accZones.title', 'acc zones'), 'acc zones', 'panel9a')}
        {createKpiAccordion(t('kpi2.decZones.title', 'dec zones'), 'dec zones', 'panel10a')}
        {createKpiAccordion(t('kpi2.met.title', 'met'), 'met', 'panel2a')}
        {createKpiAccordion(t('kpi2.powerZones.title', 'power zones'), 'power zones', 'panel7a')}
        {createKpiAccordion(t('kpi2.metabolicPowerEvents.title', 'metabolic power events'), 'metabolic power events', 'panel3a')}
        {createKpiAccordion(t('kpi2.mech.title', 'mech'), 'mech', 'panel4a')}
        {createKpiAccordion(t('kpi2.locomotion.title', 'locomotion'), 'locomotion', 'panel5a')}
        {createKpiAccordion(t('kpi2.IMUEvents.title', 'imu events'), 'IMU events', 'panel11a')}
        {createKpiAccordion(t('kpi2.HRZones.title', 'heart rate'), 'HR zones', 'panel8a')}

        { kpiCounter > 9
          && (
          <Alert
            style={{ marginTop: '20px' }}
            severity="warning"
          >
            <Trans
              i18nKey="templates.dialog.templateAlert"
              defaults="The maximum number of parameters displayable in the PDF <br/>
            (10 for the portrait mode and 12 for the landscape mode) has been reached. <br/>
            Additional parameters will be available only in CSV or Excel exports."
            />
          </Alert>
          )}

        <OrdererKpi
          kpi={kpiSelected}
          fixedKpis={fixedKpis}
          otherKpis={otherKpis}
          setKpiSelected={setKpiSelected}
          ordering={ordering}
          orderingDirection={orderingDirection}
          setOrdering={setOrdering}
          setOrderingDirection={setOrderingDirection}
        />
      </Box>
    </Box>
  );
}
