import React from 'react';
import { Line } from 'react-konva';
import { PATH_COLOR, PATH_WIDTH } from '../track/ground/constants';
import { markerTrailLength, timestampSearchRange } from './Ground';

export type PathType = {
  timestamp: number,
  x: number | null,
  y: number | null,
}[];

type PropsPath = { pathData: PathType; cursor: number; scale?: number, pathColor?: string; };

const Path = React.memo((props: PropsPath) => {
  const {
    cursor,
    pathData,
    scale = 1,
    pathColor,
  } = props;

  const paths: number[][] = [[]];
  let lineNumber = 0;
  pathData.forEach((p) => {
    if (p.timestamp > cursor - markerTrailLength && p.timestamp < (cursor - timestampSearchRange)) {
      if (p.x === null || p.y === null) {
        lineNumber += 1;
        paths[lineNumber] = [];
      } else {
        paths[lineNumber].push(p.x * scale);
        paths[lineNumber].push(p.y * scale);
      }
    }
  });

  return (
    <>
      {
        paths.map((path, idx) => (
          <Line
            /* eslint-disable-next-line react/no-array-index-key */
            key={idx}
            points={path}
            stroke={pathColor || PATH_COLOR}
            strokeWidth={PATH_WIDTH}
          />
        ))
      }
    </>
  );
});

export default Path;
