import React from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Box,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@images/IconHeader/cancel.svg';
import BaseButton from '../form/BaseButton';
import DialogBox from '../DialogBox';

interface PropsIn {
  open: boolean
  children: React.ReactNode
  onClose: () => void
  onClick?: () => void
  title: string
  content: string
}

function DialogButtonModal(props: PropsIn) {
  const { t } = useTranslation();
  const {
    open, children, onClose, onClick, title, content,
  } = props;

  return open ? (
    <DialogBox
      title={title}
      content={content}
      visible={open}
      onClick={onClick}
      onClose={onClose}
    >
      {children}
    </DialogBox>
  ) : null;
}

interface PropsWidgetIn {
  buttonLabel: string;
  buttonIcon?: React.ReactNode;
  dialogTitle: string;
  dialogContent: string;
  children?: React.ReactNode;
  onClick?: () => void;
  buttonSize?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  collapseOnMobile?: boolean;
  collapseOnSmallDesktop?: boolean;
  tooltip?: string;
}

export default function DialogButton(props: PropsWidgetIn) {
  const [open, setOpen] = React.useState(false);
  const {
    buttonIcon, buttonLabel, children, dialogTitle, dialogContent,
    onClick, buttonSize, disabled, tooltip, collapseOnMobile, collapseOnSmallDesktop,
  } = props;
  return (
    <>
      <BaseButton
        onClick={() => setOpen(true)}
        startIcon={buttonIcon}
        buttonSize={buttonSize}
        disabled={disabled}
        tooltipText={tooltip}
        collapseOnMobile={collapseOnMobile}
        collapseOnSmallDesktop={collapseOnSmallDesktop}
      >
        {buttonLabel}
      </BaseButton>
      {
      open
      && (
      <DialogButtonModal
        open={open}
        onClose={() => setOpen(false)}
        title={dialogTitle}
        content={dialogContent}
        onClick={() => {
          onClick && onClick();
          setOpen(false);
        }}
      >
        {children}
      </DialogButtonModal>
      )
    }
    </>
  );
}
