import { Box, Paper } from '@material-ui/core';
import ColorPickerWrapper from 'components/ColorPickerWrapper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';

function TestColorPickerWrapper() {
  return (
    <>
      <Paper>
        <Box p={2} mt={4}>
          <Typography variant="h5" style={{ marginBottom: '18px' }} gutterBottom>ColorPickerWrapper</Typography>
          <Row>
            <Column md={1}>
              <ColorPickerWrapper
                label="gradient"
                showDefaultColors
              />
            </Column>
            <Column md={1}>
              <ColorPickerWrapper label="default" disableGradientPicker showDefaultColors />
            </Column>
            <Column md={1}>
              <ColorPickerWrapper required label="required" disableGradientPicker showDefaultColors />
            </Column>

          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '1rem' }} />
    </>
  );
}

export default TestColorPickerWrapper;
