import React, { PropsWithChildren, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import WarningIcon from '@material-ui/icons/Warning';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';
import { Row } from './Row';
import { Column } from './Column';
import TitleView from './TitleView';
import BaseButton from '../form/BaseButton';

export function FallbackComponent({ error, resetErrorBoundary }) {
  const { t } = useTranslation();

  useEffect(() => {
    Sentry.captureException(error);
  }, []);

  return (
    <Paper>
      <Box p={4}>
        <Row>
          <Column lg={1}>
            <Box height="100%" display="flex" justifyContent="center">
              <WarningIcon fontSize="medium" />
            </Box>
          </Column>
          <Column lg={11}>
            <Box>
              <TitleView
                title={t('issues.componentError.title', 'Error')}
                align="left"
                noMargins
                size="medium"
              />
              <Box mt="0.5rem">
                {t('issues.componentError.text', 'There was an error while rendering this component')}

                <BaseButton onClick={() => resetErrorBoundary()}>
                  {t('issues.componentError.reload', 'Retry')}
                </BaseButton>
              </Box>
            </Box>
          </Column>
        </Row>
      </Box>
    </Paper>
  );
}

const ErrorGuard = (props: PropsWithChildren<any>) => {
  const { children } = props;

  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error) => console.error('[ERR] componente', error)}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorGuard;
