import { gql } from "@apollo/client";

export type TokenAuthRes = {
  res: {
    token: string,
    refreshToken: string,
    isActive: boolean,
    refreshExpiresIn: number,
  }
}
export type TokenAuthVars = {
  email: string,
  password: string,
}

export const TOKEN_AUTH = gql`
  mutation TokenAuth(
    $email: String!, $password: String!
  ) {
    res: tokenAuth(
      email: $email, password: $password
    ) {
      isActive
      token
      refreshToken
      refreshExpiresIn
      payload
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken(
    $refreshToken: String!
  ) {
    res: refreshToken(
      refreshToken: $refreshToken
    ) {
      payload
      token
      refreshToken
      refreshExpiresIn
    }
  }
`;

export const REVOKE_TOKEN = gql`
mutation RevokeToken($refreshToken: String!) {
  revokeToken(refreshToken: $refreshToken) {
    revoked
  }
}
`;
