import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import TagsField from '../form/TagsField';

const players = [
  { id: '1', name: 'Mario Rossi' },
  { id: '2', name: 'Pino Verdi' },
  { id: '3', name: 'Angelo Bianchi' },
  { id: '4', name: 'Tramonti Arancio' },
  { id: '5', name: 'Alba Rosa' },
];

function TestTagsField() {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState<string | number>('');
  const handleChange = (d) => {
    enqueueSnackbar('Selected option');
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>TagsField</Typography>
      <Paper id="test-editable-select-field">
        <Box p={2}>
          <Row>
            <Column md={6} lg={4}>
              <TagsField
                filters={{ teamId: '75' }}
                onChange={handleChange}
                value={[]}
              />
            </Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestTagsField;
