import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Popover } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { PopoverOrigin } from '@material-ui/core/Popover/Popover';
import BaseButton from './form/BaseButton';
import { getDataFromDB, setDataToDB } from '../storage/dataDbCache';

type PopoverContentType = {
  className?: string,
  label: string,
  onClick: () => void,
  prefix?: string | React.ReactElement,
  value: string,
}

type PropsIn = {
  // badge?: number,
  collapseOnMobile?: boolean,
  disabled?: boolean,
  icon?: React.ReactElement,
  active?: boolean,
  label?: string,
  popoverContent?: PopoverContentType[],
  position?: 'left' | 'center' | 'right',
  total: number,
  setRefetchDocumentsStatus: (n) => void;
}

export const getKeyFromItem = (item: PopoverContentType) => `${item.label}_${item.prefix}`;
export const DB_KEY = 'downloads';

export default function HeaderPopoverExports(props: PropsIn) {
  const {
    collapseOnMobile,
    disabled, icon,
    active, label,
    popoverContent, position,
    total,
    setRefetchDocumentsStatus,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [items, setItems] = useState<{[k: string]: boolean}>({});
  const [badge, setBadge] = useState(0);

  const open = Boolean(anchor);
  const id = open ? 'popover' : undefined;

  const positionProps = {
    anchorOrigin: {
      vertical: 40,
      horizontal: position || 'left',
    } as PopoverOrigin,
    transformOrigin: {
      vertical: 'top',
      horizontal: position || 'left',
    } as PopoverOrigin,
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchor(event.currentTarget);

    // dopo 2 secondo aggiorno i dati come visti, aggiorno counter e tolgo highlight
    setTimeout(async () => {
      const data: object | null = await getDataFromDB(DB_KEY).catch((e) => console.log(e));
      if (data) {
        const newData = { ...data };

        // setto tutti i visibili come visti
        Object.keys(newData).forEach((d) => {
          newData[d] = true;
        });

        setDataToDB(newData, DB_KEY);
        setTimeout(() => {
          // forzo l'update al reset del indexedDb
          setRefetchDocumentsStatus((prev) => prev + 1);
        }, 10);
      }
    }, 20);
  };

  useEffect(() => {
    setRefetchDocumentsStatus((prev) => prev + 1);
  }, [badge, total]);

  const badgeNum = useCallback(() => {
    let sum = 0;
    const getData = async () => {
      const data = await getDataFromDB(DB_KEY).catch((e) => console.log(e));
      popoverContent?.forEach((item) => {
        const key = getKeyFromItem(item);
        if (data && data[key] === false) {
          sum += 1;
        }
      });

      setBadge(sum);
    };

    getData();
  }, [items, total]);

  useEffect(() => {
    badgeNum();
  }, [popoverContent, total]);

  useEffect(() => {
    const getData = async () => {
      const data = await getDataFromDB(DB_KEY).catch((e) => console.log(e));

      if (!data) {
        // non ci sono elementi, carica direttamente i dati
        const newData = popoverContent?.reduce((acc, curr) => {
          const key = getKeyFromItem(curr);
          acc[key] = false;

          return acc;
        }, {}) || {};
        setDataToDB(newData, DB_KEY);
        setItems(newData);
      } else {
        const newData = { ...data };

        // aggiunge i nuovi elementi
        popoverContent?.forEach((d) => {
          const key = getKeyFromItem(d);
          if (!newData[key]) {
            newData[key] = false;
          }
        });

        setDataToDB(newData, DB_KEY);
        setItems(newData);
      }
    };

    getData();
  }, [popoverContent]);

  const highlightClass = (element: PopoverContentType) => {
    const key = getKeyFromItem(element);

    return Object.hasOwn(items, key) && items[key]
      ? 'item-highlight'
      : 'item-highlight item-new';
  };

  return (
    <div>
      <BaseButton
        tooltipText={t('tooltip.myDocuments', 'my documents')}
        onClick={handleClick}
        collapseOnMobile={collapseOnMobile}
        startIcon={label && icon}
        disabled={disabled}
        isIconButton={!label}
        active={active}
        badge={badge || 0}
      >
        {label || icon}
      </BaseButton>
      {
        popoverContent
        && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchor}
          onClose={handleClose}
          {...positionProps}
        >
          <Box>
            <Box className="popover-exports">
              <Typography variant="subtitle1">{t('table.date', 'date')}</Typography>
              <Typography variant="subtitle1">{t('exports.columns.fileName', 'file name')}</Typography>
            </Box>
            {popoverContent?.map((element, i) => (
              <MenuItem
                key={`${element.value}-${i}`}
                value={element.value}
                className={`${element.className || ''} ${highlightClass(element)}`}
                onClick={() => {
                  handleClose();
                  element.onClick();
                }}
              >
                <Box style={{ display: 'flex', gap: '3rem' }}>
                  <Typography>{element.prefix}</Typography>
                  <Typography>{element.label}</Typography>
                </Box>
              </MenuItem>
            )) || null}
            <Typography
              className="show-all"
              onClick={() => {
                navigate('/exports');
                handleClose();
              }}
              variant="button"
            >
              {t('exports.showAll', 'show all')}
              {` (${total})`}

            </Typography>
          </Box>
        </Popover>
        )
      }
    </div>
  );
}
