import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Modal from 'components/form/Modal';
import BridgeWidget from 'components/layout/BridgeWidget';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import BaseButton from '../form/BaseButton';

function TestModal() {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  return (
    <>
      <Typography variant="h5" gutterBottom>Modal</Typography>
      <Paper>
        <Box p={2} display="flex" alignItems="center" justifyContent="center">
          <Row>
            <Column md={2}>
              <BaseButton
                onClick={() => setOpen1(true)}
              >
                default
              </BaseButton>
              <Modal
                title="modal title"
                content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                buttonAction="action"
                onClose={() => setOpen1(false)}
                visible={open1}
                // function to pass when clicking on action button
                onClick={() => null}
              />
            </Column>

            <Column md={2}>
              <BaseButton
                onClick={() => setOpen2(true)}
              >
                danger
              </BaseButton>
              <Modal
                title="modal title"
                content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                buttonAction="action"
                buttonColor="danger"
                onClose={() => setOpen2(false)}
                visible={open2}
                // function to pass when clicking on action button
                onClick={() => null}
              />
            </Column>

            <Column md={3} className="other-modals">
              <Typography variant="h6">Other (with children):</Typography>
              <BridgeWidget />
            </Column>

          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestModal;
