import React from 'react';
import {
  Box, IconButton, List, ListItem, Popover,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { Cancel, CheckCircle } from '@material-ui/icons';
import DividerWithText from '../layout/DividerWithText';
import DeleteIcon from '../../img/header-icons/DeleteIcon';

/**
 * @param accordion - WIP if true, the list will be rendered as an accordion
 * @param confirmDelete - if true, the user will be prompted to confirm deletion
 * @param lockFirst - if true, the first item in the list will be locked (cannot be deleted)
 * @param rows - an array of objects, each object must have an id, title, and component
 * @param onRowDelete - a function that will be called when a row is deleted
 */
interface PropsIn {
  // accordion?: boolean // @todo wip
  lockFirst?: boolean;
  rows: {
    id: string,
    title: string,
    component: React.ReactNode,
  }[];
  onRowDelete?: (id: string | null) => void;
  confirmDelete?: boolean;
  rowsRelativeDeletion?: boolean;
}

/**
 * A list of rows with a delete button.
 *
 * @todo WIP Accordion
 * @param props
 * @constructor
 */
function ListField(props: PropsIn) {
  const {
    confirmDelete, lockFirst, onRowDelete, rows,
    rowsRelativeDeletion,
  } = props;
  const { t } = useTranslation();
  const [confirm, setConfirm] = React.useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!rows || !rows.length) {
    return null;
  }

  const handleDelete = (id: string, event) => {
    if (onRowDelete && confirmDelete) {
      setAnchorEl(event.currentTarget);
      setConfirm(String(id));
    } else if (onRowDelete && !confirmDelete) {
      onRowDelete(confirm);
      setConfirm(null);
      setAnchorEl(null);
    }
  };

  const handleConfirmDelete = () => {
    if (onRowDelete && confirmDelete) {
      onRowDelete(confirm);
      setConfirm(null);
      setAnchorEl(null);
    }
  };
  const rowDeleteCondition = (rowIndex) => {
    if (rowsRelativeDeletion) {
      return rows.length > 1;
    }
    return !lockFirst || lockFirst && rowIndex > 0;
  };

  return (
    <>
      <Popover
        open={!!(confirm && anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        className="list-field-popover"
      >
        <Box paddingX={2} paddingY={1}>
          {t('dialog.action.confirm', 'confirm')}
          ?
          {' '}
          <button type="button" onClick={handleConfirmDelete}><CheckCircle /></button>
          {' '}
          <button type="button" onClick={() => setConfirm(null)}><Cancel /></button>
        </Box>
      </Popover>

      <List
        component="div"
        className="list-field"
      >
        {
          rows.map((row, index) => (
            <ListItem
              key={row.id + index}
              component="div"
              disableGutters
            >
              <DividerWithText
                text={row.title}
                textClassName="list-field__title"
                endComponent={onRowDelete && rowDeleteCondition(index) ? (
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    {/* White rectangle that masks the Divider line beneath */}
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: '-3px',
                        right: '-3px',
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: 0,
                      }}
                    />
                    <IconButton
                      onClick={(e) => handleDelete(row.id, e)}
                      style={{ position: 'relative', zIndex: 1 }}
                    >
                      <Tooltip arrow title={t('header.action.delete', 'delete')}>
                        <span>
                          <DeleteIcon />
                        </span>
                      </Tooltip>
                    </IconButton>
                  </div>

                )
                  : null}
              />
              <Box component="div" width="100%" pt="5px" mb="16px" mt="8.5px">
                {row.component}
              </Box>
            </ListItem>
          ))
        }
      </List>
    </>
  );
}

export default ListField;
