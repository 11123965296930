import { useSnackbar } from 'notistack';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import DatePicker from '../form/DatePicker';

function TestDatepickerField() {
  const { enqueueSnackbar } = useSnackbar();
  const [date, setDate] = React.useState<Date | null>(null);

  const handleDateChange = (d) => {
    setDate(d);
    if (d) {
      enqueueSnackbar(`Selected date: ${d.toDateString()}`);
    }
  };

  /** EXAMPLE, min and max have -5 and +5 days offset */
  const today = new Date();
  const minDate = today.setDate(today.getDate() - 5);
  const maxDate = today.setDate(today.getDate() + 5);

  return (
    <>
      <Typography variant="h5" gutterBottom>DatePicker</Typography>
      <Paper id="test-datepicker">
        <Box p={2}>
          <Row>
            <Column md={2}>
              <DatePicker
                label="Default"
                date={date}
                onChange={handleDateChange}
              />
            </Column>
            <Column md={2}>
              <DatePicker
                label="Disabled"
                date={date}
                onChange={handleDateChange}
                disabled
              />
            </Column>
            <Column md={2}>
              <DatePicker
                label="readonly"
                date={date}
                onChange={handleDateChange}
                readonly
              />
            </Column>
            <Column md={2}>
              <DatePicker
                label="Min and Max Date"
                minDate={minDate}
                maxDate={maxDate}
                date={date}
                onChange={handleDateChange}
              />
            </Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestDatepickerField;
