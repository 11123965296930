import * as React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { Help } from '@material-ui/icons';
import { Row } from './layout/Row';
import { Column } from './layout/Column';
import TitleView from './layout/TitleView';
import { AppBarContext } from './AppBarContext';
import Header from './layout/Header';

const Page404 = () => {
  const { t } = useTranslation();

  const headerContext = React.useContext(AppBarContext);
  React.useEffect(() => {
    headerContext.setContent(
      <Header />,
    );
  }, [headerContext.setContent]);

  return (
    <Box width="56rem" mx="auto" mt="2">
      <Paper>
        <Box p={4}>
          <Row>
            <Column lg={1}>
              <Box height="100%" display="flex" justifyContent="center">
                <Help fontSize="large" />
              </Box>
            </Column>
            <Column lg={11}>
              <Box>
                <TitleView
                  title={t('issues.content.page404.pageNotFound.title', 'resource not found')}
                  align="left"
                  noMargins
                />
                <Box mt="0.5rem">
                  {t('issues.content.page404.pageNotFound.text', `The resource cannot be found or might have been 
                  removed, had its name changed, or is temporarily unavailable.`)}
                </Box>
              </Box>
            </Column>
          </Row>
        </Box>
      </Paper>
    </Box>
  );
};

export default Page404;
