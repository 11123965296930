import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import React from 'react';
import TitleView from '../layout/TitleView';
import { Row } from '../layout/Row';
import { icons, IconWrapper } from '../../lib/icons';
import { Column } from '../layout/Column';

function TestIconWrapper() {
  const { enqueueSnackbar } = useSnackbar();
  const copyName = (name: string) => {
    navigator.clipboard.writeText(name);
    enqueueSnackbar('Copied!', {
      variant: 'success',
      autoHideDuration: 1500,
    });
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>Icon Wrapper</Typography>
      <Paper>
        <Accordion className="accordion" elevation={3}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <TitleView title="Figma Icons (click on icons to copy the name)" size="small" noMargins />
          </AccordionSummary>
          <AccordionDetails>
            <Box p={2}>
              <Row>
                {icons.map((icon) => (
                  <Column key={icon} md={2} title={`${icon} (click to copy)`} onClick={() => copyName(icon)}>
                    <IconWrapper name={icon} size="large" color="primary" pointer />
                    <Typography variant="subtitle1" gutterBottom>{icon}</Typography>
                  </Column>
                ))}
              </Row>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
}

export default TestIconWrapper;
