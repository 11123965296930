import React, { useCallback } from 'react';
import { Circle, Text } from 'react-konva';
import { timestampSearchRange } from './Ground';

type pathType = {
  timestamp: number,
  x: number | null,
  y: number | null,
}[];

type PropsMarker = {
  active: boolean;
  hover: boolean;
  color?: string;
  textColor?: string;
  cursor: number;
  id: string;
  onClick: (data: any) => void;
  onDblClick?: (data: any) => void;
  onMouseEnter?: (data: any) => void;
  onMouseLeave?: (data: any) => void;
  pathData: pathType;
  scale: number;
  text: string;
};

const Marker = React.memo((props: PropsMarker) => {
  const {
    active,
    hover,
    color,
    textColor,
    cursor,
    id,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    pathData,
    scale,
    text,
  } = props;
  // @todo arrotondare per evitare ricerca (/ 1000 ?)
  let idx = cursor === 0
    ? 0
    : pathData.findIndex((point) => Math.abs(cursor - point.timestamp) < timestampSearchRange);

  const handleClick = useCallback(() => onClick(id), [id, onClick]);
  const handleDblClick = useCallback(() => onDblClick && onDblClick(id), [id, onDblClick]);
  const handleEnter = onMouseEnter ? useCallback(() => onMouseEnter(id), [id, onMouseEnter]) : undefined;
  const handleLeave = onMouseLeave ? useCallback(() => onMouseLeave(id), [id, onMouseLeave]) : undefined;

  // @ts-ignore
  const posX = pathData && pathData[idx] && pathData[idx].x !== null ? pathData[idx].x * scale : 0;
  // @ts-ignore
  const posY = pathData && pathData[idx] && pathData[idx].y !== null ? pathData[idx].y * scale : 0;

  return pathData && pathData[idx]
    ? (
      <>
        <Circle
          fill={color}
          onClick={handleClick}
          onDblClick={handleDblClick}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          radius={active || hover ? 8 : 5}
          stroke={active ? '' : hover ? 'orange' : 'white'}
          x={posX}
          y={posY}
        />
        <Text
          align="center"
          text={text}
          x={posX - text.length * 4}
          y={posY - 17}
          fill={textColor || '#828282'}
          fontSize={12}
          fontFamily="Roboto"
/*          sceneFunc={(con, shape) => {
            con.fillStyle = 'rgb(255,255,204)';
            con.fillRect(0 - 4, -2, shape.width() + 8, shape.height() + 4);
            (shape as Konva.Text)._sceneFunc(con);
          }} */
        />
      </>
    )
    : null;
});

export default Marker;
