const hex = ( c ) => {
  const s = '0123456789abcdef'
  let i = parseInt(c, 10)
  if (i === 0 || isNaN(c)) {
    return '00'
  }
  i = Math.round(Math.min(Math.max(0, i), 255))
  return s.charAt((i - i % 16) / 16) + s.charAt(i % 16)
}

const convertToHex = ( rgb ) => {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2])
}

const trim = ( s ) => {
  return (s.charAt(0) === '#') ? s.substring(1, 7) : s
}

const convertToRGB = ( hex ) => {
  const color = []
  color[0] = parseInt((trim(hex)).substring(0, 2), 16)
  color[1] = parseInt((trim(hex)).substring(2, 4), 16)
  color[2] = parseInt((trim(hex)).substring(4, 6), 16)
  return color
}

export const generateColor = ( colorStart, colorEnd, colorCount, light?, opacity? ) => {
  const start = convertToRGB(light ? shadeColor(colorStart, light) : colorStart)
  const end = convertToRGB(colorEnd)
  const len = colorCount
  let alpha = 0.0
  const output = []

  // tslint:disable-next-line:no-increment-decrement
  for (let i = 0; i < len; i++) {
    const c = []
    alpha += (1.0 / len)

    c[0] = start[0] * alpha + (1 - alpha) * end[0]
    c[1] = start[1] * alpha + (1 - alpha) * end[1]
    c[2] = start[2] * alpha + (1 - alpha) * end[2]

    output.push('#' + convertToHex(c) + (opacity || ''))
  }
  return output
}

const shadeColor = ( color, percent ) => {

  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = parseInt(R * (100 + percent) / 100)
  G = parseInt(G * (100 + percent) / 100)
  B = parseInt(B * (100 + percent) / 100)

  R = (R < 255) ? R : 255
  G = (G < 255) ? G : 255
  B = (B < 255) ? B : 255

  const RR = ((R.toString(16).length == 1) ? '0' + R.toString(16) : R.toString(16))
  const GG = ((G.toString(16).length == 1) ? '0' + G.toString(16) : G.toString(16))
  const BB = ((B.toString(16).length == 1) ? '0' + B.toString(16) : B.toString(16))

  return '#' + RR + GG + BB
}
