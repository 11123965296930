import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import { CloudCircle } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import BaseChip from '../form/BaseChip';

function TestBaseChip() {
  return (
    <>
      <Typography variant="h5" gutterBottom>BaseChip</Typography>
      <Paper id="test-basechip">
        <Box p={2}>
          <Row>
            <Column md={2}><BaseChip label="label" /></Column>
            <Column md={2}><BaseChip label="color - primary" color="primary" /></Column>
            <Column md={2}><BaseChip label="color - secondary" color="secondary" /></Column>
            <Column md={2}><BaseChip label="color - default" color="default" /></Column>
            <Column md={2}><BaseChip label="icon" icon={<CloudCircle />} /></Column>
            <Column md={2}><BaseChip label="inTextField" inTextField /></Column>
            <Column md={2}><BaseChip label="disable" disable /></Column>
            <Column md={2}><BaseChip label="clickable" clickable /></Column>
            <Column md={2}><BaseChip label="onClick" onClick={() => alert('Click!')} /></Column>
            <Column md={2}><BaseChip label="onDelete" onDelete={() => alert('Delete!')} /></Column>
            <Column md={2}>
              <BaseChip
                label="onClick - onDelete"
                onClick={() => alert('Click!')}
                onDelete={() => alert('Delete!')}
              />
            </Column>
            <Column md={2}><BaseChip label="size - small" size="small" /></Column>
            <Column md={2}><BaseChip label="size - medium" size="medium" /></Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestBaseChip;
