import React, {
  useMemo, useRef, useState, useEffect,
} from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { getGreatCircleBearing, getDistance, computeDestinationPoint } from 'geolib';
import GroundLPS from './webPlayer/GroundLPS';
import GroundGPS from './webPlayer/GroundGPS';
import { CursorContext } from './track/TrackChartContainer';
import { LPSFromGPSPath } from '../lib/geo';
import WebPlayer from './webPlayer/WebPlayer';

type Coordinate = {
  latitude: number;
  longitude: number;
};

const generateCoordinates = (A: Coordinate, B: Coordinate, C: number) => {
  const bearing = getGreatCircleBearing(A, B);
  const distance = getDistance(A, B);
  const stepDistance = distance / (C - 1);
  const sequence: Coordinate[] = [];

  for (let step = 0; step < C; step += 1) {
    const destination = computeDestinationPoint(A, stepDistance * step, bearing);
    sequence.push(destination);
  }

  return sequence;
};

const posA = { latitude: 46.081878720234705, longitude: 13.199290037155153 };
const posB = { latitude: 46.08204505865043, longitude: 13.200084418924286 };
const posC = { latitude: 46.081227157412535, longitude: 13.200440374833565 };
const posD = { latitude: 46.08106082146267, longitude: 13.19964600376746 };

const sequenceAD = generateCoordinates(
  posA,
  posD,
  3,
);

const sequenceAB = generateCoordinates(
  posA,
  posB,
  3,
);

const sequenceCD = generateCoordinates(
  posC,
  posD,
  3,
);

const sequenceCB = generateCoordinates(
  posC,
  posB,
  3,
);

/**
 * Componente per effettuare test in fase di sviluppo su una rotta `/test-components/current`
 *
 * @description NON COMMITTARE le modifiche. Usarlo solo per testare componenti singolarmente.
 *
 * @constructor
 */
const TestGroundsPlayer = () => {
  const intervalRef = useRef<any>(null);
  const [currentTime, setCurrentTime] = useState(1722246815000);

  const initialValue = 1722246815000;
  const maxCounter = 16;

  const [counter, setCounter] = useState(0);

  const cursorValue = useMemo(() => ({
    cursor: currentTime,
    setCursor: setCurrentTime,
  }), [currentTime]);

  const enabledPlayers = ["100"];
  const seriesLPS = {
    100: {
      pathData: [
        { timestamp: 1722246815000, x: 0, y: 0 },
        { timestamp: 1722246825000, x: 10, y: 0 },
        { timestamp: 1722246835000, x: 20, y: 0 },
        { timestamp: 1722246845000, x: 30, y: 0 },
        { timestamp: 1722246855000, x: 0, y: -10 },
        { timestamp: 1722246865000, x: 0, y: -20 },
        { timestamp: 1722246875000, x: 0, y: -30 },
        // football
        { timestamp: 1722246885000, x: 100, y: -60 },
        { timestamp: 1722246895000, x: 100, y: -50 },
        { timestamp: 1722246905000, x: 90, y: -60 },
        // futsal
        { timestamp: 1722246915000, x: 40, y: -20 },
        { timestamp: 1722246925000, x: 40, y: -10 },
        { timestamp: 1722246935000, x: 30, y: -20 },
        // basket
        { timestamp: 1722246945000, x: 28, y: -15 },
        { timestamp: 1722246955000, x: 28, y: -10 },
        { timestamp: 1722246965000, x: 18, y: -15 },
      ],
    },
  };

  const seriesGPS = {
    100: {
      pathData: [
        { timestamp: 1722246815000, x: sequenceAD[0].latitude, y: sequenceAD[0].longitude },
        { timestamp: 1722246825000, x: sequenceAD[1].latitude, y: sequenceAD[1].longitude },
        { timestamp: 1722246835000, x: sequenceAD[2].latitude, y: sequenceAD[2].longitude },

        { timestamp: 1722246845000, x: sequenceAB[0].latitude, y: sequenceAB[0].longitude },
        { timestamp: 1722246855000, x: sequenceAB[1].latitude, y: sequenceAB[1].longitude },
        { timestamp: 1722246865000, x: sequenceAB[2].latitude, y: sequenceAB[2].longitude },

        { timestamp: 1722246875000, x: sequenceCB[0].latitude, y: sequenceCB[0].longitude },
        { timestamp: 1722246885000, x: sequenceCB[1].latitude, y: sequenceCB[1].longitude },
        { timestamp: 1722246895000, x: sequenceCB[2].latitude, y: sequenceCB[2].longitude },

        { timestamp: 1722246905000, x: sequenceCD[0].latitude, y: sequenceCD[0].longitude },
        { timestamp: 1722246915000, x: sequenceCD[1].latitude, y: sequenceCD[1].longitude },
        { timestamp: 1722246925000, x: sequenceCD[2].latitude, y: sequenceCD[2].longitude },

        { timestamp: 1722246935000, x: sequenceAD[0].latitude, y: sequenceAD[0].longitude },
        { timestamp: 1722246945000, x: sequenceAD[1].latitude, y: sequenceAD[1].longitude },
        { timestamp: 1722246955000, x: sequenceAD[0].latitude, y: sequenceAD[0].longitude },
        { timestamp: 1722246965000, x: sequenceAD[2].latitude, y: sequenceAD[2].longitude },
      ],
    },
  };

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      setCounter((prevState) => (prevState < maxCounter ? prevState + 1 : 0));
    }, 1000);

    return () => {
      intervalRef.current?.clearInterval();
    };
  }, []);

  useEffect(() => {
    setCurrentTime((initialValue + counter * 10000));
  }, [counter]);

  return (
    <div style={{ maxWidth: '1600px' }}>
      <CursorContext.Provider value={cursorValue}>
        <Paper>
          <Box p={2} mb={3}>
            <GroundLPS
              activePlayer={null}
              hoverPlayer={null}
              enabledPlayers={enabledPlayers}
              groundId="59"
              jerseyOrNumber
              onPlayerClick={() => {
              }}
              onPlayerEnter={() => {
              }}
              onPlayerLeave={() => {
              }}
              playerLinks={new Set()}
              playersColors={{}}
              playersDetails={{}}
              removeLink={() => {
              }}
              series={seriesLPS}
              showGroundName
              trailsEnabled
            />
          </Box>
        </Paper>

        <Paper>
          <Box p={2} mb={3}>
            <GroundLPS
              activePlayer={null}
              hoverPlayer={null}
              enabledPlayers={enabledPlayers}
              groundId="60"
              jerseyOrNumber
              onPlayerClick={() => {
              }}
              onPlayerEnter={() => {
              }}
              onPlayerLeave={() => {
              }}
              playerLinks={new Set()}
              playersColors={{}}
              playersDetails={{}}
              removeLink={() => {
              }}
              series={seriesLPS}
              showGroundName
              trailsEnabled
            />
          </Box>
        </Paper>

        <Paper>
          <Box p={2} mb={3}>
            <GroundLPS
              activePlayer={null}
              hoverPlayer={null}
              enabledPlayers={enabledPlayers}
              groundId="61"
              jerseyOrNumber
              onPlayerClick={() => {
              }}
              onPlayerEnter={() => {
              }}
              onPlayerLeave={() => {
              }}
              playerLinks={new Set()}
              playersColors={{}}
              playersDetails={{}}
              removeLink={() => {
              }}
              series={seriesLPS}
              showGroundName
              trailsEnabled
            />
          </Box>
        </Paper>

        <Paper>
          <Box p={2} mb={3}>
            <GroundGPS
              activePlayer={null}
              hoverPlayer={null}
              enabledPlayers={enabledPlayers}
              groundId="62"
              jerseyOrNumber
              onPlayerClick={() => {
              }}
              onPlayerEnter={() => {
              }}
              onPlayerLeave={() => {
              }}
              playerLinks={new Set()}
              playersColors={{}}
              playersDetails={{}}
              removeLink={() => {
              }}
              series={{
                100: {
                  pathData: LPSFromGPSPath(
                    seriesGPS['100'].pathData,
                    posA,
                    posB,
                    posC,
                  ),
                },
              }}
              showGroundName
              showVertices
              trailsEnabled
            />
          </Box>
        </Paper>
      </CursorContext.Provider>
      <hr />
      WINTER
      <Paper>
        <Box p={2} mb={3}>
          38694

          <WebPlayer currentDrill={null} sessionId="38694" teamId="397" templateId="1227" />
        </Box>
      </Paper>

      <Paper>
        <Box p={2} mb={3}>
          38695

          <WebPlayer currentDrill={null} sessionId="38695" teamId="397" templateId="1227" />
        </Box>
      </Paper>

      <Paper>
        <Box p={2} mb={3}>
          38696

          <WebPlayer currentDrill={null} sessionId="38696" teamId="397" templateId="1227" />
        </Box>
      </Paper>

      <Paper>
        <Box p={2} mb={3}>
          38697

          <WebPlayer currentDrill={null} sessionId="38697" teamId="397" templateId="1227" />
        </Box>
      </Paper>

      <hr />
      SUMMER

      <Paper>
        <Box p={2} mb={3}>
          38698

          <WebPlayer currentDrill={null} sessionId="38698" teamId="397" templateId="1227" />
        </Box>
      </Paper>

      <Paper>
        <Box p={2} mb={3}>
          38699

          <WebPlayer currentDrill={null} sessionId="38699" teamId="397" templateId="1227" />
        </Box>
      </Paper>

      <Paper>
        <Box p={2} mb={3}>
          38700

          <WebPlayer currentDrill={null} sessionId="38700" teamId="397" templateId="1227" />
        </Box>
      </Paper>

      <Paper>
        <Box p={2} mb={3}>
          38701

          <WebPlayer currentDrill={null} sessionId="38701" teamId="397" templateId="1227" />
        </Box>
      </Paper>
    </div>
  );
};

export default TestGroundsPlayer;
