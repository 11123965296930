import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { CloudCircle } from '@material-ui/icons';
import React from 'react';
import BaseButton from '../form/BaseButton';

function TestBaseButton() {
  return (
    <>
      <Typography variant="h5" gutterBottom>BaseButton</Typography>
      <Paper id="test-buttons">
        <Box p={2}>
          <BaseButton color="default">color - default</BaseButton>
          <span style={{ color: 'red' }}>
            <BaseButton color="inherit">color - inherit</BaseButton>
          </span>
          <BaseButton color="primary">color - primary</BaseButton>
          <BaseButton color="secondary">color - secondary</BaseButton>
          <Divider style={{ margin: '0.5rem' }} />
          <BaseButton buttonSize="sm">buttonSize - sm</BaseButton>
          <BaseButton buttonSize="md">buttonSize - md</BaseButton>
          <BaseButton buttonSize="lg">buttonSize - lg</BaseButton>
          <Divider style={{ margin: '0.5rem' }} />
          <BaseButton flat>flat</BaseButton>
          <BaseButton active>active</BaseButton>
          <BaseButton noMargins>noMargins</BaseButton>
          <Divider style={{ margin: '0.5rem' }} />
          <BaseButton startIcon={<CloudCircle />}>startIcon</BaseButton>
          <BaseButton endIcon={<CloudCircle />}>endIcon</BaseButton>
          <BaseButton collapseOnMobile startIcon={<CloudCircle />}>startIcon - collapseOnMobile</BaseButton>
          <BaseButton collapseOnMobile endIcon={<CloudCircle />}>endIcon - collapseOnMobile</BaseButton>
          <Divider style={{ margin: '0.5rem' }} />
          <BaseButton badge={3}>badge - 3</BaseButton>
          <BaseButton badge={3} badgeColor="primary">badge - 3 - color primary</BaseButton>
          <BaseButton isIconButton><CloudCircle /></BaseButton>
          <BaseButton isIconButton rotate><CloudCircle /></BaseButton>
          <BaseButton color="default" tooltipText="this is the tooltip">tooltip</BaseButton>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestBaseButton;
