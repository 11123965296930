import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmIcon from '@images/icons/svg/confirm.svg';
import Modal from './Modal';

type PropsIn = {
  title: string;
  content: string;
  visible: boolean;
  onClose: () => void;
  onClick: () => void;
}

const DeleteDialogBox = (props: PropsIn) => {
  const {
    title, content, visible, onClick, onClose,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      buttonColor="danger"
      buttonAction={t('dialog.action.confirm', 'confirm')}
      icon={<ConfirmIcon />}
      content={content}
      visible={visible}
      onClick={onClick}
      onClose={onClose}
    />
  );
};

export default DeleteDialogBox;
