import { GroundType } from 'models/ground';
import React from 'react';
import {
  Circle, Group, Line, Path,
} from 'react-konva';
import {
  BASKET_AREA_OFFSET,
  BASKET_AREA_RADIUS,
  BASKET_CENTER_RADIUS,
  BASKET_FREE_TROW_AREA,
  BASKET_NO_CHARGING_AREA_RADIUS,
  BASKET_RADIUS,
  DEFAULT_PITCH_LINE_COLOR,
  DEFAULT_PITCH_STROKEWIDTH,
} from '../constants';
import {
  rotate, flipPoint, computeAngle, flipX, flipY,
} from '../utils';
import Vertices from '../Vertices';

function Area(props: {
  vertexA: { x: number; y: number };
  vertexC: { x: number; y: number };
  height: number;
  center: { x: number; y: number };
  scale: number;
  flipped?: boolean;
}) {
  const {
    vertexA, vertexC, height, scale, flipped, center,
  } = props;

  const scaledBasketRadius = BASKET_RADIUS * scale;
  const scaledCenterRadius = BASKET_CENTER_RADIUS * scale;
  const scaledNoChargingRadius = BASKET_NO_CHARGING_AREA_RADIUS * scale;
  const scaledAreaRadius = BASKET_AREA_RADIUS * scale;
  const scaledAreaOffset = BASKET_AREA_OFFSET * scale;
  const scaledFreeTrowArea = BASKET_FREE_TROW_AREA * scale;

  return (
    <Group>
      <Line
        points={
        flipped
          ? [
            flipX({ x: vertexA.x, y: vertexA.y + scaledAreaRadius - height / 2 }, center),
            flipY({ x: vertexA.x, y: vertexA.y + scaledAreaRadius - height / 2 }, center),
            flipX({ x: vertexA.x + scaledAreaOffset, y: vertexA.y + scaledAreaRadius - height / 2 }, center),
            flipY({ x: vertexA.x + scaledAreaOffset, y: vertexA.y + scaledAreaRadius - height / 2 }, center),
          ]
          : [
            vertexA.x,
            vertexA.y + scaledAreaRadius - height / 2,
            vertexA.x + scaledAreaOffset,
            vertexA.y + scaledAreaRadius - height / 2,
          ]
      }
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Path
        x={flipped ? vertexC.x - scaledAreaOffset : vertexA.x + scaledAreaOffset}
        y={vertexA.y - height / 2}
        data={`M 0 ${-scaledAreaRadius} A ${scaledAreaRadius} ${scaledAreaRadius} 0 0 1 0 ${scaledAreaRadius}`}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
        rotation={flipped ? 180 : 0}
      />
      <Line
        points={
          flipped
            ? [
              flipX({ x: vertexA.x, y: vertexA.y - scaledAreaRadius - height / 2 }, center),
              flipY({ x: vertexA.x, y: vertexA.y - scaledAreaRadius - height / 2 }, center),
              flipX({ x: vertexA.x + scaledAreaOffset, y: vertexA.y - scaledAreaRadius - height / 2 }, center),
              flipY({ x: vertexA.x + scaledAreaOffset, y: vertexA.y - scaledAreaRadius - height / 2 }, center),
            ]
            : [
              vertexA.x,
              vertexA.y - scaledAreaRadius - height / 2,
              vertexA.x + scaledAreaOffset,
              vertexA.y - scaledAreaRadius - height / 2,
            ]
        }
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={
          flipped
            ? [
              flipX({ x: vertexA.x, y: vertexA.y - scaledCenterRadius - height / 2 }, center),
              flipY({ x: vertexA.x, y: vertexA.y - scaledCenterRadius - height / 2 }, center),
              flipX({ x: vertexA.x + scaledFreeTrowArea, y: vertexA.y - scaledCenterRadius - height / 2 }, center),
              flipY({ x: vertexA.x + scaledFreeTrowArea, y: vertexA.y - scaledCenterRadius - height / 2 }, center),
              flipX({ x: vertexA.x + scaledFreeTrowArea, y: vertexA.y + scaledCenterRadius - height / 2 }, center),
              flipY({ x: vertexA.x + scaledFreeTrowArea, y: vertexA.y + scaledCenterRadius - height / 2 }, center),
              flipX({ x: vertexA.x, y: vertexA.y + scaledCenterRadius - height / 2 }, center),
              flipY({ x: vertexA.x, y: vertexA.y + scaledCenterRadius - height / 2 }, center),
            ]
            : [
              vertexA.x,
              vertexA.y - scaledCenterRadius - height / 2,
              vertexA.x + scaledFreeTrowArea,
              vertexA.y - scaledCenterRadius - height / 2,
              vertexA.x + scaledFreeTrowArea,
              vertexA.y + scaledCenterRadius - height / 2,
              vertexA.x,
              vertexA.y + scaledCenterRadius - height / 2,
            ]
        }
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Path
        x={flipped ? vertexC.x - scaledFreeTrowArea : vertexA.x + scaledFreeTrowArea}
        y={vertexA.y - height / 2}
        data={`M 0 ${-scaledCenterRadius} A ${scaledCenterRadius} ${scaledCenterRadius} 0 0 1 0 ${scaledCenterRadius}`}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
        rotation={flipped ? 180 : 0}
      />
      <Path
        x={flipped ? vertexC.x - scaledAreaOffset : vertexA.x + scaledAreaOffset}
        y={vertexA.y - height / 2}
        data={`M 0 ${-scaledNoChargingRadius} A ${scaledNoChargingRadius} ${scaledNoChargingRadius} 0 0 1 0 ${scaledNoChargingRadius}`}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
        rotation={flipped ? 180 : 0}
      />
      <Circle
        x={flipped ? vertexC.x - scaledAreaOffset : vertexA.x + scaledAreaOffset}
        y={vertexA.y - height / 2}
        radius={scaledBasketRadius < 0 ? scaledBasketRadius * -1 : scaledBasketRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
    </Group>
  );
}

export default function Basket(props: { groundData: GroundType; scale: number; vertices: boolean }) {
  const { groundData, scale, vertices } = props;

  let vertexA = {
    x:
      rotate(flipPoint({ x: groundData.vertexAX?.value, y: groundData.vertexAY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexAX?.value, y: groundData.vertexAY?.value }, groundData), computeAngle(groundData)).y
      * scale,
  };
  let vertexC = {
    x:
      rotate(flipPoint({ x: groundData.vertexCX?.value, y: groundData.vertexCY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexCX?.value, y: groundData.vertexCY?.value }, groundData), computeAngle(groundData)).y
      * scale,
  };

  if (vertexA.x > vertexC.x) {
    const swap = vertexC;
    vertexC = vertexA;
    vertexA = swap;
  }

  const width = vertexC.x - vertexA.x;
  const height = vertexA.y - vertexC.y;

  const center = { x: vertexA.x + width / 2, y: vertexA.y - height / 2 };

  const scaledCenterRadius = BASKET_CENTER_RADIUS * scale;

  return (
    <Group>
      {vertices ? <Vertices A={vertexA} C={vertexC} /> : null}
      {/* border */}
      <Line
        fill={groundData.groundSurfaceColor ? groundData.groundSurfaceColor : '#00000000'}
        points={[vertexA.x, vertexA.y, vertexC.x, vertexA.y, vertexC.x, vertexC.y, vertexA.x, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
        closed
      />
      {/* left area */}
      <Area vertexA={vertexA} vertexC={vertexC} height={height} center={center} scale={scale} />
      {/* halfcourt */}
      <Line
        points={[vertexA.x + width / 2, vertexA.y, vertexC.x - width / 2, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={vertexA.x + width / 2}
        y={vertexA.y - height / 2}
        radius={scaledCenterRadius < 0 ? scaledCenterRadius * -1 : scaledCenterRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* rightarea */}
      <Area vertexA={vertexA} vertexC={vertexC} height={height} center={center} scale={scale} flipped />
    </Group>
  );
}
