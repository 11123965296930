import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import { ImageTools } from '../../lib/images';

function TestImageTools() {
  const handleFileChange = (ev) => {
    [
      [128, 86],
      [320, 240],
      [1024, 1024],
    ].forEach((sizes, ind) => {
      setTimeout(() => {
        ImageTools.resize(ev.target.files[0], {
          width: sizes[0], // maximum width
          height: sizes[1], // maximum height
        }, (blob) => {
          // you can also now upload this blob using an XHR.
          document.getElementById(`preview-${ind + 1}`).src = window.URL.createObjectURL(blob);
        });
      }, 1000 * (ind + 1));
    });
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>ImageTools</Typography>
      <Paper>
        <Box p={2}>
          <Row>
            <Column md={6}>
              <h3>Test scalatura immagini client side</h3>
              <p>
                Tenterà di generare 3 dimensioni ([128, 86], [320, 240], [1024, 1024] ) dell'immagine,  1 al secondo
              </p>
            </Column>
            <Column md={6}><input type="file" name="image" onChange={handleFileChange} /></Column>
          </Row>
          <Row>
            <Column md={4}><img id="preview-1" /></Column>
            <Column md={4}><img id="preview-2" /></Column>
            <Column md={4}><img id="preview-3" /></Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestImageTools;
