import Typography from '@material-ui/core/Typography';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import ErrorGuard from 'components/layout/ErrorGuard';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import BaseButton from '../form/BaseButton';

export function BuggyComponent() {
  const [counter, setCounter] = React.useState(0);
  const handleClick = () => setCounter((prev) => prev + 1);
  const triggerError = () => {
    // not working, you need a try catch
    throw new Error('Invalid crash!');
  };

  if (counter === 5) {
    throw new Error('Crash!');
  }

  return (
    <>
      <Box>
        <strong>Counting:</strong>
        {' '}
        {counter}
        {' '}
        (when 5 will throw an error)
        <BaseButton onClick={handleClick}>Click me!</BaseButton>
      </Box>
      <Box mt={4}>
        This will not trigger the error boundary (check console).
        In events and handlers you must use a try/catch
        <BaseButton onClick={triggerError}>Click me!</BaseButton>
      </Box>
    </>
  );
}

function ParentComponent() {
  return (
    <Box mt={2}>
      Parent component
      <ErrorGuard>
        <Paper>
          <Box p={2}>
            Nested component
            <BuggyComponent />
          </Box>
        </Paper>
      </ErrorGuard>
    </Box>
  );
}

function TestErrorBoundary() {
  return (
    <>
      <Typography id="acl-wrapper-test" variant="h5" gutterBottom>ErrorBoundary</Typography>
      <Paper>
        <Box p={2}>
          <Row>
            <Column md={4}>
              <strong>Protected</strong>
              <hr />
              <ErrorGuard>
                <BuggyComponent />
              </ErrorGuard>
            </Column>
            <Column md={4}>
              <strong>Protected (nested)</strong>
              <hr />
              <ParentComponent />
            </Column>
            <Column md={4}>
              <strong>Unprotected, will crash entire app when 5</strong>
              <hr />
              <BuggyComponent />
            </Column>

          </Row>
        </Box>
      </Paper>
    </>
  );
}

export default TestErrorBoundary;
