import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ArrowUp from '@images/ChipIcon/fast-rewind2.svg';
import ArrowDown from '@images/ChipIcon/fast-rewind.svg';
import BaseChip from '../form/BaseChip';
import GridSortable from '../form/GridSortable';
import { KpiChip } from '../templates/SelectableKpi';
import { Column } from '../layout/Column';
import { Row } from '../layout/Row';

const initState = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
];

const TestGridSortable = () => {
  const [items, setItems] = useState(initState);

  const [selected, setSelected] = useState(initState.reduce((acc, curr) => {
    acc[curr] = false;
    return acc;
  }, {}));

  const handleDelete = (i: number) => {
    setItems((prevState) => prevState.filter((item) => item !== i));
  };

  const handleSorting = (arr, from, to) => {
    setItems(arr.map((a) => a.key));
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>GridSortable</Typography>
      <Paper id="test-basechip">
        <Box p={2}>
          <Typography variant="h5" gutterBottom>Default - Fluid</Typography>
          <GridSortable
            items={items.map((i) => (
              <BaseChip
                key={i}
                label={`Item ${i}`}
              />
            ))}
            onItemsMove={handleSorting}
          />
        </Box>

        <Divider style={{ margin: '2rem' }} />

        <Box p={2}>
          <Typography variant="h5" gutterBottom>Default - Fixed 5 columns</Typography>
          <GridSortable
            columns={5}
            items={items.map((i) => (
              <BaseChip
                key={i}
                label={`Item ${i}`}
              />
            ))}
            onItemsMove={handleSorting}
          />
        </Box>

        <Divider style={{ margin: '2rem' }} />

        <Box p={2}>
          <Typography variant="h5" gutterBottom>Removable</Typography>
          <GridSortable
            columns={5}
            items={items.map((i) => (
              <BaseChip
                key={i}
                label={`Item ${i}`}
                onDelete={() => handleDelete(i)}
              />
            ))}
            onItemsMove={handleSorting}
          />
        </Box>

        <Divider style={{ margin: '2rem' }} />

        <Box p={2}>
          <Typography variant="h5" gutterBottom>Clickable</Typography>
          <GridSortable
            onItemsMove={handleSorting}
            items={items.map((i) => (
              <KpiChip
                key={i}
                label={`Item ${i}`}
                isSelected={selected[i]}
                selectable
                action={(e) => setSelected((prevState) => (
                  {
                    ...prevState,
                    ...{ [i]: !prevState[i] },
                  }
                ))}
              />
            ))}
          />
        </Box>

        <Divider style={{ margin: '2rem' }} />

        <Box p={2}>
          <Typography variant="h5" gutterBottom>Clickable 2</Typography>
          <GridSortable
            onItemsMove={handleSorting}
            items={items.map((i) => (
              <BaseChip
                className="chipClassStatic"
                label={`Item ${i}`}
                key={i}
                onClick={(e) => setSelected((prevState) => (
                  {
                    ...prevState,
                    ...{ [i]: !prevState[i] },
                  }
                ))}
                icon={
                  i % 2 === 0 ? (
                    <Box pl={1.5} pr={1.5}>
                      <Column>
                        <Row>{selected[i] ? <ArrowUp /> : <ArrowDown />}</Row>
                      </Column>
                    </Box>
                  ) : null
                }
              />
            ))}
          />
        </Box>

      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
};

export default TestGridSortable;
