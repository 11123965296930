import React from 'react';
import { Box } from '@material-ui/core';
import BaseChip from 'components/form/BaseChip';

type PropsIn = {
  tags: string[];
};

export default function TagsCell(props: PropsIn) {
  const { tags } = props;

  return (
    <Box
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {tags.map((t) => (
        <BaseChip size="small" key={t} label={t} inTextField />
      ))}
    </Box>
  );
}
