import React, { DOMAttributes } from 'react';
import Grid, { GridSize, GridSpacing } from '@material-ui/core/Grid';

type EventsProps = DOMAttributes<HTMLDivElement>

interface PropsIn {
  id?: string;
  className?: string
  xs?: boolean | GridSize
  xl?: boolean | GridSize
  lg?: boolean | GridSize
  md?: boolean | GridSize
  sm?: boolean | GridSize
  spacing?: GridSpacing
  minWidth?: number
  title?: string
  children?: React.ReactNode
  style?: object,
}

/**
 * A column of a grid.
 * @param props
 * @constructor
 */
export function Column(props: PropsIn & EventsProps) {
  const {
    children, id, className, xs, xl, md, sm, lg, style,
  } = props;

  return (
    <Grid
      style={style}
      id={id}
      item
      component="div"
      className={className}
      lg={lg || (
        !xs && !xl && !md && !sm && !lg
          ? true
          : undefined
      )}
      {...props}
    >
      {children}
    </Grid>
  );
}
