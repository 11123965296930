import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts.js';
import { useTranslation } from 'react-i18next';
import { KPIType } from '../../query/track';
import { getPrimaryColorGradient } from '../utils/colors';
import { tooltipStyle, tooltipFormatter } from './utils';
import { getUomTranslation } from '../utils/utils';

interface PropsIn {
  data: { [k: string | number]: KPIType };
}

const WindRoseChart = (props: PropsIn) => {
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const { data } = props;
  const { t } = useTranslation();
  const chartColors = getPrimaryColorGradient(2);

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      polar: true,
      type: 'column',
      spacingBottom: 30,
      spacingTop: 0,
      spacingLeft: 20,
      spacingRight: 20,
    },
    pane: {
      size: '85%',
      startAngle: 0,
      endAngle: 360,
    },
    tooltip: {
      ...tooltipStyle,
      shared: false,
      split: false,
      headerFormat: '',
    },
    xAxis: {
      tickmarkPlacement: 'on',
      min: 0,
      max: 360,
      tickInterval: 90,
      labels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    yAxis: [
      {
        min: 0,
        endOnTick: false,
        // @ts-ignore
        showLastLabel: true,
        tickAmount: 6,
        title: {
          text: '',
        },
        reversedStacks: false,
      },
      {
        min: 0,
        angle: 90,
        endOnTick: false,
        // @ts-ignore
        showLastLabel: true,
        tickAmount: 6,
        title: {
          text: '',
        },
        reversedStacks: false,
      },
    ],
    plotOptions: {
      series: {
        stacking: 'normal',
        shadow: false,
        pointPlacement: 'on',
        pointStart: 0,
        pointInterval: 90,
      },
      column: {
        pointPadding: 0,
        groupPadding: 0,
      },
    },
    tooltip: {
      ...tooltipStyle,
      headerFormat: '',
      pointFormatter() {
        const tooltipObject = [
          {
            key: `${this.name}`,
            value: this.y,
            unit: data.front.unit,
            uom: getUomTranslation(t, data.front.uom),
            valueType: 'CustomValueType',
          },
        ];
        return tooltipFormatter(tooltipObject);
      },
    },
    title: {
      text: undefined,
    },
    series: [
      {
        type: 'column',
        name: '',
        data: [
          {
            name: 'front',
            y: data.front?.value,
            color: chartColors[0],
          },
          {
            name: 'right',
            y: data.right?.value,
            color: chartColors[1],
          },
          {
            name: 'back',
            y: data.back?.value,
            color: chartColors[0],
          },
          {
            name: 'left',
            y: data.left?.value,
            color: chartColors[1],
          },
        ],
        pointPlacement: 'on',
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />;
};

export default WindRoseChart;
