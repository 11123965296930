import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@material-ui/x-grid';
import { ClubType } from 'models/club';
import { UserRoleSetType, UserType } from 'models/user';
import Checkbox from 'components/form/Checkbox';
import TitleView from 'components/layout/TitleView';
import { countryFlags } from 'components/users/utils';
import { IconWrapper } from '../../lib/icons';
import { TextField } from '../form/TextField';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import { UNITS_LABELS } from '../utils/units';
import Table from '../Table';
import { formatName } from '../utils/utils';
import { colorsPaletteOptions } from 'components/utils/colors';

type PropsIn = {
  id?: string;
  user?: UserType;
  setContentOpen?: (open: boolean) => void;
  SetClub?: (club: ClubType) => void;
};

export function UserRoleTable(props: { user: any }) {
  const { user } = props;
  const { t } = useTranslation();
  const rows = user?.userroleSet?.map((ur, num) => ({
    id: `userrole_${num}`,
    role: `${t(`user.userRolesType.${ur.roleType.toLowerCase()}`, ur.roleType.toLowerCase())} - ${ur.club?.name}`,
    team: ur?.team ? `${ur?.team?.name} ${ur?.team?.season}` : '-',
    athlete: ur?.athlete?.name ? formatName(ur.athlete.name) : '-',
  })) || [];

  const columns: GridColDef[] = [
    {
      field: 'role',
      headerName: t('user.drawer.content.role', 'role'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      flex: 1,
      align: 'left' as 'left',
      sortable: false,
      hideSortIcons: true,
      renderCell: (params:GridCellParams) => <span className="nolink-col-left">{params.row.role}</span>,
    },
    {
      field: 'team',
      headerName: t('athlete.widgets.team.title', 'team'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      flex: 1,
      align: 'left' as 'left',
      sortable: false,
      hideSortIcons: true,
      renderCell: (params:GridCellParams) => <span className="nolink-col-left">{params.row.team}</span>,
    },
    {
      field: 'athlete',
      headerName: t('table.athlete', 'athlete'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      flex: 1,
      align: 'left' as 'left',
      sortable: false,
      hideSortIcons: true,
      renderCell: (params:GridCellParams) => <span className="nolink-col-left">{params.row.athlete}</span>,
    },
  ];
  return <Table compactHeader columns={columns} rows={rows} disableColumnFilter disableCursor hideFooter />;
}

export function UserTable(props: PropsIn) {
  const {
    user, SetClub,
  } = props;
  const { t } = useTranslation();
  return (
    <Row>
      <Column xs={11}>
        <Paper className="track--form" style={{ marginBottom: '-4px' }}>
          <Box px={2} py={2}>
            <Row spacing={2}>
              <Column xs={3}>
                <TextField readonly label={t('athlete.widgets.label.lastName', 'last name')} value={user?.lastName} />
              </Column>
              <Column xs={3}>
                <TextField
                  readonly
                  label={t('athlete.widgets.label.firstName', 'first name')}
                  value={user?.firstName}
                />
              </Column>
              <Column xs={4}>
                <TextField readonly label={t('user.drawer.content.email', 'email')} value={user?.email} />
              </Column>
              <Column xs={2}>
                <Checkbox
                  readonly
                  value={user?.isActive}
                  label={t('user.drawer.content.isActive', 'is active')}
                  defaultValue={user?.isActive}
                />
              </Column>
              <Column xs={3}>
                <TextField
                  readonly
                  label={t('user.drawer.content.language', 'language')}
                  value={user?.preferredLanguageDisplay}
                  endAdornment={countryFlags[user?.preferredLanguage.toLowerCase().replace('_', '-')]}
                />
              </Column>
              <Column xs={3}>
                <TextField
                  readonly
                  label={t('user.drawer.content.colorPalette', 'color palette')}
                  value={colorsPaletteOptions.filter((obj) => obj.value === user?.colorPalette)[0]?.label}
                />
              </Column>
            </Row>
          </Box>
        </Paper>
      </Column>
      <Column xs={8}>
        <Paper>
          <Box p={2}>
            <TitleView
              size="medium"
              prefixIcon={<IconWrapper name="userRole" size="medium" color="primary" />}
              align="left"
              title={t('user.drawer.content.roles', 'roles')}
            />
            <UserRoleTable user={user} />
          </Box>
        </Paper>
      </Column>
      <Column xs={3}>
        <Paper className="track--form">
          <Box p={2}>
            <TitleView
              size="medium"
              prefixIcon={<IconWrapper name="units" size="medium" color="primary" />}
              align="left"
              title={t('user.drawer.content.units.title', 'units')}
            />
            <Row spacing={2}>
              <Column xs={6}>
                <TextField
                  readonly
                  label={t('user.drawer.content.units.temperature', 'temperature')}
                  value={UNITS_LABELS[user?.userunits.temperature] || '-'}
                />
              </Column>
              <Column xs={6}>
                <TextField
                  readonly
                  label={t('user.drawer.content.units.speed', 'speed')}
                  value={UNITS_LABELS[user?.userunits.speed] || '-'}
                />
              </Column>
              <Column xs={6}>
                <TextField
                  readonly
                  label={t('user.drawer.content.units.power', 'power')}
                  value={UNITS_LABELS[user?.userunits.power] || '-'}
                />
              </Column>
              <Column xs={6}>
                <TextField
                  readonly
                  label={t('user.drawer.content.units.energy', 'energy')}
                  value={UNITS_LABELS[user?.userunits.energy] || '-'}
                />
              </Column>
            </Row>
          </Box>
        </Paper>
      </Column>
    </Row>
  );
}
