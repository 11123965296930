import { gql } from '@apollo/client';
import { PlayerType, PlayingRoleType } from 'models/athlete';
import {
  TeamType, TeamChangelogType, GenericValueInputType, getLimitedTeamFragmentName, getLimitedTeamFragment,
} from 'models/team';
import { TeamSessionCategoryType } from 'models/team_session';
import { TeamSessionTagsType } from '../query/session';
import { RPEFormatChoices } from './session';

export const SET_USER_TEAM = gql`
    ${getLimitedTeamFragment(['with_club'])}
    mutation SetUserTeam($teamId: ID!) {
        res: setUserTeam(teamId: $teamId) {
            id
            isSuperuser
            firstName
            lastName
            email
            isStaff
            isActive
            isLastTeamGk
            lastTeam {
                ...${getLimitedTeamFragmentName(['with_club'])}
            }
            limitedUserroleSet {
                roleType
                athlete {
                    name
                }
                team {
                    id
                    name
                    season
                }
                club {
                    name
                }
            }
            preferredLanguage
            template {
                id
                kpi {
                    name, unit, uom, value
                }
            }
            userunits {
                speed
                power
                energy
                percentage
                temperature
            }
            userroleSet{
                id
                athlete {
                    id
                    name
                }
                team {
                    id
                    name
                }
                roleType
                club {
                    id
                    name
                }
            }
        }
    }
`;

export type CreateTeamRes = {
  res: TeamType;
};
export type CreateTeamVars = {
  clubId: string;
  sport: string;
  season: string;
  league: string;
  startDate: Date;
  endDate: Date;
  rpeFormat?: 'A_1' | 'A_6';
  controllerIp?: string;
  oldTeam: string;
};
export const CREATE_TEAM = gql`
  mutation CreateTeam(
    $clubId: ID!
    $sport: SportsChoices
    $season: String!
    $league: String!
    $startDate: Date!
    $endDate: Date!
    $rpeFormat: RPEFormatChoices
    $controllerIp: String
    $oldTeam: ID!
  ) {
    res: createTeam(
      clubId: $clubId
      sport: $sport
      season: $season
      league: $league
      startDate: $startDate
      endDate: $endDate
      rpeFormat: $rpeFormat
      controllerIp: $controllerIp
      oldTeam: $oldTeam
    ) {
      sport
      season
      league
      startDate
      endDate
      rpeFormat
      controllerIp
    }
  }
`;

export type DeleteTeamRes = {
  res: TeamType;
};
export type DeleteTeamVars = {
  teamId: string;
};

export const DELETE_TEAM = gql`
  mutation deleteTeam($teamId: ID!) {
    deleteTeam(teamId: $teamId)
  }
`;

export type DeleteTeamSessionRes = {
  res: TeamType;
};
export type DeleteTeamSessionVars = {
  teamsessionId: string;
};

export const DELETE_TEAMSESSION = gql`
  mutation deleteTeamsession($teamsessionId: ID!) {
    deleteTeamsession(teamsessionId: $teamsessionId)
  }
`;
export type CustomErrorType = {
  errKey: string;
  errVal: {
    transKey: string;
    transFallback: string;
  }
};

export type EditTeamRes = {
  res: TeamType | CustomErrorType;
};
export type EditTeamVars = {
  teamId: string;
  season: string;
  league: string;
  startDate: string;
  endDate: string;
  rpeFormat?: RPEFormatChoices;
  controllerIp?: string;
};
export const EDIT_TEAM = gql`
  mutation UpdateTeam(
    $teamId: ID!
    $season: String!
    $league: String!
    $startDate: Date!
    $endDate: Date!
    $rpeFormat: RPEFormatChoices
    $controllerIp: String
  ) {
    res: updateTeam(
      teamId: $teamId
      season: $season
      league: $league
      startDate: $startDate
      endDate: $endDate
      rpeFormat: $rpeFormat
      controllerIp: $controllerIp
    ) {
      ... on TeamType {
        sport
        season
        league
        startDate
        endDate
        rpeFormat
        controllerIp
      }
      ... on CustomErrorType {
        errKey
        errVal {
          transKey
          transFallback
        }
      }
    }
  }
`;

export type CreatePlayerRes = {
  res: PlayerType;
};
export type CreatePlayerVars = {
  teamId: string;
  athleteId: string;
  playingRoleId: string;
  number?: string;
  startDate: Date;
  endDate: Date;
};
export const CREATE_PLAYER = gql`
  mutation CreatePlayer(
    $teamId: ID!
    $athleteId: ID!
    $playingRoleId: ID
    $number: String
    $startDate: Date
    $endDate: Date
  ) {
    res: createPlayer(
      teamId: $teamId
      athleteId: $athleteId
      playingRoleId: $playingRoleId
      number: $number
      startDate: $startDate
      endDate: $endDate
    ) {
      playingrole {
        id
      }
      number
      startDate
      endDate
    }
  }
`;
export type DeletePlayerRes = {
  res: boolean;
};
export type DeletePlayerVars = {
  playerId: string;
};

export const DELETE_PLAYER = gql`
  mutation deletePlayer($playerId: ID!) {
    deletePlayer(playerId: $playerId)
  }
`;

export type UpdatePlayerRes = {
  res: PlayerType;
};
export type UpdatePlayerVars = {
  playerId: string;
  playingRoleId: string;
  number?: string;
  startDate?: Date;
  endDate?: Date;
};
export const UPDATE_PLAYER = gql`
  mutation updatePlayer($playerId: ID!, $playingRoleId: ID, $number: String, $startDate: Date, $endDate: Date) {
    res: updatePlayer(
      playerId: $playerId
      playingRoleId: $playingRoleId
      number: $number
      startDate: $startDate
      endDate: $endDate
    ) {
      playingrole {
        id
      }
      number
      startDate
      endDate
    }
  }
`;

export type CreateRoleRes = {
  res: PlayingRoleType;
};
export type CreateRoleVars = {
  teamId: string;
  name: string;
  order: number;
};
export const CREATE_ROLE = gql`
  mutation createPlayingRole($teamId: ID!, $name: String!, $order: Int) {
    res: createPlayingRole(teamId: $teamId, name: $name, order: $order) {
      name
      order
    }
  }
`;

export type DeleteRoleRes = {
  res: PlayingRoleType;
};
export type DeleteRoleVars = {
  playingRoleId: string;
  teamId: string;
};

export const DELETE_ROLE = gql`
  mutation deletePlayingRole($playingRoleId: ID!, $teamId: ID!) {
    res: deletePlayingRole(playingRoleId: $playingRoleId, teamId: $teamId) 
  }
`;

export type UpdateRoleRes = {
  res: PlayingRoleType;
};
export type UpdateRoleVars = {
  playingRoleId: string;
  teamId: string;
  name: string;
  order: number;
};
export const UPDATE_ROLE = gql`
  mutation updatePlayingRole($playingRoleId: ID!, $teamId: ID, $name: String, $order: Int) {
    res: updatePlayingRole(playingRoleId: $playingRoleId, teamId: $teamId, name: $name, order: $order) {
      name
      order
    }
  }
`;

export type CreateCategoryRes = {
  res: TeamSessionCategoryType;
};
export type CreateCategoryVars = {
  teamId: string;
  name: string;
  color: string;
};
export const CREATE_CATEGORY = gql`
  mutation createSessionCategory($teamId: ID!, $name: String!, $color: String!) {
    res: createSessionCategory(teamId: $teamId, name: $name, color: $color) {
      name
      color
    }
  }
`;

export type CreateTagRes = {
  res: {
    name: string
  };
};
export type CreateTagVars = {
  teamId: string;
  name: string;
};
export const CREATE_TAG = gql`
  mutation CreateTeamsessionTag($teamId: ID!, $name: String!) {
    res: createTeamsessionTag(teamId: $teamId, name: $name) {
      name
    }
  }
`;

export type EditTagRes = {
  res: {
    name: string
  };
};
export type EditTagVars = {
  tagId: string;
  name: string;
};
export const EDIT_TAG = gql`
  mutation EditTeamsessionTag($tagId: ID!, $name: String!) {
    res: editTeamsessionTag(tagId: $tagId, name: $name) {
      name
    }
  }
`;

export type DeleteCategoryRes = {
  res: TeamSessionCategoryType;
};
export type DeleteCategoryVars = {
  sessionCategoryId: string;
};

export const DELETE_CATEGORY = gql`
  mutation deleteSessionCategory($sessionCategoryId: ID!) {
    deleteSessionCategory(sessionCategoryId: $sessionCategoryId)
  }
`;

export type UpdateCategoryRes = {
  res: TeamSessionCategoryType;
};
export type UpdateCategoryVars = {
  sessionCategoryId: string;
  teamId: string;
  name: string;
  color: string;
};
export const UPDATE_CATEGORY = gql`
  mutation updateSessionCategory($sessionCategoryId: ID!, $teamId: ID, $name: String, $color: String) {
    res: updateSessionCategory(sessionCategoryId: $sessionCategoryId, teamId: $teamId, name: $name, color: $color) {
      name
      color
    }
  }
`;

export type UpdateSpeedThresholdsRes = {
  res: TeamChangelogType;
};
export type UpdateSpeedThresholdsResVars = {
  id: string;
  areSpeedEventsZonesVisible: boolean;
  areSpeedTimeZonesVisible: boolean;
  areSpeedDistanceZonesVisible: boolean;
  speedThresholds: [GenericValueInputType];
  speedEventsThreshold: GenericValueInputType;
  speedEventsDuration: GenericValueInputType;
};
export const UPDATE_SPEEDTHRESHOLDS = gql`
  mutation updateSpeedThresholds(
    $teamId: ID!
    $areSpeedEventsZonesVisible: Boolean
    $areSpeedTimeZonesVisible: Boolean
    $areSpeedDistanceZonesVisible: Boolean
    $areSpeedZonesPlusVisible: Boolean
    $speedThresholds: [GenericValueInputType]
    $speedEventsThreshold: GenericValueInputType
    $speedEventsDuration: GenericValueInputType
    $startReprocess: Boolean
  ) {
    res: updateSpeedThresholds(
      teamId: $teamId
      areSpeedEventsZonesVisible: $areSpeedEventsZonesVisible
      areSpeedTimeZonesVisible: $areSpeedTimeZonesVisible
      areSpeedDistanceZonesVisible: $areSpeedDistanceZonesVisible
      areSpeedZonesPlusVisible: $areSpeedZonesPlusVisible
      speedThresholds: $speedThresholds
      speedEventsThreshold: $speedEventsThreshold
      speedEventsDuration: $speedEventsDuration
      startReprocess: $startReprocess
    ) {
      team {
        id
      }
      areSpeedEventsZonesVisible
      areSpeedTimeZonesVisible
      areSpeedDistanceZonesVisible
      speedThresholds {
        value
        uom
        unit
      }
      speedEventsThreshold {
        value
        uom
        unit
      }
      speedEventsDuration {
        value
        uom
        unit
      }
    }
  }
`;
export type UpdateRelativeSpeedThresholdsRes = {
  res: TeamChangelogType;
};
export type UpdateRelativeSpeedThresholdsVars = {
  id: string;
  areRelativeSpeedEventsZonesVisible: boolean;
  areRelativeSpeedTimeZonesVisible: boolean;
  areRelativeSpeedDistanceZonesVisible: boolean;
  relativeSpeedThresholds: [GenericValueInputType];
  relativeSpeedEventsThreshold: GenericValueInputType;
  relativeSpeedEventsDuration: GenericValueInputType;
};
export const UPDATE_RELATIVESPEEDTHRESHOLDS = gql`
  mutation updateRelativeSpeedThresholds(
    $teamId: ID!
    $areRelativeSpeedEventsZonesVisible: Boolean
    $areRelativeSpeedTimeZonesVisible: Boolean
    $areRelativeSpeedDistanceZonesVisible: Boolean
    $areRelativeSpeedZonesPlusVisible: Boolean
    $relativeSpeedThresholds: [GenericValueInputType]
    $relativeSpeedEventsThreshold: GenericValueInputType
    $relativeSpeedEventsDuration: GenericValueInputType
    $startReprocess: Boolean
  ) {
    res: updateRelativeSpeedThresholds(
      teamId: $teamId
      areRelativeSpeedEventsZonesVisible: $areRelativeSpeedEventsZonesVisible
      areRelativeSpeedTimeZonesVisible: $areRelativeSpeedTimeZonesVisible
      areRelativeSpeedDistanceZonesVisible: $areRelativeSpeedDistanceZonesVisible
      areRelativeSpeedZonesPlusVisible: $areRelativeSpeedZonesPlusVisible
      relativeSpeedThresholds: $relativeSpeedThresholds
      relativeSpeedEventsThreshold: $relativeSpeedEventsThreshold
      relativeSpeedEventsDuration: $relativeSpeedEventsDuration
      startReprocess: $startReprocess
    ) {
      team {
        id
      }
      areRelativeSpeedEventsZonesVisible
      areRelativeSpeedTimeZonesVisible
      areRelativeSpeedDistanceZonesVisible
      relativeSpeedThresholds {
        value
        uom
        unit
      }
      relativeSpeedEventsThreshold {
        value
        uom
        unit
      }
      relativeSpeedEventsDuration {
        value
        uom
        unit
      }
    }
  }
`;

export type UpdateAccelerationThresholdsRes = {
  res: TeamChangelogType;
};
export type UpdateAccelerationThresholdsVars = {
  id: string;
  areAccelerationEventsZonesVisible: boolean;
  areAccelerationTimeZonesVisible: boolean;
  areAccelerationDistanceZonesVisible: boolean;
  accelerationThresholds: [GenericValueInputType];
  accelerationEventsThreshold: GenericValueInputType;
  accelerationEventsDuration: GenericValueInputType;
};
export const UPDATE_ACCELERATIONTHRESHOLDS = gql`
  mutation updateAccelerationThresholds(
    $teamId: ID!
    $areAccelerationEventsZonesVisible: Boolean
    $areAccelerationTimeZonesVisible: Boolean
    $areAccelerationDistanceZonesVisible: Boolean
    $areAccelerationZonesPlusVisible: Boolean
    $accelerationThresholds: [GenericValueInputType]
    $accelerationEventsThreshold: GenericValueInputType
    $accelerationEventsDuration: GenericValueInputType
    $startReprocess: Boolean
  ) {
    res: updateAccelerationThresholds(
      teamId: $teamId
      areAccelerationEventsZonesVisible: $areAccelerationEventsZonesVisible
      areAccelerationTimeZonesVisible: $areAccelerationTimeZonesVisible
      areAccelerationDistanceZonesVisible: $areAccelerationDistanceZonesVisible
      areAccelerationZonesPlusVisible: $areAccelerationZonesPlusVisible
      accelerationThresholds: $accelerationThresholds
      accelerationEventsThreshold: $accelerationEventsThreshold
      accelerationEventsDuration: $accelerationEventsDuration
      startReprocess: $startReprocess
    ) {
      team {
        id
      }
      areAccelerationEventsZonesVisible
      areAccelerationTimeZonesVisible
      areAccelerationDistanceZonesVisible
      accelerationThresholds {
        value
        uom
        unit
      }
      accelerationEventsThreshold {
        value
        uom
        unit
      }
      accelerationEventsDuration {
        value
        uom
        unit
      }
    }
  }
`;

export type UpdateDecelerationThresholdsRes = {
  res: TeamChangelogType;
};
export type UpdateDecelerationThresholdsVars = {
  id: string;
  areDecelerationEventsZonesVisible: boolean;
  areDecelerationTimeZonesVisible: boolean;
  areDecelerationDistanceZonesVisible: boolean;
  decelerationThresholds: [GenericValueInputType];
  decelerationEventsThreshold: GenericValueInputType;
  decelerationEventsDuration: GenericValueInputType;
};
export const UPDATE_DECELERATIONTHRESHOLDS = gql`
  mutation updateDecelerationThresholds(
    $teamId: ID!
    $areDecelerationEventsZonesVisible: Boolean
    $areDecelerationTimeZonesVisible: Boolean
    $areDecelerationDistanceZonesVisible: Boolean
    $areDecelerationZonesPlusVisible: Boolean
    $decelerationThresholds: [GenericValueInputType]
    $decelerationEventsThreshold: GenericValueInputType
    $decelerationEventsDuration: GenericValueInputType
    $startReprocess: Boolean
  ) {
    res: updateDecelerationThresholds(
      teamId: $teamId
      areDecelerationEventsZonesVisible: $areDecelerationEventsZonesVisible
      areDecelerationTimeZonesVisible: $areDecelerationTimeZonesVisible
      areDecelerationDistanceZonesVisible: $areDecelerationDistanceZonesVisible
      areDecelerationZonesPlusVisible: $areDecelerationZonesPlusVisible
      decelerationThresholds: $decelerationThresholds
      decelerationEventsThreshold: $decelerationEventsThreshold
      decelerationEventsDuration: $decelerationEventsDuration
      startReprocess: $startReprocess
    ) {
      team {
        id
      }
      areDecelerationEventsZonesVisible
      areDecelerationTimeZonesVisible
      areDecelerationDistanceZonesVisible
      decelerationThresholds {
        value
        uom
        unit
      }
      decelerationEventsThreshold {
        value
        uom
        unit
      }
      decelerationEventsDuration {
        value
        uom
        unit
      }
    }
  }
`;

export type UpdateHeartRateThresholdsRes = {
  res: TeamChangelogType;
};
export type UpdateHeartRateThresholdsVars = {
  id: string;
  areHeartRateTimeZonesVisible: boolean;
  areHeartRateDistanceZonesVisible: boolean;
  heartRateThresholdsInPercent: [GenericValueInputType];
};
export const UPDATE_HEARTRATETHRESHOLDS = gql`
  mutation updateHeartRateThresholds(
    $teamId: ID!
    $useHrMin: Boolean
    $areHeartRateTimeZonesVisible: Boolean
    $areHeartRateDistanceZonesVisible: Boolean
    $areHeartRateZonesPlusVisible: Boolean
    $heartRateThresholdsInPercent: [GenericValueInputType]
    $startReprocess: Boolean
  ) {
    res: updateHeartRateThresholds(
      teamId: $teamId
      useHrMin: $useHrMin
      areHeartRateTimeZonesVisible: $areHeartRateTimeZonesVisible
      areHeartRateDistanceZonesVisible: $areHeartRateDistanceZonesVisible
      areHeartRateZonesPlusVisible: $areHeartRateZonesPlusVisible
      heartRateThresholdsInPercent: $heartRateThresholdsInPercent
      startReprocess: $startReprocess
    ) {
      team {
        id
      }
      useHrMin
      areHeartRateTimeZonesVisible
      areHeartRateDistanceZonesVisible
      heartRateThresholdsInPercent {
        value
        uom
        unit
      }
    }
  }
`;

export type UpdatePowerZonesThresholdsRes = {
  res: TeamChangelogType;
};
export type UpdatePowerZonesThresholdsVars = {
  id: string;
  arePowerTimeZonesVisible: boolean;
  arePowerDistanceZonesVisible: boolean;
  powerThresholds: [GenericValueInputType];
};
export const UPDATE_POWERTHRESHOLDS = gql`
  mutation updatePowerZonesThresholds(
    $teamId: ID!
    $arePowerTimeZonesVisible: Boolean
    $arePowerDistanceZonesVisible: Boolean
    $arePowerZonesPlusVisible: Boolean
    $powerThresholds: [GenericValueInputType]
    $startReprocess: Boolean
  ) {
    res: updatePowerZonesThresholds(
      teamId: $teamId
      arePowerTimeZonesVisible: $arePowerTimeZonesVisible
      arePowerDistanceZonesVisible: $arePowerDistanceZonesVisible
      arePowerZonesPlusVisible: $arePowerZonesPlusVisible
      powerThresholds: $powerThresholds
      startReprocess: $startReprocess
    ) {
      team {
        id
      }
      arePowerTimeZonesVisible
      arePowerDistanceZonesVisible
      powerThresholds {
        value
        uom
        unit
      }
    }
  }
`;

export type UpdateMpeThresholdsRes = {
  res: TeamChangelogType;
};
export type UpdateMpeThresholdsVars = {
  id: string;
  arePowerEventsZonesVisible: boolean;
  powerEventsSpeedThresholds: [GenericValueInputType];
  powerEventsDistanceThresholds: [GenericValueInputType];
  powerEventsTimeThresholds: [GenericValueInputType];
};
export const UPDATE_MPETHRESHOLDS = gql`
  mutation updateMpeThresholds(
    $teamId: ID!
    $arePowerEventsZonesVisible: Boolean
    $powerEventsSpeedThresholds: [GenericValueInputType]
    $powerEventsDistanceThresholds: [GenericValueInputType]
    $powerEventsTimeThresholds: [GenericValueInputType]
  ) {
    res: updateMpeThresholds(
      teamId: $teamId
      arePowerEventsZonesVisible: $arePowerEventsZonesVisible
      powerEventsSpeedThresholds: $powerEventsSpeedThresholds
      powerEventsDistanceThresholds: $powerEventsDistanceThresholds
      powerEventsTimeThresholds: $powerEventsTimeThresholds
    ) {
      team {
        id
      }
      arePowerEventsZonesVisible
      powerEventsSpeedThresholds {
        value
        uom
        unit
      }
      powerEventsDistanceThresholds {
        value
        uom
        unit
      }
      powerEventsTimeThresholds {
        value
        uom
        unit
      }
    }
  }
`;

export type UpdateImuThresholdsRes = {
  res: TeamChangelogType;
};
export type UpdateImuThresholdsVars = {
  id: string;
  jumpHeightThresholds: [GenericValueInputType];
  diveLaunchLoadThresholds: [GenericValueInputType];
  diveLoadThresholds: [GenericValueInputType];
  diveReturnTimeThresholds: [GenericValueInputType];
  impactIntensityThresholds: [GenericValueInputType];
};
export const UPDATE_IMUTHRESHOLDS = gql`
  mutation updateImuThresholds(
    $teamId: ID!
    $jumpHeightThresholds: [GenericValueInputType]
    $diveLaunchLoadThresholds: [GenericValueInputType]
    $diveLoadThresholds: [GenericValueInputType]
    $diveReturnTimeThresholds: [GenericValueInputType]
    $impactIntensityThresholds: [GenericValueInputType]
    $startReprocess: Boolean
  ) {
    res: updateImuThresholds(
      teamId: $teamId
      jumpHeightThresholds: $jumpHeightThresholds
      diveLaunchLoadThresholds: $diveLaunchLoadThresholds
      diveLoadThresholds: $diveLoadThresholds
      diveReturnTimeThresholds: $diveReturnTimeThresholds
      impactIntensityThresholds: $impactIntensityThresholds
      startReprocess: $startReprocess
    ) {
      team {
        id
      }
      jumpHeightThresholds {
        value
        uom
        unit
      }
      diveLaunchLoadThresholds {
        value
        uom
        unit
      }
      diveLoadThresholds {
        value
        uom
        unit
      }
      diveReturnTimeThresholds {
        value
        uom
        unit
      }
      impactIntensityThresholds {
        value
        uom
        unit
      }
    }
  }
`;

export type DeleteTagRes = {
  res: TeamSessionTagsType;
};
export type DeleteTagVars = {
  tagId: string;
};

export const DELETE_TAG = gql`
  mutation deleteTeamsessionTag($tagId: ID!) {
    res: deleteTeamsessionTag(tagId: $tagId) 
  }
`;

export type UpdateTagRes = {
  res: TeamSessionTagsType;
};
export type UpdateTagVars = {
  tagId: string;
  name: string;
};
export const UPDATE_TAG = gql`
  mutation editTeamsessionTag($tagId: ID!, $name: String) {
    res: editTeamsessionTag(tagId: $tagId, name: $name) {
      name
    }
  }
`;
