import { Box } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ShirtSVG from '@images/icons/ShirtSVG';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';
import { Row } from './layout/Row';
import { Column } from './layout/Column';
import getImageFile from '../storage/imgDbCache';
import { getLocalPicturePath } from './utils/athlete';

type PropsCard= {
  id?: string;
  shortName?: string;
  thumbnail?: string;
  number?: string;
}

const AthleteCardBasic = (props: PropsCard) => {
  const {
    id: athleteId, thumbnail: img, shortName, number,
  } = props;

  const [imageSrcData, setImageSrcData] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const loadImg = async (imgUrl) => {
      if (imgUrl && imgUrl.indexOf('?') > 0) {
        const imgData = await getImageFile(getLocalPicturePath(imgUrl));
        if (imgData) {
          setImageSrcData(imgData);
        }
      }
    };

    loadImg(img).catch((e) => console.error(e));
  });

  return (
    <Box className="BoxCardBasic" position="relative">
      <CardHeader
        style={{ width: '100%' }}
      />

      <CardActions>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Row>
            <Column xs={12}>
              {!img ? (
                <span>
                  <ShirtSVG number={number} name={shortName} />
                </span>
              ) : (
                <span>
                  <CardMedia image={imageSrcData} component="img" />
                </span>
              )}
            </Column>
          </Row>
          <Row>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              m={2}
              mx={2.5}
              justifyContent="space-between"
              onClick={() => (athleteId ? navigate(`/athletes/${athleteId}`) : null)}
              className={athleteId ? 'BoxCardBasic-shortnameBox' : 'BoxCardBasic-shortnameBox-noLink'}
            >
              <Typography variant="subtitle1">{shortName}</Typography>
              <button type="button">
                {athleteId && <LinkIcon />}
              </button>
            </Box>
          </Row>
        </Box>
      </CardActions>
    </Box>
  );
};

export default React.memo(AthleteCardBasic);
