import { AthleteSessionType } from './athlete_session';
import { TeamType, getTeamFragment, getTeamFragmentName } from './team';
import { GenericValueType, IntervalType, TeamSessionType, WeatherType } from '../query/session';
import { KPIType } from '../query/track';
import { AthleteType } from './athlete';
import { TrackRpeFormatChoices } from '__generated__/graphql';

export type AthleteSessionNatureFilter = 'SESSION' | 'DRILL';
export type SessionType = 'STD' | 'RPE_ONLY' | 'DRILL' | 'RPE';
export type TeamSessionCategoryType = {
  id: string;
  createdOn?: Date;
  updatedOn?: Date;
  color: string; // TrackTeamSessionCategoryColorChoices!
  name: string;
  isDeletable: boolean;
  occurrences: number;
  team: TeamType;
  teamsessionSet: TeamSessionsType[];
};

export type DrillType = {
  end: string;
  id: number;
  totalTime: GenericValueType;
  index: number | null;
  start: string;
  intervals: IntervalType[]
  notes: string;
  tags: string[]
};

export type RoleAverages = {
  playingRole: string;
  nAthletes: number;
  kpi: KPIType[];
};

export type TeamAverages = {
  kpi: KPIType[];
};

export type TopBottomFirstType = {
  name: string;
  top: Boolean | null;
  bottom: Boolean | null;
  firts: Boolean | null;
};
export type TeamSessionRelevantValuesType = {
  athletesessionId: string | number;
  kpi: TopBottomFirstType[];
};

export type TeamSessionComplementaryType = {
  averages: string[][];
  roles: RoleAverages[];
  relevantValues: TeamSessionRelevantValuesType[];
  team: TeamAverages;
  topBottomFirst: TopBottomFirstType[];
};

export type MatchDistanceType = {
  date: null | string;
  nextDays: number;
  nextHome: boolean;
  nextOpponent: string;
  prevDays: number;
  prevHome: boolean;
  prevOpponent: string;
};

export interface RpeType {
  athlete: AthleteType
  duration: number
  format: TrackRpeFormatChoices
  id: string
  starter: Boolean
  teamsession: TeamSessionType
  value: number
}

export type TeamSessionsType = {
  athleteSessionsCount: number;
  athleteCount: number;
  athleteSessions: AthleteSessionType[];
  needAthletesessionReprocess?: boolean;
  category: TeamSessionCategoryType;
  complementaryData: TeamSessionComplementaryType;
  createdOn?: Date;
  drill?: number;
  drills: {
    wholeSession: DrillType;
    relatedDrills: DrillType[];
  };
  drillCount?: number;
  drillEnabled: boolean;
  drillSet: TeamSessionsType[];
  endTimestamp: Date;
  getCardioFirstbeat: boolean;
  hasRpe: boolean;
  id: string;
  isStatsValid: boolean;
  matchCycle: number;
  matchDistance: MatchDistanceType;
  nature?: SessionType;
  notes: string;
  originalTeamsession: TeamSessionsType;
  rpeSet: RpeType[]
  startTimestamp: Date; // hh:mm:ss format
  state?: 'L' | 'P' | 'R' | 'LO';
  tags: any[];
  team: TeamType;
  totalTime: GenericValueType;
  updatedOn?: Date;
  weather: WeatherType;
};
export type TeamSessionTagType = {
  id: string;
  createdOn?: Date;
  updatedOn?: Date;
  name?: string;
  team?: TeamType;
  occurrences?: number;
};
export type TeamSessionCategoryResponseType = 'with_team' | 'with_team_session';
export const getTeamSessionCategoryFragmentName = (types: TeamSessionCategoryResponseType[]) => `TeamSessionCategoryTypeFields${types
  .sort()
  .map((t) => `_${t}`)
  .join('')}`;
export const getTeamSessionCategoryFragment = (types: TeamSessionCategoryResponseType[]) => `
      ${types.includes('with_team') ? getTeamFragment([]) : ''}
      ${types.includes('with_team_session') ? getTeamSessionFragment([]) : ''}
      fragment ${getTeamSessionCategoryFragmentName(types)} on TeamSessionCategoryType {
        id
        createdOn
        updatedOn
        color
        occurrences
        isDeletable
        name${
  types.includes('with_team')
    ? `
        team {
          ...${getTeamFragmentName([])}
        }`
    : ''
}${
  types.includes('with_team_session')
    ? `
        teamsessionSet {
          ...${getTeamSessionFragmentName([])}
        }`
    : ''
}
      }
    `;

export const getWeatherFragmentName = (types: 'with_weather') => `WeatherTypeFields_${types}`;

export const getWeatherFragment = (types: 'with_weather') => `
      fragment ${getWeatherFragmentName(types)} on WeatherType {
         ${types === 'with_weather'
    ? `
              temperature {
                  unit
                  value
                  uom
              }
              visibility {
                  unit
                  value
                  uom
              }
              latitude {
                  unit
                  value
                  uom
              }
              longitude {
                  unit
                  value
                  uom
              }
              summary
              icon
          `
    : ''
}
      }
    `;

export type TeamSessionResponseType =
  | 'with_team'
  | 'with_team_session_category'
  | 'with_team_session'
  | 'with_team_session_weather';
export const getTeamSessionFragmentName = (types: TeamSessionResponseType[]) => `TeamSessionTypesFields${types
  .sort()
  .map((t) => `_${t}`)
  .join('')}`;
export const getTeamSessionFragment = (types: TeamSessionResponseType[]) => `
      ${types.includes('with_team') ? getTeamFragment([]) : ''}
      ${types.includes('with_team_session_category') ? getTeamSessionCategoryFragment([]) : ''}
      ${types.includes('with_team_session') ? getTeamSessionFragment([]) : ''}
      ${types.includes('with_team_session_weather') ? getTeamSessionWeatherFragment([]) : ''}

      fragment ${getTeamSessionFragmentName(types)} on TeamSessionType {
        id
        createdOn
        updatedOn
  ${
  types.includes('with_team')
    ? `
        team {
          ...${getTeamFragmentName([])}
        }`
    : ''
}
${
  types.includes('with_team_session_category')
    ? `
        category {
          ...${getTeamSessionCategoryFragmentName([])}
        }`
    : ''
}

        notes
        drill
        startTimestamp
        endTimestamp
        totalTime {
          unit
          value
          uom
        }
        isStatsValid
        ${
  types.includes('with_team_session_weather')
    ? `
                weather {
                  ${getTeamSessionWeatherFragmentName(['with_team_session_weather'])}
                }`
    : ''
}
        matchCycle
        matchDistance {
          prevOpponent
          nextOpponent
          prevDays
          nextDays
        }
        drillEnabled${
  types.includes('with_team_session')
    ? `
        originalTeamsession {
          ...${getTeamSessionFragmentName([])}
        }
        drillSet {
          ...${getTeamSessionFragmentName([])}
        }`
    : ''
}
        drill
        tags {
          id
          name
        }
        drillCount
        nature
      }
    `;
