import { useSnackbar } from 'notistack';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import BaseButton from '../form/BaseButton';
import DialogBox from '../DialogBox';

function TestDialogBox() {
  const { enqueueSnackbar } = useSnackbar();
  const [openedID, setOpenedID] = React.useState(0);
  const handleClose = () => {
    enqueueSnackbar('Closed');
    setOpenedID(0);
  };

  const handleConfirm = () => {
    enqueueSnackbar('Confirmed', { variant: 'success' });
    setOpenedID(0);
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>DialogButtonModal</Typography>
      <Paper>
        <Box p={2} display="flex" alignItems="center" justifyContent="center">
          <Row>
            <Column md={2}><BaseButton onClick={() => setOpenedID(1)}>Default</BaseButton></Column>
            <Column md={2}><BaseButton onClick={() => setOpenedID(2)}>Secondary</BaseButton></Column>
          </Row>
        </Box>
      </Paper>

      <DialogBox title="Default" onClose={handleClose} onClick={handleConfirm} visible={openedID === 1} />
      <DialogBox
        title="Secondary"
        onClose={handleClose}
        onClick={handleConfirm}
        visible={openedID === 2}
        buttonColor="danger"
      />

      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestDialogBox;
