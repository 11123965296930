import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const MACHINES_RUNNING_COUNT = gql`
  query HowManyMachinesAreRunning(
    $queue: String!,
  ) {
    res: howManyMachinesAreRunning(queue: $queue)
}
`;
