import React, { useState, useRef } from 'react';
import { MenuItem, Popover } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import BaseButton from '../form/BaseButton';

export type PopoverContentType = {
  className?: string,
  label: React.ReactElement | string,
  onClick?: () => void,
  onMouseEnter?: () => void,
  prefix?: React.ReactElement | string,
  value: string,
  isActive?: boolean,
  disabled?: boolean,
}

type PropsIn = {
  buttonId:string,
  badge?: number,
  collapseOnMobile?: boolean,
  collapseOnSmallDesktop?: boolean,
  disabled?: boolean,
  icon?: React.ReactElement,
  active?: boolean,
  label?: string,
  popoverContent?: PopoverContentType[],
  position?: 'left' | 'center' | 'right',
  tooltip?: string,
  hoverOnly?: boolean
  className: string,
  popoverTitle?: string,
  disabledExportMenuItems?: object,
  buttonSize?: 'sm' | 'md' | 'lg',
  buttonColor?: string,
  onClick?: () => void,
}

export default function HeaderPopover(props: PropsIn) {
  const {
    buttonId, badge, collapseOnMobile, collapseOnSmallDesktop, disabled, icon, active,
    label, popoverContent, position, tooltip, hoverOnly, className, popoverTitle,
    disabledExportMenuItems, buttonSize, onClick, buttonColor,
  } = props;

  const [anchor, setAnchor] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const open = Boolean(anchor);
  const id = open ? 'popover' : undefined;

  const handleClose = () => {
    setAnchor(null);
  };

  const handlePopoverMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // check if mouse is leaving both popover and its content
    if (!popoverRef.current?.contains(event.relatedTarget as Node)) {
      handleClose();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    setAnchor(event.currentTarget);
    if (hoverOnly) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
    if (onClick) {
      onClick();
    }
  };

  const positionProps = {
    anchorOrigin: {
      vertical: 40,
      horizontal: position || 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: position || 'left',
    },
  };

  return (
    <div
      className={className || ''}
      onMouseEnter={hoverOnly ? handleClick : undefined}
      onMouseLeave={hoverOnly ? handleClose : undefined}
    >
      <BaseButton
        id={buttonId || ''}
        tooltipText={tooltip || ''}
        onClick={!hoverOnly ? handleClick : undefined}
        collapseOnMobile={collapseOnMobile}
        collapseOnSmallDesktop={collapseOnSmallDesktop}
        className="force-large"
        startIcon={label && icon}
        disabled={disabled}
        isIconButton={!label}
        active={active}
        badge={badge}
        buttonSize={buttonSize}
        color={buttonColor}
      >
        {label || icon}
      </BaseButton>
      {popoverContent && (
        /* A `Menu` that is displayed on top of the page. */
        <Popover
          id={id}
          className={className}
          open={open}
          anchorEl={anchor}
          onClose={handleClose}
          {...positionProps}
        >
          <div ref={popoverRef} onMouseLeave={hoverOnly ? handlePopoverMouseLeave : undefined}>
            {popoverTitle && <h3 className="title">{popoverTitle}</h3>}
            {popoverContent.map((element, i) => {
              const isMenuItemDisabled = disabledExportMenuItems?.hasOwnProperty(element.label);
              return (
                <MenuItem
                  key={`${element.value}-${i}`}
                  value={element.value}
                  className={`${element.className} ${isMenuItemDisabled || element.disabled ? 'disabled-popover-menu-items' : ''}`}
                  onClick={() => {
                    if (!isMenuItemDisabled && !element.disabled) {
                      handleClose();
                      element.onClick();
                    }
                  }}
                  onMouseEnter={() => {
                    if (!isMenuItemDisabled && !element.disabled && element.onMouseEnter) {
                      element.onMouseEnter();
                    }
                  }}
                >
                  <Tooltip arrow placement="right" title={isMenuItemDisabled ? disabledExportMenuItems[element.label] : ''}>
                    <div>
                      {element.prefix}
                      {element.label}
                      {element.isActive && <span className="menu-item-checkmark">✓</span>}
                    </div>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </div>
        </Popover>
      )}
    </div>
  );
}
