import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import AddIcon from '@images/IconHeader/add.svg';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import HeaderPopover from '../layout/HeaderPopover';

function TestHeaderPopover() {
  return (
    <>
      <Typography variant="h5" gutterBottom>HeaderPopover</Typography>
      <Paper id="test-editable-table">
        <Box p={2}>
          <Row>
            <Column md={2}>
              <HeaderPopover
                key="test-1"
                label="Default"
                popoverContent={[
                  {
                    value: 'session',
                    label: 'First option',
                    onClick: console.log,
                  },
                  {
                    value: 'rpe_session',
                    label: 'Second option',
                    onClick: console.log,
                  },
                  { value: 'match', label: 'Third option', onClick: console.log },
                ]}
              />
            </Column>
            <Column md={2}>
              <HeaderPopover
                key="test-1"
                label="Icon"
                icon={<AddIcon />}
                popoverContent={[
                  {
                    value: 'session',
                    label: 'First option',
                    onClick: console.log,
                  },
                  {
                    value: 'rpe_session',
                    label: 'Second option',
                    onClick: console.log,
                  },
                  { value: 'match', label: 'Third option', onClick: console.log },
                ]}
              />
            </Column>
            <Column md={2}>
              <HeaderPopover
                key="test-1"
                icon={<AddIcon />}
                label="Left"
                position="left"
                popoverContent={[
                  {
                    value: 'session',
                    label: 'First option',
                    onClick: console.log,
                  },
                  {
                    value: 'rpe_session',
                    label: 'Second option',
                    onClick: console.log,
                  },
                  { value: 'match', label: 'Third option', onClick: console.log },
                ]}
              />
            </Column>
            <Column md={2}>
              <HeaderPopover
                key="test-1"
                icon={<AddIcon />}
                label="Center"
                position="center"
                popoverContent={[
                  {
                    value: 'session',
                    label: 'First option',
                    onClick: console.log,
                  },
                  {
                    value: 'rpe_session',
                    label: 'Second option',
                    onClick: console.log,
                  },
                  { value: 'match', label: 'Third option', onClick: console.log },
                ]}
              />
            </Column>
            <Column md={2}>
              <HeaderPopover
                key="test-1"
                icon={<AddIcon />}
                label="Right"
                position="right"
                popoverContent={[
                  {
                    value: 'session',
                    label: 'First option',
                    onClick: console.log,
                  },
                  {
                    value: 'rpe_session',
                    label: 'Second option',
                    onClick: console.log,
                  },
                  { value: 'match', label: 'Third option', onClick: console.log },
                ]}
              />
            </Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestHeaderPopover;
