import React, {
  ReactEventHandler, useState, useEffect, useRef,
} from 'react';
import {
  Box, BoxProps, CircularProgress,
} from '@material-ui/core';
import QM from '@images/icons/svg/_.svg';
import { WithTooltipWrapper } from 'components/form/BaseButton';

// all valid icon names (filenames without extension)
export const icons = [
  'acc', 'accEvent', 'accZones', 'activeMuscleLoad', 'avgActiveMusclePower', 'activeMusclePower', 'activeTeam', 'add',
  'aerobic', 'alert', 'anaerobic', 'asp', 'athlete', 'athletes', 'athleteSession', 'athParameters', 'avgExtPower',
  'avEP+', 'avgHR', 'avgMP', 'avgSpeed', 'avgVo2', 'basicKPITab', 'brakes', 'bridge', 'bursts', 'changelog', 'categories',
  'changelog', 'chartsTab', 'clearDay', 'clearNight', 'cloudy', 'confirm', 'copy', 'currentTeam', 'decEvent', 'decZones', 'details', 'distance',
  'distByBodyOrientation', 'dives', 'duration', 'EDI', 'edit', 'EIPower',
  'energy', 'equivalentDistance', 'ERROR', 'expiredTeam', 'extWork+', 'false', 'fog', 'hail',
  'highExternalWork-', 'highExternalWork', 'highExternalWork+', 'HR', 'HRKPITab', 'HRMax',
  'HRMin', 'HRZones', 'info', 'impacts', 'IMU', 'IMUTab', 'IMUZones', 'ipad', 'jumps', 'KPI', 'KPICustomizationTab', 'leftDives',
  'leftArrow', 'LOADED', 'LOADING', 'match', 'maxAcc', 'maxDec', 'maxSpeed', 'mechKPITab', 'mediaAcademy', 'mediaPdf', 'mediaVideo', 'metKPI', 'more', 'MP', 'MPEAvgPw',
  'MPEAvgTime', 'MPEMaxSpeed', 'MPERecAvgPw', 'MPERecAvgTime', 'MPEvent', 'MPKpiTab', 'MPMax', 'mpp',
  'MPZones', 'new', 'partlyCloudyDay', 'partlyCloudyNight', 'pdf', 'PROCESSED', 'PROCESSING', 'rain', 'recovery',
  'remove', 'rocket', 'RPE', 'run', 'session', 'settings', 'sleet', 'snow', 'speed', 'session', 'session2', 'speedEvent', 'speedZones', 'stats', 'submit', 'tablesTab',
  'team', 'teams', 'teamAverage', 'teamSession', 'time', 'timeCourse', 'total_tracks', 'total_tracks2', 'trend', 'tracking', 'true', 'units', 'unknownDevice', 'upload',
  'userRole', 'vo2', 'vo2Max', 'walk', 'webApp', 'webAppNew', 'webAppOld', 'wind'] as const;

export type iconType = typeof icons[number]

const sizes = {
  extralarge: '50',
  large: '38',
  medium: '30',
  small: '24',
  smaller: '18',
};

interface UseDynamicSVGImportOptions {
  onCompleted?: (name: iconType, SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> | undefined) => void;
  onError?: ReactEventHandler<SVGSVGElement> | undefined;
}

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: iconType;
  color?: 'primary' | 'secondary' | string;
  colorCode?: string;
  size: 'large' | 'medium' | 'small' | 'smaller';
  onCompleted?: UseDynamicSVGImportOptions['onCompleted'];
  onError?: UseDynamicSVGImportOptions['onError'];
  disableHover?: boolean;
  pointer?: boolean;
  tooltipText?: string;
  center?: boolean
  noMargins?: boolean
}

/**
 * A bit hacky, we create a hook to use webpackMode for lazy load svg "modules"
 * All svg files will become separated modules and compiled.
 *
 * @param name
 * @param options
 */
function useDynamicSVGImport(name: iconType, options: UseDynamicSVGImportOptions = {}) {
  const isMounted = useRef(false);
  const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const { onCompleted, onError } = options;

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        // path is relative to this file
        const mod = await import(/* webpackMode: "lazy-once" */ `!@svgr/webpack!../img/icons/svg/${name}.svg`);
        ImportedIconRef.current = mod.default;
        onCompleted?.(name, ImportedIconRef.current);
      } catch (err: any) {
        if (isMounted.current) {
          onError?.(err);
          setError(err);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };
    importIcon();
  }, [name, onCompleted, onError]);

  return { error, loading, SvgIcon: ImportedIconRef.current };
}

export const IconWrapper: React.FC<IconProps> = ({
  name,
  size,
  color,
  colorCode,
  onCompleted,
  onError,
  disableHover,
  pointer,
  noMargins,
  tooltipText,
  center,
  ...rest
}): React.ReactElement<any, any> | null => {
  // sanitization path traversal protection (minimal)
  if (!name) {
    return null;
  }

  const processedKey = name.replaceAll('\\', '').replaceAll('.', '').replaceAll('/', '') as iconType;
  const { error, SvgIcon } = useDynamicSVGImport(processedKey, {
    onCompleted,
    onError,
  });

  function getColor() {
    if (disableHover) {
      if (colorCode === 'primary' || color === 'primary') {
        return 'var(--secondary-color)';
      }
      if (!color) {
        return 'var(--primary-color)';
      }
      return color;
    }
    return 'inherit';
  }

  if (error) {
    console.error(error);
    return (
      <QM
        style={{
          width: `${sizes[size]}px`,
          height: `${sizes[size]}px`,
          fill: color === 'primary' ? 'var(--secondary-color)' : 'var(--primary-color)',
          stroke: color === 'primary' ? 'var(--secondary-color)' : 'var(--primary-color)',
          color: getColor(),
          strokeWidth: '0',
          cursor: pointer ? 'pointer' : 'default',
        }}
        {...rest}
      />
    );
  }

  if (SvgIcon) {
    return (
      <WithTooltipWrapper text={tooltipText} noMargins={noMargins}>
        <SvgIcon
          style={{
            width: `${sizes[size]}px`,
            height: `${sizes[size]}px`,
            fill: color === 'primary' ? 'var(--secondary-color)' : 'var(--primary-color)',
            stroke: color === 'primary' ? 'var(--secondary-color)' : 'var(--primary-color)',
            color: getColor(),
            strokeWidth: '0',
            cursor: pointer ? 'pointer' : 'default',
            margin: center ? '0 auto' : undefined,
          }}
          {...rest}
        />
      </WithTooltipWrapper>
    );
  }
  return null;
};

export function Loader(props: { className?:string; noPadding?: boolean; small?: boolean; fixed?: boolean; style?: BoxProps['style'] }) {
  const {
    fixed, noPadding, small, style, className,
  } = props;
  return fixed ? (
    <Box
      className={className || 'loader-icon'}
      sx={{
        ...{
          width: '48px',
          height: '48px',
          padding: noPadding ? `0${small ? '' : ' 0 1.4rem'}` : '3rem',
          textAlign: 'center',
          position: 'fixed',
          top: '17px',
          right: '50%',
          zIndex: 100,
        },
        ...(style || {}),
      }}
    >
      <CircularProgress size={small ? 20 : undefined} color="primary" />
    </Box>
  ) : (
    <Box
      className={className || 'loader-icon'}
      sx={{
        ...{ width: '100%', padding: noPadding ? `0${small ? '' : ' 0 1.4rem'}` : '3rem', textAlign: 'center' },
        ...(style || {}),
      }}
    >
      <CircularProgress size={small ? 20 : undefined} color="primary" />
    </Box>
  );
}
