import React, { HTMLInputTypeAttribute } from 'react';
import InputTextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';

interface PropsIn {
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  error?: boolean;
  fullWidth?: boolean;
  height?: number;
  id?: string;
  inputProps?: any;
  InputProps?: any;
  label?: string;
  maxRow?: number;
  minRow?: number;
  multiline?: boolean;
  onChange?: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  onKeyDown?: React.MouseEventHandler<HTMLDivElement>;
  onFocus?: React.MouseEventHandler<HTMLDivElement>;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  startAdornment?: React.ReactNode;
  type?: HTMLInputTypeAttribute;
  value?: string;
  helperText?: string;
  onBlur?: () => void;
  className?: string;
  ref?: React.Ref<any>
  autoFocus?: boolean;
}

export function TextField(props: PropsIn) {
  const {
    label,
    value,
    onChange,
    onClick,
    onMouseLeave,
    onKeyDown,
    onFocus, 
    type,
    readonly,
    disabled,
    height,
    minRow,
    maxRow,
    multiline,
    placeholder,
    required,
    error,
    endAdornment,
    startAdornment,
    InputProps,
    inputProps,
    id,
    fullWidth,
    helperText,
    onBlur,
    className,
    ref,
    autoFocus,
    style,
  } = props;
  return (
    <InputTextField
      ref={ref}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      aria-readonly={readonly}
      className={`${readonly ? 'readonly' : ''} ${onClick ? 'clickable' : ''} ${className || ''}`}
      disabled={disabled}
      fullWidth={fullWidth ?? true}
      id={id}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={InputProps || {
        endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        readOnly: readonly,
        style: {
          height: height || undefined,
        },
      }}
      inputProps={inputProps}
      label={label}
      margin="dense"
      maxRows={maxRow}
      minRows={minRow}
      multiline={multiline}
      onChange={onChange}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      required={required}
      style={{...style, paddingBottom: '1px', marginBottom: 0 }}
      type={type}
      value={value}
      variant="outlined"
      autoFocus={autoFocus}
    />
  );
}
