/* eslint-disable no-shadow */
/* eslint no-use-before-define: 0 */
// IndexedDB

// @ts-ignore
const indexedDB = window.indexedDB
  // @ts-ignore
  || window.webkitIndexedDB
  // @ts-ignore
  || window.mozIndexedDB
  // @ts-ignore
  || window.OIndexedDB
  // @ts-ignore
  || window.msIndexedDB;

const dbVersion = 1.0;

const dataRequest = indexedDB.open('dataStorage', dbVersion);
let db;
const createImgObjectStore = (dataBase) => {
  dataBase.createObjectStore('exelioDataCache');
};

export const getDataFromDB = (key) => new Promise<{[k:string]: boolean}>((resolve, reject) => {
  const readTransaction = db.transaction(['exelioDataCache'], 'readonly');

  const req = readTransaction.objectStore('exelioDataCache').openCursor(key);
  req.onsuccess = async (e) => {
    const data = e.target.result;
    if (!data) {
      return resolve(null);
    }

    return resolve(data.value);
  };
  req.onerror = () => resolve(null);
});

export const setDataToDB = (dataToStore, key) => new Promise((resolve, reject) => {
  const readTransaction = db.transaction(['exelioDataCache'], 'readonly');

  const req = readTransaction.objectStore('exelioDataCache').openCursor(key);
  req.onsuccess = async () => {
    const writeTransaction = await db.transaction(['exelioDataCache'], 'readwrite');
    await writeTransaction.objectStore('exelioDataCache').put(dataToStore, key);
    await writeTransaction.commit();

    return resolve(dataToStore);
  };
  req.onerror = (err) => reject(err);
});

dataRequest.onerror = () => {
  console.log('Error creating/accessing IndexedDB database');
};

dataRequest.onsuccess = async () => {
  console.log('Success creating/accessing IndexedDB database');
  db = dataRequest.result;

  db.onerror = () => {
    console.log('Error creating/accessing IndexedDB database');
  };
};

// For future use. Currently only in the latest Firefox versions
dataRequest.onupgradeneeded = (event) => {
  // @ts-ignore
  createImgObjectStore(event.target.result);
};

export const clearCachePersist = () => {
  const dbVersion = 2.0;
  const dbNames = ['queryPersist', 'queryPersistWorker'];
  const objectStoreName = 'keyvaluepairs';
  const keysToRemove = ['apollo-cache-persist', 'apollo-cache-persist-worker'];

  const clearCacheForIndexedDb = (dbName: string, key: string): Promise<void> => new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = (e) => {
      reject(new Error(`Error opening IndexedDB: ${(e.target as IDBRequest)?.error}`));
    };

    request.onsuccess = (e) => {
      const db = (e.target as IDBOpenDBRequest).result;
      const transaction = db.transaction(objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(objectStoreName);
      const deleteRequest = objectStore.delete(key);

      deleteRequest.onsuccess = () => {
        db.close();
        resolve();
      };

      deleteRequest.onerror = () => {
        db.close();
        reject(new Error(`Error deleting key: ${key}`));
      };
    };
  });

  return Promise.all(dbNames.map((dbName, index) => clearCacheForIndexedDb(dbName, keysToRemove[index])));
};
