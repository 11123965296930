import React, { useEffect } from 'react';
import { highcharts } from 'App';
import HighchartsReact from 'highcharts-react-official';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { getPrimaryColorGradient } from '../utils/colors';
import { tooltipStyle, tooltipFormatter, TooltipKeys } from './utils';
import LabelWithMeasure from '../layout/LabelWithMeasure';
import { getUomTranslation } from '../utils/utils';
import { IconWrapper } from '../../lib/icons';
import { UnitsType, UomType } from '../utils/unitsFormatter';

type PropsIn = {
  data: number,
  i18nLabel: string,
  uom: UomType,
  unit: UnitsType,
  min: number,
  max: number,
  icon?: React.ReactElement,
  reverseChart?: boolean,
  tooltipKey: string,
  tooltipPercKey: string,
  noTooltipTextPerc: boolean,
}

const GaugeChart = React.memo((props: PropsIn) => {
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const {
    data, min, max, icon, uom, unit, reverseChart,
    i18nLabel, tooltipKey, tooltipPercKey, noTooltipTextPerc,
  } = props;
  const { t } = useTranslation();
  const formattedData = reverseChart && data < min ? min : data;
  const colors = getPrimaryColorGradient(6);

  const gaugeChartValue = () => {
    if (max) {
      return ((formattedData / max) * 100).toFixed(0);
    }
    return ((Math.abs(formattedData) / min) * 100).toFixed(0);
  };

  const title = t(`${i18nLabel}.label`).length > 8
    ? t(`${i18nLabel}.short`) : t(`${i18nLabel}.label`);

  const stops = [[0.1666, colors[0]],
    [0.3332, colors[1]],
    [0.4998, colors[2]],
    [0.6664, colors[3]],
    [0.833, colors[4]],
    [1, colors[5]],
  ];

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    chart: {
      type: 'solidgauge',
      height: 250,
    },
    pane: {
      size: '95%',
      startAngle: -90,
      endAngle: 90,
      background: [{
        backgroundColor: '#ffffff',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
        borderColor: 'var(--stroke-gray)',
      }],
    },
    title: {
      text: undefined,
    },
    series: [{
      type: 'solidgauge',
      data: [reverseChart ? 0 : formattedData],
      threshold: reverseChart ? formattedData < 0 ? formattedData : -1 * formattedData : null,
      tooltip: {
        valueSuffix: 'km/h',
      },
    }],
    tooltip: {
      ...tooltipStyle,
      shared: false,
      split: false,
      shadow: false,
      headerFormat: '',
      shape: 'callout',
      formatter() {
        const tooltipObject = [
          {
            key: tooltipKey || '',
            value: data,
            uom,
            unit,
            valueType: 'GenericValueType',
          },
          {
            valueType: 'CustomRow',
            customTranslation: !noTooltipTextPerc ? t('tracks.labels.gauge%', {
              defaultValue: '{{value}} of the athlete {{key}}',
              value: `<span style="fontFamily: var(--font-family-alt); font-weight: 600;">${gaugeChartValue()}</span> %`,
              key: tooltipPercKey || tooltipKey,
            }) : '',
          },
        ] as TooltipKeys[];
        return tooltipFormatter(tooltipObject);
      },
      positioner() {
        return {
          x: (this.chart.plotWidth / 2) - 50,
          y: 0,
        };
      },
    },
    plotOptions: {
      solidgauge: {
        animation: false,
        dataLabels: {
          allowOverlap: true,
          borderWidth: 0,
          useHTML: true,
          overflow: 'allow',
          color: 'var(--secondary-color)',
          formatter() {
            return ReactDOMServer.renderToString(
              <LabelWithMeasure
                text={title}
                measure={data}
                unit={unit}
                uom={uom}
                icon={icon}
              />,
            );
          },
          y: -55,
        },
      },
    },
    xAxis: {
      labels: {
        style: {
          fontSize: '18px',
          fontFamily: 'var(--font-family-main)',
          color: 'var(--text-secondary)',
        },
      },
    },
    yAxis: {
      stops: max ? stops : stops.reverse(),
      startOnTick: false,
      endOnTick: false,
      min,
      max,
      minorTickPosition: 'inside',
      minorTicks: true,
      tickPositioner() {
        return [this.min, this.max];
      },
      tickPosition: 'inside',
      tickAmount: 0,
      labels: {
        y: 26,
        distance: -20,
        style: {
          fontSize: 'var(--labels-font-size)',
          fontFamily: 'var(--font-family-main)',
        },
      },
    },
  };

  return (
    <div className="highcharts-gauge">
      {icon ? <div className="gauge-center-icon">{icon}</div> : null}
      <HighchartsReact
        highcharts={highcharts}
        options={options}
        ref={chartComponentRef}
      />
    </div>
  );
});

export { GaugeChart };
