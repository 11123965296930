import { getPrimaryColorGradient } from './utils/colors';

export const REFRESH_TOKEN_INTERVAL = 180000; // every 3 minutes
export const DEFAULT_DATA_PLACEHOLDER = '---';
export const HHMMSS_PLACEHOLDER = 'hh:mm:ss';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const COLUMN_LABEL_MULTIPLIER = 16;
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const SNACKBAR_TIMEOUT = 3000;
export const SNACKBAR_TIMEOUT_LONGER = 5000;
export const RELOAD_DELAY = 10000;
export const DEFAULT_COLORS = [
  'rgb(255, 55, 0)',
  'rgb(153, 0, 204)',
  'rgb(102, 0, 204)',
  'rgb(51, 102, 153)',
  'rgb(51, 204, 255)',
  'rgb(51, 204, 204)',
  'rgb(51, 153, 153)',
  'rgb(51, 153, 102)',
  'rgb(153, 204, 102)',
  'rgb(204, 204, 0)',
  'rgb(255, 51, 255)',
  'rgb(255, 204, 0)',
  'rgb(255, 153, 51)',
  'rgb(255, 102, 0)',
  'rgb(102, 51, 255)',
  'rgb(102, 102, 153)',
];

export const WEB_SAFE_COLORS = {
  'rgb(255, 51, 102)': '#FF3366',
  'rgb(153, 0, 204)': '#9900CC',
  'rgb(102, 0, 204)': '#6600CC',
  'rgb(51, 102, 153)': '#336699',
  'rgb(51, 204, 255)': '#33CCFF',
  'rgb(51, 204, 204)': '#33CCCC',
  'rgb(51, 153, 153)': '#339999',
  'rgb(51, 153, 102)': '#339966',
  'rgb(153, 204, 102)': '#99CC66',
  'rgb(204, 204, 0)': '#CCCC00',
  'rgb(255, 51, 255)': '#FF33FF',
  'rgb(255, 204, 0)': '#FFCC00',
  'rgb(255, 153, 51)': '#FF9933',
  'rgb(255, 102, 0)': '#FF6600',
  'rgb(102, 51, 255)': '#6633FF',
  'rgb(102, 102, 153)': '#666699',
};

export const greenColors = getPrimaryColorGradient(6).reverse();

export const blueColors = ['#002960', '#0050bd', '#0063e9', '#4488e3', '#76b9df', '#ace2f3'];

export const redColors = ['#640017', '#9d0025', '#ba002c', '#d50032', '#ed8ba2', '#ffe5eb'];

export const HIGHCHART_OPTIONS = {
  MaxColumnWidth: 100,
};

export const HIGHCHART_COLORS = ['#2caffe', '#544fc5', '#00e272', '#fe6a35', '#6b8abc', '#d568fb', '#2ee0ca', '#fa4b42', '#feb56a', '#91e8e1'];

export const RPEType1 = 'A_1';
export const RPEType2 = 'A_2';
export const RPEExtremes = {
  RPEType1: {
    max: 11,
    min: 0,
  },
  RPEType2: {
    max: 22,
    min: 6,
  },
};

export const TIME_COURSE_KPI = 6;

export const PROFILE_DELTA_DAYS = 30;

export const DEFAULT_DECIMALS_NUM = 1;

export const RETRY_TIMEOUT = 5000;
