import React, { useContext } from 'react';
import { Paper } from '@material-ui/core';
import 'leaflet/dist/leaflet.css';
import {
  Circle,
  CircleMarker,
  Polyline,
  Popup,
} from 'react-leaflet';
import 'proj4leaflet';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import MapComponent from '../layout/maps/MapComponent';
import { TeamContext } from '../../Authenticator';

const multiPolylineItaly = [
  [
    [46.09, 13.22],
    [46.09, 13.222],
    [46.092, 13.222],
  ],
  [
    [46.09, 13.225],
    [46.09, 13.226],
    [46.092, 13.226],
  ],
];
const multiPolylineChina = [
  [
    [31.43, 120.84],
    [31.43, 120.842],
    [31.432, 120.842],
  ],
  [
    [31.43, 120.845],
    [31.43, 120.846],
    [31.432, 120.846],
  ],
];

const fillBlueOptions = { fillColor: 'blue' };
const limeOptions = { color: 'red' };
const redOptions = { color: 'red' };

const positionItaly: [number, number] = [46.099265, 13.2235799];
const positionChina: [number, number] = [31.432026740690574, 120.8439179532812];

const TestLeaflet = () => {
  const { globalTeam } = useContext(TeamContext);

  return (
    <Paper>
      <Row>
        <Column xs={12} lg={3}>
          <h3>
            FROM CLUB: (
            {globalTeam?.club.mapService || 'not set'}
            )
          </h3>
          <MapComponent
            center={positionItaly}
            zoom={13}
          >
            <Circle center={positionItaly} pathOptions={fillBlueOptions} radius={200} />
            <CircleMarker
              center={positionItaly}
              pathOptions={redOptions}
              radius={20}
            >
              <Popup>Popup in CircleMarker</Popup>
            </CircleMarker>
            <Polyline pathOptions={limeOptions} positions={multiPolylineItaly} />
          </MapComponent>
        </Column>
        <Column xs={12} lg={3}>
          <h3>OSM</h3>
          <MapComponent
            center={positionItaly}
            zoom={13}
            type="OSM"
          >
            <Circle center={positionItaly} pathOptions={fillBlueOptions} radius={200} />
            <CircleMarker
              center={positionItaly}
              pathOptions={redOptions}
              radius={20}
            >
              <Popup>Popup in CircleMarker</Popup>
            </CircleMarker>
            <Polyline pathOptions={limeOptions} positions={multiPolylineItaly} />
          </MapComponent>
        </Column>

        <Column xs={12} lg={3}>
          <h3>GOOGLE</h3>
          <MapComponent
            center={positionItaly}
            type="GOOGLE"
          >
            <Circle center={positionItaly} pathOptions={fillBlueOptions} radius={200} />
            <CircleMarker
              center={positionItaly}
              pathOptions={redOptions}
              radius={20}
            >
              <Popup>Popup in CircleMarker</Popup>
            </CircleMarker>
            <Polyline pathOptions={limeOptions} positions={multiPolylineItaly} />
          </MapComponent>
        </Column>

        <Column xs={12} lg={3}>
          <h3>BAIDU</h3>
          <MapComponent
            center={positionChina}
            type="BAIDU"
          >
            <Circle center={positionChina} pathOptions={fillBlueOptions} radius={200} />
            <CircleMarker
              center={positionChina}
              pathOptions={redOptions}
              radius={20}
            >
              <Popup>Popup in CircleMarker</Popup>
            </CircleMarker>
            <Polyline pathOptions={limeOptions} positions={multiPolylineChina} />
          </MapComponent>
        </Column>
      </Row>
    </Paper>
  );
};

export default TestLeaflet;
