import { gql } from '@apollo/client';
import { getClubFragment, getClubFragmentName } from 'models/club';
import { GroundType } from 'models/ground';
import { TeamType } from 'models/team';

export type ClubTypeRes = {
  res: {
    club: {
      name: string;
      shieldImage: string;
      groundSet: GroundType[];
      activationDate: Date;
      mapService: string;
      supportNote: string;
      shieldText: string;
      shieldTextColor: string;
      shieldBackgroundColor: string;
      teamSet: TeamType[];
    };
  };
};
export type ClubTypeVars = {
  id: string;
  sort?: string;
};
export const CLUB = gql`
  ${getClubFragment(['with_shields', 'with_supports', 'with_groundSet', 'with_team'])}
  query Club(
    $id:ID!
  ) {
    res: team(
       id: $id
    ) {
      club{
        ...${getClubFragmentName(['with_shields', 'with_supports', 'with_groundSet', 'with_team'])}
      }
    }
  }
`;

export type ClubUpdateTypeVars = {
  teamId: string;
  name: string;
};

export type ClubUpdateTypeRes = {
  ClubTypeRes;
};

export const UPDATE_CLUB = gql`
  mutation UpdateClub(
    $teamId: ID!
    $name: String
    $shieldText: String
    $shieldTextColor: String
    $shieldBackgroundColor: String
    $logoType: String
  ) {
    res: updateClub(
      teamId: $teamId
      name: $name
      shieldText: $shieldText
      shieldTextColor: $shieldTextColor
      shieldBackgroundColor: $shieldBackgroundColor
      logoType: $logoType
    ) {
      name
      shieldText
      shieldTextColor
      shieldBackgroundColor
    }
  }
`;
