import React from 'react';
import { Loader } from '../../lib/icons';

const MainLoader = () => (
  <Loader
    noPadding
    style={{
      top: '80px',
      left: '50%',
      position: 'fixed',
      zIndex: 100,
      width: 'auto',
    }}
  />
);
export default MainLoader;
