import { ME } from 'query/team';
import React, { useContext } from 'react';
import { AuthenticatorContext } from 'Authenticator';
import MainLoader from './layout/MainLoader';
import { MaintenanceMessage } from './Layout';
import { CACHE_AND_NETWORK } from '../lib/cache';
import { useQueryCached } from './utils/graphql';

export function withMe<TProps>(
  Cmp: React.ComponentType<TProps>,
  opts?: { ErrorCmpC?: React.JSXElementConstructor<{ error: string }>, LoadingCmpC?: React.JSXElementConstructor<any> },
) {
  const CmpRet: React.FunctionComponent<TPropsOut> = (props: TProps) => {
    const { isTokenValid } = useContext(AuthenticatorContext);
    const { loading, data, error } = useQueryCached(ME, {
      skip: !isTokenValid, // skip if jwt token not valid
      ...CACHE_AND_NETWORK,
      onCompleted(data) {
        localStorage.setItem('userColorsPalette', data?.res?.colorPalette)
      },
    }
  );

    if (error) {
      if (opts && opts.ErrorCmpC) return <opts.ErrorCmpC error={error.message} />;
      setTimeout(() => {
        window.location.reload();
      }, 10000);
      return <MaintenanceMessage />;
    }

    if (loading || !data) {
      if (opts && opts.LoadingCmpC) return <opts.LoadingCmpC />;
      return <MainLoader />;
    }

    const me = data && data.res;
    return (
      <Cmp me={me} isTeamLocked={me?.lastTeam?.locked} {...props} />
    );
  };

  type TPropsOut = Omit<TProps, 'me'>

  return (props: TPropsOut) => <CmpRet {...props} />;
}
