import { gql } from '@apollo/client'

export const BridgeQuery = gql`
  query BridgeInfo {
  res: bridgeInfo {
    opSystem
    system
    version
    downloadUrl
    updateUrl
    changelog
  }
}`
