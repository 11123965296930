import { useCallback, useEffect, useRef } from 'react';
import useWebSocket from 'react-use-websocket';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CLIENT_SETTINGS from '../lib/client_settings';
import { useQueryCached } from '../components/utils/graphql';
import { ME } from '../query/team';
import { SNACKBAR_TIMEOUT } from '../components/constants';

/**
 * Utilizza gli eventi WebSocket per eseguire una chiamata quando viene ricevuto un messaggio WebSocket specifico.
 *
 * @param key - I messaggi WebSocket da ascoltare. Può essere una stringa o un array di stringhe.
 * @param callback - La funzione di callback da eseguire quando viene ricevuto il
 * messaggio WebSocket specificato.
 * @param zeroed - Se attivo verifica anche che il count sia zero
 * @param avoidFirstZero - Se attivo skip del primo evento con zero (quelli di inizializzazione ad esempio)
 */
export default function useWsEvents(key: string | string[], callback: (d: any) => void, zeroed?: boolean, avoidFirstZero?: boolean) {
  const eventCounter = useRef(0);
  const { loading, data, error } = useQueryCached(ME, {
    fetchPolicy: 'cache-first',
  });
  const me = data && data.res;

  const getSocketUrl = useCallback(
    () => `${CLIENT_SETTINGS.public.gpexeWssUrl}team/${me.lastTeam?.id}/`,
    [me, error, loading],
  );

  const {
    lastJsonMessage,
  } = useWebSocket(
    !error && !loading && me ? getSocketUrl : null,
    {
      share: true,
    },
  );

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (
        ( // verifico se il codice messaggio corrisponde (singolo) o è incluso (multipli)
          Array.isArray(key) && key.includes(lastJsonMessage.msg)
          || !Array.isArray(key) && key === lastJsonMessage.msg
        )
        // zeroed non attivo oppure count === 0ù
        && (!zeroed || Object.hasOwn(lastJsonMessage, 'count') && lastJsonMessage.count === 0)
      ) {
        // avoidFirstZero non attivo oppure skip del primo evento con zero (quelli di inizializzazione ad esempio)
        if (!avoidFirstZero || eventCounter.current > 0) {
          callback(lastJsonMessage);
        }

        eventCounter.current += 1;
      }
    }
  }, [lastJsonMessage]);
}

/**
 * Controlla le modifiche dei dati ed esegue una funzione di callback quando i dati vengono modificati.
 *
 * @param key - La chiave o le chiavi per controllare le modifiche dei dati.
 * @param cb - La funzione di callback da eseguire quando i dati vengono modificati.
 * @param zeroed - Se attivo verifica anche che il count sia zero
 * @param avoidFirstZero - Se attivo skip del primo evento con zero (quelli di inizializzazione ad esempio)
 */
export const watchDataChange = (key: string | string[], cb: (d: any) => void, zeroed?: boolean, avoidFirstZero?: boolean) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useWsEvents(
    key,
    (data) => {
      enqueueSnackbar(
        t('general.dataChanged', 'Data is changed, reloading...'),
        {
          variant: 'info',
          autoHideDuration: SNACKBAR_TIMEOUT,
        },
      );
      cb(data);
    },
    zeroed,
    avoidFirstZero,
  );
};
