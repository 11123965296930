import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import TitleView from 'components/layout/TitleView';
import { StackedColumnChart } from '../charts/StackedColumnChart';
import { IconWrapper } from '../../lib/icons';
import { eventsColorByNameFMS } from '../charts/colors';

/*
@TODO
- DRY: create a common chart component
 */

interface PropsIn {
  data: any // @todo fix
}

export default function DivesByReturnTime(props: PropsIn) {
  const { data } = props;
  const { t } = useTranslation();

  return (
    (data?.res?.imu?.eventsDivesByReturnTime?.length > 0 && (
      <Box className="chart-widget">
        <Box className="title">
          <TitleView
            title={t('track.widgets.mecKPI.labels.divesByReturnTime', 'dives by return time')}
            prefixIcon={<IconWrapper name="dives" size="medium" color="primary" />}
            align="left"
          />
        </Box>
        <StackedColumnChart
          data={data?.res?.imu}
          colors={eventsColorByNameFMS}
          categories={[
            t('kpi2.IMUEvents.eventDivesCount.label', 'dives'),
          ]}
          validKeys={['eventsDivesByReturnTime']}
        />
      </Box>
    ))
    || null
  );
}
