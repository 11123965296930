import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import CLIENT_SETTINGS from './client_settings';
import { getWithExpiry, setWithExpiry } from '../components/utils/storage';
import { globalEventsChannel } from './events';

if (process.env.GPEXE_DISABLE_I18N_DEBUG) {
  console.info('i18Next: Debug disabled via ENVs');
}

const storageTranslationsKey = 'gpexe:translations';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production' && !process.env.GPEXE_DISABLE_I18N_DEBUG,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: CLIENT_SETTINGS.public.gpexeGraphUrl,
      request(options, url, payload, callback) {
        /* const cachedTranslations = getWithExpiry(storageTranslationsKey);
        if (cachedTranslations && JSON.stringify(cachedTranslations) !== '{}') {
          return callback(undefined, { status: 200, data: cachedTranslations });
        } */
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: `JWT ${localStorage.getItem('exelio_token')}`,
          },
          body: JSON.stringify({ query: '{text(teamId:null)}' }),
        })
          .then((response) => response.json())
          .then((response) => {
            const data = response?.data?.text ? JSON.parse(response.data.text) : {};

            setWithExpiry(storageTranslationsKey, data, 900);
            callback(undefined, {
              status: 200,
              data,
            });
          })
          .catch((error) => callback(error, undefined));
      },
    },
  });

globalEventsChannel.on('onLanguageChange', (lang) => {
  localStorage.removeItem(storageTranslationsKey);
  i18n.changeLanguage(lang);
});

export default i18n;
