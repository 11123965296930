import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import { CloudCircle } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import DialogButton from '../layout/DialogButton';

function TestDialogButton() {
  return (
    <>
      <Typography variant="h5" gutterBottom>DialogButtonModal</Typography>
      <Paper>
        <Box p={2} display="flex" alignItems="center" justifyContent="center">
          <Row>
            <Column md={3}>
              <DialogButton
                buttonLabel="Default"
                dialogTitle="Some title"
                buttonIcon={<CloudCircle />}
              >
                <div>
                  <h2>A title</h2>
                  This is the content!
                </div>
              </DialogButton>
            </Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestDialogButton;
