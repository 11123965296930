import { gql } from '@apollo/client';
import { getUserRoleSetFragment, getUserRoleSetFragmentName } from 'models/user';

export const USERS = gql`
  ${getUserRoleSetFragment()}
  query Users(
    $withStaff: Boolean,
    $clubId: ID!,
    $first: Int,
    $skip: Int,
    $sort: String
  ) {
    res: users(
      withStaff: $withStaff,
      clubId: $clubId,
      first: $first,
      skip: $skip,
      sort: $sort
    ) {
      content {
        id
        firstName
        lastName
        email
        isActive
        preferredLanguage
        colorPalette
        preferredLanguageDisplay
        userroleSet{
          ...${getUserRoleSetFragmentName()}
        }
        userunits {
            energy
            speed
            power
            temperature
            percentage
        }
      }
      count
    }
  }
`;

export const USER = gql`
  ${getUserRoleSetFragment()}
  query Users(
    $id: ID!
    $club: ID
  ) {
    res: user(
      id: $id
      club: $club
    ) {
        id
        firstName
        lastName
        email
        isActive
        isStaff
        preferredLanguage
        colorPalette
        preferredLanguageDisplay
        userroleSet{
          ...${getUserRoleSetFragmentName()}
        }
        userunits {
            energy
            speed
            power
            temperature
            percentage
        }
    }
  }
`;

export const AVAILABLE_LANGUAGES = gql`
  query settingsQuery {
    res: settingsQuery {
      availableLanguages{
        key, value
      }
    }
  }
`;

export const AVAILABLE_UNITS = gql`
  query settingsQuery {
    res: settingsQuery {
      availableUserUnits {
        speed,
        power,
        energy,
        temperature
      }
    }
  }
`;

export type UserPermissionsVars = {
  teamId: string
}

export type UserPermissionsRes = {
  data: {
    userPermissions: null | object
  }
}

export const USER_PERMISSIONS = gql`
    query UserPermissions($teamId: ID!) {
        userPermissions(teamId: $teamId)
    }
`;

export const USER_ACADEMY = gql`
    query userAcademy {
        userAcademy {
            passwd
            login
            host
        }
    }
`;

export interface UpdateUserPasswordVars {
  password: string,
  resetUrl: string
}

export interface UpdateUserPasswordRes {
  updateUserPassword: {
    success: boolean,
    messageKeys: string[]
  }
}

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword (
      $password: String!,
      $resetUrl: String!
  ) {
      updateUserPassword(
          password: $password,
          resetUrl: $resetUrl
      ) {
          success
          messageKeys
      }
  }
`;
