import React from 'react';
import Table, { PropsIn } from './Table';

const EditableTable = (props: PropsIn) => (
  <Table
    className="editable-table"
    {...props}
  />
);

export default React.memo(EditableTable);
