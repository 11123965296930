import React from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { Cancel } from '@material-ui/icons';

const StyledChip = withStyles(() => ({
  root: {
    height: '24px',
    background: '#e0e0e0',
    color: 'var(--text-secondary)',
    borderRadius: '12px',
    fontSize: '13px',
    fontFamily: 'var(--font-family-main)',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.16px',
    boxSizing: 'border-box',
    display: 'inline-flex',
    outline: '0',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    margin: '6px 3px 2px 3px',
  },
}))(Chip);

export type PropsIn = ChipProps & {
  disable?: boolean;
  clickable?: boolean;
  label?: any;
  onClick?: any;
  icon?: any;
  className?: string;
  size?: 'small' | 'medium';
  onDelete?: () => void;
  inTextField?: boolean;
  hasBackground?: string;
};

const useStyles = makeStyles(() => createStyles({
  inTextField: {
    boxShadow: 'none',
    margin: '6px 4px 6px 4px',
  },
  smallDeleteIcon: {
    '& .MuiChip-deleteIcon': {
      height: '18px',
      width: '18px',
    },
  },
  inheritColor: {
    '& .MuiChip-deleteIcon': {
      color: '#FFFFFF',
    },
  },
}));

const BaseChip = React.forwardRef<HTMLDivElement, PropsIn>((props, ref) => {
  const {
    icon, className, label, disable, clickable, onClick, size, onDelete, inTextField, hasBackground,
  } = props;

  const classes = useStyles();
  const chipClasses = `${inTextField ? classes.inTextField : ''} ${size === 'small' ? classes.smallDeleteIcon : ''} ${
    hasBackground ? classes.inheritColor : ''
  }`;

  /**
   * @todo sistemare la property `hasBackground`
   * La chip per ora può avere solo un colore.
   * Un attributo che inizia con "has", "is" o simili va messo booleano
   * Va quindi modificata usando una property generale `color` (che vedo presente ma non usata)
   * ed eventualmente usare `hasBackground` per logiche (se proprio poi serve)
   *
   * @todo sistemare la property `cliackable`
   * Possiamo probabilmente vincolarla alla presenza di una callback di click
   * Altrimenti potenzialmente andrebbe messo anche `deletable`
   */

  return (
    <StyledChip
      label={label}
      disabled={disable}
      clickable={clickable}
      onClick={onClick}
      icon={icon}
      className={`base-chip ${className || ''} ${chipClasses || ''}`}
      style={{
        backgroundColor: `${hasBackground}`,
        color: `${hasBackground ? '#FFFFFF' : ''}`,
      }}
      size={size}
      onDelete={onDelete}
      deleteIcon={<span data-no-dnd="true"><Cancel /></span>}
      ref={ref}
    />
  );
});

export default BaseChip;
