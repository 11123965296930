import { AthleteType, getAthleteFragment, getAthleteFragmentName } from './athlete';
import { getTeamFragment, getTeamFragmentName, TeamType } from './team';
import { getGroundSetFragment, getGroundSetFragmentName } from './groundSet';

type PortalClubMapServiceChoices = 'BAIDU' | 'GOOGLE';
type PortalClubSupportTypeChoices = 'BASIC' | 'PREMIUM';
export type ClubType = {
  id: string;
  createdOn?: Date;
  updatedOn?: Date;
  name: string;
  activationDate?: Date; //  yyyy-mm-dd
  mapService: PortalClubMapServiceChoices;
  supportNote: string;
  shieldText: string; // Max 2 characters
  shieldTextColor: string;
  shieldBackgroundColor: string;
  shieldImage?: string;
  teamSet: TeamType[];
  athleteSet: AthleteType[];
  logoType: 'file' | 'standard';
  locked: boolean;
};

export type LimitedClubType = {
  name: string;
};

export type ClubResponseType =
  | 'with_team'
  | 'with_athlete'
  | 'with_shields'
  | 'with_supports'
  | 'with_groundSet';
export const getClubFragmentName = (types: ClubResponseType[]) => `ClubTypeFields${types
  .sort()
  .map((t) => `_${t}`)
  .join('')}`;
export const getClubFragment = (types: ClubResponseType[]) =>
  // tslint:disable-next-line: prefer-template
  `
    ${types.includes('with_groundSet') ? getGroundSetFragment() : ''}
    ${types.includes('with_team') ? getTeamFragment([]) : ''}
    ${types.includes('with_athlete') ? getAthleteFragment([]) : ''}
    fragment ${getClubFragmentName(types)} on ClubType {
      id
      name
      mapService${
  types.includes('with_shields')
    ? `
      shieldText
      shieldTextColor
      shieldBackgroundColor
      shieldImage
      `
    : ''
}${
  types.includes('with_team')
    ? `
      teamSet {
        ...${getTeamFragmentName([])}
      }`
    : ''
}${
  types.includes('with_athlete')
    ? `
      athleteSet {
        ...${getAthleteFragmentName([])}
      }`
    : ''
}${
  types.includes('with_groundSet')
    ? `
      groundSet{
        ...${getGroundSetFragmentName()}
      }`
    : ''
}
    }
  `;
