import React, { useEffect, useContext } from 'react';
import {
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { QueryHookOptions } from '@apollo/client';
import { useLazyQueryCached } from 'components/utils/graphql';
import {
  BasicKPI, HrKPI, MechKPI, MetKPI,
} from './TrackTabComponents';
import { IconWrapper } from '../../lib/icons';
import { elementIsEnabled } from '../utils/layout';
import {
  BASIC_KPI, GPEXE_KPI, HR_KPI, LayoutType, MECH_KPI,
} from '../../query/track';
import { TabsWidget } from '../layout/Tabs';
import { AthleteSessionBySessionVars, AthleteSessionByTrackVars, AthSessionRes } from '../../query/athletesessions';
import { GlobalFiltersContext } from './View';
import { useQueryParams } from '../utils/hooks';

type PropsIn = {
  changeDevice: boolean;
  layout?: LayoutType[];
  athleteSessionId?: string;
  templateId?: string;
  trackId?: string;
  currentDrill?: number;
  modelVersion?: number;
  isGk?: boolean;
};

function TrackTabContainer(props: PropsIn) {
  const {
    changeDevice, layout, athleteSessionId, templateId, trackId, currentDrill, modelVersion, isGk,
  } = props;

  const { t } = useTranslation();
  const [queryParams] = useQueryParams();
  const globalFilters = useContext(GlobalFiltersContext);
  let firstTab = null;
  const componentOptions = (trkId: string) => ({
    variables: trkId
      ? {
        templateId,
        trackId: trkId,
        start: globalFilters.filters.min,
        end: globalFilters.filters.max,
      } as AthleteSessionByTrackVars
      : {
        templateId,
        id: athleteSessionId,
        start: globalFilters.filters.min,
        end: globalFilters.filters.max,
        drill: currentDrill,
      } as AthleteSessionBySessionVars,
  }) as QueryHookOptions;

  const [loadBasicKPIData, basicKPIData] = trackId
    ? useLazyQueryCached<AthSessionRes, AthleteSessionByTrackVars>(BASIC_KPI('track'), componentOptions(trackId))
    : useLazyQueryCached<AthSessionRes, AthleteSessionBySessionVars>(BASIC_KPI('session'), componentOptions(trackId));

  const [loadMetKPIData, metKPIData] = trackId
    ? useLazyQueryCached<AthSessionRes, AthleteSessionByTrackVars>(GPEXE_KPI('track'), componentOptions(trackId))
    : useLazyQueryCached<AthSessionRes, AthleteSessionBySessionVars>(GPEXE_KPI('session'), componentOptions(trackId));

  const [loadHDKPIData, HDKPIData] = trackId
    ? useLazyQueryCached<AthSessionRes, AthleteSessionByTrackVars>(HR_KPI('track'), componentOptions(trackId))
    : useLazyQueryCached<AthSessionRes, AthleteSessionBySessionVars>(HR_KPI('session'), componentOptions(trackId)); // togliere drills

  const [loadMechKPIData, mechKPIData] = trackId
    ? useLazyQueryCached<AthSessionRes, AthleteSessionByTrackVars>(MECH_KPI('track'), componentOptions(trackId))
    : useLazyQueryCached<AthSessionRes, AthleteSessionBySessionVars>(MECH_KPI('session'), componentOptions(trackId));

  const tabs = {
    basic_kpi: {
      label: t('track.widgets.basicKPI.title', 'basic kpi'),
      icon: <IconWrapper name="basicKPITab" size="large" color="secondary" pointer />,
      component: elementIsEnabled(layout, 'basic_kpi') && (
        <BasicKPI
          changedevice={changeDevice}
          templateId={templateId}
          trackId={trackId}
          athleteSessionId={athleteSessionId}
          currentDrill={currentDrill}
          layout={layout}
          loading={basicKPIData.loading}
          error={basicKPIData.error}
          data={basicKPIData.data}
        />
      ) || null,
    },
    met_kpi: {
      label: t('track.widgets.metKPI.title', 'met kpi'),
      icon: <IconWrapper name="energy" size="large" color="secondary" pointer />,
      component: elementIsEnabled(layout, 'met_kpi') && (
        <MetKPI
          changedevice={changeDevice}
          templateId={templateId}
          trackId={trackId}
          athleteSessionId={athleteSessionId}
          currentDrill={currentDrill}
          layout={layout}
          loading={metKPIData.loading}
          error={metKPIData.error}
          data={metKPIData.data}
        />
      ) || null,
    },
    mec_kpi: {
      label: t('track.widgets.mecKPI.title', 'mech kpi'),
      icon: <IconWrapper name="mechKPITab" size="large" color="secondary" pointer />,
      component: elementIsEnabled(layout, 'mec_kpi') && (
        <MechKPI
          changedevice={changeDevice}
          templateId={templateId}
          trackId={trackId}
          athleteSessionId={athleteSessionId}
          currentDrill={currentDrill}
          layout={layout}
          loading={mechKPIData.loading}
          error={mechKPIData.error}
          data={mechKPIData.data}
          modelVersion={modelVersion}
          isGk={isGk}
        />
      ) || null,
    },
    hr_kpi: {
      label: t('track.widgets.hrKPI.title', 'hr kpi'),
      icon: <IconWrapper name="HRKPITab" size="large" color="secondary" pointer />,
      component: elementIsEnabled(layout, 'hr_kpi')
        && (
          <HrKPI
            changedevice={changeDevice}
            templateId={templateId}
            trackId={trackId}
            layout={layout}
            athleteSessionId={athleteSessionId}
            currentDrill={currentDrill}
            loading={HDKPIData.loading}
            error={HDKPIData.error}
            data={HDKPIData.data}
          />
        ) || null,
    },
  };

  const sortOrderKeys = layout?.filter((l) => l.key !== 'main_chart' && elementIsEnabled(layout, l.key))
    .sort((a, b) => (parseInt(a.order, 10) < parseInt(b.order, 10) ? -1 : 1));

  // se l'url contiene un tab predefinito diverso da quello dell'ordine effettuo lo switch
  let firstTabId = 0;
  if (
    queryParams.tab && (sortOrderKeys.length > 0)
    && queryParams.tab !== sortOrderKeys[0].key
  ) {
    firstTabId = sortOrderKeys.findIndex((l) => l.key === queryParams.tab);
  }

  const sortedLayout = sortOrderKeys.map((ta) => {
    const order = parseInt(ta.order, 10);

    if (order === 0) {
      firstTab = Object.keys(tabs).findIndex((tab) => tab === ta.key);
    }

    return {
      ...tabs[ta.key],
      ...{
        order,
        key: ta.key,
      },
    };
  });

  const loadTabData = (tabName: string) => {
    switch (tabName) {
      case 'basic_kpi':
        return loadBasicKPIData;

      case 'met_kpi':
        return loadMetKPIData;

      case 'mec_kpi':
        return loadMechKPIData;

      case 'hr_kpi':
        return loadHDKPIData;

      default:
        return () => new Promise((resolve) => resolve());
    }
  };

  useEffect(() => {
    // load the first tab data
    loadTabData(sortOrderKeys[firstTabId].key)()
      .then(() => {
        // lazy load remaining tabs data
        setTimeout(() => {
          sortOrderKeys
            .filter((l) => sortOrderKeys[firstTabId].key !== l.key)
            .forEach((tab) => {
              loadTabData(tab.key)();
            });
        }, 2000);
      });
  }, []);

  return (
    <>
      <TabsWidget
        tabs={sortedLayout}
        firstTabSelected={isGk ? 1 : 0}
      />
      <Box className="bottom-spacer" />
    </>
  );
}

export default TrackTabContainer
