import { useMutation, useQuery } from '@apollo/client';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { SET_USER_TEAM } from 'mutation/team';
import {
  ME, TEAM_SELECTOR, TeamsRes, TeamsVars,
} from 'query/team';
import React, { useState, useContext, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AppBar from '@material-ui/core/AppBar';
import { Box, Popover, Typography } from '@material-ui/core';
import { withMe } from 'components/MeUserWrapper';
import { MeType } from 'models/me';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconWrapper, Loader } from 'lib/icons';
import TeamIcon from '@images/icons/svg/team.svg';
import { useSnackbar } from 'notistack';
import Error from 'components/layout/Error';
import { DividerWithText } from 'components/templates/SelectableKpi';
import BaseButton from '../form/BaseButton';
import { LoadingContext, TeamContext } from '../../Authenticator';
import { globalEventsChannel } from '../../lib/events';
import { USER_PERMISSIONS } from '../../query/user';
import { checkUserPermissions } from '../Layout';
import useWsEvents from '../../custom_hooks/useWsEvents';
import { changeUIStatus } from '../utils/dom';
import { useQueryParams } from '../utils/hooks';

type Props = {
  me: MeType;
  isTeamLocked: boolean;
};

type teamListType = {
  me:MeType,
  onSelected: () => void;
}

function TeamsList(props: teamListType) {
  const navigate = useNavigate();
  const { setIsLoading } = useContext(LoadingContext);
  const location = useLocation();
  const {
    me, onSelected,
  } = props;
  const { t } = useTranslation();
  const [_, setQueryParams] = useQueryParams();

  const today = new Date();
  const isTeamExpired = me?.lastTeam && new Date(me?.lastTeam.endDate) < today;
  const [active, setActive] = useState(isTeamExpired ? 0 : 1);
  const [disableTransition, setDisableTransition] = useState<boolean>(true);
  const { loading, error, data } = useQuery<TeamsRes, TeamsVars>(TEAM_SELECTOR, {
    variables: {
      sort: 'club__name,name,season',
      active: Boolean(active),
    },
    fetchPolicy: 'no-cache',
    skip: !me.lastTeam?.id,
  });

  const teamsData = data?.res?.content || [];
  const currentTeamSelected = teamsData?.filter((tm) => tm.id === me.lastTeam?.id) || [];
  const otherTeams = teamsData.filter((tm) => tm.id !== me.lastTeam?.id) || [];
  const teams = [...currentTeamSelected, ...otherTeams];

  const currentTeams = teams.filter((tm) => new Date(tm.startDate) <= today);
  const comingSoonTeams = teams.filter((tm) => new Date(tm.startDate) > today);

  const [setTeam, { client }] = useMutation(SET_USER_TEAM, {
    onError: (e) => {
      console.error(e);
    },
    onCompleted: async (data1) => {
      const cacheres = client.readQuery({ query: ME });

      client.writeQuery({
        query: ME,
        data: {
          ...cacheres,
          res: data1.res,
        },
      });

      setQueryParams({ teamId: data1.res.lastTeam.id });

      const {
        data: permissionData,
        error: permissionError,
        loading: permissionLoading,
      } = await client.query({
        query: USER_PERMISSIONS,
        variables: {
          teamId: data1?.res?.lastTeam?.id,
        },
        fetchPolicy: 'network-only',
      });

      const newUserCan = (section, type, permission) => {
        const user = {
          isStaff: data1.res.isStaff,
          ...JSON.parse(permissionData?.userPermissions),
        };
        return me && !permissionError
          && !permissionLoading && checkUserPermissions(me.isStaff, user, section, type, permission);
      };

      const currentLocation = location.pathname.split('/')[1];

      if (newUserCan(`${currentLocation}`, 'view', 'page_view')) {
        if (location.pathname.match(/^\/.+\/\d+|new/)) {
          navigate(`/${location.pathname.split('/')[1]}`);
          setIsLoading(false); // resets all global loaders
        }
      } else if (newUserCan('calendar', 'view', 'page_view')) {
        navigate('/calendar');
        setIsLoading(false);
      } else {
        navigate('/tracks');
        setIsLoading(false);
      }
    },
  });

  if (error) return <Error />;

  return (
    <Box className="team-list">
      <AppBar elevation={0} position="relative">
        <Tabs
          TabIndicatorProps={{
            style: (active === 1 && disableTransition)
              ? { transition: 'none', left: '0', width: '50%' }
              : (active === 0 && disableTransition)
                ? { transition: 'none', left: '50%', width: '50%' }
                : {},
          }}
          indicatorColor="secondary"
          variant="fullWidth"
          value={active}
        >
          <Tab
            value={1}
            icon={
              <IconWrapper name="activeTeam" size="medium" color={active === 1 ? 'secondary' : 'primary'} pointer />
            }
            label={(
              <Typography variant="button" color={active === 1 ? 'secondary' : 'primary'}>
                {t('teams.tabs.activeTeams', 'active teams')}
              </Typography>
            )}
            onClick={() => {
              setActive(1);
              setDisableTransition(false);
            }}
          />
          <Tab
            value={0}
            icon={
              <IconWrapper name="expiredTeam" size="medium" color={active === 0 ? 'secondary' : 'primary'} pointer />
            }
            label={(
              <Typography variant="button" color={active === 0 ? 'secondary' : 'primary'}>
                {t('teams.tabs.expiredTeams', 'expired teams')}
              </Typography>
            )}
            onClick={() => {
              setActive(0);
              setDisableTransition(false);
            }}
          />
        </Tabs>
      </AppBar>
      {loading && <Loader />}
      <List component="nav" className="team-selector-list" disablePadding>
        {
        currentTeams.map((tm) => {
          const currTeamSelected = me?.lastTeam?.id === tm.id;

          return (
            <ListItem
              key={tm.id}
              button
              onClick={() => {
                onSelected();
                if (!currTeamSelected) {
                  globalEventsChannel.emit('onBeforeTeamChange', me.lastTeam?.id);
                }
                setTeam({
                  variables: {
                    teamId: tm.id,
                  },
                });
              }}
              className={currTeamSelected ? 'team-list-item-selected' : 'team-list-item'}
            >
              {currTeamSelected && <CheckCircleIcon color="secondary" />}
              &nbsp;
              <span className="team-list-item-label">
                {`${tm?.limitedClub?.name} ${tm.name} ${tm.season}`}
              </span>
            </ListItem>
          );
        })
      }
      </List>
      { active === 1
      && comingSoonTeams.length ? (
        <Box style={{ paddingBottom: '8px' }}>
          <Box pt={3} pb={2}>
            <DividerWithText text={t('teams.labels.comingSoon', 'coming soon').toUpperCase()} spacing={3} />
          </Box>
          <List component="nav" className="team-selector-list" disablePadding>
            {comingSoonTeams.map((tm) => {
              const currTeamSelected = me?.lastTeam?.id === tm.id;

              return (
                <ListItem
                  key={tm.id}
                  button
                  onClick={() => {
                    onSelected();
                    if (!currTeamSelected) {
                      globalEventsChannel.emit('onBeforeTeamChange', me.lastTeam?.id);
                    }
                    setTeam({
                      variables: {
                        teamId: tm.id,
                      },
                    });
                  }}
                  className={currTeamSelected ? 'team-list-item-selected' : 'team-list-item'}
                >
                  {currTeamSelected && <CheckCircleIcon color="secondary" />}
                  &nbsp;
                  <span className="team-list-item-label">
                    {`${tm?.limitedClub?.name} ${tm.name} ${tm.season}`}
                  </span>
                </ListItem>
              );
            })}
          </List>
        </Box>
        ) : null}
    </Box>
  );
}

function Cmp(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const { t } = useTranslation();
  const { me, isTeamLocked } = props;
  const [setTeam] = useMutation(SET_USER_TEAM);
  const { setGlobalTeam } = useContext(TeamContext);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);
  const id = open ? 'team-selector' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (me.lastTeam) {
      setGlobalTeam(me.lastTeam);
    }
  }, [me.lastTeam?.id]);

  useEffect(() => {
    const unsubscribeTeamChange = globalEventsChannel.on('onTeamChange', (teamId) => {
      enqueueSnackbar(t('teams.dialog.teamChanged', 'Data from another team. Changing team.'), {
        variant: 'warning',
        autoHideDuration: 5000,
      });
      setTeam({
        variables: {
          teamId,
        },
      });
    });
    return () => {
      unsubscribeTeamChange();
    };
  }, []);

  useWsEvents('ws.teamChange', (eventData) => {
    setTimeout(() => {
      if (me.lastTeam?.id && parseInt(me.lastTeam.id, 10) !== eventData.nextData && !document.hasFocus()) {
        changeUIStatus({
          status: false,
          message: t('global.changedTeamAnotherTab', 'User changed team on another tab, editing is disabled'),
        });
      } else {
        changeUIStatus({
          status: true,
        });
      }
    }, 2000);
  });

  return (
    <>
      <BaseButton
        tooltipText={t('tooltip.team', 'team')}
        startIcon={<TeamIcon width="30" height="30" />}
        aria-describedby={id}
        onClick={handleClick}
        style={{ textTransform: 'uppercase' }}
        expiredWarning={isTeamLocked}
        className="force-large"
      >
        {me.lastTeam ? `${me.lastTeam.name} ${me.lastTeam.season}` : t('header.team.select')}
      </BaseButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <TeamsList onSelected={handleClose} {...props} />
      </Popover>
    </>
  );
}

export default withMe(Cmp);
