import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import ScatterChart from '../charts/ScatterChart';
import { IconWrapper } from '../../lib/icons';
import { getUomTranslation, secondsToHHMMSS } from '../utils/utils';
import { eventsColors } from '../charts/colors';
import getAxisTitleFormat, { tooltipFormatter } from '../charts/utils';

interface PropsIn {
  data: any // @todo fix
}

export default function DistanceTimes(props: PropsIn) {
  const { data } = props;
  const { t } = useTranslation();
  return (
    (data?.res?.powerseries.length && (
      <Box className="chart-widget chart--scatter-chart">
        <ScatterChart
          xLabel={
            getAxisTitleFormat(
              t('table.duration', 'duration'),
              getUomTranslation(t, data?.res?.powerseries[0].x.uom),
            )
          }
          xIcon={<IconWrapper name="time" size="medium" color="primary" />}
          yLabel={
            getAxisTitleFormat(
              t('kpi2.main.totalDistance.label', 'distance'),
              getUomTranslation(t, data?.res?.powerseries[0].y.uom),
            )
          }
          yIcon={<IconWrapper name="distance" size="medium" color="primary" />}
          height={600}
          data={[
            {
              data: data?.res?.powerseries.map((dot) => ({
                x: dot.x.value,
                y: dot.y.value,
                id: dot.id,
                color: eventsColors[2],
                tooltip: tooltipFormatter(dot.props.map((p) => ({
                  key: p.key,
                  value: p.value.unit === 'time' ? secondsToHHMMSS(p.value.value) : p.value.value,
                  uom: p.value.unit === 'time' ? '' : getUomTranslation(t, p.value.uom),
                  unit: p.value.unit,
                  valueType: 'CustomValueType',
                }))),
              })),
            },
          ]}
        />
      </Box>
    ))
    || null
  );
}
