import React, { useContext, useEffect } from 'react';
import ArrowForward from '@material-ui/icons/ArrowForward';
import {
  Breadcrumbs, Link, Typography, withStyles,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { BreadCrumbsContext } from '../Authenticator';

export type StoryType = { path: string; label: string }[];

type PropsIn = {
  story: StoryType;
};

const StyledLink = withStyles(() => ({
  root: {
    fontFamily: 'var(--font-family-alt)',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '175%',
    margin: '0px 4px',
    color: '#00000061',
  },
}))(Link) as typeof Link;

const StyledFirstLink = withStyles(() => ({
  root: {
    fontFamily: 'var(--font-family-alt)',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '175%',
    margin: '0px 4px',
    color: 'var(--secondary-color)',
  },
}))(Link) as typeof Link;

const StyledTypography = withStyles(() => ({
  root: {
    fontFamily: 'var(--font-family-alt)',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '175%',
    margin: '0px 4px',
    color: 'var(--secondary-color)',
  },
}))(Typography) as typeof Typography;

/**
 * Breadcrumbs component
 *
 * @param props
 * @description MUI 4 Breadcrumbs component with custom styling
 */
export default function BreadcrumbsWidget(props: PropsIn) {
  const { story } = props;
  const navigate = useNavigate();
  const { setBreadCrumbs } = useContext(BreadCrumbsContext);

  useEffect(() => {
    setBreadCrumbs(story);
  }, []);

  function preventRefresh(e, path) {
    e.preventDefault();
    navigate(path);
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center" ml="0.8rem">
      <Breadcrumbs separator={<ArrowForward />} aria-label="breadcrumb">
        {story.map((curr, idx) => {
          if (idx === 0) {
            return (
              <StyledFirstLink key={curr.path} href={curr.path} onClick={(e) => preventRefresh(e, curr.path)}>
                {curr.label}
              </StyledFirstLink>
            );
          }
          if (idx === story.length - 1) {
            return <StyledTypography key={curr.path}>{curr.label}</StyledTypography>;
          }
          return (
            <StyledLink key={curr.path} href={curr.path} onClick={(e) => preventRefresh(e, curr.path)}>
              {curr.label}
            </StyledLink>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}
