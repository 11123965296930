export type FetchPolicy =
  | 'cache-first'
  | 'cache-and-network'
  | 'no-cache'
  | 'network-only'
  | 'cache-only'

export type WatchQueryFetchPolicy = FetchPolicy | undefined;

/**
 * Fetch Policy - Cache first
 * @description Da utilizzare per `fetchPolicy` per query di dati che NON cambiano mai. È settata a `cache-first`.
 * @constant
 * @type {string}
 * @example nei parametri `useQuery`
 * useQuery(QUERY, {
 *   fetchPolicy: CACHE_FP_NOT_CHANGING,
 *   nextFetchPolicy: CACHE_NFP_NOT_CHANGING,
 * });
 */
export const CACHE_FP_NOT_CHANGING: WatchQueryFetchPolicy = 'cache-first';

/**
 * Next Fetch Policy - Cache first
 * @description Da utilizzare per `nextFetchPolicy` per query di dati che NON cambiano mai. È settata a `cache-first`.
 * @constant
 * @type {string}
 * @example nei parametri `useQuery`
 * useQuery(QUERY, {
 *   fetchPolicy: CACHE_FP_NOT_CHANGING,
 *   nextFetchPolicy: CACHE_NFP_NOT_CHANGING,
 * });
 */
export const CACHE_NFP_NOT_CHANGING: WatchQueryFetchPolicy = 'cache-first';

/**
 * Fetch Policy - Cache and Network
 * @description Da utilizzare per `fetchPolicy` per query di dati che CAMBIANO. È settata a `cache-and-network`.
 * @constant
 * @type {string}
 * @example nei parametri `useQuery`
 * useQuery(QUERY, {
 *   fetchPolicy: CACHE_FP_CHANGING,
 *   nextFetchPolicy: CACHE_NFP_CHANGING,
 * });
 */
export const CACHE_FP_CHANGING: WatchQueryFetchPolicy = 'cache-and-network';

/**
 * Next Fetch Policy - Cache first
 * @description Da utilizzare per `nextFetchPolicy` per query di dati che non CAMBIANO. È settata a `cache-first`.
 * @constant
 * @type {string}
 * @example nei parametri `useQuery`
 * useQuery(QUERY, {
 *   fetchPolicy: CACHE_FP_CHANGING,
 *   nextFetchPolicy: CACHE_NFP_CHANGING,
 * });
 */
export const CACHE_NFP_CHANGING: WatchQueryFetchPolicy = 'cache-and-network';

export const CACHE_FIRST = {
  fetchPolicy: CACHE_FP_NOT_CHANGING,
  nextFetchPolicy: CACHE_NFP_NOT_CHANGING,
};

export const CACHE_AND_NETWORK = {
  fetchPolicy: CACHE_FP_CHANGING,
  nextFetchPolicy: CACHE_NFP_CHANGING,
};

export const CACHE_AND_NETWORK_CACHE_FIRST = {
  fetchPolicy: CACHE_FP_CHANGING,
  nextFetchPolicy: CACHE_NFP_NOT_CHANGING,
};

export const CACHE_FIRST_CACHE_AND_NETWORK = {
  fetchPolicy: CACHE_FP_NOT_CHANGING,
  nextFetchPolicy: CACHE_NFP_CHANGING,
};
