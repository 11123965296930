/* eslint no-use-before-define: 0 */
// IndexedDB

// @ts-ignore
const indexedDB = window.indexedDB
  // @ts-ignore
  || window.webkitIndexedDB
  // @ts-ignore
  || window.mozIndexedDB
  // @ts-ignore
  || window.OIndexedDB
  // @ts-ignore
  || window.msIndexedDB;

const dbVersion = 1.0;
export const RESET_IMG_CACHE_KEY = 'reset_img_cache';
export const ACTIVATE_RESET_IMAGE_CACHE = 1;

const imagesRequest = indexedDB.open('imageFiles', dbVersion);
let db;
const createImgObjectStore = (dataBase) => {
  dataBase.createObjectStore('exelioImageCache');
};

const getImageFromDB = (src) => new Promise((resolve, reject) => {
  const name = src.split('?')[0];

  const readTransaction = db.transaction(['exelioImageCache'], 'readonly');

  const req = readTransaction.objectStore('exelioImageCache').openCursor(name);
  req.onsuccess = async (e) => {
    const imgFile = e.target.result;
    let imgData;

    if (!imgFile) {
      const controller = new AbortController();
      const { signal } = controller;
      const response = await fetch(src, { signal });
      if (!response.ok) {
        console.error('[ERR] response', response);
      }
      const imgBlob = await response.blob();

      imgData = await new Promise((r) => { const a = new FileReader(); a.onload = r; a.readAsDataURL(imgBlob); })
        .then((d: any) => d.target.result);

      const writeTransaction = await db.transaction(['exelioImageCache'], 'readwrite');
      await writeTransaction.objectStore('exelioImageCache').put(imgData, name);

      await writeTransaction.commit();
      resolve(imgData);
    }

    resolve(imgFile?.value || imgData);
  };
  req.onerror = (err) => reject(err);
});

imagesRequest.onerror = () => {
  console.log('Error creating/accessing IndexedDB database');
};

imagesRequest.onsuccess = async () => {
  console.log('Success creating/accessing IndexedDB database');
  db = imagesRequest.result;

  db.onerror = () => {
    console.log('Error creating/accessing IndexedDB database');
  };
};

// For future use. Currently only in the latest Firefox versions
imagesRequest.onupgradeneeded = (event) => {
  // @ts-ignore
  createImgObjectStore(event.target.result);
};

const getImageFile = async (src) => {
  try {
    if (src && src.indexOf('?') > 0) {
      return await getImageFromDB(src);
    }
    return null;
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    return null;
  }
};

export default getImageFile;

export const resetExelioImageCache = () => new Promise((resolve, reject) => {
  const transaction = db.transaction(['exelioImageCache'], 'readwrite');
  const objectStore = transaction.objectStore('exelioImageCache');

  const clearRequest = objectStore.clear();
  clearRequest.onsuccess = () => {
    console.log('All records have been cleared from exelioImageCache.');
    resolve();
  };

  clearRequest.onerror = (err) => {
    console.error('Failed to clear records from exelioImageCache', err);
    reject(err);
  };
});
