import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Row } from 'components/layout/Row';
import TitleView from 'components/layout/TitleView';
import { StackedColumnChart } from '../charts/StackedColumnChart';
import { IconWrapper } from '../../lib/icons';
import { eventsColorByName } from '../charts/colors';

interface PropsIn {
  data: any // @todo fix
}

export const DivesByTotalLoad = React.memo((props: PropsIn) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    (
      data?.res?.imu?.eventLeftDives && data?.res?.imu?.eventLeftDives?.length > 0
      && data?.res?.imu?.eventRightDives && data?.res?.imu?.eventRightDives?.length > 0
      && (
      <Box className="chart-widget">
        <Row className="imu-kpi-chart-label-count">
          <Box className="title">
            <TitleView
              title={t('track.widgets.mecKPI.labels.divesByTotalLoad', 'dives by total load')}
              prefixIcon={<IconWrapper name="dives" size="medium" color="primary" />}
              align="left"
            />
          </Box>
        </Row>
        <StackedColumnChart
          data={data?.res?.imu}
          categories={[
            t('kpi2.IMUEvents.eventLeftDivesCount.label', 'left dives'),
            t('kpi2.IMUEvents.eventRightDivesCount.label', 'right dives')]}
          colors={eventsColorByName}
          validKeys={['eventLeftDives', 'eventRightDives']}
        />
      </Box>
      ))
    || null
  );
});

export default DivesByTotalLoad;
