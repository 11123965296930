import * as React from 'react';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { SideDrawer } from '../layout/SideDrawer';
import BaseButton from '../form/BaseButton';

interface Item {
  id: number,
  label: string
}

interface ChildProps {
  items: Item[]
  onDeleteItem: (id) => void
}

/**
 * This drawer child component should be treated as a classic "input" component.
 * It receives properties and exposes callbacks.
 * We generally don't use a local state for properties that come from the parent.
 * The child only gets the values from the parent via props and changes them via props.
 * This will keep the component generic and more testable.
 * Example: a list of items from the parent, we can change them from this child component
 * calling `onDeleteItem()` property
 *
 * @param props
 * @constructor
 */
const ChildComponent = (props: ChildProps) => {
  const { items, onDeleteItem } = props;

  return (
    <Paper>
      <Box p={2}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p>This is the child component, let's keep it separate for code cleanup and readability</p>

        <Paper>
          <Box mt={4} p={2}>
            <table>
              <tbody>
                {
                items.map((i) => (
                  <tr key={i.id}>
                    <td>{i.label}</td>
                    <td>
                      <BaseButton
                        color="primary"
                        onClick={() => onDeleteItem(i.id)}
                        buttonSize="sm"
                      >
                        Delete
                      </BaseButton>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};

/**
 * The main component, with the state and the drawer component
 * @constructor
 */
const TemplateSideDrawer = () => {
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  /**
   * The state is in the parent, near where you will do mutations (for example a save mutation)
   */
  const [items, setItems] = React.useState<Item[]>([
    { id: 1, label: 'Primo' },
    { id: 2, label: 'Secondo' },
  ]);

  const handleDelete = (id: number) => {
    setItems((prev) => prev.filter((i) => i.id !== id));
  };

  const handleSave = () => {
    // do mutations
    console.table(items);
    // eslint-disable-next-line no-alert
    alert(`Saved ${items.length} items`);
    // if we want/need we can then close the drawer, empty the state, etc...
    setDrawerOpen(false);
  };

  return (
    <>
      <Paper>
        <Box p={2}>
          This is the main component, it keeps the state and handles events from childs

          <BaseButton onClick={() => setDrawerOpen(true)}>OPEN DRAWER</BaseButton>

          <SideDrawer
            open={drawerOpen}
            setOpen={setDrawerOpen}
            actionsLeft={[
              <BaseButton
                key="close-action" // always add a key, like for `map`
                onClick={() => setDrawerOpen(false)}
              >
                CLOSE DRAWER
              </BaseButton>,
            ]}
            actionsRight={[
              <BaseButton
                key="save-action" // always add a key, like for `map`
                onClick={handleSave}
              >
                SAVE
              </BaseButton>,
            ]}
          >
            <ChildComponent
              items={items}
              onDeleteItem={handleDelete}
            />
          </SideDrawer>
        </Box>
      </Paper>

      <Divider style={{ margin: '1rem' }} />
    </>
  );
};

export default TemplateSideDrawer;
