import React from 'react';
import Alert from '@images/icons/svg/alert.svg';
import BaseButton from 'components/form/BaseButton';

interface WarningButtonProps {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

const WarningButton: React.FC<WarningButtonProps> = ({ onMouseEnter, onMouseLeave, onClick }) => (
  <BaseButton
    className="warning-button"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    isIconButton
    customHoverBgColor="#FFF4E5"
  >
    <Alert className="warning-icon" />
  </BaseButton>
);

export default WarningButton;
