import * as React from 'react';
import { TextField } from 'components/form/TextField';
import { IconButton, InputAdornment } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import EditableIcon from '@images/icons/svg/editable.svg';

interface PropsIn {
  autoCommit?: boolean;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  forceFocus?: boolean;
  id?: string;
  label?: string;
  link?: string;
  loading?: boolean;
  multiline?: boolean;
  name?: string;
  onSave: (value: string) => void;
  onClear?: () => void;
  onKeyDown?: (e) => void;
  onBlur?: () => void;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  isNotesField?: boolean;
}

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      fontFamily: 'var(--font-family-main)',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'var(--font-family-main)',
    },
    '& -webkit-input-placeholder': {
      fontFamily: 'var(--font-family-main)',
    },
    '& label.Mui-focused': {
      color: '#828282',
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'inherit',
    },
  },
});

export default function EditableTextField(props: PropsIn) {
  const classes = useStyles();
  const {
    className,
    defaultValue,
    disabled,
    forceFocus,
    id,
    label,
    link,
    loading,
    multiline,
    name,
    onClear,
    onSave,
    onKeyDown,
    onBlur,
    placeholder,
    required,
    rows,
    autoCommit,
    style,
    isNotesField,
  } = props;

  const [editing, setEditing] = React.useState(false);
  const [changed, setChanged] = React.useState(defaultValue);
  const [showEditInfo, setShowEditInfo] = React.useState(true);
  const ref = React.createRef<HTMLDivElement>();

  const handleSave = () => {
    onSave(changed || '');
    setChanged(defaultValue);
    setEditing(false);
  };

  const handleClear = () => {
    setChanged(defaultValue);
    setEditing(false);
    if (onClear) {
      onClear();
    }
  };

  const handleFocus = () => {
    setEditing(true);
    ref?.current?.focus();
  };

  const handleLinkClick = () => {
    const navigate = useNavigate();
    if (link) {
      navigate(link);
    }
  };

  const handleChange = (value: string) => {
    setChanged(value);

    if (autoCommit) {
      onSave(value || '');
    }
  };

  useEffect(() => {
    setChanged(defaultValue);
  }, [defaultValue]);

  useLayoutEffect(() => {
    if (forceFocus) {
      handleFocus();
    }
  }, []);

  const actionIcons = [];

  if (link) {
    actionIcons.push(<IconButton key="link-icon" onClick={handleLinkClick}><LinkIcon /></IconButton>);
  }

  return (
    <div
      style={{ position: 'relative', width: '100%' }}
      onMouseEnter={() => !isNotesField && setShowEditInfo(false)}
      onMouseLeave={() => setShowEditInfo(true)}
      onFocus={() => isNotesField && setShowEditInfo(false)}
      onBlur={() => isNotesField && setShowEditInfo(true)}
    >
      {
        showEditInfo && changed === defaultValue
          ? (
            <div
              style={{
                position: 'absolute',
                top: '13px',
                right: '45px',
              }}
            >

              <EditableIcon />
            </div>
          )
          : null
      }
      <TextField
        ref={ref}
        style={style}
        className={`${classes.root} editable-text-field ${className}`}
        value={changed}
        disabled={disabled || loading}
        id={id || name}
        label={label}
        minRows={rows}
        fullWidth
        multiline={multiline}
        required={required}
        variant="outlined"
        margin="dense"
        onFocus={handleFocus}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        InputProps={{
          endAdornment: loading
            ? <AccessTimeIcon />
            : autoCommit
              ? null
              : (changed !== defaultValue)
                ? (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSave}><DoneIcon /></IconButton>
                    <IconButton onClick={handleClear}><ClearIcon /></IconButton>
                  </InputAdornment>
                )
                : (!editing)
                  ? <InputAdornment position="end">{actionIcons}</InputAdornment>
                  : (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear}><ClearIcon /></IconButton>
                    </InputAdornment>
                  ),
        }}
      />
    </div>
  );
}
