export const orderKpis = [
  'basic',
  'speed zones',
  '% speed zones',
  'acc zones',
  'dec zones',
  'met',
  'power zones',
  'metabolic power events',
  'mech',
  'locomotion',
  'IMU events',
  'heart rate',
];
