import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import Checkbox from '../form/Checkbox';

function TestCheckbox() {
  return (
    <>
      <Typography variant="h5" gutterBottom>Checkbox</Typography>
      <Paper id="test-checkbox">
        <Box p={2}>
          <Row>
            <Column md={2}><Checkbox label="default" value={false} defaultValue="test" /></Column>
            <Column md={2}><Checkbox label="label" value="test" defaultValue="test" /></Column>
            <Column md={2}><Checkbox label="value" value="test" defaultValue="test" /></Column>
            <Column md={2}><Checkbox label="defaultValue" value="test" defaultValue="test" /></Column>
            <Column md={2}><Checkbox label="disabled" value="test" defaultValue="test" disabled /></Column>
            <Column md={2}><Checkbox label="readonly" value="test" defaultValue="test" readonly /></Column>
            <Column md={2}><Checkbox label="required" value="test" defaultValue="test" required /></Column>
            <Column md={2}><Checkbox label="onClick" value="test" defaultValue="test" onClick={() => alert('Click!')} /></Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestCheckbox;
