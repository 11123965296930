import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Row } from 'components/layout/Row';
import TitleView from 'components/layout/TitleView';
import { StackedColumnChart } from '../charts/StackedColumnChart';
import { IconWrapper } from '../../lib/icons';
import { eventsColorByName } from '../charts/colors';

interface PropsIn {
  data: any;
  jumps: number;
}

export default function JumpsByHeight(props: PropsIn) {
  const { data, jumps } = props;
  const { t } = useTranslation();

  return (
    (data?.res?.imu?.eventsJumps && (
      <Box className="chart-widget">
        <Row className="imu-kpi-chart-label-count">
          <Box className="title">
            <TitleView
              title={t('track.widgets.mecKPI.labels.jumpsByHeight', 'jumps by height')}
              prefixIcon={<IconWrapper name="jumps" size="medium" color="primary" />}
              align="left"
            />
          </Box>
        </Row>
        <StackedColumnChart
          data={data?.res?.imu}
          categories={['jumps']}
          colors={eventsColorByName}
          validKeys={['eventsJumps']}
        />
      </Box>
    ))
    || null
  );
}