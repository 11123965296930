import React, { useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import { DATE_TIME_FORMAT, DATE_FORMAT, SNACKBAR_TIMEOUT_LONGER } from 'components/constants';
import HeaderPopoverExports from 'components/HeaderPopoverExports';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import DownloadIcon from '@images/IconHeader/download.svg';
import { useTranslation } from 'react-i18next';
import { isJsonString } from 'components/utils/utils';
import useWebSocket from 'react-use-websocket';
import MyStatsPopover from 'components/stats/MyStatsPopover';
import { MY_STATS } from 'query/stats';
import StatsIcon from '@images/icons/svg/stats.svg';
import { useLazyQueryCached } from 'components/utils/graphql';
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import TemplateSelector from '../template/TemplateSelector';
import TeamSelector from '../team/TeamSelector';
import Account from '../Account';
import { MeType } from '../../models/me';
import { withMe } from '../MeUserWrapper';
import {
  EXPORT_STATUS, ExportStatusRes, ExportStatusVars, ReportType,
} from '../../query/track';
import HeaderPopover, { PopoverContentType } from './HeaderPopover';
import IconWrapper from './IconWrapper';
import {
  DocumentsContext, LoadingContext, MyStatsContext, WebsocketRoomsContext, WebsocketRoomsState,
} from '../../Authenticator';
import { APP_STATES, AppState, useGlobalState } from '../../lib/global';
import { globalEventsChannel, websocketEventsChannel } from '../../lib/events';
import CLIENT_SETTINGS from '../../lib/client_settings';

const LoadingTopBar = () => <div className="loading-top-bar" />;

const IncomingFiles = withMe((props: {
  reloadReadyFiles: () => Promise<void>
  teamId: string,
  me: MeType,
}) => {
  const content: PopoverContentType[] = [];
  const { messages, setMessages } = useContext(WebsocketRoomsContext);
  const { t } = useTranslation();
  const { me } = props;

  const bgIconsKeys = [
    'ws.athletesessionsStatus',
    'ws.incomingStatus',
    'ws.outcomingLoadedStatus',
    'ws.profilesStatus',
    'ws.statsStatus',
    'ws.aspPoints',
  ];

  const filterDataMessages = (m) => isJsonString(m.data);
  const messageKeys = [
    'ws.athletesessionsStatus',
    'ws.incomingStatus',
    'ws.outcomingLoadedStatus',
    'ws.profilesStatus',
    'ws.statsStatus',
    'ws.statssessionRow',
    'ws.aspPoints',
    'ws.aspProcessed',
    'ws.mppProcessed',
    'ws.trackUpdateStatus',
    'ws.installationAlert',
  ];
  const triggerMessageKeys = ['ws.outcoming'];
  const jwtToken = localStorage.getItem('exelio_token');

  const { sendJsonMessage } = useWebSocket(
    `${CLIENT_SETTINGS.public.gpexeWssUrl}team/${me.lastTeam?.id}/`,
    {
      share: true,
      filter: filterDataMessages,
      onOpen: () => {
        sendJsonMessage({ name: 'login', token: jwtToken });
      },
      onMessage: (message) => {
        const dataJson = isJsonString(message.data) ? JSON.parse(message.data) as WebsocketRoomsState : false;
        if (dataJson && dataJson.msg && triggerMessageKeys.includes(dataJson.msg)) {
          websocketEventsChannel.emit('onFileReady');
        } else if (
          dataJson && dataJson.msg
          && messageKeys.includes(dataJson.msg)
        ) {
          const key = dataJson.msg;
          delete dataJson.msg;

          setMessages((prev: {[k: string]: WebsocketRoomsState}) => ({
            ...prev,
            ...{
              [key]: {
                ...dataJson,
                ...{
                  label: t(key, key),
                },
              } as WebsocketRoomsState,
            },
          }));
        }
      },
      onError: (err) => { console.error('WS onError', err); },
      onClose: (err) => { console.error('WS onClose', err); },
      onReconnectStop: (err) => { console.error('WS onReconnectStop', err); },
      retryOnError: true,
      reconnectAttempts: 999,
      reconnectInterval: 3000,
      shouldReconnect: () => true,
    },
  );

  const count = Object.keys(messages).reduce((acc, curr) => {
    const value = bgIconsKeys.includes(curr) ? messages[curr].count : 0;
    if (value > 0 && bgIconsKeys.includes(curr)) {
      content.push({
        value: String(value),
        prefix: <span className="popover-col__l">{t(curr, curr)}</span>,
        label: <span className="popover-col__r">{value}</span>,
        className: 'readonly-button',
      });
    }

    return acc + value;
  }, 0);

  return (
    <HeaderPopover
      buttonId=""
      icon={(
        <IconWrapper rotate={count > 0}>
          <TimelapseIcon />
        </IconWrapper>
      )}
      tooltip={count > 0 ? undefined : t('ws.noBackgroundActivities', 'no background activities')}
      badge={count}
      popoverContent={count > 0 ? content : undefined}
      popoverTitle={t('ws.backgroundActivities', 'background activities')}
      disabled={!(count > 0)}
      active={count > 0}
      hoverOnly
    />
  );
});

interface PropsIn {
  me: MeType,
  leftButtons?: React.ReactNode[]
  rightButtons?: React.ReactNode[]
  hideDefaultButtons?: boolean
}
/**
 * Header component
 * @param props
 *
 * @example
 *
       <Header
        leftButtons={[
          <div>
            <BaseButton onClick={() => exampleAction()} startIcon={<EditIcon />}>
              {t('header.action.edit', 'edit')}
            </BaseButton>
            <BaseButton startIcon={<DeleteIcon />}>
              {t('header.action.delete', 'delete')}
            </BaseButton>
          </div>,
        ]}
      />
 *
 * Valid buttons:
 * <BaseButton startIcon={<ArrowBackIcon />}>medium</BaseButton>
 * <BaseButton startIcon={<img src={IconTeam} />}>Etichetta lunga lunga</BaseButton>
 *
 * <BaseButton isIconButton={true} disabled><TimelapseIcon /></BaseButton>
 * <BaseButton isIconButton={true}><img src={IconFileSettings} /></BaseButton>
 * <BaseButton isIconButton={true}><PersonIcon /></BaseButton>
 */
function Header(props: PropsIn) {
  const [appState] = useGlobalState(AppState);
  const { messages, setMessages } = useContext(WebsocketRoomsContext);
  const { isLoading } = useContext(LoadingContext);
  const { refetchDocumentsStatus, setRefetchDocumentsStatus } = useContext(DocumentsContext);
  const { myStats, setMyStats } = useContext(MyStatsContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    hideDefaultButtons, leftButtons, rightButtons, me,
  } = props;

  const teamId = me?.lastTeam?.id || '';
  const isClubLocked = me?.lastTeam?.club?.locked;

  const userInfo = {
    lastName: me?.lastName,
    firstName: me?.firstName,
    email: me?.email,
    userLanguage: me?.preferredLanguage,
    userroleSet: me?.limitedUserroleSet,
  };

  const [loadReadyExports, { data, refetch: refetchDocuments }] = useLazyQueryCached<ExportStatusRes, ExportStatusVars>(EXPORT_STATUS, {
    variables: {
      teamId: me.lastTeam?.id || '',
      reportsLimit: 99,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetchDocuments();
  }, [refetchDocumentsStatus]);

  const {
    data: myStatsData, refetch: refetchMyStats,
  } = useQuery(MY_STATS, {
    variables: {
      teamId: me.lastTeam?.id || '',
      statsLimit: 10,
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setMyStats((prev) => ({ ...prev, statsSessionsData: data.res.myStats }));
    },
  });

  const statsMessages = messages['ws.statssessionRow'];

  useEffect(() => {
    refetchMyStats();
  }, [myStats?.refetchMyStatsStatus]);

  useEffect(() => {
    if (statsMessages) {
      enqueueSnackbar(
        t('stats.dialog.statsReady', 'stats ready! Check your stats pages'),
        {
          variant: 'success',
          autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
        },
      );
    }
  }, [statsMessages]);

  const handleDownload = (file:ReportType) => {
    if (file.storedOnS3) {
      window.open(file.url, '_blank');
    } else {
      const localPath = process.env.GPEXE_BACKEND_URL;
      window.open(localPath + file.url.slice(1), '_blank');
    }
  };

  useEffect(() => {
    const unsubscribeOnFileReady = websocketEventsChannel.on('onFileReady', () => {
      loadReadyExports();
    });

    loadReadyExports();

    return () => {
      unsubscribeOnFileReady();
    };
  }, []);

  useEffect(() => {
    const unsubscribeTeamChange = globalEventsChannel.on('onBeforeTeamChange', (teamId) => {
      // setMessages({});

      setMessages((prevState) => {
        const newState = { ...prevState };

        for (const key of Object.keys(prevState)) {
          if (prevState[key].room === 'team') {
            delete newState[key];
          }
        }

        return newState;
      });

      useWebSocket(
        `${CLIENT_SETTINGS.public.gpexeWssUrl}team/${me.lastTeam?.id}/`,
        {
          share: true,
        },
        false,
      );
    });

    return () => {
      unsubscribeTeamChange();
    };
  }, []);

  return (
    <Box
      boxShadow={3}
      pt={1}
      pr={2}
      display="flex"
      justifyContent="space-between"
      bgcolor="primary.contrast"
      style={isClubLocked ? { width: 'calc(100% + 235px)', position: 'relative', left: '-235px' } : {}}
    >
      <Box display="flex" justifyContent="space-between" pb={1}>
        {!(appState !== APP_STATES.normal) && leftButtons }
      </Box>
      <Box display="flex" justifyContent="space-between" pb={1}>
        {!(appState !== APP_STATES.normal) && rightButtons}
        {!hideDefaultButtons && (
        <>
          {
            !(appState !== APP_STATES.normal)
            && (
            <>
              <IncomingFiles
                teamId={teamId}
                reloadReadyFiles={loadReadyExports}
              />
              <MyStatsPopover
                position="right"
                icon={<StatsIcon />}
                disabled={!myStatsData?.res?.myStatsCount}
                count={myStatsData?.res?.myStatsCount}
                popoverContent={myStatsData?.res?.myStats
                && myStatsData?.res?.myStats?.map((s, i) => ({
                  value: '',
                  prefix: `${format(new Date(s.statsSessionDate), `${DATE_FORMAT}`)}`,
                  label: s.statsSessionName,
                  key: `${s.statsSessionName}-${i}`,
                  onClick: () => {
                    const stat = JSON.parse(localStorage.getItem('s.statsSessionType')) || {};
                      const updatedStat = {
                        ...stat,
                        [teamId]: {
                          ...stat[teamId],
                          statsSessionID: s?.statsSessionId,
                          statsSessionRowsID:  s?.statsSessionRowsIds,
                          loading: false,
                          newLoading: false,
                          addLoading: false,
                          ready: true,
                          oldStatsData: !stat[teamId] ? false : stat[teamId].oldStatsData,
                        },
                      };
                    localStorage.setItem(s.statsSessionType, JSON.stringify(updatedStat));
                    setMyStats(() => ({
                      statsSessionId: s?.statsSessionId,
                      statsSessionRowsIds: s?.statsSessionRowsIds,
                      statsSessionType: s?.statsSessionType,
                      statsSessionsData: myStatsData?.res?.myStats,
                    }));
                    setTimeout(() => navigate(`/${s.statsSessionType}`), 500);
                  }
                  ,
                }))
                || undefined}
              />
              <HeaderPopoverExports
                position="right"
                icon={<DownloadIcon />}
                // badge={data?.statusQuery?.reports?.processed?.length}
                disabled={!data?.statusQuery?.reports?.processed?.length}
                total={data?.statusQuery?.reports?.processed?.length || 0}
                popoverContent={data?.statusQuery?.reports?.processed && [
                  ...data.statusQuery.reports.processed.slice(0, 9).map((f, i) => ({
                    value: f.url,
                    prefix: `${format(new Date(f.processedDate), `${DATE_TIME_FORMAT}`)}`,
                    label: `${f.name}`,
                    key: `${f.name}-${i}`,
                    onClick: () => handleDownload(f),
                  })),
                ] || undefined}
                setRefetchDocumentsStatus={setRefetchDocumentsStatus}
              />
              <TemplateSelector teamId={teamId} templateId={me?.template?.id || ''} />
              <TeamSelector />
            </>
            )
          }
          <Account
            disableMyStats={!myStatsData?.res?.myStatsCount}
            disableMyDocuments={!data?.statusQuery?.reports?.processed?.length}
            me={me}
            myUnits={me.userunits}
            userInfo={userInfo}
          />
        </>
        )}
      </Box>
      {isLoading && <LoadingTopBar />}
    </Box>
  );
}
// @todo optimize render
export default withMe(Header);
