import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';
import BaseButton from 'components/form/BaseButton';
import { Row } from 'components/layout/Row';
import { Column } from 'components/layout/Column';
import Modal from 'components/form/Modal';
import CancelIcon from '@images/IconHeader/cancel.svg';
import { useQuery } from '@apollo/client';
import { BridgeQuery } from '../../query/bridge';

export default function BridgeWidget() {
  const { loading, error, data } = useQuery(BridgeQuery, {
    fetchPolicy: 'no-cache',
  });
  const { t } = useTranslation();
  function getRequirements(version: string) {
    const flatVersion = version.split('.').join('');
    if (parseInt(flatVersion) > 840) {
      return {
        WIN: t('bridge.dialog.windowsArchitecture', 'os WIN 10 - 64 bit architecture, administrator privileges'),
        MAC: t('bridge.dialog.macArchitectureAfter11', 'os version 11 and above'),
      };
    }
    return {
      WIN: t('bridge.dialog.windowsArchitecture', 'os WIN 10 - 64 bit architecture, administrator privileges'),
      MAC: t('bridge.dialog.macArchitecture', 'os version > 10.13'),
    };
  }
  const [open, setOpen] = useState(false);

  function bridgeOrder(arr) {
    const macs = [];
    const wins = [];
    arr.forEach((item) => {
      if (item.opSystem === 'MAC') {
        macs.push(item);
      } else if (item.opSystem === 'WIN') {
        wins.push(item);
      }
    });
    const orderedArr = [];
    const maxLength = Math.max(macs.length, wins.length);
    // alternate 'mac' and 'win' versions
    for (let i = 0; i < maxLength; i++) {
      if (i < macs.length) {
        orderedArr.push(macs[i]);
      }
      if (i < wins.length) {
        orderedArr.push(wins[i]);
      }
    }
    return orderedArr;
  }

  const orderedData = data?.res ? bridgeOrder(data?.res) : [];

  const handleDownload = (downloadUrl: string) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = downloadUrl;
    link.style.display = 'none'; // hide URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <BaseButton
        startIcon={<GetAppIcon />}
        onClick={() => setOpen(true)}
      >
        bridge
      </BaseButton>
      <Modal
        buttonActionClassName="button-action-svg-fill-white"
        title={t('dialog.title.download', 'download')}
        buttonColor="secondary"
        buttonAction={t('drawer.actions.close', 'close')}
        onClose={() => setOpen(false)}
        visible={open}
        onClick={() => setOpen(false)}
        noCancel
        icon={<CancelIcon />}
      >
        <Box m="2">
          <Row>
            {
          loading
            ? t('bridge.dialog.loading')
            : error
              ? t('bridge.dialog.error')
              : orderedData.map((item) => (
                <Column xs={6} key={item.opSystem + item.version} className="bridge-widget-container">
                  <Box
                    className="bridge-widget-download"
                    key={item.version + item.opSystem}
                    my={1}
                  >
                    <Box component="span" mb={1}>
                      <span className="bridge-widget-label">ver</span>
                      <span className="bridge-widget-version">{item.version}</span>
                      <span>&nbsp;for:&nbsp;</span>
                      <span className="bridge-widget-version">{item.system.join(' ')}</span>
                    </Box>
                    <BaseButton
                      onClick={() => handleDownload(item.downloadUrl)}
                      noMargins
                      startIcon={<GetAppIcon />}
                    >
                      {`${t('bridge.dialog.downloadFor', 'download for')} ${item.opSystem}`}
                    </BaseButton>
                    <Box className="bridge-widget-requirements">
                      {t('bridge.dialog.minRequirements', 'Minimal requirements')}
                      :
                      <br />
                      {getRequirements(item.version)[item.opSystem]}
                    </Box>
                  </Box>
                </Column>
              ))
        }
          </Row>
        </Box>
      </Modal>
    </>
  );
}
