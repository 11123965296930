import { useMutation, useQuery } from '@apollo/client';
import { Loader } from 'lib/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { SET_USER_TEAM_TEMPLATE } from 'mutation/template';
import { ME } from 'query/team';
import { TEMPLATES } from 'query/template';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';
import { Box, Popover } from '@material-ui/core';
import TemplatesSvg from 'img/IconHeader/template.svg';
import { useSnackbar } from 'notistack';
import BaseButton from '../form/BaseButton';
import { ThemeOptionsContext } from '../../Authenticator';

type CmpProps = {
  templateId: string,
  teamId: string,
}

function ListTemplates(props: CmpProps & {
  onSelected: () => void,
}) {
  const {
    teamId,
    templateId,
    onSelected,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { themeOptions, setThemeOptions } = useContext(ThemeOptionsContext);
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(TEMPLATES, {
    variables: {
      ...(!teamId ? {} : { teamId }), withFields: false,
    },
    fetchPolicy: 'no-cache',
    skip: !teamId || !templateId,
  });
  const [setTemplate, { client }] = useMutation(SET_USER_TEAM_TEMPLATE, {
    onError: (error1) => enqueueSnackbar(
      error1,
      {
        variant: 'error',
        autoHideDuration: 10000,
      },
    ),
    onCompleted: (data1) => {
      const cacheres = client.readQuery({ query: ME });

      client.writeQuery({
        query: ME,
        data: {
          ...cacheres,
          res: data1.res,
        },
      });
      onSelected();
    },
  });

  const handleSetTemplate = (tmplId: string) => () => {
    setThemeOptions({
      ...themeOptions,
      ...{ 
        isTemplateSelectorActive: false,
      },
    });

    return setTemplate({
      variables: {
        teamId,
        templateId: tmplId,
      },
    });
  };

  if (error) return <div>error 😅</div>;

  const currentTemplate = data?.res?.content?.find((tmpl) => tmpl.id === templateId) || { id: '' };

  return (
    <Box className="template-selector-poppover poppoverMaxHeight">
      <List component="nav" className="template-list">
        {loading ? <Loader /> : (data?.res.content || [])
          .filter((tmpl) => tmpl.id === currentTemplate.id)
          .concat(data?.res.content.filter((tmpl) => tmpl.id !== currentTemplate.id)
            .sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)))
          .map((tmpl) => (
            <ListItem
              key={tmpl.id}
              button
              onClick={handleSetTemplate(tmpl.id)}
              className="template-name"
            >
              {currentTemplate.id === tmpl.id && (
              <>
                <CheckCircleIcon color="secondary" style={{ marginLeft: '-4px' }} />
&nbsp;
              </>
              )}
              {t(`templates.labels.${tmpl.name.toLowerCase()}`, `${tmpl.name.toLowerCase()}`)}
            </ListItem>
          ))}
      </List>
    </Box>
  );
}

function TemplateSelector(props: CmpProps) {
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const { themeOptions, setThemeOptions } = useContext(ThemeOptionsContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTimeout(() => setThemeOptions({
      ...themeOptions,
      ...{
        isTemplateSelectorActive: true,
      },
    }), 5000); // RELOAD_DELAY
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'template-selector' : undefined;
  const { t } = useTranslation();

  return (
    <>
      <BaseButton
        tooltipText={t('tooltip.template', 'template')}
        aria-describedby={id}
        onClick={handleClick}
        isIconButton
        disabled={!themeOptions.isTemplateSelectorActive}
      >
        <TemplatesSvg />
      </BaseButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ListTemplates
          onSelected={handleClose}
          {...props}
        />
      </Popover>
    </>
  );
}
export default TemplateSelector;
