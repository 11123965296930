import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface PropsIn<T> {
  disabled?: boolean
  label?: string
  name?: string
  onChange?: (v: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: any
  options: {
    value: T,
    label: string,
  }[]
  readonly?: boolean
  required?: boolean
  style?: React.CSSProperties
  type: 'radio' | 'checkbox'
  value?: T
  defaultValue?: RadioGroupProps['defaultValue'],
  disabledCheckbox?: boolean,
  className?: string,
}

const useStyles = makeStyles(() => createStyles({
  outlined: {
    minHeight: '41px',
    padding: '5px 14px',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderRadius: 4,
    '&:hover': {
      borderColor: 'var(--primary-color)',
    },
    color: 'var(--text-secondary)',
  },
  outlinedDisabled: {
    minHeight: '41px',
    padding: '5px 14px',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderRadius: 4,
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    color: 'var(--text-secondary)',

  },
  outlinedReadonly: {
    minHeight: '41px',
    padding: '5px 14px 4px 14px',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderRadius: 4,
    '&:hover': {
      cursor: 'default !important',
    },
    '& span': {
      cursor: 'default',
    },
    '& label': {
      cursor: 'default',
    },
    color: 'var(--text-secondary)',
  },
}));

export function OutlinedWrapper({
  children, disabled, label, readonly, style, className, padding,
}: {
  children: React.ReactElement,
  disabled?: boolean,
  label?: string,
  readonly?: boolean,
  style?: React.CSSProperties,
  className?: string,
  padding?: string
}) {
  const classes = useStyles();
  return (
    <Box className={`outlined-wrapper ${!label || label === '' ? 'no-label' : ''} ${className || ''}`} style={style}>
      <FormControl
        disabled={disabled}
        component="fieldset"
        className={`${disabled ? classes.outlinedDisabled : readonly ? classes.outlinedReadonly : classes.outlined}`}
        style={{ width: '100%', marginTop: -6, padding }}
      >
        {
          label
          && (
          <FormLabel
            aria-readonly={readonly}
            component="legend"
            style={{
              fontFamily: 'var(--font-family-main)', fontSize: '12px', padding: '0px 5px', marginLeft: -5,
            }}
          >
            {label}
          </FormLabel>
          )
        }
        {children}
      </FormControl>
    </Box>
  );
}

export function FieldsGroup<T,
  >(props: PropsIn<T>) {
  const {
    disabled, label, name, onChange, options, required, type, value, defaultValue, onClick, style, readonly,
    disabledCheckbox, className,
  } = props;

  return (
    <OutlinedWrapper disabled={disabled} label={label} readonly={readonly} style={style} className={className}>
      <RadioGroup
        aria-labelledby={name || 'radio-buttons-group-label'}
        value={value}
        defaultValue={defaultValue || undefined}
        name={name || 'radio-buttons-group'}
        row
        aria-readonly={readonly}
        onChange={onChange}
        onClick={onClick}
      >
        {
        options?.map((option, i) => (
          <FormControlLabel
            key={`${option.value}-${i}`}
            value={option.value}
            aria-readonly={readonly}
            control={type === 'radio'
              ? (
                <Radio
                  required={required}
                  readOnly={readonly}
                />
              )
              : (
                <Checkbox
                  required={required}
                  readOnly={readonly}
                  checked={!!option.value}
                  disabled={disabledCheckbox}
                />
              )}
            label={option.label}
          />
        ))
      }
      </RadioGroup>
    </OutlinedWrapper>
  );
}
