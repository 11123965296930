/**
 * Classe per capire se siamo alla prima apertura di un link dentro l'app
 *
 * Ci serve per differenziare l'evento di mount generico dell'app da un'apertura
 * iniziale dell'app tramite link.
 *
 * Caso d'uso: caricare i dati di un componente dai query params solo alla prima
 * apertura dell'applicazione.
 */
class IsFirstRender {
  private isFirst: boolean;

  constructor() {
    this.isFirst = true;
  }

  isFirstTime() {
    if (this.isFirst) {
      this.isFirst = false;
      return true;
    }

    return false;
  }
}

const FirstTimeRender = new IsFirstRender();

export default FirstTimeRender;
