import { gql } from '@apollo/client'

export type TableConfig = 'TRACK' | 'TRACKS' | 'ATHLETESESSION' | 'ATHLETESESSIONS' | 'TEAMSESSION' | 'TEAMSESSIONS'

export type ColumnType = {
  id: string
  uom: string
}

export type TableConfigVars = {
  context: TableConfig,
  templateId: number,
}

export type TableConfigRes = {
  res: {
    headers: {
      fixed: ColumnType[],
      var: ColumnType[]
    },
  },
}

export const TABLE_CONFIG = gql`
    query TableConfig(
        $context: TableConfig!,
        $templateId: ID!
    ) {
        res: tableConfig(templateId: $templateId, context: $context) {
            headers {
                fixed {
                    id
                    uom
                }
                var {
                    id
                    uom
                }
            }
        }
    }

`
