import { Box, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import ColorPicker from '../form/ColorPicker';

function TestColorPicker() {
  return (
    <>
      <Paper>
        <Box p={2} mt={4}>
          <Typography variant="h5" gutterBottom>ColorPicker</Typography>
          <Row>
            <Column md={2}>
              Default
              <ColorPicker
                onChange={console.log}
                onClose={() => console.info('closed')}
              />
            </Column>
            <Column md={2}>
              Custom Color
              <ColorPicker
                onChange={console.log}
                onClose={() => console.info('closed')}
                defaultColor="#ffaacc"
              />
            </Column>
            <Column md={2}>
              Presets
              <ColorPicker
                onChange={console.log}
                onClose={() => console.info('closed')}
                showDefaultColors
              />
            </Column>
            <Column md={2}>
              Presets (no gradient)
              <ColorPicker
                onChange={console.log}
                onClose={() => console.info('closed')}
                showDefaultColors
                disableGradientPicker
              />
            </Column>
            <Column md={2}>
              Readonly
              <ColorPicker
                readonly
              />
            </Column>
            <Column md={2}>
              Disabled
              <ColorPicker
                disabled
              />
            </Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '1rem' }} />
    </>
  );
}

export default TestColorPicker;
