import { useEffect, useState } from 'react';

/**
 * Stato globale dell'applicazione
 */
export const APP_STATES = {
  /** 0: normal, tutto regolare */
  normal: 0,
  /** 1: dopo fase di maintenance (2), periodo di transizione verso normal (0). Utilizzato per via dei load balance e per eventuali pod in ripristino */
  restoration: 1,
  /** 2: maintenance, il backend sta inviando risposte 503, il sistema è in manutenzione */
  maintenance: 2,
  /** 3: down, errori di connessione al BE, retry */
  down: 3,
} as const;

function GlobalState(initialValue) {
  this.value = initialValue;
  this.subscribers = [];

  this.getValue = function () {
    return this.value;
  };

  this.setValue = function (newState) {
    if (this.getValue() === newState) {
      return;
    }

    this.value = newState;
    this.subscribers.forEach((subscriber) => {
      subscriber(this.value);
    });
  };

  this.subscribe = function (itemToSubscribe) {
    if (this.subscribers.indexOf(itemToSubscribe) > -1) {
      return;
    }
    this.subscribers.push(itemToSubscribe);
  };

  this.unsubscribe = function (itemToUnsubscribe) {
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber !== itemToUnsubscribe,
    );
  };
}

export const AppState = new GlobalState(0);
export const useGlobalState = (globalState) => {
  const [, setState] = useState<object | undefined>();
  const state = globalState.getValue();

  function reRender() {
    setState({});
  }

  useEffect(() => {
    globalState.subscribe(reRender);

    return () => {
      globalState.unsubscribe(reRender);
    };
  });

  function setNewState(newState) {
    globalState.setValue(newState);
  }

  return [state, setNewState];
};
