import { gql } from '@apollo/client';
import { GroundType } from 'models/ground';
import { GroundSetType } from '../models/groundSet';

export type GroundsByClubVars = {
  clubId?: string;
};

export type GroundsRes = {
  res: {
    content: GroundSetType[];
    count: number;
  };
};

export type GroundVars = {
  id: string;
};

export type GroundRes = {
  res: GroundType;
};

export const GROUNDS_BY_CLUB = gql`
  query GroundsByClub($clubId: ID!) {
    res: grounds(clubId: $clubId) {
      content {
        id
        name
      }
      count
    }
  }
`;

export const GROUND_DETAILS = gql`
  query GroundDetails($id: ID!) {
    res: ground(id: $id) {
      id
      name
      groundType
      groundCoordsType
      groundSurface
      groundSurfaceColor
      vertexAX { unit, value, uom}
      vertexAY { unit, value, uom}
      vertexBX { unit, value, uom}
      vertexBY { unit, value, uom}
      vertexCX { unit, value, uom}
      vertexCY { unit, value, uom}
      vertexDX { unit, value, uom}
      vertexDY { unit, value, uom}
      vertexALatitude
      vertexALongitude
      vertexBLatitude
      vertexBLongitude
      vertexCLatitude
      vertexCLongitude
      vertexDLatitude
      vertexDLongitude
      offset
      hasTracks
    }
  }
`;

export const GROUND_EXISTS = gql`
    query groundExists(
        $clubId: ID!,
    ) {
        res: groundExists(
            clubId: $clubId,
        )  
    }
`;