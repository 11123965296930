/**
 * Formatta i numeri con migliaia (k), milioni (M) ecc
 *
 * @param num
 * @param digits
 */
export const nFormatter = (num: number | string, digits: number) => {
  const n = typeof num === 'string' ? parseFloat(num) : num;

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((i) => Math.abs(n) >= i.value);

  return item ? (n / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : Math.abs(n);
};

export const toNumber = (val: string | number) => (typeof val === 'number' ? val : parseFloat(val));
