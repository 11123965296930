import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import ListField from '../form/ListField';

function TestListField() {
  return (
    <>
      <Typography variant="h5" gutterBottom>ListField.tsx</Typography>
      <Paper>
        <ListField
          rows={[
            {
              id: '1',
              title: 'ListField 1',
              component: <div>ListField 1 Content</div>,
            },
            {
              id: '2',
              title: 'ListField 2',
              component: <div>ListField 2 Content</div>,
            },
            {
              id: '3',
              title: 'ListField 3',
              component: <div>ListField 3 Content</div>,
            },
          ]}
          onRowDelete={(r) => alert(`Deleted ${r}`)}
          confirmDelete
          lockFirst
        />
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestListField;
