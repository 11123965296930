import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import { OptionType, SelectField } from '../form/SelectField';

const players = [
  { id: '1', value: 'Mario Rossi' },
  { id: '2', value: 'Pino Verdi' },
  { id: '3', value: 'Angelo Bianchi' },
  { id: '4', value: 'Tramonti Arancio' },
  { id: '5', value: 'Alba Rosa' },
];

function TestSelectField() {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState<string>('');
  const [valueAutocomplete, setValueAutocomplete] = useState<OptionType[] | undefined>(undefined);
  const handleChange = (d) => {
    enqueueSnackbar(`Selected option: ${d.target.value}`);
    setValue(d);
  };
  const handleChangeAutocomplete = (d) => {
    enqueueSnackbar(`Selected option: ${d.target.value}`);
    setValueAutocomplete(d);
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>SelectField</Typography>
      <Paper id="test-editable-select-field">
        <Box p={2}>
          <Row>
            <Column xs={6} lg={3}>
              <SelectField
                label="default"
                onChange={handleChange}
                options={players}
                value={value}
              />
            </Column>
            <Column xs={6} lg={3}>
              <SelectField
                label="disabled"
                onChange={handleChange}
                options={players}
                value={value}
                disabled
              />
            </Column>
            <Column xs={6} lg={3}>
              <SelectField
                label="readonly"
                onChange={handleChange}
                options={players}
                value={value}
                readonly
              />
            </Column>
            <Column xs={6} lg={3}>
              <SelectField
                label="selected"
                onChange={handleChange}
                options={players}
                value="Tramonti Arancio"
                readonly
              />
            </Column>

            <Column xs={6} lg={3}>
              <SelectField
                autocomplete
                id="autocomplete-default"
                label="autocomplete default"
                onChange={handleChangeAutocomplete}
                options={players}
                value={valueAutocomplete}
              />
            </Column>
            <Column xs={6} lg={3}>
              <SelectField
                autocomplete
                id="autocomplete-disabled"
                label="autocomplete disabled"
                onChange={handleChangeAutocomplete}
                options={players}
                value={valueAutocomplete}
                disabled
              />
            </Column>
            <Column xs={6} lg={3}>
              <SelectField
                autocomplete
                id="autocomplete-readonly"
                label="autocomplete readonly"
                onChange={handleChangeAutocomplete}
                options={players}
                value={valueAutocomplete}
                readonly
              />
            </Column>
            <Column xs={6} lg={3}>
              <SelectField
                autocomplete
                id="autocomplete-selected"
                label="autocomplete selected"
                onChange={handleChangeAutocomplete}
                options={players}
                value={[{ id: '4', value: 'Tramonti Arancio' }]}
              />
            </Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestSelectField;
