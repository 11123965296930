import { Box, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode, useEffect, useState } from 'react';
import LinkIcon from '@material-ui/icons/Link';
import { formatterChoice, UnitsType, UomType } from '../utils/unitsFormatter';

type PropsIn = {
  style?: object;
  text: string;
  measure?: string | number;
  unit?: string | UnitsType;
  uom?: string | UomType;
  icon?: React.ReactElement;
  alignItems?: 'flex-start' | 'center' | 'flex-end';
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  classNameWrapper?: string;
  classNameElement?: string;
  animationDuration?: number;
  isNumAverage?: boolean;
  isLink?: boolean;
  overAllColor?: string;
  underlineText?: boolean;
  gap?: string;
  isEventCounter?: boolean;
  tooltipTitle?: ReactNode;
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
};

const useTooltipStyles = makeStyles((theme) => ({
  options: {
    fontFamily: 'Montserrat, sans-seriff',
    maxWidth: 300,
  },
}));

export default function LabelWithMeasure(props: PropsIn) {
  const {
    style, text, measure, unit, uom, icon,
    alignItems, justifyContent, classNameWrapper,
    classNameElement, animationDuration, isNumAverage,
    isLink, overAllColor, underlineText, gap,
    isEventCounter, tooltipTitle, tooltipPlacement,
  } = props;

  const [count, setCount] = useState(measure || 0);
  const tooltipClasses = useTooltipStyles();

  useEffect(() => {
    let timer;

    if (animationDuration && typeof measure === 'number') {
      let start = 0;
      const intervals = animationDuration * 25;
      const end = measure;
      if (start >= end) return;

      const incrementTime = Math.floor(1000 / intervals);
      const increment = end / intervals;

      timer = setInterval(() => {
        start += increment;
        setCount(parseInt(start, 10));
        if (start >= end) clearInterval(timer);
      }, incrementTime);
    } else if (measure) {
      setCount(measure);
    }

    return () => {
      clearInterval(timer);
    };
  }, [measure, animationDuration]);

  return (
    <Box
      className={`label-with-measure ${classNameWrapper} ${isLink ? 'label-has-link' : ''}`}
      style={style || { paddingTop: '28px' }}
      display="flex"
      flexDirection="column"
      alignItems={alignItems || 'center'}
      justifyContent={justifyContent || 'center'}
    >
      <Tooltip
        title={tooltipTitle}
        placement={tooltipPlacement || 'top'}
        arrow
        classes={{ tooltip: tooltipClasses.options }}
        disableHoverListener={!isEventCounter}
        disableFocusListener={!isEventCounter}
        disableTouchListener={!isEventCounter}
      >
        <Box className={classNameElement} display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ gap: gap || '' }}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ gap: gap || '' }}>
            <Box height="30px">{icon}</Box>
            <Typography
              style={{
                fontFamily: 'var(--font-family-alt)',
                fontSize: '18px',
                fontWeight: 700,
                color: overAllColor,
                textDecoration: underlineText ? 'underline' : 'none',
              }}
            >
              {text}
              {isLink && <LinkIcon />}
            </Typography>
          </Box>
          {![null, undefined].includes(measure) ? (
            <Typography
              style={{
                fontFamily: 'var(--font-family-alt)', fontSize: '24px', fontWeight: 500, lineHeight: '1.2', color: overAllColor,
              }}
            >
              {typeof measure === 'number'
                ? formatterChoice(unit, isNumAverage && 'number_avg')(uom, count)
                : measure}
              <Box
                component="span"
                sx={{
                  display: 'inline',
                  fontFamily: 'var(--font-family-alt)',
                  fontSize: '18px',
                  fontWeight: 400,
                }}
              >
                {' '}
                {uom}
              </Box>
            </Typography>
          ) : (
            '-'
          )}
        </Box>
      </Tooltip>
    </Box>
  );
}
