type changeUIStatusProps =
  | { status: true }
  | { status: false; message: string }

export const changeUIStatus = (props: changeUIStatusProps): void => {
  const wrapperNode = document.getElementById('main-content-wrapper');

  if (!wrapperNode) {
    return;
  }

  if (!props.status) {
    wrapperNode.dataset.message = props.message;
    document.body.classList.add('show-ui-message');
  } else {
    document.body.classList.remove('show-ui-message');
    delete wrapperNode.dataset.message;
  }
};
