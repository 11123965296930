import { GridCellParams } from '@material-ui/x-grid';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import BaseTable from '../Table';
import { SelectField } from '../form/SelectField';
import { TextField } from '../form/TextField';

const players = [
  { id: 1, name: 'Mario Rossi' },
  { id: 2, name: 'Pino Verdi' },
  { id: 3, name: 'Angelo Bianchi' },
  { id: 4, name: 'Tramonti Arancio' },
  { id: 5, name: 'Alba Rosa' },
];

function TestEditableTable() {
  const tabelProps = {
    columns: [
      {
        field: 'id',
      },
      {
        field: 'name',
        width: 200,
      },
      {
        field: 'textField1',
        headerName: 'text field 1',
        flex: 1,
        renderCell: (params: GridCellParams) => <TextField placeholder={`value for 1-${params.id}`} />,
      },
      {
        field: 'textField2',
        headerName: 'text field 2',
        flex: 1,
        renderCell: (params: GridCellParams) => <TextField placeholder={`value for 2-${params.id}`} />,
      },
      {
        field: 'dropdown',
        headerName: 'dropdown',
        flex: 1,
        renderCell: () => (
          <SelectField
            value=""
            onChange={console.log}
            options={players.map((p) => ({ id: p.id, value: p.name }))}
          />
        ),
      },
    ],
    rows: players.map((p) => ({
      id: p.id,
      name: p.name,
    })),
  };
  return (
    <>
      <Typography variant="h5" gutterBottom>Editable Table</Typography>
      <Paper id="test-editable-table">
        <Box p={2}>
          <BaseTable
            {...tabelProps}
          />
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />

      <Typography variant="h5" gutterBottom>Editable Table (compact header)</Typography>
      <Paper id="test-editable-table">
        <Box p={2}>
          <BaseTable
            {...{
              compactHeader: true,
            }}
            {...tabelProps}
          />
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />

      <Typography variant="h5" gutterBottom>Editable Table (compact rows)</Typography>
      <Paper id="test-editable-table">
        <Box p={2}>
          <BaseTable
            {...{
              compactRows: true,
            }}
            {...tabelProps}
          />
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestEditableTable;
