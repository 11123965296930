import * as React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, InputAdornment, Link } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { ReactElement } from 'react';
import EditableIcon from '@images/icons/svg/editable.svg';

interface PropsIn {
  className?: string;
  defaultValue: string | number;
  disabled?: boolean;
  id?: string;
  label?: string;
  loading?: boolean;
  link?: string;
  name?: string;
  onSave?: (value: string | number) => Promise<void>;
  options?: {
    id: string;
    name: string;
  }[];
  readonly ?: boolean;
  required?: boolean;
  noneLabel?: string;
  hideNone?: boolean;
  onBlur?: () => void;
  isEditable?: boolean;
}

function EditableSelectField(props: PropsIn) {
  const {
    className, defaultValue, disabled, hideNone, id, label, loading, link, name, onSave, options, readonly, required, noneLabel,
    onBlur, isEditable
  } = props;
  const [changed, setChanged] = React.useState<string | number>(defaultValue);
  const [showEditInfo, setShowEditInfo] = React.useState(true);

  const handleSave = () => {
    if (onSave) {
      onSave(changed);
    }
  };

  const handleClear = () => {
    setChanged(defaultValue);
  };

  const actionIcons: ReactElement[] = [];

  if (link) {
    actionIcons.push(
      <Link key="link-icon" href={link} target="_blank" className="action">
        <LinkIcon />
      </Link>,
    );
  }

  const selectOptions = options || [];

  return (
    <div style={{position:'relative'}}>
    { showEditInfo && isEditable && changed === defaultValue && !readonly && !disabled ?
    <div style={{position:'absolute', top:'11px', right:'35px'}}>
    <EditableIcon />
    </div>
  : null}
    <FormControl
      id={id}
      variant="outlined"
      className={
        `${(className || '')
        + (link ? ' has-link' : '')
        + (changed !== defaultValue ? ' is-changed' : '')
        } editable-select-field`
      }
      fullWidth
      margin="dense"
    >
      <InputLabel id="select-outlined-label">{label}</InputLabel>
      <Select
        className={`editable-select-field${disabled && ' disabled' || ''}${readonly ? ' readonly' : ''}`}
        disabled={disabled || loading || options?.length === 0}
        readOnly={readonly}
        id={name}
        label={label}
        margin="dense"
        labelId="select-outlined-label"
        onBlur={onBlur || ((event) => {
          if (!event.relatedTarget) {
            handleClear();
          }
        })}
        onChange={(e) => {
          setChanged(e.target.value || '');
        }}
        style={{
          textTransform: name === 'athlete' ? 'capitalize' : undefined,
        }}
        required={required}
        value={changed}
        defaultValue={defaultValue}
        endAdornment={
          loading ? (
            <InputAdornment position="end">
              <IconButton key="icon-wait">
                <AccessTimeIcon style={{ marginRight: '8px' }} />
              </IconButton>
            </InputAdornment>
          ) : changed !== defaultValue ? (
            <InputAdornment position="end">
              <IconButton key="button-save" onClick={handleSave}>
                <DoneIcon />
              </IconButton>
              <IconButton key="button-clear" onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : actionIcons
}
      >
        {!hideNone
          // todo vedere se questo effettivamente serve, per ora ci metto una props per toglierlo
          && (
          <MenuItem value={noneLabel || '-'}>
            {noneLabel || '-'}
          </MenuItem>
          )}
        {selectOptions.map((option) => (
          <MenuItem key={option.id} value={option.id} style={{ textTransform: name === 'athlete' ? 'capitalize' : undefined }}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </div>
  );
}

export default EditableSelectField;
