/**
 * Definisco i tipi di mappa disponibili
 */
export const mapLayerTypes = ['satellite', 'roadmap'];
export type MapLayerType = typeof mapLayerTypes[number]

/**
 * Imposta il tipo di layer se disponibile di default per le mappe
 * @constant
 * @type {string}
 */
export const MAP_LAYER_TYPE_DEFAULT = 'satellite';
