import React, {
  useState, useContext, useMemo, useRef,
} from 'react';
import { CursorContext } from '../track/TrackChartContainer';
import { useQueryCached } from '../utils/graphql';
import { GroundRes, GroundVars, GROUND_DETAILS } from '../../query/grounds';
import { CACHE_AND_NETWORK } from '../../lib/cache';
import Error from '../layout/Error';
import { SeriesState, PlayersColors } from './WebPlayer';
import { PlayersDetails } from './PlayersTable';
import GroundField from './GroundField';
import { getPlayersLines, getPlayersPointsLPS, getSizesFromRef } from '../utils/ground';
import { GroundType } from '../../models/ground';

function getScale(
  width: number,
  height: number,
  winWidth: number,
  winHeight: number,
) {
  const scaleX = winWidth / width;
  const scaleY = winHeight / height;
  return Math.min(scaleX, scaleY);
}

interface GroundProps {
  activePlayer: string | null;
  hoverPlayer: string | null;
  defaultPlayersColor?: string;
  enabledPlayers: Array<string>;
  groundId: string;
  jerseyOrNumber: boolean;
  onPlayerClick: (playerID: string) => void;
  onPlayerEnter?: (playerID: string) => void;
  onPlayerLeave?: (playerID: string) => void;
  playerLinks: Set<[string, string]>;
  playersColors: PlayersColors;
  playersDetails: PlayersDetails;
  removeLink: (idx: number) => void;
  series: SeriesState;
  showGroundName?: boolean;
  showVertices?: boolean;
  trailsEnabled: boolean;
}

export type Ref = HTMLDivElement;

const GroundLPS = (props: GroundProps) => {
  const {
    activePlayer,
    hoverPlayer,
    defaultPlayersColor,
    enabledPlayers,
    groundId,
    jerseyOrNumber,
    onPlayerClick,
    onPlayerEnter,
    onPlayerLeave,
    playerLinks,
    playersColors,
    playersDetails,
    removeLink,
    series,
    showGroundName,
    showVertices,
    trailsEnabled,
  } = props;

  const parentRef = useRef<HTMLDivElement | undefined>(undefined);
  const [zoom, setZoom] = useState(1);
  const [lpsGroundData, setLpsGroundData] = useState<GroundType | undefined>(undefined);
  const [scale, setScale] = useState(1);
  const { cursor } = useContext(CursorContext);
  const [vertex, setVertex] = useState({
    vertexA: {
      x: 0,
      y: 0,
    },
    vertexC: {
      x: 0,
      y: 0,
    },
  });

  const { WINDOW_WIDTH, WINDOW_HEIGHT } = getSizesFromRef(parentRef);

  const {
    error: errorGround,
  } = useQueryCached<GroundRes, GroundVars>(GROUND_DETAILS, {
    variables: {
      id: groundId,
    },
    skip: groundId == null,
    ...CACHE_AND_NETWORK,
    onCompleted: (response) => {
      // setto i default
      let vertexA = {
        x: response?.res.vertexAX?.value !== undefined ? response?.res.vertexAX?.value : 0,
        y: response?.res.vertexAY?.value !== undefined ? response?.res.vertexAY?.value : 0,
      };
      let vertexC = {
        x: response?.res.vertexCX?.value !== undefined ? response?.res.vertexCX?.value : 0,
        y: response?.res.vertexCY?.value !== undefined ? response?.res.vertexCY?.value : 0,
      };

      // verifico se sono invertiti x e y
      if (vertexA.x > vertexC.x) {
        const swap = vertexC;
        vertexC = vertexA;
        vertexA = swap;
      }

      if (vertexA.y < vertexC.y) {
        const swap = vertexC.y;
        vertexC.y = vertexA.y;
        vertexA.y = swap;
      }

      // calcolo le dimensioni del campo (dai dati)
      const width = vertexC.x - vertexA.x;
      const height = vertexA.y - vertexC.y;

      // calcolo il valore della scala per adattare il campo alla canvas
      const scaleValue = getScale(
        width + 10,
        height + 10,
        WINDOW_WIDTH,
        WINDOW_HEIGHT,
      );

      setScale(scaleValue);
      // calcolo le nuove dimensioni del campo scalate
      /* vertexA = {
        x:
          rotate(
            flipPoint({ // serve per verificare qual è il lato più lungo
              x: response.res.vertexAX.value,
              y: response.res.vertexAY.value,
            }, response?.res),
            computeAngle(response?.res),
          ).x * scaleValue,
        y:
          rotate(
            flipPoint({
              x: response.res.vertexAX.value,
              y: response.res.vertexAY.value,
            }, response?.res),
            computeAngle(response?.res),
          ).y * scaleValue,
      };

      vertexC = {
        x:
          rotate(
            flipPoint({
              x: response.res.vertexCX.value,
              y: response.res.vertexCY.value,
            }, response?.res),
            computeAngle(response?.res),
          ).x * scaleValue,
        y:
          rotate(
            flipPoint({
              x: response.res.vertexCX.value,
              y: response.res.vertexCY.value,
            }, response?.res),
            computeAngle(response?.res),
          ).y * scaleValue,
      }; */
      // sovrascrivo i dati locali nei dettagli del ground da passare al Pitch
      setLpsGroundData(response.res);

      setVertex({
        vertexA,
        vertexC,
      });
    },
  });

  const scaledWidth = (vertex.vertexC.x - vertex.vertexA.x) * scale * zoom;
  const scaledHeight = (vertex.vertexA.y - vertex.vertexC.y) * scale * zoom;

  const offX = - ((WINDOW_WIDTH - scaledWidth) / 2);
  const offY = - (scaledHeight + (WINDOW_HEIGHT - scaledHeight) / 2);
  /*
  @todo varie
  selettore nome breve o jersey
  linee tra i player con la distanza tra i punti (A-B, C-D, A-C)
  area tra i vari punti
  selettore velocità
  integrare con sessions (se singolo drill mostrare solo quello)
  full screen controlli traccia su hover 1/5 inferiore

  sentire loris per la parte GPS
   */

  const playersPoints = useMemo(() => getPlayersPointsLPS({
    cursor,
    enabledPlayers,
    scale,
    series,
  }), [series, cursor, enabledPlayers, playerLinks]);

  const playersLines = useMemo(() => getPlayersLines({
    playersPoints,
    playerLinks,
  }), [playerLinks, playersPoints]);

  if (errorGround) return <Error />;

  return (
    <>
      {showGroundName
        && (
          <div>
            {lpsGroundData?.id}
            {' '}
            -
            {lpsGroundData?.name}
          </div>
        )}

      <GroundField
        activePlayer={activePlayer}
        hoverPlayer={hoverPlayer}
        cursor={cursor}
        dataGround={lpsGroundData}
        defaultPlayersColor={defaultPlayersColor}
        enabledPlayers={enabledPlayers}
        jerseyOrNumber={jerseyOrNumber}
        onPlayerClick={onPlayerClick}
        onPlayerEnter={onPlayerEnter}
        onPlayerLeave={onPlayerLeave}
        playersColors={playersColors}
        playersDetails={playersDetails}
        playersLines={playersLines}
        removeLink={removeLink}
        series={series}
        setZoom={setZoom}
        showVertices={!!showVertices}
        trailsEnabled={trailsEnabled}
        offX={offX}
        offY={offY}
        scale={scale}
        ref={parentRef}
        WINDOW_WIDTH={WINDOW_WIDTH}
        WINDOW_HEIGHT={WINDOW_HEIGHT}
        zoom={zoom}
      />
    </>
  );
};

export default GroundLPS;
