export const h2r = (hex: string) => {
  const short = hex.length === 4;
  const result = short
    ? /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex)
    : /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? [
    parseInt(`${result[1]}${short ? result[1] : ''}`, 16),
    parseInt(`${result[2]}${short ? result[2] : ''}`, 16),
    parseInt(`${result[3]}${short ? result[3] : ''}`, 16),
  ] : null;
};

// eslint-disable-next-line no-bitwise
export const r2h = (rgb: [number, number, number]) => `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2])
  .toString(16)
  .slice(1)}`;

export const interpolateColor = (color1, color2, factor = 0.5) => {
  const result = color1.slice();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
};

export enum ColorsPaletteChoices {
  PURPLE='purple',
  BLUE='blue',
  RED_SALMON_BLUE='red_salmon_blue'
}

export const colorsPalette = (typeof window !== 'undefined' && typeof localStorage !== 'undefined' 
  && (localStorage.getItem('testUserColorsPalette') || localStorage.getItem('userColorsPalette'))) 
  || 'blue';


export const colorsPaletteOptions = [
  { id: '0', value: ColorsPaletteChoices.BLUE, label: 'blue'}, 
  { id: '1', value: ColorsPaletteChoices.PURPLE, label:'purple'}, 
  { id: '2', value:ColorsPaletteChoices.RED_SALMON_BLUE, label:'red blue'}
]

export const RED_SALMON_BLUE_2_COLORS = ['#3268EF', '#FB6161']

export const get2ColorsPalette = (colorsPalette: string) => {
  switch (colorsPalette) {
    case 'red_salmon_blue':
      return RED_SALMON_BLUE_2_COLORS;
    case 'purple':
      return getPrimaryColorGradient(3).slice(1);
    default:
      return getPrimaryColorGradient(2);
  }
};

export const  getPrimaryColorGradient = (num: number, palette : string = colorsPalette) => {
  // Green Variant (original)
  const greenColors = [
    '#CAFFF5',
    '#43FCDB',
    '#00DDB5',
    '#00B292',
    '#00876F',
    '#004D41',
  ];

  // Green variant (secondary)
  const greenColors2 = [
    '#64DAB7',
    '#55BBB2',
    '#479EA6',
    '#387F92',
    '#376376',
    '#344756',
  ];

  // Purple Variant
  const purpleColors = [
    '#ECE7FC',
    '#C7B6F5',
    '#8F6CEC',
    '#450ADF',
    '#290686',
    '#150343',
  ];

  // Green/Blue Variant
  const greenBlueColors = [
    '#00DDB5',
    '#05BCAD',
    '#0B9AA5',
    '#10799C',
    '#165794',
    '#1B368C',
  ];

  // Green/Red Variant
  const greenRedColors = [
    '#17FFC2',
    '#4DD7BB',
    '#73BCA9',
    '#C49DA8',
    '#D85785',
    '#E6296E',
  ];

  // Orange/Red Variant
  const orangeRedColors = [
    '#FAB40E',
    '#F19424',
    '#E87539',
    '#DE554F',
    '#DE3364',
    '#CC157A',
  ];

  // Orange/Red Variant
  const orangeRedColors2 = [
    '#E6DD6D',
    '#FC9B65',
    '#FB6E63',
    '#EF3360',
    '#BD2347',
    '#9B1837',
  ];

  // Orange/Blue Variant
  const orangeBlueColors = [
    '#61A2FB',
    '#3268EF',
    '#6D5AAB',
    '#A74C66',
    '#E23E22',
    '#FF3700',
  ];

   // Red salmon Blue Variant
   const redSalmonBlue = [
    '#9BD8F9',
    '#61A2FB',
    '#3268EF', 
    '#F99B9B',
    '#FB6161',
    '#D81D1D',
  ]; 

  // Blue Variant
  const blueColors = [
    '#9BD8F9',
    '#61A2FB',
    '#3268EF',
    '#1D4DD8',
    '#1E418D',
    '#283951',
  ];

  // Green/Yellow Variant
  const greenYellowColors = [
    '#00DDB5',
    '#33DD91',
    '#66DD6D',
    '#99DD48',
    '#CCDD24',
    '#FFDD00',
  ];

  const getColorsPalette = (option: string) => {
    if (option === 'green') {
      return [
        ...([3, 4, 5, 6].includes(num) && [greenColors[0]] || []),
        ...([5, 6].includes(num) && [greenColors[1]] || []),
        ...[greenColors[2]],
        ...([4, 5, 6].includes(num) && [greenColors[3]] || []),
        ...([2, 3, 5, 6].includes(num) && [greenColors[4]] || []),
        ...([4, 6].includes(num) && [greenColors[5]] || []),
      ];
    }
    if (option === 'green2') {
      return [
        ...([4, 5, 6].includes(num) && [greenColors2[0]] || []),
        ...([2, 3, 5, 6].includes(num) && [greenColors2[1]] || []),
        ...([3, 4, 5, 6].includes(num) && [greenColors2[2]] || []),
        ...[greenColors2[3]],
        ...([4, 5, 6].includes(num) && [greenColors2[4]] || []),
        ...([6].includes(num) && [greenColors2[5]] || []),
      ];
    }
    if (option === 'purple') {
      return [
        ...([4, 5, 6].includes(num) && [purpleColors[0]] || []),
        ...([2, 3, 5, 6].includes(num) && [purpleColors[1]] || []),
        ...([3, 4, 5, 6].includes(num) && [purpleColors[2]] || []),
        ...[purpleColors[3]],
        ...([4, 5, 6].includes(num) && [purpleColors[4]] || []),
        ...([6].includes(num) && [purpleColors[5]] || []),
      ];
    }
    if (option === 'green-blue') {
      return [
        ...([4, 5, 6].includes(num) && [greenBlueColors[0]] || []),
        ...([2, 3, 5, 6].includes(num) && [greenBlueColors[1]] || []),
        ...([4, 6].includes(num) && [greenBlueColors[2]] || []),
        ...([3, 5, 6].includes(num) && [greenBlueColors[3]] || []),
        ...([4, 5, 6].includes(num) && [greenBlueColors[4]] || []),
        ...[greenBlueColors[5]],
      ];
    }
    if (option === 'green-red') {
      return [
        ...([3, 4, 5, 6].includes(num) && [greenRedColors[0]] || []),
        ...([2, 5, 6].includes(num) && [greenRedColors[1]] || []),
        ...([3, 4, 6].includes(num) && [greenRedColors[2]] || []),
        ...([5, 6].includes(num) && [greenRedColors[3]] || []),
        ...([4, 5, 6].includes(num) && [greenRedColors[4]] || []),
        ...[greenRedColors[5]],
      ];
    }
    if (option === 'orange-red') {
      return [
        ...([3, 4, 5, 6].includes(num) && [orangeRedColors[0]] || []),
        ...([2, 4, 5, 6].includes(num) && [orangeRedColors[1]] || []),
        ...([3, 4, 6].includes(num) && [orangeRedColors[2]] || []),
        ...([5, 6].includes(num) && [orangeRedColors[3]] || []),
        ...[orangeRedColors[4]],
        ...([5, 6].includes(num) && [orangeRedColors[5]] || []),
      ];
    }
    if (option === 'orange-red2') {
      return [
        ...([6].includes(num) && [orangeRedColors2[0]] || []),
        ...([2, 4, 5, 6].includes(num) && [orangeRedColors2[1]] || []),
        ...([3, 4, 5, 6].includes(num) && [orangeRedColors2[2]] || []),
        ...[orangeRedColors2[3]],
        ...([3, 4, 5, 6].includes(num) && [orangeRedColors2[4]] || []),
        ...([5, 6].includes(num) && [orangeRedColors2[5]] || []),
      ];
    }
    if (option === 'orange-blue') {
      return [ 
        ...[orangeBlueColors[0]],
        ...([5, 6].includes(num) && [orangeBlueColors[1]] || []),
        ...([3, 4, 5, 6].includes(num) && [orangeBlueColors[2]] || []),
        ...([6].includes(num) && [orangeBlueColors[3]] || []),
        ...[orangeBlueColors[4]],
        ...([4, 5, 6].includes(num) && [orangeBlueColors[5]] || []),
      ];
    }
    if (option === 'red_salmon_blue') {
      return [ 
        ...([5, 6].includes(num) && [redSalmonBlue[0]] || []),
        ...([3, 4, 5, 6].includes(num) && [redSalmonBlue[1]] || []),
        ...([1, 2, 3, 4, 5, 6].includes(num) && [redSalmonBlue[2]] || []),
        ...([4, 5, 6].includes(num) && [redSalmonBlue[3]] || []),
        ...([2, 3, 4, 5, 6].includes(num) && [redSalmonBlue[4]] || []),
        ...([6].includes(num) && [redSalmonBlue[5]] || []),
      ];
    }
    if (option === 'blue') {
      return [ 
        ...([4, 5, 6].includes(num) && [blueColors[0]] || []),
        ...([2, 3, 5, 6].includes(num) && [blueColors[1]] || []),
        ...([3, 4, 5, 6].includes(num) && [blueColors[2]] || []),
        ...[blueColors[3]],
        ...([4, 5, 6].includes(num) && [blueColors[4]] || []),
        ...([6].includes(num) && [blueColors[5]] || []),
      ];
    }
    if (option === 'green-yellow') {
      return [ 
        ...([4, 5, 6].includes(num) && [greenYellowColors[0]] || []),
        ...([3, 5, 6].includes(num) && [greenYellowColors[1]] || []),
        ...([2, 4, 6].includes(num) && [greenYellowColors[2]] || []),
        ...([3, 5, 6].includes(num) && [greenYellowColors[3]] || []),
        ...([4, 5, 6].includes(num) && [greenYellowColors[4]] || []),
        ...[greenYellowColors[5]],
      ];
    }
    return [];
  };

  return getColorsPalette(palette);
};

export const getColorsCombination = () => {
  const variableColors = getPrimaryColorGradient(6, colorsPalette);
  const order = [1, 3, 0, 4, 2, 5];
  const colors: string[] = [];
  order.forEach((n) => {
    colors.push(variableColors[n]);
  });
  return colors;
};

export const getFrontColorFromBG = (hex, darkColor = '#828282', lightColor = '#ffffff') => {
  const hexcolor = hex.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? darkColor : lightColor;
};
