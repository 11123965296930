import React from 'react';
import { Group, Text } from 'react-konva';
import {
  BOTTOM_OFFSET, DEFAULT_PITCH_LINE_COLOR, LEFT_OFFSET, RIGHT_OFFSET, TOP_OFFSET,
} from './constants';

type Vertex = {
  x: number;
  y: number;
};

export default function Vertices(props: { A: Vertex; C: Vertex }) {
  const { A, C } = props;
  return (
    <Group>
      <Text x={A.x - LEFT_OFFSET} y={A.y - BOTTOM_OFFSET} fontSize={25} text="A" fill={DEFAULT_PITCH_LINE_COLOR} />
      <Text x={C.x + RIGHT_OFFSET} y={C.y - TOP_OFFSET} fontSize={25} text="C" fill={DEFAULT_PITCH_LINE_COLOR} />
      <Text x={A.x - LEFT_OFFSET} y={C.y - TOP_OFFSET} fontSize={25} text="B" fill={DEFAULT_PITCH_LINE_COLOR} />
      <Text x={C.x + RIGHT_OFFSET} y={A.y - BOTTOM_OFFSET} fontSize={25} text="D" fill={DEFAULT_PITCH_LINE_COLOR} />
    </Group>
  );
}
