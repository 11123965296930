import React from 'react';

const WidgetComponentWithLoading: React.FC<{
  children: React.ReactElement<any, any> | null,
  loading: boolean,
  width?: number | string,
  height?: number | string
}> = (props) => {
  const {
    children, loading, width, height,
  } = props;

  return loading
    ? (
      <div
        className="loading-gradient"
        style={{
          ...{
            width: width || '100%',
            borderRadius: '12px',
            padding: '12px',
          },
          ...height
            ? { height }
            : {
              height: '100%',
              minHeight: '100px',
            },
        }}
      />
    )
    : children;
};

export default WidgetComponentWithLoading;
