import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React, { useState } from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import EditableTextField from '../form/EditableTextField';

function TestEditableTextField() {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState('');

  const baseProps = {
    placeholder: 'placeholder test',
    multiline: true,
    rows: 3,
    defaultValue: value,
    onSave: (value) => {
      enqueueSnackbar(`New notes: ${value}`, { variant: 'success' });
      setValue(value);
    },
    onClear: () => {
      enqueueSnackbar('Clear event', { variant: 'info' });
      setValue('');
    },
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>EditableTextField</Typography>
      <Paper id="test-editable-text-field">
        <Box p={2}>
          <Row>
            <Column md={4}>
              <EditableTextField
                label="notes"
                {...baseProps}
              />
            </Column>
            <Column md={4}>
              <EditableTextField
                label="single line"
                {...baseProps}
                multiline={false}
                rows={1}
              />
            </Column>
            <Column md={4}>
              <EditableTextField
                label="default value"
                defaultValue="default value"
                {...baseProps}
              />
            </Column>
            <Column md={4}>
              <EditableTextField
                label="loading"
                loading
                {...baseProps}
              />
            </Column>
            <Column md={4}>
              <EditableTextField
                label="disabled"
                disabled
                {...baseProps}
              />
            </Column>
            <Column md={4}>
              <EditableTextField
                label="required"
                required
                {...baseProps}
              />
            </Column>
            <Column md={4}>
              <EditableTextField
                label="autoCommit"
                autoCommit
                {...baseProps}
              />
            </Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestEditableTextField;
