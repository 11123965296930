import React from 'react';
import shirt from '@images/jersey.png';
import { Box, Typography } from '@material-ui/core';

export default function (props: { name?: string; number?: string }) {
  const { name, number } = props;
  return (
    <Box position="relative" display="flex" justifyContent="center" alignItems="center" style={{ paddingTop: '3px' }}>
      <img src={shirt} alt="shirt" />
      <Box position="absolute" justifyContent="center" top="20px" left="0" width="100%">
        <Typography
          style={{
            fontFamily: 'var(--font-family-alt)',
            fontSize: '20px',
            fontWeight: 700,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {name?.substring(0, 3)}
        </Typography>
        <Typography
          style={{
            marginTop: '-20px',
            fontFamily: 'var(--font-family-alt)',
            fontSize: '60px',
            fontWeight: 700,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {number}
        </Typography>
      </Box>
    </Box>
  );
}
