import React from 'react';

import MoveIcon from '@images/IconHeader/move.svg';
import { Action, ActionProps } from './Action';

export function Move( props: ActionProps ) {
  return (
    <Action
      {...props}
      active={{
        fill: 'rgba(255, 70, 70, 0.95)',
        background: 'rgba(255, 70, 70, 0.1)',
      }}
    >
      <MoveIcon/>
    </Action>
  );
}
