import React, { DOMAttributes } from 'react';
import Grid, { GridSpacing } from '@material-ui/core/Grid';

type EventsProps = DOMAttributes<HTMLDivElement>

interface PropsIn {
  id?: string;
  className?: string;
  spacing?: GridSpacing;
  children: React.ReactNode;
  style?: object;
}
/**
 * Row
 * @param children
 * @param props
 * @constructor
 */
export const Row = ({ children, ...props }: PropsIn & EventsProps) => (
  <Grid
    id={props.id}
    container
    spacing={props.spacing ? props.spacing : 2}
    {...props}
    className={`layout-row ${props.className || ''}`}
  >
    {children}
  </Grid>
);
