import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import Box from '@material-ui/core/Box';
import { useNavigate } from 'react-router';
import { gql } from '@apollo/client';
import { Loader } from 'lib/icons';
import BaseButton from './form/BaseButton';
import { useQueryCached } from './utils/graphql';
import useLocalStorage from '../custom_hooks/useLocalStorage';

export default function GpexeVersion() {
  const navigate = useNavigate();
  const [beVersion, setBeVersion] = useLocalStorage('be_version', '');
  const { data: versionData, loading } = useQueryCached(gql`query gpexeVersion { res: versionQuery }`, {
    onCompleted(data) {
      setBeVersion(data?.res.split(' ')[0]);
    },
  });

  if (loading) return <Loader />;

  if (versionData.res === 'unconfigured') {
    return (
      <Box className="gpexe-version-button">
        <BaseButton
          flat
          style={{
            marginTop: '5px',
          }}
          startIcon={<LinkIcon />}
          onClick={() => navigate('/changelog')}
          hoverAll
        >
          v. unconfigured
        </BaseButton>
      </Box>
    );
  }

  const [version, branchName] = versionData.res.split(' ');

  return (
    <Box className="gpexe-version-button">
      <BaseButton
        flat
        style={{
          marginTop: '5px',
        }}
        startIcon={<LinkIcon />}
        onClick={() => navigate('/changelog')}
        hoverAll
      >
        {`v. ${branchName?.includes('release') ? version : versionData.res}`}
      </BaseButton>
    </Box>
  );
}
