import React from 'react';
import styles from './GridContainer.module.css';

export interface Props {
  children: React.ReactNode;
  columns: number;
}

export function GridContainer({ children, columns }: Props) {
  return (
    <ul
      className={columns ? styles.GridContainer_Fixed : styles.GridContainer_Fluid}
      style={
        {
          ...columns ? {
            '--col-count': columns,
            gap: '20px'
          } : {},
        } as React.CSSProperties
      }
    >
      {children}
    </ul>
  );
}
