import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import TitleView from 'components/layout/TitleView';
import { StackedColumnChart } from '../charts/StackedColumnChart';
import { IconWrapper } from '../../lib/icons';
import { eventsColorByName } from '../charts/colors';

interface PropsIn {
  data: any // @todo fix
}

export const DivesByLoad = React.memo((props: PropsIn) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    (data?.res?.imu?.eventsDivesLeft?.length > 0 && data?.res?.imu?.eventsDivesRight?.length > 0 && (
      <Box className="chart-widget">
        <Box className="title">
          <TitleView
            title={t('track.widgets.mecKPI.labels.divesByLaunchLoad', 'dives by launch load')}
            prefixIcon={<IconWrapper name="dives" size="medium" color="primary" />}
            align="left"
          />
        </Box>
        <StackedColumnChart
          data={data?.res?.imu}
          categories={[
            t('kpi2.IMUEvents.eventDivesCount.label', 'dives'),
          ]}
          colors={eventsColorByName}
          singleColumn
          validKeys={['eventsDivesLeft', 'eventsDivesRight']}
        />
      </Box>
    ))
    || null
  );
});

export default DivesByLoad;
