export enum ExportFormat {
  CSV = 'csv',
  SENSOR_CSV = "sensor_csv",
  XLSX = 'xlsx',
  XML = 'xml',
  PDF = 'pdf',
  TXT = 'txt',
  ZIP = "zip",
}

export enum ExportTarget {
  ATHLETE_SESSION = 'AthleteSession',
  ATHLETE_SESSION_LIST = 'AthleteSessionList',
  TRACK =  "Track",
  TEAM_SESSION = 'TeamSession',
  INCOMING_FILE = 'IncomingFile',
}
