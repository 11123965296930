import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import { CloudCircle } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import { TextField } from '../form/TextField';

function TestTextField() {
  return (
    <>
      <Typography variant="h5" gutterBottom>TextField</Typography>
      <Paper id="test-textfield">
        <Box p={2}>
          <Row>
            <Column md={2}><TextField label="default" value="text value" /></Column>
            <Column md={2}><TextField label="disabled" disabled value="text value" /></Column>
            <Column md={2}><TextField label="readonly" readonly value="text value" /></Column>
            <Column md={2}><TextField label="label" helperText="helper text" value="text value" /></Column>
            <Column md={2}>
              <TextField
                label="startAdornment"
                startAdornment={<CloudCircle />}
                value="text value"
              />
            </Column>
            <Column md={2}><TextField label="endAdornment" endAdornment={<CloudCircle />} value="text value" /></Column>
            <Column md={2}><TextField label="placeholder" placeholder="placeholder text" /></Column>
            <Column md={2}><TextField label="minRow 3 - maxRow 5" minRow={3} maxRow={5} multiline value="text value" /></Column>
            <Column md={2}><TextField label="required" required /></Column>
            <Column md={2}><TextField label="value" value="default value" /></Column>
            <Column md={2}><TextField value="no label" /></Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestTextField;
