import React from 'react';

import EnlargeIcon from '@images/IconHeader/enlarge.svg';
import { Action, ActionProps } from './Action';

export function Enlarge( props: ActionProps ) {
  return (
    <Action
      {...props}
      active={{
        fill: 'rgba(255, 70, 70, 0.95)',
        background: 'rgba(255, 70, 70, 0.1)',
      }}
    >
      <EnlargeIcon/>
    </Action>
  );
}
