import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import DoneIcon from '@material-ui/icons/Done';
import UploadIcon from '@images/icons/svg/upload.svg';
import { makeStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import CLIENT_SETTINGS from '../../lib/client_settings';
import { ImageTools } from '../../lib/images';

export type formFieldType = {
  fieldName: string,
  fieldValue: string,
}

export type PropsIn = {
  acceptedFiles: string[],
  autoUpload?: boolean,
  disabled?: boolean,
  dropzoneMaxSize?: number,
  endPoint?: string,
  fileFieldName?: string,
  files?: File[],
  filesLimit?: number,
  formData?: formFieldType[],
  initialFiles?: File[],
  method?: string,
  onSubmitSuccess?: () => void,
  setFiles?: (file: File[]) => void,
  showFileNames?: boolean,
  useChips?: boolean,
  resizeToMaxValues?: [number, number],
}

export type UploadPropsIn = {
  endPoint: string,
  file: File,
  fileFieldName: string,
  formData?: formFieldType[],
  method: string,
  onSubmitSuccess?: () => void,
}

export function uploadFile(props: UploadPropsIn) {
  const backend = CLIENT_SETTINGS.public.gpexeGraphUrl;
  const url = `${backend.substr(0, backend.indexOf('/ui/'))}${props.endPoint}`;

  const fd = new FormData();
  fd.append(props.fileFieldName, props.file);
  props?.formData?.forEach((d) => {
    fd.append(d.fieldName, d.fieldValue);
  });

  fetch(url, {
    method: props.method,
    headers: {
      Authorization: `JWT ${localStorage.getItem('exelio_token')}`,
    },
    body: fd,
  })
    .then(() => {
      if (props.onSubmitSuccess) props.onSubmitSuccess();
    })
    .catch((error) => {
      console.error(error);
    });
}

export function Dropzone(props: PropsIn) {
  const {
    setFiles, endPoint, method, fileFieldName, formData, initialFiles,
    onSubmitSuccess, autoUpload, showFileNames, disabled, useChips,
    dropzoneMaxSize, filesLimit, files, acceptedFiles, resizeToMaxValues,
  } = props;

  const { t } = useTranslation();

  const maxFileSize = dropzoneMaxSize || 30000000;
  const maxSizeInMb = maxFileSize / 1000000;

  const fileLimitExceededText = () => `The file you are attempting to upload exceeds the allowable size limit;
  its size should not exceed ${maxSizeInMb} megabytes.`;

  const useStyles = makeStyles(() => ({
    dropZone: {
      height: 'fit-content !important',
      padding: '10px',
      border: '2px dashed var(--border-card-color) !important',
      borderRadius: '10px !important',
      cursor: 'pointer !important',
    },
    disabledDropZone: {
      height: 'fit-content !important',
      padding: '10px',
      border: '2px dashed var(--border-disabled) !important',
      borderRadius: '10px !important',
      cursor: 'default',
    },
  }));

  const classes = useStyles();

  const handleChange = async (changedFiles) => {
    if (setFiles) {
      if (!resizeToMaxValues) {
        setFiles(changedFiles);
      } else {
        Promise.all(changedFiles.map((f) => new Promise((resolve) => {
          ImageTools.resize(f, {
            width: resizeToMaxValues[0],
            height: resizeToMaxValues[1],
          }, (blob) => {
            resolve(ImageTools.toFile(blob, f.name));
          });
        }))).then((newFiles) => {
          setFiles(newFiles);
        });
      }
    }

    if (autoUpload && endPoint && fileFieldName && method) {
      changedFiles.forEach((file) => {
        if (changedFiles.indexOf(file) === -1) {
          uploadFile({
            file,
            endPoint,
            method,
            fileFieldName,
            formData,
            onSubmitSuccess,
          });
        }
      });
    }
  };

  return (
    <DropzoneArea
      Icon={UploadIcon}
      acceptedFiles={acceptedFiles}
      dropzoneClass={disabled ? classes.disabledDropZone : classes.dropZone}
      dropzoneProps={{ disabled }}
      dropzoneText={(
        <Trans
          i18nKey="files.dropzone"
          defaults="drop files here <br/> or click to upload"
        />
)}
      filesLimit={filesLimit || 50}
      initialFiles={initialFiles}
      maxFileSize={maxFileSize}
      onChange={handleChange}
      showFileNames={showFileNames}
      useChipsForPreview={useChips}
      getDropRejectMessage={fileLimitExceededText}
      previewChipProps={{
        disabled: true,
        clickable: false,
        deleteIcon: <DoneIcon />,
      }}
      classes={{ root: `${files && files.length > 0 ? 'has-files' : 'no-files'} max-uploads-${filesLimit}` }}
    />
  );
}
