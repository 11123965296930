/**
 * setWithExpiry
 *
 * Imposta una chiave nel `localStorage` con una scadenza
 *
 * Nota: la scadenza è solo impostata. Il GC viene eseguito nella chiamata `getWithExpiry`
 *
 * @param key string
 * @param value any
 * @param {number} ttl number (secondi)
 */
export const setWithExpiry = (key: string, value: any, ttl: number) => {
  const now = new Date();

  const item = {
    value,
    expiry: now.getTime() + ttl * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * Recupera una chiave dal `localStorage` con scadenza
 *
 * @param key string
 */
export const getWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};