import { useSearchParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import BaseButton from '../form/BaseButton';

function TestUrlHook() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParams = (event, values) => {
    event.preventDefault();
    setSearchParams(values);
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>useSearchParams</Typography>
      <p>(look at the browser url)</p>
      <Paper id="test-editable-table">
        <Box p={2}>
          <BaseButton onClick={(event) => setParams(event, { param1: 'a' })}>Set URL 1</BaseButton>
          <BaseButton onClick={(event) => setParams(event, { param2: 'TEST 2' })}>Set URL 2</BaseButton>
          <BaseButton
            onClick={(event) => setParams(event, { longParam: '123456789ABCDEFGHILMNOPQRSTUVZ123456789ABCDEFGHILMNOPQRSTUVZ123456789ABCDEFGHILMNOPQRSTUVZ1234' })}
          >
            Set
            URL 3
          </BaseButton>
          <BaseButton onClick={() => window.history.back()}>History BACK</BaseButton>
          <BaseButton onClick={() => window.history.forward()}>History FORWARD</BaseButton>
          <Divider style={{ margin: '1rem' }} />
          <Paper>
            <Box p={2}>
              Results:
              {' '}
              {Array.from(searchParams).join('<br />')}
            </Box>
          </Paper>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestUrlHook;
