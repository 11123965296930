import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import ACLWrapper, { UserCanProps } from '../ACLWrapper';

function TestACLWrapper() {
  const testRoles: UserCanProps[] = [
    { section: 'templates', type: 'actions', permission: 'create_template' },
    { section: 'sessions', type: 'actions', permission: 'create_session' },
    { section: 'radar', type: 'exports', permission: 'pdf' },
    { section: 'radar', type: 'exports', permission: 'invalidKey' },
  ];

  return (
    <>
      <Typography id="acl-wrapper-test" variant="h5" gutterBottom>ACLWrapper</Typography>
      <Paper>
        <Box p={2}>
          <Row>
            {
              testRoles.map((tr) => (
                <Column md={4} key={tr.section + tr.type + tr.permission}>
                  <Paper>
                    <Box p={1}>
                      <strong>
                        '
                        {tr.section}
                        ' - '
                        {tr.type}
                        ' - '
                        {tr.permission}
                        '
                      </strong>
                      <br />
                      <ACLWrapper section={tr.section} type={tr.type} permission={tr.permission}>Tada!! You can see me!</ACLWrapper>
                    </Box>
                  </Paper>
                </Column>
              ))
            }
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestACLWrapper;
