import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: '$spin 2s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }));

type PropsIn= {
  children: React.ReactNode,
  rotate?: boolean,
  className?: string,
}

export default function IconWrapper({children, className, rotate, ...props }:PropsIn){
const classes = useStyles();

  return (
    <Box
      display="inline-flex"
      className={`icon-wrapper ${className || ''} ${rotate ? classes.rotateIcon : ''}`} 
      {...props}>
      {children}
    </Box>
  );
}
