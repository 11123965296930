import { GenericValueType } from 'query/track';
import { AthleteType, getAthleteFragment, getAthleteFragmentName } from './athlete';
import { AthleteSessionType, getAthleteSessionFragment, getAthleteSessionFragmentName } from './athlete_session';
import { DeviceType } from './device';
import { TeamType } from './team';
import { GroundType } from './ground';
import { IncomingFileType } from './file';

export type TrackTrackAccelerativeEventsDataframeStateChoices = 'INVALID' | 'PROCESSING' | 'ERROR' | 'READY';
export type TrackTrackSpeedEventsDataframeStateChoices = 'INVALID' | 'PROCESSING' | 'ERROR' | 'READY';
export type TrackType = {
  accelerativeEventsDataframeState: TrackTrackAccelerativeEventsDataframeStateChoices;
  athlete?: AthleteType;
  athleteWeight?: number;
  createdOn?: Date;
  device?: DeviceType;
  dt: number;
  ecDragK: number;
  eventSlope: number;
  goalkeeper: boolean;
  ground: GroundType;
  id: string;
  incomingFile: IncomingFileType;
  incomingFileFormat: string;
  notes: string;
  sampleCount: number;
  sampleStoreIdent: number;
  tagId: string;
  team: TeamType;
  timestamp: Date;
  timezone: string;
  updatedOn?: Date;
  withEcW: boolean;
  vo2Max: GenericValueType;
  speedEventsThreshold: {
    value?: number;
    unit?: string;
    uom?: string;
  };
  speedEventsDuration: {
    value?: number;
    unit?: string;
    uom?: string;
  };
  accelerationEventsThreshold: {
    value?: number;
    unit?: string;
    uom?: string;
  };
  accelerationEventsDuration: {
    value?: number;
    unit?: string;
    uom?: string;
  };
  decelerationEventsThreshold: {
    value?: number;
    unit?: string;
    uom?: string;
  };
  decelerationEventsDuration: {
    value?: number;
    unit?: string;
    uom?: string;
  };
  athletesessionSet: AthleteSessionType[];
  nSessions?: number;
  duration?: GenericValueType;
  hasCardio?: boolean;
};
export type TrackResponseType = 'with_athlete' | 'with_athlete_session';
export const getTrackFragmentName = (types: TrackResponseType[]) => `TrackTypeFields${types
  .sort()
  .map((t) => `_${t}`)
  .join('')}`;
export const getTrackFragment = (types: TrackResponseType[]) =>
  // tslint:disable-next-line: prefer-template
  (
    `
    ${types.includes('with_athlete') ? getAthleteFragment([]) : ''}
    ${types.includes('with_athlete_session') ? getAthleteSessionFragment([]) : ''}
    fragment ${getTrackFragmentName(types)} on TrackType {
      id
      tagId${
    types.includes('with_athlete')
      ? `
      athlete {
        ...${getAthleteFragmentName([])}
      }`
      : ''
    }
      timestamp
      timezone
      dt
      sampleCount
      notes
      eventSlope
      ecDragK
      withEcW
      sampleStoreIdent
      goalkeeper
      speedEventsThreshold {
        value
        unit
        uom
      }
      speedEventsDuration {
        value
        unit
        uom
      }
      accelerationEventsThreshold {
        value
        unit
        uom
      }
      accelerationEventsDuration {
        value
        unit
        uom
      }
      decelerationEventsThreshold {
        value
        unit
        uom
      }
      decelerationEventsDuration {
        value
        unit
        uom
      }${
    types.includes('with_athlete_session')
      ? `
      athletesessionSet {
        ...${getAthleteSessionFragmentName([])}
      }`
      : ''
    }
      nSessions
      duration {
        value
        unit
        uom
      }
    }
  `
  );
