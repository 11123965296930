import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useQueryParams } from '../components/utils/hooks';
import { ME } from '../query/team';
import { globalEventsChannel } from '../lib/events';

export default function useTeamParams() {
  const client = useApolloClient();
  const [queryParams, setQueryParams] = useQueryParams();
  const cacheres = client.readQuery({ query: ME });

  useEffect(() => {
    const lastTeamId = cacheres.res?.lastTeam?.id;

    if (queryParams.teamId) {
      if (queryParams.teamId !== lastTeamId) {
        setTimeout(() => {
          globalEventsChannel.emit('onTeamChange', queryParams.teamId);
        }, 500);
      }
    } else {
      setQueryParams({ teamId: lastTeamId });
    }
  }, []);
}
