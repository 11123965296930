import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import DeleteIcon from '@images/header-icons/DeleteIcon';
import InfoIcon from '@material-ui/icons/Info';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import BaseButton from '../form/BaseButton';

function TestMediaRecorder() {
  const { t } = useTranslation();

  const videoRef = useRef<any>();
  const recordingRef = useRef<any>();
  const downloadButton = useRef<any>();
  const stopButton = useRef<any>();

  const [files, setFiles] = useState<File[]>([]);
  const [screenshot, setScreenshot] = useState<any>(null);
  const [screenshotVideo, setScreenshotVideo] = useState<any>(null);
  const [details, setDetails] = useState<any>({});
  const [isRecording, setIsRecording] = useState(false);

  const takeScreenShot = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: { mediaSource: 'screen' },
    });
    const track = stream.getVideoTracks()[0];
    const imageCapture = new ImageCapture(track);

    setTimeout(async () => {
      const bitmap = await imageCapture.grabFrame();
      track.stop();

      const canvas = document.createElement('canvas');
      canvas.width = bitmap.width;
      canvas.height = bitmap.height;
      const context = canvas.getContext('2d');
      context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
      const image = canvas.toDataURL();

      const res = await fetch(image);
      const buff = await res.arrayBuffer();
      const file = new File([buff], `photo_${new Date()}.jpg`, {
        type: 'image/jpeg',
      });

      setFiles((prevState) => [
        ...prevState,
        ...[{
          ...file,
          ...{
            path: file.name,
          },
        }],
      ]);

      setScreenshot(canvas.toDataURL('image/jpeg')
        .replace('image/jpeg', 'image/octet-stream'));
      document.body.classList.remove('screenshot-running');
    }, 400);
  };

  const takeScreenShotVideo = async () => {
    async function startRecording() {
      setIsRecording(true);
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      })
        .then((str) => {
          if (videoRef.current) {
            videoRef.current.srcObject = str;
            videoRef.current.captureStream = videoRef.current.captureStream || videoRef.current.mozCaptureStream;
          }

          return str;
        });

      const recorder = new MediaRecorder(stream);

      const chunks = [];
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = (_) => {
        const blob = new Blob(chunks, { type: chunks[0].type });

        stream.getVideoTracks()[0].stop();
        const filename = 'capturedVideo';

        if (recordingRef.current) {
          recordingRef.current.src = window.URL.createObjectURL(blob);
        }

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
        } else if (downloadButton.current) {
          downloadButton.current.href = window.URL.createObjectURL(blob);
          downloadButton.current.download = filename;
        }
      };

      stopButton.current.addEventListener('click', () => {
        recorder.stop();
        setIsRecording(false);
        document.body.classList.remove('screenshot-running');
      });

      recorder.start();
    }

    startRecording();
  };

  const handleTakeScreenshot = () => {
    document.body.classList.add('screenshot-running');
    takeScreenShot();
  };

  const handleTakeScreenshotVideo = () => {
    document.body.classList.add('screenshot-running');
    takeScreenShotVideo();
  };

  const getInfo = () => {
    setDetails({
      screen: {
        availHeight: window.screen.availHeight,
        availLeft: window.screen.availLeft,
        availTop: window.screen.availTop,
        availWidth: window.screen.availWidth,
        colorDepth: window.screen.colorDepth,
        height: window.screen.height,
        isExtended: window.screen.isExtended,
        pixelDepth: window.screen.pixelDepth,
        orientation: window.screen.orientation.type,
      },
      navigator: {
        appCodeName: window.navigator.appCodeName,
        appName: window.navigator.appName,
        appVersion: window.navigator.appVersion,
        deviceMemory: window.navigator.deviceMemory,
        language: window.navigator.language,
        languages: window.navigator.languages.toString(),
        platform: window.navigator.platform,
        product: window.navigator.product,
        productSub: window.navigator.productSub,
        userAgent: window.navigator.userAgent,
        vendor: window.navigator.vendor,
      },
    });
  };

  return (
    <>
      <Typography id="acl-wrapper-test" variant="h5" gutterBottom>Media Recorder & infos</Typography>
      <Paper>
        <Box p={2}>
          <Row>

            <Column md={6}>

              <Paper>
                <Box p={2}>
                  <Row>
                    <Column md={6}>
                      {
                          !screenshot
                          && (
                            <BaseButton
                              key="send"
                              color="primary"
                              startIcon={<AspectRatioIcon />}
                              onClick={() => {
                                handleTakeScreenshot();
                              }}
                              noMargins
                            >
                              {t('issues.content.takeScreenshot', 'take screenshot')}
                            </BaseButton>
                          )
                        }

                      {
                          !!screenshot
                          && (
                            <BaseButton
                              key="send"
                              color="danger"
                              startIcon={<DeleteIcon />}
                              onClick={() => {
                                setScreenshot(null);
                              }}
                              noMargins
                            >
                              {t('issues.content.removeScreenshot', 'remove screenshot')}
                            </BaseButton>
                          )
                        }
                    </Column>
                    <Column md={6}>
                      PREVIEW
                      <div style={{
                        border: '1px solid #aaa',
                        borderRadius: '4px',
                        padding: '4px',
                      }}
                      >
                        {
                            screenshot
                            && (
                              <Column xs={6}>
                                <Box style={{ border: '1px solid #aaa' }}>
                                  <img src={screenshot} width="100%" alt="" />
                                </Box>
                              </Column>
                            )
                          }
                      </div>
                    </Column>
                  </Row>
                </Box>
              </Paper>

              <Paper>
                <Box p={2} mt={2}>
                  <Row>
                    <hr />
                    <Column md={12}>
                      <BaseButton
                        key="getinfo"
                        color="success"
                        startIcon={<InfoIcon />}
                        onClick={() => {
                          getInfo(null);
                        }}
                        noMargins
                      >
                        {t('issues.content.getInfo', 'get info')}
                      </BaseButton>
                    </Column>

                    <Column md={12}>
                      Screen
                      <div className="info-window">
                        <ul>
                          {
                              details.screen
                              && Object.keys(details.screen)
                                .map((pi) => (
                                  <li>
                                    <b>{pi}</b>
                                    :
                                    {' '}
                                    {details.screen[pi]}
                                  </li>
                                ))
                            }
                        </ul>
                      </div>

                      Navigator
                      <div className="info-window">
                        <ul>
                          {
                              details.navigator
                              && Object.keys(details.navigator)
                                .map((pi) => (
                                  <li>
                                    <b>{pi}</b>
                                    :
                                    {' '}
                                    {details.navigator[pi]}
                                  </li>
                                ))
                            }
                        </ul>
                      </div>
                    </Column>
                  </Row>
                </Box>
              </Paper>

            </Column>

            <Column md={6}>
              <Paper>
                <Box p={2}>
                  <Row>
                    <Column>
                      {
                      !screenshotVideo
                      && (
                        <BaseButton
                          key="send"
                          color="primary"
                          startIcon={<AspectRatioIcon />}
                          onClick={() => {
                            handleTakeScreenshotVideo();
                          }}
                          noMargins
                        >
                          {t('issues.content.takeScreenshotVideo', 'take video')}
                        </BaseButton>
                      )
                    }

                      {
                      !!screenshotVideo
                      && (
                        <BaseButton
                          key="send"
                          color="danger"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            setScreenshotVideo(null);
                          }}
                          noMargins
                        >
                          {t('issues.content.removeScreenshotVideo', 'remove video')}
                        </BaseButton>
                      )
                    }
                    </Column>

                    <Column>
                      <div>
                        PREVIEW
                        <div style={{
                          border: '1px solid #aaa',
                          borderRadius: '4px',
                          padding: '4px',
                        }}
                        >
                          <video id="preview" width="320" height="240" autoPlay muted ref={videoRef} />
                        </div>
                      </div>

                      <div>
                        RECORDED
                        <div style={{
                          border: '1px solid #aaa',
                          borderRadius: '4px',
                          padding: '4px',
                        }}
                        >
                          <video id="recording" width="320" height="240" controls ref={recordingRef} />
                        </div>
                      </div>

                      <a id="stop-video" type="button" ref={stopButton}>STOP RECORDING</a>
                      <a className="debug-btn" type="button" ref={downloadButton}>DOWNLOAD</a>
                    </Column>
                  </Row>
                </Box>
              </Paper>
            </Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestMediaRecorder;
