import React, { useState } from 'react';
import {
  Box, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { SideDrawer } from '../layout/SideDrawer';
import DropdownButton from '../DropdownButton';
import Checkbox from '../form/Checkbox';
import { TextField } from '../form/TextField';

const players = [
  { id: 1, name: 'Mario Rossi' },
  { id: 2, name: 'Pino Verdi' },
  { id: 3, name: 'Angelo Bianchi' },
  { id: 4, name: 'Tramonti Arancio' },
  { id: 5, name: 'Alba Rosa' },
];

const fields = {
  single: {
    label: 'Single',
    values: [
      { id: 'kpi11', name: 'KPI 1', type: 'checkbox' },
    ],
  },
  multiple: {
    label: 'Multiple',
    values: [
      { id: 'kpi21', name: 'KPI 1', type: 'input' },
      { id: 'kpi22', name: 'KPI 2', type: 'input' },
    ],
  },
  multipleMixed: {
    label: 'Multiple mixed',
    values: [
      { id: 'kpi31', name: 'KPI 1', type: 'input' },
      { id: 'kpi32', name: 'KPI 2', type: 'checkbox' },
      { id: 'kpi33', name: 'KPI 3', type: 'input' },
    ],
  },
};

function TestMultiEditSideDrawer() {
  const [open, setOpen] = useState(false);
  const [fieldValues, setFieldValues] = useState<string[]>([]);
  const [inputValues, setInputValues] = useState<any>({});

  return (
    <>
      <Paper>
        <Box p={2} mt={4}>
          <Typography variant="h5" gutterBottom>Side Drawer with multi edit</Typography>
          <Button variant="outlined" onClick={() => setOpen(true)}>Open side drawer</Button>
        </Box>
      </Paper>

      <SideDrawer
        open={open}
        onClose={() => setOpen(false)}
        actionsLeft={[
          <Button key="close" onClick={() => setOpen(false)}>Close</Button>,
        ]}
        actionsRight={[
          <Button key="save" onClick={() => setOpen(false)}>Save</Button>,
        ]}
      >
        <DropdownButton
          label="Select field"
          values={fieldValues}
          popoverContent={Object.keys(fields).map((k) => ({
            value: k,
            label: inputValues[k]
              ? (
                <>
                  {fields[k].label}
                  <Chip
                    style={{ marginLeft: 'auto' }}
                    size="small"
                    label={Object.keys(inputValues[k]).length}
                    color="primary"
                  />
                </>
              )
              : fields[k].label,
            onClick: () => setFieldValues([k]),
          }))}
        />

        <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Athlete</TableCell>
                {
                  fieldValues
                  && fieldValues[0]
                  && fields[fieldValues[0]]
                  && fields[fieldValues[0]].values.map((f) => (
                    <TableCell align="right" key={f.id}>{f.name}</TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                players.map((p) => (
                  <TableRow key={p.id}>
                    <TableCell component="th" scope="row">
                      {p.name}
                    </TableCell>

                    {
                      fieldValues && fieldValues[0] && fields[fieldValues[0]]
                      && fields[fieldValues[0]].values.map((f) => (
                        <TableCell align="right" key={f.id}>
                          {
                            f.type === 'checkbox'
                              ? (
                                <Checkbox
                                  checked={inputValues[fieldValues[0]]
                                    && inputValues[fieldValues[0]][p.id]
                                    && inputValues[fieldValues[0]][p.id][f.id]}
                                  onChange={(e) => setInputValues((prev) => ({
                                    ...prev,
                                    [fieldValues[0]]: {
                                      ...prev[fieldValues[0]],
                                      ...{
                                        [p.id]: {
                                          [f.id]: e.currentTarget.checked,
                                        },
                                      },
                                    },
                                  }))}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                  size="small"
                                />
                              )
                              : (
                                <TextField
                                  id="outlined-basic"
                                  label={undefined}
                                  size="small"
                                  value={inputValues[fieldValues[0]]
                                    && inputValues[fieldValues[0]][p.id]
                                    && inputValues[fieldValues[0]][p.id][f.id]}
                                  onChange={(e) => setInputValues((prev) => ({
                                    ...prev,
                                    [fieldValues[0]]: {
                                      ...prev[fieldValues[0]],
                                      ...{
                                        [p.id]: {
                                          [f.id]: e.target.value,
                                        },
                                      },
                                    },
                                  }))}
                                />
                              )
                          }
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </SideDrawer>
      <Divider style={{ margin: '1rem' }} />
    </>
  );
}

export default TestMultiEditSideDrawer;
