import React from 'react';
import Box from '@material-ui/core/Box';

export default function ContentWrapper({ children }) {
  return (
    <Box pt={2} px={2} pb={12} overflow="auto" id="main-content-wrapper">
      {children}
    </Box>
  );
}
