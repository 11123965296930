import { gql } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core/types';
import { format as dateFormat } from 'date-fns';
import { ExportFormat, ExportTarget } from '../lib/export';

export const EXPORT_DATETIME_FORMAT = 'yyyyMMdd-HHmm';
export const EXPORT_DATE_FORMAT = 'ddMMMyyyy';

export const athleteSessionListOutcomingFilename = (
  format: ExportFormat,
) => `details_${dateFormat(new Date(), EXPORT_DATETIME_FORMAT)}.${format}`;

export function athleteSessionListOutcomingVariables(
  format: ExportFormat,
  teamId,
  templateId,
  exportContext,
): OperationVariables {
  return {
    filename: athleteSessionListOutcomingFilename(format),
    target: ExportTarget.ATHLETE_SESSION_LIST,
    teamId,
    templateId,
    format,
    data: JSON.stringify(exportContext),
  };
}

export const CREATE_OUTCOMING_FILE = gql`
  mutation CreateOutcomingFile(
      $teamId: ID!, 
      $target: String!, 
      $format: ExportFormat!, 
      $templateId: ID!, 
      $data: JSONString!, 
      $filename: String!
  ){
    res: createOutcomingFile(
            teamId: $teamId
            target: $target
            format: $format
            templateId: $templateId            
            data: $data
            filename: $filename) {
        id
        state
        filename
    }
  }
`;

export const CREATE_MULTIPLE_OUTCOMING_FILE = gql`
  mutation CreateMultipleOutcomingFile(
      $teamId: ID!, 
      $templateId: ID!,
      $format: ExportFormat!, 
      $targetIds: [ID]!, 
      $type: ExportTarget!,
  ){
    res: massiveExport(
            teamId: $teamId,
            templateId: $templateId,
            format: $format, 
            targetIds: $targetIds,
            type: $type,
          ) 
  }
`;


export const DELETE_REPORT = gql`
mutation deleteOutcomingFile($outcomingFileListId:[ID]!){
  res: deleteOutcomingFile(outcomingFileListId: $outcomingFileListId)
}
`;

export const EXPORT_ZIP_OUTCOMING_FILES = gql`
mutation groupReports($teamId: ID!, $outcomingList:[ID]!){
  res: groupReports(teamId: $teamId, outcomingList: $outcomingList)
}
`;
