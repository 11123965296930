import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Popover } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import BaseButton from '../form/BaseButton';

type PopoverContentType = {
  className?: string;
  statsName: string;
  onClick: () => void;
};

type PropsIn = {
  badge?: number;
  collapseOnMobile?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
  active?: boolean;
  label?: string;
  popoverContent?: PopoverContentType[];
  count: number;
  position?: 'left' | 'center' | 'right';
  tooltip?: string;
};

export default function MyStatsPopover(props: PropsIn) {
  const {
    badge,
    collapseOnMobile,
    disabled,
    icon,
    active,
    label,
    popoverContent,
    count,
    position,
    tooltip,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchor);
  const id = open ? 'popover' : undefined;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const positionProps = {
    anchorOrigin: {
      vertical: 40,
      horizontal: position || 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: position || 'left',
    },
  };

  return (
    <div>
      <BaseButton
        tooltipText={t('stats.labels.myStats', 'my stats')}
        onClick={handleClick}
        collapseOnMobile={collapseOnMobile}
        startIcon={label && icon}
        disabled={disabled}
        isIconButton={!label}
        active={active}
        badge={badge}
      >
        {label || icon}
      </BaseButton>
      {popoverContent && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchor}
          onClose={handleClose}
          {...positionProps}
        >
          <Box>
            <Box className="my-stats-header">
              <Typography variant="subtitle1">{t('table.date', 'date')}</Typography>
              <Typography variant="subtitle1">{t('stats.labels.statName', 'stat name')}</Typography>
            </Box>
            {popoverContent?.map((element, i) => (
              <MenuItem
                key={`${element.value}-${i}`}
                value={element.value}
                className={element.className}
                onClick={() => {
                  handleClose();
                  element.onClick();
                }}
              >
                <Box style={{ display: 'flex', gap: '3rem' }}>
                  <Typography>{element.prefix}</Typography>
                  <Typography>{element.label}</Typography>
                </Box>
              </MenuItem>
            )) || null}
            <Typography
              className="show-all"
              onClick={() => {
                navigate('/my_stats');
                handleClose();
              }}
              variant="button"
            >
              {`${t('exports.showAll', 'show all')} (${count})`}
            </Typography>
          </Box>
        </Popover>
      )}
    </div>
  );
}
