import { gql } from '@apollo/client'
import { TeamSessionCategoryType } from 'models/team_session'

export type TeamSessionCategoryTypeRes = {
  content?: TeamSessionCategoryType[],
  count?: number,
  offset?: number,
  pageSize?: number,
}
export type TeamSessionCategoryTypeVars = {
  teamId?: string,
  first?: number,
  skip?: number,
  sort?: string,
  hasTeamsession?: boolean,
  hasAsp?: boolean,
  hasMpp?: boolean,
  bypassPermission?: boolean,
}
export const TSCATEGORY = gql`
  query TeamsessionCategory(
    $teamId: ID!,
    $first: Int,
    $skip: Int,
    $sort: String,
    $hasTeamsession: Boolean
    $hasAsp: Boolean,
    $hasMpp: Boolean,
    $bypassPermission: Boolean,
  ) {
    res: teamSessionsCategories(
      teamId: $teamId,
      first: $first,
      skip: $skip,
      sort: $sort,
      hasTeamsession: $hasTeamsession,
      hasAsp: $hasAsp,
      hasMpp: $hasMpp,
      bypassPermission: $bypassPermission,
    ) {
      content {
        id
        color
        name
      },
      count
      offset
      pageSize
    }
  }
`;
