import AppBar from '@material-ui/core/AppBar';
import React, { useContext } from 'react';
import { AppBarContext } from './AppBarContext';
import { ThemeOptionsContext } from '../Authenticator';

/**
 * GlobalHeader component
 *
 * @description MUI 4
 */
export default function () {
  const { themeOptions } = useContext(ThemeOptionsContext);
  const { noHeaderMargins } = themeOptions;

  const sidebarWidth = themeOptions.sideBarOpen ? 235 : 77;

  const style = {
    minHeight: noHeaderMargins ? 51 : 60,
    right: 0,
    top: 0,
    left: sidebarWidth,
    width: `calc(100% - ${sidebarWidth}px)`,
  };

  return (
    <AppBar
      style={style}
      elevation={0}
      position="fixed"
    >
      <AppBarContext.Consumer>
        {({ content }) => content}
      </AppBarContext.Consumer>
    </AppBar>
  );
}
