import { LayoutType } from '../../query/track';

/**
 * Checks, against layout object, if tab or element is enabled
 * @param layout
 * @param tabId
 * @param elementId
 */
export const elementIsEnabled = (layout: LayoutType[] | undefined, tabId: string, elementId?: string, elementValue?: string) => {
  if (!layout) {
    return true;
  }

  const tabIsVisible = layout.find((tab) => tab.key === tabId);

  const elementIsVisible = elementId
    && tabIsVisible?.value?.find((el) => el.key === elementId && (
      !elementValue
    || elementValue && (el.value === elementValue)
    ));

  return tabIsVisible && (!elementId || elementIsVisible);
};

export const elementsAreEnabled = (layout: LayoutType[] | undefined, tabId: string, elementIds?: string[]) => {
  if (!layout) {
    return true; // default enabled @TODO check if valid
  }

  return !!elementIds?.find((id) => elementIsEnabled(layout, tabId, id));
};
