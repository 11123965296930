import React from 'react'

export default function () {
    return <svg width="139" height="35" viewBox="0 0 139 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8307 18.6589C10.191 18.6589 7.40062 16.2199 7.40062 12.5614V12.4395C7.40062 8.90294 10.191 6.342 13.8307 6.342C17.4703 6.342 20.2607 8.90294 20.2607 12.4395V12.5614C20.2607 16.098 17.4703 18.6589 13.8307 18.6589ZM12.9814 35.0002C18.0769 35.0002 21.8379 33.9026 24.2643 31.4636C26.4481 29.2685 27.54 25.854 27.54 21.0979V0.610359H20.1394V4.02495C18.0769 1.82986 15.7718 0.122559 11.6469 0.122559C5.5808 0.122559 0 4.51275 0 12.4395V12.5614C0 20.3662 5.5808 24.8784 11.6469 24.8784C15.6505 24.8784 18.0769 23.293 20.2607 20.6101V21.9516C20.2607 26.7076 17.8343 29.1466 12.7388 29.1466C9.22045 29.1466 6.55137 28.2929 3.88229 26.7076L1.33454 32.1953C4.61022 34.0246 8.61384 35.0002 12.9814 35.0002Z" fill="white"/>
        <path d="M46.7089 21.0973C43.1905 21.0973 40.2788 18.1705 40.2788 13.7803V13.6584C40.2788 9.26819 43.1905 6.34139 46.7089 6.34139C50.2272 6.34139 53.1389 9.26819 53.1389 13.6584V13.7803C53.1389 18.2925 50.2272 21.0973 46.7089 21.0973ZM32.9995 34.8776H40.4001V23.5363C42.22 25.6094 44.6464 27.4387 48.5287 27.4387C54.7161 27.4387 60.5395 22.5607 60.5395 13.7803V13.6584C60.5395 4.878 54.7161 0 48.5287 0C44.6464 0 42.0986 1.82925 40.4001 4.26824V0.487801H32.9995V34.8776Z" fill="white"/>
        <path d="M86.7447 23.5369C87.1087 23.1711 87.3513 22.6833 87.3513 22.3174C87.3513 21.4638 86.6234 20.6101 85.6528 20.6101C85.1676 20.6101 84.8036 20.854 84.5609 21.0979C82.6198 22.9272 80.3147 24.1467 77.039 24.1467C72.4288 24.1467 68.4252 20.976 67.8186 15.2443H86.6234C87.594 15.2443 88.4432 14.5126 88.4432 13.4151C88.4432 6.46395 84.0757 0.122559 76.3111 0.122559C69.1531 0.122559 63.8149 6.22005 63.8149 13.7809V13.9029C63.8149 21.9516 69.6384 27.5613 76.7964 27.5613C81.2853 27.4393 84.197 25.854 86.7447 23.5369ZM67.8186 12.3175C68.3038 7.19564 71.7009 3.41521 76.3111 3.41521C81.5279 3.41521 84.197 7.56149 84.6823 12.3175H67.8186Z" fill="white"/>
        <path d="M89.5356 25.6101C89.5356 26.5857 90.2636 27.4393 91.3555 27.4393C92.0834 27.4393 92.5687 27.0735 93.054 26.4637L101.061 16.2199L109.19 26.5857C109.675 27.1954 110.16 27.5613 110.888 27.5613C111.98 27.5613 112.708 26.8296 112.708 25.732C112.708 25.2442 112.465 24.7564 112.101 24.2686L103.366 13.537L111.737 3.29326C112.101 2.92741 112.223 2.56155 112.223 1.9518C112.223 0.854257 111.495 0.122559 110.403 0.122559C109.675 0.122559 109.19 0.488412 108.704 1.09816L101.061 10.8541L93.4179 1.09816C92.9326 0.488412 92.4474 0.122559 91.7194 0.122559C90.6275 0.122559 89.8996 0.854257 89.8996 1.9518C89.8996 2.4396 90.1422 2.92741 90.5062 3.41521L98.7561 13.537L90.0209 24.3906C89.7783 24.7564 89.5356 25.1223 89.5356 25.6101Z" fill="white"/>
        <path d="M136.73 23.5369C137.094 23.1711 137.336 22.6833 137.336 22.3174C137.336 21.4638 136.608 20.6101 135.638 20.6101C135.152 20.6101 134.788 20.854 134.546 21.0979C132.605 22.9272 130.3 24.1467 127.024 24.1467C122.414 24.1467 118.41 20.976 117.803 15.2443H136.608C137.579 15.2443 138.428 14.5126 138.428 13.4151C138.428 6.46395 134.061 0.122559 126.296 0.122559C119.138 0.122559 113.8 6.22005 113.8 13.7809V13.9029C113.8 21.9516 119.623 27.5613 126.781 27.5613C131.391 27.4393 134.303 25.854 136.73 23.5369ZM117.925 12.3175C118.41 7.19564 121.807 3.41521 126.417 3.41521C131.634 3.41521 134.303 7.56149 134.788 12.3175H117.925Z" fill="white"/>
    </svg>
}

