import { gql } from '@apollo/client';
import {
  AthleteType, getAthleteFragment, getAthleteFragmentName, PlayingRoleTypeRes,
} from 'models/athlete';

export enum AthletesTabType {
  CURRENT='CURRENT',
  ACTIVE='ACTIVE',
  EXPIRED='EXPIRED'
}

export type AthleteTypeRes = {
  content?: AthleteType[],
  count?: number,
  offset?: number,
  pageSize?: number,
}

export type AthleteTypeVars = {
  inUse?: boolean,
  hasAthletesession?: boolean,
  teamId?: string,
  excludeTeamId?: string,
  clubId?: string,
  first?: number,
  skip?: number,
  sort?: string,
  hasAsp?: boolean | null,
  hasMpp?: boolean | null,
  onDate?: Date | null,
  tab?: AthletesTabType,
}

export const ATHLETES = gql`
  ${getAthleteFragment(['with_deviceSet'])}
  query Athletes(
    $teamId: ID,
    $onDate: Date,
    $clubId: ID,
    $first: Int,
    $skip: Int,
    $sort: String,
    $hasAthletesession: Boolean
    $tab: AthletesTabType,
  ) {
    res: athletes(
      teamId: $teamId,
      onDate: $onDate,
      clubId: $clubId,
      first: $first,
      skip: $skip,
      sort: $sort,
      hasAthletesession: $hasAthletesession,
      tab: $tab,
    ) {
      content {
        ...${getAthleteFragmentName(['with_deviceSet'])}
      },
      count
      offset
      pageSize
    }
  }
`;

export const CLUB_ATHLETES_BASIC = gql`
  query Athletes($clubId: ID, $sort: String, $onDate: Date) {
    res: athletes(clubId: $clubId, sort: $sort, onDate: $onDate) {
      content {
      id
      name
      hasTracks
      }
    }
  }
`;

export type AthleteOptionsVars = {
  teamId?: string,
  onDate?: Date | null,
  sort?: string,
  hasAsp?: boolean,
  hasMpp?: boolean,
  hasAthletesession?: boolean,
  hasRpe?: boolean,
  hasTracks?: boolean,
  actual?: boolean | null,
};

export type AthleteOptionsRes = {
  res: {
    content?: {
      id: string,
      name: string,
    }[],
  },
};

export const ATHLETES_OPTIONS = gql`
  query TeamAthlestes($teamId: ID, $sort: String, $onDate: Date) {
    res: athletes(teamId: $teamId, sort: $sort, onDate: $onDate) {
      content {
        id
        name
      }
    }
  }
`;

export const ATHLETES_FILTER_OPTIONS = gql`
  query TeamAthletes(
    $teamId: ID,
    $onDate: Date,
    $sort: String,
    $hasAthletesession: Boolean,
    $hasRpe: Boolean,
    $hasAsp: Boolean,
    $hasMpp: Boolean,
    $hasTracks: Boolean,
    )
      {
        res: athletes(
        teamId: $teamId,
        onDate: $onDate,
        sort: $sort,
        hasAthletesession: $hasAthletesession,
        hasRpe: $hasRpe,
        hasAsp: $hasAsp,
        hasMpp: $hasMpp,
        hasTracks: $hasTracks,
      ) {
        content {
          id
          firstName
          lastName
        }
      }
  }
`;

export type AthleteAndRolesRes = {
  resAhletes: AthleteTypeRes,
  resRoles: PlayingRoleTypeRes,
}
export const ATHLETES_AND_ROLES = gql`
  ${getAthleteFragment(['with_deviceSet'])}
  query AthletesAndRoles(
    $inUse: Boolean,
    $teamId: ID!,
    $sort: String,
    $hasAthletesession: Boolean,
    $onDate: Date,
  ) {
    resAthletes: athletes(
      teamId: $teamId,
      sort: $sort,
      hasAthletesession: $hasAthletesession,
      onDate: $onDate,
    ) {
      content {
        ...${getAthleteFragmentName(['with_deviceSet'])}
      },
      count
      offset
      pageSize
    }
    resRoles: roles(
      inUse: $inUse,
      teamId: $teamId,
      hasAthletesession: $hasAthletesession
    ) {
      content {
        id
        name
      }
      count
      offset
      pageSize
    }
  }
`;

export const ATHLETE = gql`
    query Athlete(
        $id: ID!
    ) {
        res: athlete(
            id: $id
        ) {
            firstName
            lastName
            shortName
            birthdate
            customId
            thumbnail
            isDeletable
            deviceSet {
                id
                deviceId
                serialId
                active
            }
            teams{
              id
              locked
            }
            playerSet {
                id
                team {
                  id
                  league
                  season
                  name
                  playingroleSet {
                    id
                    name
                  }
                }
                startDate
                endDate
                number
            }
            hrMax {
                value, unit, uom
            }
            hrMin {
                value, unit, uom
            }
            v0 {
                value, unit, uom
            }
            a0 {
                value, unit, uom
            }
            speedMax {
                value, unit, uom
            }
            vo2Max {
                value, unit, uom
            }
            athletechangelogSet {
                id,
                hrMax {
                    value, unit, uom
                }
                hrMin {
                    value, unit, uom
                }
                speedMax {
                    value, unit, uom
                }
                vo2Max {
                    value, unit, uom
                }
                v0 {
                    value, unit, uom
                }
                a0 {
                    value, unit, uom
                }
                setDatetime
            }
            
        }
    }
`;

export const DEFAULT_ATHLETE_PARAMETERS = gql`
  query {
    res: defaultAthleteValues{
      defaultVo2Max {
        uom
        value
        unit
      }
      defaultMaxSpeed{
        uom
        value
        unit
      }
      defaultHrMin{
        uom
        value
        unit
      }
      defaultHrMax{
        uom
        value
        unit
      }
      defaultA0{
        uom
        value
        unit
      }
      defaultV0{
        uom
        value
        unit
      }
      
    }
}
`;

export const ATHLETE_CHANGELOG = gql`

query AthleteChangelogSetQuery(
  $athleteId: ID!
  $first: Int,
  $skip: Int,
  $sort: String,
  )
  {
    res: athleteChangelogSetQuery(
      athleteId: $athleteId
      first: $first,
      skip: $skip,
      sort: $sort,
      )
      {
      content {
          id
          createdOn
          updatedOn
          setDatetime
          email
          hrMax {
              value, unit, uom
            }
          hrMin {
              value, unit, uom
            }
          speedMax {
            value, unit, uom
          }
          vo2Max {
            value, unit, uom
          }
          v0 {
          value, unit, uom
          }
          a0 {
            value, unit, uom
          }
            },
      count
          },
      },
`;