import React from 'react';
import {
  MenuItem, Popover, PopoverProps, withStyles,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CustomButton from './CustomButton';
import StyledHeaderIconButton from './HeaderIconButton';

type PopoverContentType = {
  value: string;
  label: string;
  onClick: () => void;
};

type PropsIn = {
  disabled?: boolean;
  icon?: React.ReactElement;
  label: string;
  maxValues?: number;
  multiple?: boolean;
  popoverContent: PopoverContentType[];
  small?: boolean;
  values: string[];
  anchor?: 'left' | 'right';
  multipleSelection?: boolean;
};

const StyledPopover = withStyles({
  root: {
    '& .Mui-selected': {
      color: 'var(--primary-color)',
    },
  },
})((props: PopoverProps) => <Popover {...props} style={{ marginTop: '4px' }} />);

export default function DropdownButton(props: PropsIn) {
  const {
    disabled, icon, label, maxValues, multiple, popoverContent, small,
    values, anchor, multipleSelection,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="box-tracks-buttons">
      {label ? (
        <CustomButton
          action={handleClick}
          icon={icon}
          label={label}
          disabled={disabled}
          isDropdown
          size={small ? 'small' : 'medium'}
        />
      ) : (
        <StyledHeaderIconButton onClick={handleClick}>{icon}</StyledHeaderIconButton>
      )}
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: anchor || 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: anchor || 'left',
        }}
      >
        {popoverContent.map((element) => {
          const active = values.includes(element.value);
          return (
            <MenuItem
              className="dropdown--button__small"
              key={element.value}
              value={element.value}
              onClick={() => {
                element.onClick();
                if (!multipleSelection) {
                  handleClose();
                }
              }}
              dense
              disabled={!active && maxValues && values.length >= maxValues}
              aria-selected={active}
              selected={active}
            >
              {multiple && <Checkbox checked={active} />}
              {element.label}
            </MenuItem>
          );
        })}
      </StyledPopover>
    </div>
  );
}
