import React, { useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import EditableChip from '../form/EditableChip';

function TestEditableChip() {
  const [label, setLabel] = useState('base label');
  return (
    <>
      <Paper>
        <Box p={2} mt={4}>
          <Typography variant="h5" gutterBottom>Editable Chip</Typography>
          <EditableChip
            label={label}
            onDelete={console.trace}
            onSave={setLabel}
          />
        </Box>
      </Paper>
      <Divider style={{ margin: '1rem' }} />
    </>
  );
}

export default TestEditableChip;
