import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Row } from '../layout/Row';
import { Column } from '../layout/Column';
import { Dropzone } from '../form/Dropzone';

function TestDropzone() {
  return (
    <>
      <Typography id="acl-wrapper-test" variant="h5" gutterBottom>Dropzone</Typography>
      <Paper>
        <Box p={2}>
          <Row>
            <Column md={6}>
              <Dropzone
                autoUpload
                method="POST"
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                dropzoneText="Drop here"
                resizeToMaxValues={[800, 800]}
                setFiles={console.info}
              />
            </Column>
          </Row>
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestDropzone;
