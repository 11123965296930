import React from 'react';
import Box from '@material-ui/core/Box';
import TitleView from 'components/layout/TitleView';
import { SingleStackedColumnData, StackedColumnChart } from '../charts/StackedColumnChart';
import { icons, IconWrapper } from '../../lib/icons';
import { eventsColorByName } from '../charts/colors';
import { UnitsType, UomType } from '../utils/unitsFormatter';

interface PropsIn {
  iconName: icons;
  chartLabel: string;
  chartCategories?: string[];
  data: {[k:string]: SingleStackedColumnData[]};
  yMax?: number;
  yLabel: string;
  hideXLabel?: boolean;
  uom: string | UomType;
  unit: string | UnitsType;
}

export default function TrackStackedChart(props: PropsIn) {
  const {
    iconName, chartLabel, chartCategories, data, yMax,
    yLabel, hideXLabel, uom, unit,
  } = props;
  return (
    <Box className="chart-widget">
      <Box className="title">
        <TitleView
          title={chartLabel}
          prefixIcon={<IconWrapper name={iconName} size="medium" color="primary" />}
          align="left"
        />
      </Box>
      <StackedColumnChart
        data={data}
        colors={eventsColorByName}
        categories={chartCategories}
        yMax={yMax}
        yLabel={yLabel}
        unit={unit}
        uom={uom}
        skipKey
        hideXLabel={hideXLabel}
      />
    </Box>
  );
}
