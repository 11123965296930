import { Line, Text, Layer } from 'react-konva';
import React from 'react';
import Konva from 'konva';
import { useTranslation } from 'react-i18next';
import { DEFAULT_GROUND_COLOR, PATH_WIDTH, PATH_WIDTH_HOVER } from '../track/ground/constants';

interface PlayersLinkI {
  distance: number
  end: [number, number],
  start: [number, number]
}

interface PlayersLinkProps {
  playersLines: PlayersLinkI[];
  removeLink: (id: number) => void;
  groundSurfaceColor?: string;
}

const isValidLink = (point1: [number, number], point2: [number, number]) => {
  return point1[0] !== 0 && point1[1] !== 0
  && point2[0] !== 0 && point2[1] !== 0;
}

/**
 * Renders a set of player links on a canvas layer.
 *
 * @param props - The props object containing playersLines and removeLink.
 * @returns - The rendered layer of player links.
 */
const PlayersLinks = (props: PlayersLinkProps) => {
  const {
    playersLines,
    removeLink,
    groundSurfaceColor,
  } = props;

  const [isHover, setHover] = React.useState({});

  const handleMouseEnter = (idx: number) => {
    setHover((prev) => ({
      ...prev,
      ...{ [idx]: true },
    }));
  };

  const handleMouseLeave = (idx: number) => {
    setHover((prev) => ({
      ...prev,
      ...{ [idx]: false },
    }));
  };

  const { t } = useTranslation();

  return (
    <Layer id="layer-players-links">
      {
        playersLines.map((player, idx) =>  isValidLink(player.start, player.end) ? (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={idx}>
            <Line
              hitStrokeWidth={3}
              onClick={() => removeLink(idx)}
              onMouseEnter={() => handleMouseEnter(idx)}
              onMouseLeave={() => handleMouseLeave(idx)}
              points={[
                player.start[0],
                player.start[1],
                player.end[0],
                player.end[1],
              ]}
              stroke="red"
              strokeWidth={isHover[idx] ? PATH_WIDTH_HOVER : PATH_WIDTH}
            />
            <Text
              fill={groundSurfaceColor === 'rgb(0,221,181)' || groundSurfaceColor === DEFAULT_GROUND_COLOR ? '#004d41' : '#ccc'}
              height={10}
              fontSize={10}
              fontStyle={isHover[idx] ? 'bold' : 'normal'}
              fontFamily="Roboto"
              onClick={() => removeLink(idx)}
              onMouseEnter={() => handleMouseEnter(idx)}
              onMouseLeave={() => handleMouseLeave(idx)}
              text={`${String(player.distance)}`}
              width={30}
              x={(player.start[0] + player.end[0]) / 2 + 5}
              y={(player.start[1] + player.end[1]) / 2 + 5}
            />

            {
              isHover[idx]
              && (
                <Text
                  fill="#5b5b5b"
                  fontSize={12}
                  fontStyle={isHover[idx] ? 'bold' : 'normal'}
                  fontFamily="Roboto"
                  onClick={() => removeLink(idx)}
                  onMouseEnter={() => handleMouseEnter(idx)}
                  onMouseLeave={() => handleMouseLeave(idx)}
                  text={t('session.dialog.removeConnection', 'click to remove connection')}
                  x={(player.start[0] + player.end[0]) / 2 + 5}
                  y={(player.start[1] + player.end[1]) / 2 + 25}
                  sceneFunc={(con, shape) => {
                    if (isHover[idx]) {
                      con.fillStyle = '#fff4e5';
                      con.fillRect(0 - 4, -2, shape.width() + 8, shape.height() + 4);
                      (shape as Konva.Text)._sceneFunc(con);
                    }
                  }}
                />
              )
            }

          </React.Fragment>
        ) : null)
      }
    </Layer>
  );
};

export default PlayersLinks;
