import {
  Box, createStyles, makeStyles, Typography,
} from '@material-ui/core';
import React from 'react';
import { Variant } from '@material-ui/core/styles/createTypography';
// @todo probabile errato import
import { none } from 'ramda';

type PropsIn = {
  title: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  align?: 'center' | 'left' | 'right';
  variant?: 'inherit' | Variant;
  className?: string;
  color?: string;
  smallMargin?: boolean;
  size?: 'large' | 'medium' | 'small';
  noMargins?: boolean;
  maxContent?: boolean;
  isPageTitle?: boolean;
  onClick?: () => void;
};

const useStyles = makeStyles(() => createStyles({
  largeTitle: {
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'var(--font-family-alt)',
    '& h4': {
      '&.MuiTypography-root::after': {
        border: none,
      },
    },
  },
  mediumTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'var(--font-family-alt)',
    '& .MuiTypography-root::after': {
      border: none,
    },
  },
  smallTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'var(--font-family-alt)',
    '& .MuiTypography-root::after': {
      border: none,
    },
  },
}));

export default function TitleView(props: PropsIn) {
  const {
    align, className, color, prefixIcon, title, variant, suffixIcon, smallMargin, size, noMargins, maxContent, isPageTitle, onClick,
  } = props;

  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent={`${align === 'left' ? 'start' : align === 'right' ? 'end' : 'center'}`}
      alignItems="center"
      height="100%"
      width={maxContent ? 'max-content' : '100%'}
      flexDirection="row"
      marginBottom={`${noMargins || isPageTitle ? '0px' : smallMargin ? '8px' : '16px'}`}
      marginTop={isPageTitle ? '-5px' : '0px'}
      paddingBottom={isPageTitle ? '12px' : '0px'}
      className={className}
      onClick={onClick}
    >
      {prefixIcon ? <span style={{ marginRight: '8px' }}>{prefixIcon}</span> : null}
      <Typography
        className={
            `${size === 'medium' ? classes.mediumTitle : size === 'small' ? classes.smallTitle : classes.largeTitle}`
          }
        variant={variant || 'h4'}
        style={{
          color: color || 'inherit',
        }}
      >
        {title}
      </Typography>
      {suffixIcon ? <span style={{ marginLeft: '8px' }}>{suffixIcon}</span> : null}
    </Box>
  );
}
