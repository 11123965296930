import React, { useState } from 'react';
import { Box, Popover } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { GridCellParams } from '@material-ui/x-grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
    '& .MuiBox-root': {
      padding: '3px',
    },
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function categoriesExtendedCell(params: GridCellParams, width:number = 230) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const textLength = params?.value?.reduce((acc, curr) => {
    const [value, color, note] = curr.toString()
      .split('-');
    return acc + value.length * 9 + 38;
  }, 0) || 0;

  const isLong = textLength > width;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const cellContent = params?.value?.map((p) => {
    const [value, color, note] = p.toString()
      .split('-');
    return (
      <Tooltip
        arrow
        title={note}
      >
        <Box
          key={p.id}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            gap: '.75rem',
          }}
        >
          <Box
            style={{
              width: 20,
              height: 20,
              borderRadius: '100%',
              backgroundColor: color,
            }}
          />
          {value}
        </Box>
      </Tooltip>
    );
  });

  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
      }}
      data-long={isLong}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {cellContent}
      {isLong && (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
            {cellContent}
        </Popover>
      )}
    </div>
  );
}
