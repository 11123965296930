import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme, ThemeOptions } from '@material-ui/core/styles';

const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xlg: 1600,
  xl: 1600,
};

const breakpoints = createBreakpoints({
  values: BREAKPOINTS,
  keys: Object.keys(BREAKPOINTS),
});

export const ownTheme = {
  breakpoints: {
    values: BREAKPOINTS,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#828282',
      light: '#00DDB5',
      dark: '#00876F',
      contrast: '#FFFFFF',
    },
    secondary: {
      main: '#00DDB5',
      light: '#FF003C',
      dark: '#AF012A',
      contrast: '#FFFFFF',
    },
    error: {
      main: '#D50032',
      light: '#D68296',
      dark: '#B2002A',
      contrast: '#FFFFFF',
    },
    warning: {
      main: '#FF9800',
      light: '#FFB547',
      dark: '#C77700',
      contrast: '#000000DE',
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
      contrast: '#FFFFFF',
    },
    info: {
      main: '#2196F3',
      light: '#64B6F7',
      dark: '#0B79D0',
      contrast: '#FFFFFF',
    },
    text: {
      primary: '#828282',
      secondary: '#00000099',
      disabled: '#E0E0E0',
    },
  },
  typography: {
    fontFamily: ['Montserrat-Regular'].join(','),
  },
  overrides: {
    /* MuiCssBaseline: {
      '@global': {
        '@font-face': [montserrat],
      },
    }, */
    MuiPickersDay: {
      daySelected: {
        backgroundColor: 'var(--primary-color)',
        '&:hover': {
          backgroundColor: 'var(--primary-gradient-4)',
        },
      },
      dayDisabled: {
        color: 'rgba(0, 0, 0, 0.25)',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: 'var(--secondary-color)',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: 'var(--secondary-color)',
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: 'var(--secondary-color)',
        },
      },
    },
    MuiChip: {
      sizeSmall: {
        height: '22px',
      },
      root: {
        '& .MuiChip-label': {
          textOverflow: 'clip',
        },
      },
    },
    MuiAccordion: {
      rounded: {
        borderRadius: '12px',
        position: 'unset',
        '&:first-child': {
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        },
        '&:last-child': {
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px',
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'transparent',
      },
      root: {
        zIndex: 50,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 'bold',
        fontFamily: 'var(--font-family-alt)',
      },
      outlined: {
        border: '1px solid #828282',
        boxSizing: 'border-box',
        borderRadius: '21px',
        whiteSpace: 'nowrap',
        '&&:hover': {
          background: 'rgba(0, 255, 210, 0.08)',
          border: '1px solid #00DDB5',
        },
      },
      outlinedSizeLarge: {
        border: '1px solid #828282',
        boxSizing: 'border-box',
        borderRadius: '24px',
      },
      contained: {
        '&&:hover': {
          backgroundColor: 'var(--primary-color)',
        },
      },
      containedSecondary: {
        '&&:hover': {
          backgroundColor: 'rgba(213, 0, 50, 1)',
        },
      },
      textPrimary: {
        '&&:hover': {
          backgroundColor: 'rgba(0, 255, 210, 0.08)',
        },
      },
      textSecondary: {
        '&&:hover': {
          backgroundColor: 'rgba(213, 0, 50, 0.08)',
        },
      },
      outlinedSecondary: {
        color: 'var(--secondary-color)',
        border: '1px solid rgba(213, 0, 50, 0.5)',
        '&&:hover': {
          backgroundColor: 'rgba(213, 0, 50, 0.08)',
          border: '1px solid rgba(213, 0, 50, 0.5)',
        },
      },
    },
    MuiBreadcrumbs: {
      ol: {
        marginTop: '-3.5px',
        paddingBottom: '16px',
      },
      separator: {
        color: '#0000008A',
      },
      root: {
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
      },
    },
    MuiTextField: {
      root: {
        '& label.Mui-focused': {
          color: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
        },
        '&:not(.readonly):hover .MuiOutlinedInput-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--primary-color)',
        },
        '& .MuiOutlinedInput': {
          '&.Mui-focused fieldset': {
            borderColor: 'var(--primary-color)',
          },
        },
        '& .MuiOutlinedInput-inputAdornedEnd': {
          paddingTop: '12px',
          paddingBottom: '10px',
        },
      },
    },
    MuiCard: {
      root: {
        width: '200px',
        height: 'full',
        boxShadow:
          '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
      },
    },
    MuiOutlinedInput: {
      input: {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
    },
    MuiCardHeader: {
      root: {
        display: 'inline-block',
        verticalAlign: 'top',
      },
      title: {
        fontSize: '16px',
        fontWeight: 400,
        color: 'var(--secondary-color)',
      },
      subheader: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#BDBDBD',
      },
    },
    MuiCardMedia: {
      root: {
        height: '194px',
        width: 'full',
        justifyContent: 'center',
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: 'var(--font-family-alt)',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '96px',
        lineHeight: '117%',
        letterSpacing: '-1.5px',
      },
      h2: {
        fontFamily: 'var(--font-family-alt)',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '60px',
        lineHeight: '120%',
        letterSpacing: '-0.5px',
      },
      h3: {
        fontFamily: 'var(--font-family-alt)',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '48',
        lineHeight: '116.7%',
      },
      h4: {
        fontFamily: 'var(--font-family-alt)',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '30px',
        lineHeight: '123.5%',
        letterSpacing: '0.25px',
      },
      h5: {
        fontFamily: 'var(--font-family-alt)',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '133.4%',
      },
      h6: {
        fontFamily: 'var(--font-family-alt)',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '160%',
        letterSpacing: '0.15px',
      },
      body1: {
        fontFamily: 'var(--font-family-main)',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
      },
      body2: {
        fontFamily: 'var(--font-family-main)',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '143%',
        letterSpacing: '0.15px',
      },
      subtitle1: {
        fontFamily: 'var(--font-family-alt)',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '175%',
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontFamily: 'var(--font-family-main)',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '157%',
        letterSpacing: '0.15px',
      },
      caption: {
        fontFamily: 'var(--font-family-main)',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '166%',
        letterSpacing: '0.4px',
      },
      button: {
        textTransform: 'none',
        fontFamily: 'var(--font-family-alt)',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        large: {
          fontFamily: 'var(--font-family-alt)',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '15px',
          lineHeight: '26px',
          letterSpacing: '0.46px',
          textTransform: 'lowercase',
        },
        medium: {
          fontFamily: 'var(--font-family-alt)',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.4px',
          textTransform: 'lowercase',
        },
        small: {
          fontFamily: 'var(--font-family-alt)',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.4px',
          textTransform: 'lowercase',
        },
      },
    },

    MuiDropzoneArea: {
      root: {
        minHeight: '175px',
        '& img': {
          width: 'auto',
          height: 'auto',
        },
        '& .MuiDropzonePreviewList-root': {
          margin: 0,
          width: '100%',
          '& .MuiDropzonePreviewList-image': {
            boxShadow: 'none',
          },
        },
        '& .MuiDropzonePreviewList-imageContainer': {
          padding: '12px',
        },
        '&.max-uploads-1.has-files': {
          '& .MuiDropzoneArea-textContainer': {
            display: 'none',
          },
          '& .MuiDropzonePreviewList-root': {
            justifyContent: 'center',
          },
          '& .MuiDropzonePreviewList-imageContainer': {
            maxWidth: '100%',
            flexBasis: '100%',
          },
          '& .MuiDropzonePreviewList-removeButton': {
            top: '2px',
            right: '2px',
          },
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '12px',
      },
    },
    MuiTablePagination: {
      caption: {
        textTransform: 'lowercase',
      },
    },
    MuiTab: {
      root: {
        [breakpoints.up('md')]: {
          minWidth: '190px',
        },
        '&:not(.Mui-selected)': {
          backgroundColor: '#e0e0e0',
        },
        '& .MuiTypography-button': {
          fontSize: '18px',
          textTransform: 'none',
          fontFamily: 'var(--font-family-alt)',
          fontWeight: 'bold',
        },
        height: '80px',
      },
      labelIcon: {
        margin: '0 !important',
        borderTopRightRadius: '0px !important',
      },
      wrapper: {
        '& > :first-child': {
          margin: '0 !important',
        },
      },
    },
    MuiTabs: {
      flexContainer: {
        backgroundColor: '#f9f9f9',
      },
      root: {
        '& .Mui-selected': {
          backgroundColor: 'white',
        },
        '&:not(.Mui-selected)': {
          backgroundColor: '#E0E0E0',
        },
      },
      indicator: {},
    },
    MuiDataGrid: {
      root: {
        '& .MuiDataGrid-row': {
          '& .MuiDataGrid-cell': {
            padding: '0 16px',
          },
          '&:hover': {
            backgroundColor: '#BFFFF3 !important',
          },
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontSize: '16px',
          fontFamily: 'var(--font-family-alt)',
          fontWeight: 'bold',
          paddingTop: '2px',
          paddingBottom: '2px',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          '& .MuiDataGrid-iconButtonContainer': {
            alignSelf: 'flex-start',
          },
        },
        '& .MuiDataGrid-cell': {
          fontSize: '16px',
          fontFamily: 'var(--font-family-main)',
          fontWeight: 400,
          '& .MuiInputBase-input': {
            height: '1.9em',
          },
          '& .MuiFormControl': {
            root: {
              border: 'none !important',
            },
          },
          '& .MuiSelect-root': {
            padding: '5px 14px',
          },
        },
        '& .MuiButton-label': {
          fontSize: '18px !important',
        },
      },
    },
    MuiGridFilterForm: {
      linkOperatorSelect: {
        visibility: 'hidden',
        display: 'none',
      },
      filterValueInput: {
        width: '290px',
      },
    },
    MuiIconButton: {
      root: {
        '& .MuiIconButton-label': {
          width: 'auto',
        },
      },
    },
    MuiBadge: {
      root: {
        '& .MuiBadge-badge': {
          padding: '0 6px',
          height: '18px',
          minWidth: '18px',
          top: '3px',
        },
      },
    },
    MuiBackdrop: {
      root: {
        zIndex: 0,
      },
    },
    MuiFormControl: {
      root: {
        '&.readonly:hover': {
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
      },
      marginDense: {
        marginTop: 0,
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'var(--font-family-main)',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
      },
    },
    MuiInputLabel: {
      root: {
        '&$outlined': {
          fontFamily: 'var(--font-family-main)',
        },
        '&$disabled': {
          color: 'var(--label-disabled-color)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-disabled': {
          color: 'var(--label-disabled-color)',
        },
      },
    },
    MuiDrawer: {
      root: {
        '&.is-loading .sidedrawer__content': {
          opacity: 0.5,
          pointerEvents: 'none',
        },
      },
    },
    MuiAlert: {
      root: {
        '&.compact': {
          '& .MuiAlert-message': {
            padding: 0,
          },
          '& .MuiAlert-icon': {
            padding: 0,
          },
        },
      },
    },
    MuiAutocomplete: {
      root: {
        '& .MuiAutocomplete-inputRoot': {
          '&.MuiOutlinedInput-root': {
            padding: '1px 65px 4px 14px',
            '& .MuiAutocomplete-input:first-child': {
              paddingLeft: 0,
            },
            '& .MuiChip-root': {
              marginTop: '8px',
              marginLeft: 0,
            },
          },
        },
      },
      listbox: {
        fontFamily: 'var(--font-family-main)',
        fontSize: 'var(--font-size-main)',
      },
      groupLabel: {
        fontFamily: 'var(--font-family-alt)',
        fontWeight: 'bold',
        fontSize: 'var(--font-size-main)',
        color: 'var(--secondary-color)',
        lineHeight: '28px',
        paddingTop: '10px',
      },
    },
    MuiPickersCalendar: {
      week: {
        '& .MuiPickersDay-day': {
          '& .valid-date': {
            color: 'var(--primary-gradient-4)',
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'var(--font-family-main)',
        fontSize: 'var(--font-size-main)',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'var(--hover-list-color)',
        },
      },
    },
    MuiStep: {
      root: {
        '& .MuiStepIcon-active': {
          transform: 'scale(1.5)',
          '& circle': {
            fill: 'var(--primary-color)',
          },
          '&.MuiStepIcon-completed': {
            color: 'var(--primary-color)',
          },
        },
        '&.MuiStepIcon-completed': {
          color: 'var(--primary-color)',
        },
      },
    },
    MuiStepper: {
      root: {
        paddingTop: '12px',
        paddingBottom: 0,
      },
    },
    MuiStepIcon: {
      text: {
        fontFamily: 'var(--font-family-alt)',
        fontWeight: 'bold',
      },
      root: {
        '&.MuiStepIcon-completed': {
          color: 'var(--primary-color)',
        },
      },
    },
  },
} as ThemeOptions;

export const theme = createTheme(ownTheme);
