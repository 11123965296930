import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts.js';
import ReactDOMServer from 'react-dom/server';
import { tooltipStyle } from './utils';

interface PropsIn {
  xIcon?: React.ReactElement,
  xLabel?: string,
  xLabelColor?: string,
  yIcon?: React.ReactElement,
  yLabel?: string,
  yLabelColor?: string,
  data: any[],
  xMin?: number,
  xMax?: number,
  yMax?: number,
  plotLines?: any,
  height?: number,
}

Highcharts.SVGRenderer.prototype.symbols.triangleLeft = function TriangleLeft(x, y, w, h) {
  return [
    'M', x + w, y,
    'L', x, y + h / 2,
    x + w, y + h,
    'Z',
  ];
};

Highcharts.SVGRenderer.prototype.symbols.triangleRight = function TriangleRight(x, y, w, h) {
  return [
    'M', x, y,
    'L', x, y + h,
    x + w, y + h / 2,
    'Z',
  ];
};

export default function ScatterChart(props: PropsIn) {
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const {
    data, height, xMin, xMax, xLabel, xLabelColor, xIcon, yMax, yLabel, yIcon, yLabelColor, plotLines,
  } = props;

  const options: Highcharts.Options = {
    chart: {
      type: 'scatter',
      zooming: {
        type: 'xy',
      },
      height,
      className: 'chart-scatter',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    tooltip: {
      ...tooltipStyle,
      shared: false,
      split: false,
      headerFormat: '',
    },
    xAxis: {
      showLastLabel: true,
      min: xMin,
      max: xMax,
      gridLineWidth: 1,
      tickWidth: 0,
      startOnTick: true,
      endOnTick: true,
      plotLines,
      title: {
        useHTML: true,
        text: `${xLabel ? `${xLabel}` : ''}${xIcon && ReactDOMServer.renderToStaticMarkup(xIcon)}`,
        rotation: 0,
        style: {
          color: xLabelColor || 'var(--secondary-color)',
          fontSize: 'var(--labels-font-size)',
          display: 'inline-flex',
          paddingTop: '2.5px',
        },
      },
    },
    yAxis: {
      max: yMax,
      gridLineWidth: 1,
      tickWidth: 0,
      startOnTick: true,
      endOnTick: true,
      title: {
        useHTML: true,
        text: `${yLabel ? `${yLabel}` : ''}${yIcon && ReactDOMServer.renderToStaticMarkup(yIcon)}`,
        rotation: -90,
        textAlign: 'center',
        margin: 20,
        style: {
          color: xLabelColor || 'var(--secondary-color)',
          fontSize: 'var(--labels-font-size)',
          display: 'inline-flex',
        },
      },
    },

    legend: {
      enabled: false,
    },
    plotOptions: {
      line: {
        dashStyle: 'LongDash',
        lineWidth: 1,
      },
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgb(100,100,100)',
            },
          },
        },
        states: {
          hover: {},
        },
        tooltip: {
          headerFormat: null,
          // pointFormat: '{point.x} cm, {point.y} kg',
          pointFormatter(point) {
            return this.options.tooltip;
          },
        },
      },
    },
    series: data || [],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
}
