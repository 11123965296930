import { GroundType } from 'models/ground';
import React from 'react';
import {
  Circle, Group, Line, Path, Text,
} from 'react-konva';
import {
  DEFAULT_PITCH_LINE_COLOR,
  DEFAULT_PITCH_STROKEWIDTH,
  ICE_HOCKEY_CENTER_RADIUS,
  ICE_HOCKEY_CORNER_RADIUS,
  ICE_HOCKEY_DISK_RADIUS,
  ICE_HOCKEY_DISK_Y_OFFSET,
  ICE_HOCKEY_FIRST_DISK_X_OFFSET,
  ICE_HOCKEY_GOAL_AREA_RADIUS,
  ICE_HOCKEY_GOAL_LINE,
  ICE_HOCKEY_LINE_OFFSET,
  ICE_HOCKEY_MARK_LENGHT,
  ICE_HOCKEY_MARK_SPACING,
  ICE_HOCKEY_SCOREKEEPER_AREA_RADIUS,
  ICE_HOCKEY_SECOND_DISK_X_OFFSET,
} from '../constants';
import {
  rotate, flipPoint, computeAngle, flipX, flipY, describeArc,
} from '../utils';
import Vertices from '../Vertices';

function Area(props: {
  vertexA: { x: number; y: number };
  vertexC: { x: number; y: number };
  height: number;
  center: { x: number; y: number };
  scale: number;
  flipped?: boolean;
}) {
  const {
    vertexA, vertexC, height, scale, flipped, center,
  } = props;

  const scaledCenterRadius = ICE_HOCKEY_CENTER_RADIUS * scale;
  const scaledDiskRadius = ICE_HOCKEY_DISK_RADIUS * scale;
  const scaledCornerRadius = ICE_HOCKEY_CORNER_RADIUS * scale;
  const scaledGoalAreaRadius = ICE_HOCKEY_GOAL_AREA_RADIUS * scale;
  const scaledGoalLineOffset = ICE_HOCKEY_GOAL_LINE * scale;
  const scaledFirstDiskXOffset = ICE_HOCKEY_FIRST_DISK_X_OFFSET * scale;
  const scaledSecondDiskXOffset = ICE_HOCKEY_SECOND_DISK_X_OFFSET * scale;
  const scaledDiskYOffset = ICE_HOCKEY_DISK_Y_OFFSET * scale;
  const scaledLineOffset = ICE_HOCKEY_LINE_OFFSET * scale;
  const scaledMarkSpacing = ICE_HOCKEY_MARK_SPACING * scale;
  const scaledMarkLenght = ICE_HOCKEY_MARK_LENGHT * scale;

  const cornerYDeficit = 1.29 * scale;
  const markYDeficit = 0.02 * scale;

  return (
    <Group>
      <Path
        data={describeArc(
          flipped ? vertexC.x - scaledCornerRadius : vertexA.x + scaledCornerRadius,
          flipped ? vertexC.y + scaledCornerRadius : vertexA.y - scaledCornerRadius,
          scaledCornerRadius < 0 ? scaledCornerRadius * -1 : scaledCornerRadius,
          flipped ? 0 : 180,
          flipped ? 90 : 270,
        )}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Path
        data={describeArc(
          flipped ? vertexC.x - scaledGoalLineOffset : vertexA.x + scaledGoalLineOffset,
          center.y,
          scaledGoalAreaRadius < 0 ? scaledGoalAreaRadius * -1 : scaledGoalAreaRadius,
          flipped ? 180 : 0,
          flipped ? 0 : 180,
        )}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={
          flipped
            ? [
              flipX({ x: vertexA.x + scaledGoalLineOffset, y: vertexA.y - height + cornerYDeficit }, center),
              flipY({ x: vertexA.x + scaledGoalLineOffset, y: vertexA.y - height + cornerYDeficit }, center),
              flipX({ x: vertexA.x + scaledGoalLineOffset, y: vertexA.y - cornerYDeficit }, center),
              flipY({ x: vertexA.x + scaledGoalLineOffset, y: vertexA.y - cornerYDeficit }, center),
            ]
            : [
              vertexA.x + scaledGoalLineOffset,
              vertexA.y - height + cornerYDeficit,
              vertexA.x + scaledGoalLineOffset,
              vertexA.y - cornerYDeficit,
            ]
        }
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={
          flipped
            ? [
              flipX(
                {
                  x: vertexA.x + scaledFirstDiskXOffset - scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
                },
                center,
              ),
              flipY(
                {
                  x: vertexA.x + scaledFirstDiskXOffset - scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
                },
                center,
              ),
              flipX(
                {
                  x: vertexA.x + scaledFirstDiskXOffset - scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
                },
                center,
              ),
              flipY(
                {
                  x: vertexA.x + scaledFirstDiskXOffset - scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
                },
                center,
              ),
            ]
            : [
              vertexA.x + scaledFirstDiskXOffset - scaledMarkSpacing,
              center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
              vertexA.x + scaledFirstDiskXOffset - scaledMarkSpacing,
              center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
            ]
        }
        dash={[scaledMarkLenght, scaledCenterRadius * 2 - markYDeficit * 2]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={
          flipped
            ? [
              flipX(
                {
                  x: vertexA.x + scaledFirstDiskXOffset + scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
                },
                center,
              ),
              flipY(
                {
                  x: vertexA.x + scaledFirstDiskXOffset + scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
                },
                center,
              ),
              flipX(
                {
                  x: vertexA.x + scaledFirstDiskXOffset + scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
                },
                center,
              ),
              flipY(
                {
                  x: vertexA.x + scaledFirstDiskXOffset + scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
                },
                center,
              ),
            ]
            : [
              vertexA.x + scaledFirstDiskXOffset + scaledMarkSpacing,
              center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
              vertexA.x + scaledFirstDiskXOffset + scaledMarkSpacing,
              center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
            ]
        }
        dash={[scaledMarkLenght, scaledCenterRadius * 2 - markYDeficit * 2]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={
          flipped
            ? flipX({ x: vertexA.x + scaledFirstDiskXOffset, y: center.y + scaledDiskYOffset }, center)
            : vertexA.x + scaledFirstDiskXOffset
        }
        y={
          flipped
            ? flipY({ x: vertexA.x + scaledFirstDiskXOffset, y: center.y + scaledDiskYOffset }, center)
            : center.y + scaledDiskYOffset
        }
        radius={scaledDiskRadius < 0 ? scaledDiskRadius * -1 : scaledDiskRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={
          flipped
            ? flipX({ x: vertexA.x + scaledFirstDiskXOffset, y: center.y - scaledDiskYOffset }, center)
            : vertexA.x + scaledFirstDiskXOffset
        }
        y={
          flipped
            ? flipY({ x: vertexA.x + scaledFirstDiskXOffset, y: center.y - scaledDiskYOffset }, center)
            : center.y - scaledDiskYOffset
        }
        radius={scaledDiskRadius < 0 ? scaledDiskRadius * -1 : scaledDiskRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={
          flipped
            ? flipX({ x: vertexA.x + scaledFirstDiskXOffset, y: center.y + scaledDiskYOffset }, center)
            : vertexA.x + scaledFirstDiskXOffset
        }
        y={
          flipped
            ? flipY({ x: vertexA.x + scaledFirstDiskXOffset, y: center.y + scaledDiskYOffset }, center)
            : center.y + scaledDiskYOffset
        }
        radius={scaledCenterRadius < 0 ? scaledCenterRadius * -1 : scaledCenterRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={
          flipped
            ? flipX({ x: vertexA.x + scaledFirstDiskXOffset, y: center.y - scaledDiskYOffset }, center)
            : vertexA.x + scaledFirstDiskXOffset
        }
        y={
          flipped
            ? flipY({ x: vertexA.x + scaledFirstDiskXOffset, y: center.y - scaledDiskYOffset }, center)
            : center.y - scaledDiskYOffset
        }
        radius={scaledCenterRadius < 0 ? scaledCenterRadius * -1 : scaledCenterRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={
          flipped
            ? [
              flipX({ x: center.x - scaledLineOffset, y: vertexA.y - height }, center),
              flipY({ x: center.x - scaledLineOffset, y: vertexA.y - height }, center),
              flipX({ x: center.x - scaledLineOffset, y: vertexA.y }, center),
              flipY({ x: center.x - scaledLineOffset, y: vertexA.y }, center),
            ]
            : [center.x - scaledLineOffset, vertexA.y - height, center.x - scaledLineOffset, vertexA.y]
        }
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={
          flipped
            ? [
              flipX(
                {
                  x: vertexC.x - scaledFirstDiskXOffset - scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
                },
                center,
              ),
              flipY(
                {
                  x: vertexC.x - scaledFirstDiskXOffset - scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
                },
                center,
              ),
              flipX(
                {
                  x: vertexC.x - scaledFirstDiskXOffset - scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
                },
                center,
              ),
              flipY(
                {
                  x: vertexC.x - scaledFirstDiskXOffset - scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
                },
                center,
              ),
            ]
            : [
              vertexC.x - scaledFirstDiskXOffset - scaledMarkSpacing,
              center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
              vertexC.x - scaledFirstDiskXOffset - scaledMarkSpacing,
              center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
            ]
        }
        dash={[scaledMarkLenght, scaledCenterRadius * 2 - markYDeficit * 2]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={
          flipped
            ? [
              flipX(
                {
                  x: vertexC.x - scaledFirstDiskXOffset + scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
                },
                center,
              ),
              flipY(
                {
                  x: vertexC.x - scaledFirstDiskXOffset + scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
                },
                center,
              ),
              flipX(
                {
                  x: vertexC.x - scaledFirstDiskXOffset + scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
                },
                center,
              ),
              flipY(
                {
                  x: vertexC.x - scaledFirstDiskXOffset + scaledMarkSpacing,
                  y: center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
                },
                center,
              ),
            ]
            : [
              vertexC.x - scaledFirstDiskXOffset + scaledMarkSpacing,
              center.y + scaledDiskYOffset + scaledCenterRadius - markYDeficit + scaledMarkLenght,
              vertexC.x - scaledFirstDiskXOffset + scaledMarkSpacing,
              center.y + scaledDiskYOffset - scaledCenterRadius - markYDeficit - scaledMarkLenght,
            ]
        }
        dash={[scaledMarkLenght, scaledCenterRadius * 2 - markYDeficit * 2]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={
          flipped
            ? flipX({ x: center.x - scaledSecondDiskXOffset, y: center.y - scaledDiskYOffset }, center)
            : center.x - scaledSecondDiskXOffset
        }
        y={
          flipped
            ? flipY({ x: center.x - scaledSecondDiskXOffset, y: center.y - scaledDiskYOffset }, center)
            : center.y - scaledDiskYOffset
        }
        radius={scaledDiskRadius < 0 ? scaledDiskRadius * -1 : scaledDiskRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={
          flipped
            ? flipX({ x: center.x - scaledSecondDiskXOffset, y: center.y + scaledDiskYOffset }, center)
            : center.x - scaledSecondDiskXOffset
        }
        y={
          flipped
            ? flipY({ x: center.x - scaledSecondDiskXOffset, y: center.y + scaledDiskYOffset }, center)
            : center.y + scaledDiskYOffset
        }
        radius={scaledDiskRadius < 0 ? scaledDiskRadius * -1 : scaledDiskRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Path
        data={describeArc(
          flipped ? vertexC.x - scaledCornerRadius : vertexA.x + scaledCornerRadius,
          flipped ? vertexA.y - scaledCornerRadius : vertexC.y + scaledCornerRadius,
          scaledCornerRadius < 0 ? scaledCornerRadius * -1 : scaledCornerRadius,
          flipped ? 90 : 270,
          flipped ? 180 : 0,
        )}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
    </Group>
  );
}

export default function Football(props: { groundData: GroundType; scale: number; vertices: boolean }) {
  const { groundData, scale, vertices } = props;

  let vertexA = {
    x:
      rotate(flipPoint({ x: groundData.vertexAX?.value, y: groundData.vertexAY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexAX?.value, y: groundData.vertexAY?.value }, groundData), computeAngle(groundData)).y
      * scale,
  };
  let vertexC = {
    x:
      rotate(flipPoint({ x: groundData.vertexCX?.value, y: groundData.vertexCY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexCX?.value, y: groundData.vertexCY?.value }, groundData), computeAngle(groundData)).y
      * scale,
  };

  if (vertexA.x > vertexC.x) {
    const swap = vertexC;
    vertexC = vertexA;
    vertexA = swap;
  }

  const width = vertexC.x - vertexA.x;
  const height = vertexA.y - vertexC.y;

  const center = { x: vertexA.x + width / 2, y: vertexA.y - height / 2 };

  const scaledCornerRadius = ICE_HOCKEY_CORNER_RADIUS * scale;
  const scaledCenterRadius = ICE_HOCKEY_CENTER_RADIUS * scale;
  const scaledScorekeeperAreaRadius = ICE_HOCKEY_SCOREKEEPER_AREA_RADIUS * scale;
  const scaledDiskRadius = ICE_HOCKEY_DISK_RADIUS * scale;

  return (
    <Group>
      {vertices ? <Vertices A={vertexA} C={vertexC} /> : null}
      {/* border */}
      <Line
        fill={groundData.groundSurfaceColor ? groundData.groundSurfaceColor : '#00000000'}
        points={[vertexA.x + scaledCornerRadius, vertexA.y, vertexC.x - scaledCornerRadius, vertexA.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={[vertexC.x, vertexC.y + scaledCornerRadius, vertexC.x, vertexA.y - scaledCornerRadius]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={[
          vertexA.x + scaledCornerRadius,
          vertexA.y - height,
          vertexC.x - scaledCornerRadius,
          vertexA.y - height,
        ]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={[vertexA.x, vertexC.y + scaledCornerRadius, vertexA.x, vertexA.y - scaledCornerRadius]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* left area */}
      <Area vertexA={vertexA} vertexC={vertexC} height={height} center={center} scale={scale} />
      {/* halfcourt */}
      <Line
        points={[vertexA.x + width / 2, vertexA.y, vertexC.x - width / 2, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={vertexA.x + width / 2}
        y={vertexA.y - height / 2}
        radius={scaledCenterRadius < 0 ? scaledCenterRadius * -1 : scaledCenterRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={vertexA.x + width / 2}
        y={vertexA.y - height / 2}
        radius={scaledDiskRadius < 0 ? scaledDiskRadius * -1 : scaledDiskRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Path
        data={describeArc(
          center.x,
          vertexA.y,
          scaledScorekeeperAreaRadius < 0 ? scaledScorekeeperAreaRadius * -1 : scaledScorekeeperAreaRadius,
          -90,
          90,
        )}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* rightarea */}
      <Area vertexA={vertexA} vertexC={vertexC} height={height} center={center} scale={scale} flipped />
    </Group>
  );
}
