import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import { GridCellParams } from '@material-ui/x-grid';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import EditableTable from '../EditableTable';
import { TextField } from '../form/TextField';
import { SelectField } from '../form/SelectField';

const players = [
  { id: 1, name: 'Mario Rossi' },
  { id: 2, name: 'Pino Verdi' },
  { id: 3, name: 'Angelo Bianchi' },
  { id: 4, name: 'Tramonti Arancio' },
  { id: 5, name: 'Alba Rosa' },
];

function TestEditableTableClientSide() {
  return (
    <>
      <Typography variant="h5" gutterBottom>Editable Table (client side sorting)</Typography>
      <Paper id="test-editable-table-client-side">
        <Box p={2}>
          <EditableTable
            clientSorting
            columns={[
              {
                field: 'id',
              },
              {
                field: 'name',
                width: 200,
                sortable: true,
              },
              {
                field: 'textField1',
                headerName: 'text field 1',
                flex: 1,
                renderCell: (params: GridCellParams) => <TextField placeholder={`value for 1-${params.id}`} />,
              },
              {
                field: 'textField2',
                headerName: 'text field 2',
                flex: 1,
                renderCell: (params: GridCellParams) => <TextField placeholder={`value for 2-${params.id}`} />,
              },
              {
                field: 'dropdown',
                headerName: 'dropdown',
                flex: 1,
                renderCell: () => (
                  <SelectField
                    value=""
                    onChange={console.log}
                    options={players.map((p) => ({ id: p.id, value: p.name }))}
                  />
                ),
              },
            ]}
            rows={players.map((p) => ({
              id: p.id,
              name: p.name,
            }))}
          />
        </Box>
      </Paper>
      <Divider style={{ margin: '2rem' }} />
    </>
  );
}

export default TestEditableTableClientSide;
