import { GroundType } from 'models/ground';
import React from 'react';
import {
  Circle, Group, Line, Path, Text,
} from 'react-konva';
import {
  DEFAULT_PITCH_LINE_COLOR,
  DEFAULT_PITCH_STROKEWIDTH,
  FOOTBALL_CENTER_RADIUS,
  FOOTBALL_DISK_OFFSET,
  FOOTBALL_DISK_RADIUS,
  FOOTBALL_GOAL_AREA_HEIGHT,
  FOOTBALL_GOAL_AREA_HEIGHT_SMALL,
  FOOTBALL_GOAL_AREA_WIDTH,
  FOOTBALL_GOAL_AREA_WIDTH_SMALL,
} from '../constants';
import {
  rotate, flipPoint, computeAngle, flipX, flipY, describeArc,
} from '../utils';
import Vertices from '../Vertices';

function Area(props: {
  vertexA: { x: number; y: number };
  vertexC: { x: number; y: number };
  height: number;
  center: { x: number; y: number };
  scale: number;
  flipped?: boolean;
}) {
  const {
    vertexA, vertexC, height, scale, flipped, center,
  } = props;

  const scaledCenterRadius = FOOTBALL_CENTER_RADIUS * scale;
  const scaledAreaWidth = FOOTBALL_GOAL_AREA_WIDTH * scale;
  const scaledAreaHeight = FOOTBALL_GOAL_AREA_HEIGHT * scale;
  const scaledAreaWidthSm = FOOTBALL_GOAL_AREA_WIDTH_SMALL * scale;
  const scaledAreaHeightSm = FOOTBALL_GOAL_AREA_HEIGHT_SMALL * scale;
  const scaledDiskOffset = FOOTBALL_DISK_OFFSET * scale;
  const scaledDiskRadius = FOOTBALL_DISK_RADIUS * scale;
  const scaledCornerRadius = scale;

  return (
    <Group>
      <Path
        data={describeArc(
          flipped ? vertexC.x - scaledCornerRadius : vertexA.x + scaledCornerRadius,
          flipped ? vertexC.y + scaledCornerRadius : vertexA.y - height + scaledCornerRadius,
          scaledCornerRadius < 0 ? scaledCornerRadius * -1 : scaledCornerRadius,
          flipped ? 90 : 0,
          flipped ? 0 : -90,
        )}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={
          flipped
            ? [
              flipX({ x: vertexA.x, y: vertexA.y + scaledAreaHeight / 2 - height / 2 }, center),
              flipY({ x: vertexA.x, y: vertexA.y + scaledAreaHeight / 2 - height / 2 }, center),
              flipX({ x: vertexA.x + scaledAreaWidth, y: vertexA.y + scaledAreaHeight / 2 - height / 2 }, center),
              flipY({ x: vertexA.x + scaledAreaWidth, y: vertexA.y + scaledAreaHeight / 2 - height / 2 }, center),
              flipX({ x: vertexA.x + scaledAreaWidth, y: vertexA.y - scaledAreaHeight / 2 - height / 2 }, center),
              flipY({ x: vertexA.x + scaledAreaWidth, y: vertexA.y - scaledAreaHeight / 2 - height / 2 }, center),
              flipX({ x: vertexA.x, y: vertexA.y - scaledAreaHeight / 2 - height / 2 }, center),
              flipY({ x: vertexA.x, y: vertexA.y - scaledAreaHeight / 2 - height / 2 }, center),
            ]
            : [
              vertexA.x,
              vertexA.y + scaledAreaHeight / 2 - height / 2,
              vertexA.x + scaledAreaWidth,
              vertexA.y + scaledAreaHeight / 2 - height / 2,
              vertexA.x + scaledAreaWidth,
              vertexA.y - scaledAreaHeight / 2 - height / 2,
              vertexA.x,
              vertexA.y - scaledAreaHeight / 2 - height / 2,
            ]
        }
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Line
        points={
          flipped
            ? [
              flipX({ x: vertexA.x, y: vertexA.y + scaledAreaHeightSm / 2 - height / 2 }, center),
              flipY({ x: vertexA.x, y: vertexA.y + scaledAreaHeightSm / 2 - height / 2 }, center),
              flipX({ x: vertexA.x + scaledAreaWidthSm, y: vertexA.y + scaledAreaHeightSm / 2 - height / 2 }, center),
              flipY({ x: vertexA.x + scaledAreaWidthSm, y: vertexA.y + scaledAreaHeightSm / 2 - height / 2 }, center),
              flipX({ x: vertexA.x + scaledAreaWidthSm, y: vertexA.y - scaledAreaHeightSm / 2 - height / 2 }, center),
              flipY({ x: vertexA.x + scaledAreaWidthSm, y: vertexA.y - scaledAreaHeightSm / 2 - height / 2 }, center),
              flipX({ x: vertexA.x, y: vertexA.y - scaledAreaHeightSm / 2 - height / 2 }, center),
              flipY({ x: vertexA.x, y: vertexA.y - scaledAreaHeightSm / 2 - height / 2 }, center),
            ]
            : [
              vertexA.x,
              vertexA.y + scaledAreaHeightSm / 2 - height / 2,
              vertexA.x + scaledAreaWidthSm,
              vertexA.y + scaledAreaHeightSm / 2 - height / 2,
              vertexA.x + scaledAreaWidthSm,
              vertexA.y - scaledAreaHeightSm / 2 - height / 2,
              vertexA.x,
              vertexA.y - scaledAreaHeightSm / 2 - height / 2,
            ]
        }
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={flipped ? vertexC.x - scaledDiskOffset : vertexA.x + scaledDiskOffset}
        y={vertexA.y - height / 2}
        radius={scaledDiskRadius < 0 ? scaledDiskRadius * -1 : scaledDiskRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
        fill={DEFAULT_PITCH_LINE_COLOR}
      />
      <Path
        data={describeArc(
          flipped ? vertexC.x - scaledDiskOffset : vertexA.x + scaledDiskOffset,
          vertexA.y - height / 2,
          scaledCenterRadius < 0 ? scaledCenterRadius * -1 : scaledCenterRadius,
          flipped ? -143 : 37,
          flipped ? -37 : 143,
        )}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Path
        data={describeArc(
          flipped ? vertexC.x : vertexA.x + scaledCornerRadius,
          flipped ? vertexA.y : vertexA.y - scaledCornerRadius,
          scaledCornerRadius < 0 ? scaledCornerRadius * -1 : scaledCornerRadius,
          flipped ? 270 : 270,
          flipped ? 0 : 180,
        )}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
    </Group>
  );
}

export default function Football(props: { groundData: GroundType; scale: number; vertices: boolean }) {
  const { groundData, scale, vertices } = props;

  let vertexA = {
    x:
      rotate(flipPoint({ x: groundData.vertexAX?.value, y: groundData.vertexAY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexAX?.value, y: groundData.vertexAY?.value }, groundData), computeAngle(groundData)).y
      * scale,
  };
  let vertexC = {
    x:
      rotate(flipPoint({ x: groundData.vertexCX?.value, y: groundData.vertexCY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexCX?.value, y: groundData.vertexCY?.value }, groundData), computeAngle(groundData)).y
      * scale,
  };

  if (vertexA.x > vertexC.x) {
    const swap = vertexC;
    vertexC = vertexA;
    vertexA = swap;
  }

  const width = vertexC.x - vertexA.x;
  const height = vertexA.y - vertexC.y;

  const center = { x: vertexA.x + width / 2, y: vertexA.y - height / 2 };

  const scaledCenterRadius = FOOTBALL_CENTER_RADIUS * scale;
  const scaledDiskRadius = FOOTBALL_DISK_RADIUS * scale;

  return (
    <Group>
      {vertices ? <Vertices A={vertexA} C={vertexC} /> : null}
      {/* border */}
      <Line
        fill={groundData.groundSurfaceColor ? groundData.groundSurfaceColor : '#00000000'}
        points={[vertexA.x, vertexA.y, vertexC.x, vertexA.y, vertexC.x, vertexC.y, vertexA.x, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
        closed
      />
      {/* left area */}
      <Area vertexA={vertexA} vertexC={vertexC} height={height} center={center} scale={scale} />
      {/* halfcourt */}
      <Line
        points={[vertexA.x + width / 2, vertexA.y, vertexC.x - width / 2, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={vertexA.x + width / 2}
        y={vertexA.y - height / 2}
        radius={scaledCenterRadius < 0 ? scaledCenterRadius * -1 : scaledCenterRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      <Circle
        x={vertexA.x + width / 2}
        y={vertexA.y - height / 2}
        radius={scaledDiskRadius < 0 ? scaledDiskRadius * -1 : scaledDiskRadius}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
        fill={DEFAULT_PITCH_LINE_COLOR}
      />
      {/* rightarea */}
      <Area vertexA={vertexA} vertexC={vertexC} height={height} center={center} scale={scale} flipped />
    </Group>
  );
}
