const CLIENT_SETTINGS = {
  public: {
    environment: process.env.BUILD,
    gpexeUiUrl: process.env.GPEXE_UI_URL,
    gpexeBackendUrl: process.env.GPEXE_BACKEND_URL,
    gpexeGraphUrl: process.env.GPEXE_GRAPH_URL || `${process.env.GPEXE_BACKEND_URL}ui/v2/`,
    gpexeRestUrl: process.env.GPEXE_REST_URL || `${process.env.GPEXE_BACKEND_URL}api/`,
    gpexeWssUrl: process.env.GPEXE_WSS_URL,
    ga4_ID: process.env.GA4_ID,
    gpexeFeVersion: process.env.GPEXE_FE_VERSION,
    sentry: {
      cors: process.env.SENTRY_CORS || '',
      dsnUrl: process.env.SENTRY_DSN_URL || '',
      enableReplay: process.env.SENTRY_ENABLE_REPLAY || false,
      replaySessionRate: process.env.SENTRY_REPLAY_SESSION_RATE
        ? parseInt(process.env.SENTRY_REPLAY_SESSION_RATE, 10)
        : 0.1,
      replayErrorRate: process.env.SENTRY_REPLAY_ERROR_RATE
        ? parseInt(process.env.SENTRY_REPLAY_ERROR_RATE, 10)
        : 1.0,
    },
    youtrackFormUUID: {
      webapp: 'b7b76e42-515c-4f37-b3c9-d9d505ede0cc',
      ipadapp: 'e52bb4bc-17ca-4590-a3a2-d8e66cc625f3',
      bridge: 'da571c0b-1bfa-48e5-a126-90f0b5c142f9',
      hardware: '027e0166-b582-4fdb-87f5-dc7cdf6fe39c',
    },
    googleApiKey: 'AIzaSyBIKi8lsPux7174yuMefYf6S3F28bNIxaM',
  },
};

export default CLIENT_SETTINGS;
