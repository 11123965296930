import { Box, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@images/IconHeader/delete.svg';
import ScreenshotIcon from '@images/icons/svg/screenshot.svg';
import VideoRecordingIcon from '@images/icons/svg/videoRecording.svg';
import { Row } from './layout/Row';
import { Column } from './layout/Column';
import BaseButton from './form/BaseButton';

type propsIn = {
  screenshotFiles: File,
  setScreenshotFiles: (file: File[]) => void,
  videoFiles: File,
  setVideoFiles: (file: File[]) => void,
}

export default function ScreenVideoCapture(props: propsIn) {
  const {
    screenshotFiles, setScreenshotFiles, videoFiles, setVideoFiles,
  } = props;
  const { t } = useTranslation();
  const [screenshots, setScreenshots] = useState<any>([]);
  const [videos, setVideos] = useState<any>([]);

  const takeScreenShot = async () => {
    let file: File;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    // supported on 03/04/2024 only by Chrome/Edge/Opera
    if ('ImageCapture' in window) {
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: { mediaSource: 'screen' },
        });
        setTimeout(async () => {
          const track = stream.getVideoTracks()[0];
          const imageCapture = new ImageCapture(track);
          const bitmap = await imageCapture.grabFrame();
          track.stop();
          canvas.width = bitmap.width;
          canvas.height = bitmap.height;
          context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
          const image = canvas.toDataURL('image/jpeg');
          const res = await fetch(image);
          const buff = await res.arrayBuffer();
          file = new File([buff], `photo_${screenshots.length + 1}.jpg`, {
            type: 'image/jpeg',
          });
          setScreenshots((prev) => ([...prev, canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')]));
          document.body.classList.remove('screenshot-running');
        }, 500);
      } catch (error) {
        console.error(error);
        document.body.classList.remove('screenshot-running');
      }
    } else {
      // if not supported: use video element for now
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: { mediaSource: 'screen' },
        });
        const video = document.createElement('video');
        video.srcObject = stream;
        await new Promise((resolve) => {
          video.onloadedmetadata = () => {
            setTimeout(() => {
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
              canvas.toBlob((blob) => {
                file = new File([blob], `photo_${screenshots.length + 1}.jpg`, {
                  type: 'image/jpeg',
                });
                setScreenshots((prev) => ([...prev, URL.createObjectURL(blob)]));
                document.body.classList.remove('screenshot-running');
                resolve();
              }, 'image/jpeg');
            }, 500);
          };
          video.play();
        });
      } catch (error) {
        console.error(error);
        document.body.classList.remove('screenshot-running');
      }
    }
    setTimeout(() => {
      if (file) {
        setScreenshotFiles((prevState) => ([
          ...prevState,
          file,
        ]));
      }
    }, 1000);
  };

  const takeScreenShotVideo = async () => {
    async function startRecording() {
      let stream: Promise<MediaStream>;

      try {
        stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
        });
      } catch (e) {
        document.body.classList.remove('screenshot-running');
        console.error(e);
      }

      if (stream) {
        // supported by all Browsers:
        const recorder = new MediaRecorder(stream);

        const chunks = [];
        recorder.ondataavailable = (e) => chunks.push(e.data);
        recorder.onstop = (_) => {
          const blob = new Blob(chunks, { type: chunks[0].type });
          stream.getVideoTracks()[0].stop();

          const recordedVideoFile = new File([blob], `video_${videos.length + 1}.mp4`, {
            type: blob.type,
            lastModified: Date.now(),
          });

          setVideoFiles((prevFiles) => [...prevFiles, recordedVideoFile]);
          setVideos((prev) => ([...prev, window.URL.createObjectURL(blob)]));

          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          }
        };

        recorder.start();

        const anchorElement = document.createElement('a');
        anchorElement.setAttribute('id', 'stop-recording-video');
        anchorElement.setAttribute('type', 'button');
        anchorElement.textContent = 'STOP RECORDING';

        anchorElement.addEventListener('click', () => {
          recorder.stop();
          document.body.classList.remove('screenshot-running');
          setTimeout(() => document.body.removeChild(anchorElement), 250);
        });

        document.body.appendChild(anchorElement);
      }
    }

    startRecording();
  };

  const handleTakeScreenshot = () => {
    document.body.classList.add('screenshot-running');
    takeScreenShot();
  };

  const handleTakeScreenshotVideo = () => {
    document.body.classList.add('screenshot-running');
    takeScreenShotVideo();
  };

  return (
    <Box style={{ marginTop: '20px' }}>
      <Row>
        <Column md={12}>
          <Paper style={{ height: 'fit-content' }}>
            <Box p={2}>
              <Row>
                <Column md={12} style={{ display: 'flex', gap: '1rem' }}>
                  <BaseButton
                    key="send"
                    color="primary"
                    startIcon={<ScreenshotIcon />}
                    onClick={() => {
                      handleTakeScreenshot();
                    }}
                    noMargins
                    buttonSize="sm"
                  >
                    {t('issues.content.takeScreenshot', 'take screenshot')}
                  </BaseButton>
                  <BaseButton
                    key="send"
                    color="primary"
                    startIcon={<VideoRecordingIcon />}
                    onClick={handleTakeScreenshotVideo}
                    noMargins
                    buttonSize="sm"
                  >
                    {t('issues.content.takeVideo', 'take video')}
                  </BaseButton>
                </Column>
                {screenshots.length > 0 && (
                  <Column md={12}>
                    <div className="mediaRecorder-preview">
                      {
                        screenshots.map((sh, index) => (
                          <Column xs={12} style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '-1.2rem', right: '-10px' }}>
                              <BaseButton
                                onClick={() => {
                                  // remove file/screenshot
                                  const updatedFiles = [...screenshotFiles];
                                  updatedFiles.splice(index, 1);
                                  const updatedScreenshots = [...screenshots];
                                  updatedScreenshots.splice(index, 1);
                                  setScreenshotFiles(updatedFiles);
                                  setScreenshots(updatedScreenshots);
                                }}
                                isIconButton
                                size="small"
                              >
                                <DeleteIcon />
                              </BaseButton>
                            </div>
                            <Box>
                              <img src={sh} width="100%" alt="preview" />
                            </Box>
                          </Column>
                        ))
                      }
                    </div>
                  </Column>
                )}
                {videos.length > 0 && (
                  <Column md={12}>
                    <div className="mediaRecorder-preview">
                      {
                        videos.map((video, index) => (
                          <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '-1.2rem', right: '-10px' }}>
                              <BaseButton
                                onClick={() => {
                                  // remove file/video
                                  const updatedFiles = [...videoFiles];
                                  updatedFiles.splice(index, 1);
                                  const updatedScreenshots = [...videos];
                                  updatedScreenshots.splice(index, 1);
                                  setVideoFiles(updatedFiles);
                                  setVideos(updatedScreenshots);
                                }}
                                isIconButton
                                size="small"
                              >
                                <DeleteIcon />
                              </BaseButton>
                            </div>
                            <video id={`recording-${index}`} width="645" height="365" controls src={video} />
                          </div>
                        ))
                      }
                    </div>
                  </Column>
                )}
              </Row>
            </Box>
          </Paper>
        </Column>
      </Row>
    </Box>
  );
}
