import { AthleteType } from '../../models/athlete';
import CLIENT_SETTINGS from '../../lib/client_settings';
import { capitalize } from '../stats/Filters';

export const getAthleteName = (athlete: AthleteType | false | undefined, defaultValue = '', short = false) => (!athlete
  ? defaultValue
  : short
    ? athlete.shortName || defaultValue
    : `${capitalize(athlete?.lastName) || ''} ${capitalize(athlete.firstName) || defaultValue}`);

export const getAthleteNumber = (athlete: AthleteType | false, teamId: string) => (athlete ? athlete?.playerSet?.find((set) => set.team?.id === teamId)?.number || '' : '');

export const getAthleteLink = (athlete: AthleteType) => `/athletes/${athlete?.id}`;

/**
 * Camel Case spaziato per stringhe
 *
 * @param name
 *
 * @todo verificare se utilizzabile al posto di convertToTitle
 */
export const capitalizeName = (name: string) => {
  if (!name || typeof name !== 'string') { // @todo temporary check
    return '';
  }
  return capitalize(name);
};

export const getLocalPicturePath = (pictureURL: string) => (pictureURL.slice(0, 4) !== 'http'
  ? `${CLIENT_SETTINGS.public.gpexeBackendUrl}${pictureURL.slice(1, pictureURL.length)}`
  : pictureURL);
