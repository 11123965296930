import React from 'react';
import { RadioGroupProps } from '@material-ui/core/RadioGroup';
import { FieldsGroup } from './FieldsGroup';

type PropsIn = {
  value: string | boolean;
  label: string;
  defaultValue?: RadioGroupProps['defaultValue'];
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  onClick?: () => void;
};

export default function Checkbox(props: PropsIn) {
  const {
    value, label, defaultValue, disabled, readonly, required, onClick,
  } = props;

  return (
    <FieldsGroup
      onClick={(readonly || disabled) ? null : onClick}
      required={required}
      disabled={disabled}
      readonly={readonly}
      options={[
        {
          value,
          label,
        },
      ]}
      type="checkbox"
      defaultValue={defaultValue}
    />
  );
}
