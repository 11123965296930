/* eslint-disable camelcase */
export const UNITS_LABELS = {
  KM_H: 'km/h',
  M_MIN: 'm/min',
  M_S: 'm/s',
  M_S2: 'm/s²',
  M: 'm',
  B_MIN: 'b/min',
  J_KG: 'J/kg',
  ML_O2_KG: 'ml\u00A0O\u2082/kg',
  KCAL_KG: 'kcal/kg',
  W_KG: 'W/kg',
  ML_O2_KG_MIN: 'ml\u00A0O\u2082/kg\u00B7min',
  _P: '%',
  N: 'n',
  S: 's',
  MIN: 'min',
  C: '°C',
  F: '°F',
};

export const UNITS_LABELS_FORMATS = {
  ...UNITS_LABELS,
  ML_O2_KG_STANDARD: 'ml O₂/kg',
  ML_O2_KG_MIN_STANDARD: 'ml O₂/kg·min',
  DEGREE: '°',
  C: 'C',
  F: 'F',
  CM: 'cm',
};
export const UNITS_VALUES = {
  ...UNITS_LABELS,
  ML_O2_KG: 'ml O₂/kg',
};

export const UNITS_DB_FORMAT = {
  M_S2: 'm/s2',
  ML_O2_KG_STANDARD: 'ml O2/kg',
  ML_O2_KG_MIN_STANDARD: 'ml O2/kg/min',
};

const v0_KM_H = {
  min: 20,
  max: 45,
};

const maxSpeed_KM_H = {
  min: 20,
  max: 40,
};

const vO2Max = {
  min: 15,
  max: 25,
};

export const PARAMETERS_RANGE = {
  hrMin: {
    min: 35,
    max: 80,
  },
  hrMax: {
    min: 170,
    max: 230,
  },
  a0: {
    min: 4,
    max: 9,
  },
  v0: {
    KM_H: v0_KM_H,
    M_S: {
      min: Math.round(v0_KM_H.min / 3.6),
      max: Math.round(v0_KM_H.max / 3.6),
    },
    M_MIN: {
      min: Math.round(v0_KM_H.min * 16.66),
      max: Math.round(v0_KM_H.max * 16.66),
    },
  },
  maxSpeed: {
    KM_H: maxSpeed_KM_H,
    M_S: {
      min: Math.round(maxSpeed_KM_H.min / 3.6),
      max: Math.round(maxSpeed_KM_H.max / 3.6),
    },
    M_MIN: {
      min: Math.round(maxSpeed_KM_H.min * 16.66),
      max: Math.round(maxSpeed_KM_H.max * 16.66),
    },
  },
  vO2Max: {
    W_KG: {
      min: 15,
      max: 25,
    },
    ML_O2_KG_MIN: {
      min: Math.round(vO2Max.min * (60.0 / 20.9)),
      max: Math.round(vO2Max.max * (60.0 / 20.9)),
    },
  },
};

export const formatUomsBackend = (uom) => {
  switch (uom) {
    case UNITS_LABELS.M_S2:
      return UNITS_DB_FORMAT.M_S2;
    case UNITS_LABELS.ML_O2_KG:
      return UNITS_DB_FORMAT.ML_O2_KG_STANDARD;
    case UNITS_LABELS.ML_O2_KG_MIN:
      return UNITS_DB_FORMAT.ML_O2_KG_MIN_STANDARD;
    default:
      return uom;
  }
};
