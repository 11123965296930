import React, { useState } from 'react';
import { Box, Popover } from '@material-ui/core';
import { GridCellParams } from '@material-ui/x-grid';
import { makeStyles } from '@material-ui/core/styles';
import TagsCell from './layout/TagsCell';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
    '& .MuiBox-root': {
    },
  },
}));

export default function tagsExtendedCell(params: GridCellParams, width:number = 230) {
  if (params?.value === '-' || params?.value === '') {
    return '';
  }
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const tags = Array.isArray(params) ? params : (Array.isArray(params.value) ? params.value : params?.value?.split(','));
  let index = 0;
  const textLength = tags?.reduce((acc, curr) => {
    if (acc <= width) {
      index += 1;
    }
    return acc + curr.length * 8 + 38;
  }, 0) || 0;

  const isLong = textLength > width;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const cellContent = tags?.map((t) => (
    <Box key={t}>
      <TagsCell tags={[t] as string[]} />
    </Box>
  ));

  return (
    <div
      style={{
        display: 'flex',
        gap: '5px',
      }}
      data-long={isLong}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {isLong ? cellContent.slice(0, index - 1) : cellContent}
      {isLong && (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div className="tags-extended-cell" style={{ width: width - 50 }}>
            {cellContent}
          </div>
        </Popover>
      )}
    </div>
  );
}
