import { gql } from '@apollo/client'
import { DeviceType } from 'models/device'

export type DeviceTypeRes = {
  res: {
    content: DeviceType[],
    count: number,
    offset: number,
    pageSize: number,
  },
}
export type DeviceTypeVars = {
  clubId: string,
  serialIds: string[] | null,
  first?: number,
  skip?: number,
  sort?: string,
}
export const DEVICES = gql`
  query Devices(
    $clubId: ID!,
    $serialIds: [ID],
    $first: Int,
    $skip: Int,
    $sort: String,
  ) {
    res: devices(
      clubId: $clubId,
      serialIds: $serialIds,
      first: $first,
      skip: $skip,
      sort: $sort,
    ) {
      content {
        id
        createdOn
        updatedOn
        deviceId
        serialId
        uniqueId
        active
        athlete {
          id
          name
          firstName
          lastName
        }
      }
      count
      offset
      pageSize
    }
  }
`
